/* -------------------------------------------------------
   Start of _toggle-container
   Card set menu component
   ------------------------------------------------------- */
.cui.toggle-container {
	display: none;

	.row-equal-height-cards > [class*="col-"] {
		padding-left: 0;
	}

	.cui.card {
		margin-bottom: $gutter/2;
	}
}

.toggle-btn.cui {
	opacity: 1;
	transform: matrix( 1, 0, 0, 1, 0, 0 );

	.card-body {
		padding-right: 0;
		padding-left: 0;
	}

	.card-cta-text {
		color: $coc-main;
		font-weight: $font-weight-semi-bold;
		font-size: $h3-font-size; //26px
	}

	&:hover {
		@include transform-scale-important;
	}
}

.toggle-modal.cui {
	.modal-header {
		background: transparent;
		border-bottom: none;
	}

	.background-image {
		margin-top: -$basic-space * 63rem; // modal header height
		padding-bottom: $toggle-container-img-ratio-lg;
		z-index: -1;
	}
}

.toggle-container-menu-list {
	margin-bottom: $spacer-sm;
	position: relative;

	ul {
		border-radius: $default-border-radius;
		width: 100%;
		padding: $spacer-sm;
		@include listUnstyled();
	}

	li {
		margin-bottom: 0;
	}

	a {
		@include flexbox();
		@include justify-content(nomal);
		width: 100%;
		border-bottom-width: $border-thin;
		border-color: $border-color-dark;
		border-style: solid;
		text-decoration: none;
		padding: $spacer-sm 5% $spacer-sm $spacer-xs;

		&:hover {
			background-color: $bg-medium;
			text-decoration: none;
		}

		&::after {
			position: absolute;
			right: 2%;
			@include align-self(center);
			content: '\e15a';// .cicon-angle-right
			font-family: $font-icon;
			font-size: $cicon-size-xs;
			padding-left: $spacer-xs;
			display: block;
		}
	}

	[class*="cicon-"] {
		margin-right: $spacer-sm;
		margin-top: $tiny-spacer-xs;
	}
}

@media( min-width: $breakpoint-sm ) {
	.toggle-modal.cui {
		.background-image {
			padding-bottom: $toggle-container-img-ratio-md;
		}
	}
}

@media( min-width: $breakpoint-md ) {
	.cui.toggle-container {
		@include flexbox;

		.content-body {
			$col-size: 12;
			@include calcColWidth( $col-size );

			.row-equal-height-cards {
				margin-right: -$gutter/2;
			}
		}

		.content-media {
			display: none;
		}
	}

	.toggle-btn.cui {
		display: none;
	}

	.toggle-modal.cui {
		.background-image {
			margin-top: -$basic-space * 75rem; // modal header height
			padding-bottom: $toggle-container-img-ratio-sm;
		}
	}
}

@media( min-width: $breakpoint-lg ) {
	.cui.toggle-container {
		.content-body {
			$col-size: 8;
			@include calcColWidth( $col-size );


			.row-equal-height-cards {
				margin-right: 0;
			}
		}

		.content-media {
			display: block;
			width: calc( 33.33333% - #{$gutter} );
			-webkit-box-flex: 0 0 calc( 33.33333% - #{$gutter} );
			-webkit-flex: 0 0 calc( 33.33333% - #{$gutter} );
			-moz-flex: 0 0 calc( 33.33333% - #{$gutter} );
			-ms-flex: 0 0 calc( 33.33333% - #{$gutter} );
			flex: 0 0 calc( 33.33333% - #{$gutter} );
			max-width: calc( 33.33333% - #{$gutter} );
			margin-left: $gutter;
		}
	}
}
