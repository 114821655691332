/* -------------------------------------------------------
   Start of _tables
   ------------------------------------------------------- */

table.cui {
	background-color: $white;
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
	//word-break: break-word;
	text-align: left;

	thead th {
		color: $coc-dark-text-color;
	}

	tbody {
		th[scope="row"] {
			font-weight: $font-weight-semi-bold;
		}
	}

	td,
	th {
		padding: $tiny-spacer-sm $spacer-xs;
	}

	caption {
		@include tableCaption;
	}

	// text alignment options
	// The horizontal alignment is also available by adding the class "text-right" and "text-center"
	&.vertical-top {
		th,
		td {
			vertical-align: top;
		}
	}

	&.vertical-center {
		th,
		td {
			vertical-align: center;
		}
	}

	&.vertical-bottom {
		th,
		td {
			vertical-align: bottom;
		}
	}

	&.heading-large {
		thead th {
			font-size: $large-font-size;
		}
	}

	// Shown in stacked view on mobile screens
	.table-label {
		display: none;
		font-weight: $font-weight-bold;
	}

	// MC.2018.11.20: For the context of the table, the .bg-medium
	// util class will be one level darker than the normal .bg-medium value.
	// In addition, the border color is darkened as the table border color
	// is the same as the new .bg-medium color.
	// Apply this to any element in the table, like <tbody>, <tr>, etc.
	// MC.2019.03.05: This is probably not necessary anymore. This was a stylistic decision made
	// at the time but this same look is now achievable with .bg-dark and .border-dark.
	.bg-medium {
		background-color: $coc-secondary-8L !important;

		td,
		th {
			border-color: $coc-secondary-7L !important;
		}
	}

	// Add this to a <tr> to provide additional vertical padding and emphasis
	.tr-emphasis {
		td,
		th {
			padding-top: $spacer-sm;
			padding-bottom: $spacer-sm;
		}
	}

	&.bordered {
		td,
		th {
			border: $border-thin solid $table-border-color;
		}

		// Add this to the <tr> to provide styling when the row acts as the sub-total row
		// Designed to visually indicate the values above are added together to create the sub-total
		.tr-sub-total {
			td,
			th {
				border-top-color: $coc-secondary-2D !important;
				border-top-width: $basic-space*3rem; //3px
			}
		}
	}

	&.striped {
		thead tr,
		tr:nth-child(even) {
			background-color: $coc-secondary-10L;
		}

		thead tr {
			border-top: $border-thin solid $table-border-color;
		}

		tr {
			border-bottom: $border-thin solid $table-border-color;
		}
	}

	&.dark-header {
		thead {
			tr {
				background-color: $bg-dark;
			}
		}

		&.bordered {
			thead {
				th {
					border-color: $border-color-dark;
				}
			}
		}
	}

	// -------------------------------------------------------
	// Stacked responsive view
	//
	// -------------------------------------------------------

	// Stacked view for all screen sizes
	&.table-mobile-version {
		@include tableStacked();
	}

	// Stacked view for mobile-sized screens only
	@media( max-width: $breakpoint-sm-max ) {
		// All tables are affected except those with the `normal-view` class
		&:not(.normal-view) {
			@include tableStacked();
		}// End of &:not( .normal-view )
	}

	// -------------------------------------------------------
	// Stacked responsive view
	//
	// -------------------------------------------------------

	@media print {
		&.dark-header {
			thead {
				tr {
					background-color: transparent;
				}

				th {
					font-weight: $font-weight-bold;
				}
			}
		}
	}
}// End of table.cui

// -------------------------------------------------------
// Normal responsive view
//
// -------------------------------------------------------

.table-responsive-wrapper {
	// This is a parent wrapper needed to contain the overlays
	// AND the .table-responsive-* elements
	position: relative;

	// Adds the faded edge effect to the table
	.overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		width: $spacer-lg;

		&.left {
			left: 0;
			@include gradient-x( $table-gradient-color-opaque, $table-gradient-color-transparent );
		}

		&.right {
			right: 0;
			@include gradient-x( $table-gradient-color-transparent, $table-gradient-color-opaque );
		}
	}
}

.table-responsive {
	@include tableResponsive();
}

// The following breakpoint names are meant to match Bootsrap.
// The names are out of sync with PL's breakpoint variables.
// These classes set the .table-responsive-* wrapper to overflow
// horizontally UP to the specified breakpoint. For screen sizes
// above the specified breakpoint, the table will display as normal.
// Functionality is the same as BS, with the addition of a min-width
// applied to the table.
@media( max-width: $breakpoint-xs-max ) {
	// 575px
	.table-responsive-sm {
		@include tableResponsive( $breakpoint-xs-max );
	}
}

@media( max-width: $breakpoint-sm-max ) {
	// 767px
	.table-responsive-md {
		@include tableResponsive( $breakpoint-sm-max );
	}
}

@media( max-width: $breakpoint-md-max ) {
	// 990px
	.table-responsive-lg {
		@include tableResponsive( $breakpoint-md-max );
	}
}

@media( max-width: $breakpoint-lg-max ) {
	// 1199px
	.table-responsive-xl {
		@include tableResponsive( $breakpoint-lg-max );
	}
}

.table-responsive-msg {
	margin-bottom: $spacer-xxs;
}
