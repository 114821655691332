/* -------------------------------------------------------
   Start of _tabs
   ------------------------------------------------------- */

// Accessibility update for tabs, visual for focused content
[role="tabpanel"]:focus {
	box-shadow: $focus-box-shadow;
	outline: 0;
	position: relative;
	z-index: 2;
}

.cui.tab-container {
	.tab-nav {
		@include listUnstyled();
		display: none;
	}

	// .title-bar and .cui.collapsible are only shown in mobile as the tabs convert to accordions.
	// These styles are the exact same as the accordions.
	.title-bar {
		@include accordion-title-bar;
	}

	.cui.collapsible {
		@include accordion-content;
	}

	@media( min-width: $breakpoint-md ) {
		.tab-nav {
			@include flexbox();
			@include flex-wrap(wrap);

			li {
				margin-bottom: -$acctab-border-width;
			}

			li + li {
				margin-left: -$acctab-border-width;
			}

			button {
				background: $acctab-link-bg;
				border: $acctab-border-width solid $acctab-border-color;
				color: $coc-default-color;
				display: block;
				max-width: $acctab-button-max-width-xs;
				overflow: hidden;
				padding: $spacer-xxs $spacer-xs;
				text-decoration: none;
				text-overflow: ellipsis;
				transition-property: background;
				white-space: nowrap;

				&:hover {
					background-color: $acctab-link-hover-bg;
				}

				&:focus {
					background-color: $acctab-link-hover-bg;
				}
			}

			button[aria-selected="true"] {
				background: $acctab-link-focus-bg;
				border-top-color: $coc-accent-color;
				border-bottom-color: $white;
				color: $coc-main;
				position: relative;
				z-index: $focus-z;
			}

			// Character limits based on the number of tabs
			// 2 tabs - 30 chars
			li:nth-last-child(2) button[role="tab"],
			li:nth-last-child(2) ~ li button[role="tab"] {
				max-width: $acctab-button-max-width-xl;
			}

			// 3 tabs - 20 chars
			li:nth-last-child(3) button[role="tab"],
			li:nth-last-child(3) ~ li button[role="tab"] {
				max-width: $acctab-button-max-width-lg;
			}

			// 4 tabs - 15 chars
			li:nth-last-child(4) button[role="tab"],
			li:nth-last-child(4) ~ li button[role="tab"] {
				max-width: $acctab-button-max-width-md;
			}

			// 5 tabs - 12 chars
			li:nth-last-child(5) button[role="tab"],
			li:nth-last-child(5) ~ li button[role="tab"] {
				max-width: $acctab-button-max-width-sm;
			}

			// 6 tabs - 10 chars
			li:nth-last-child(6) button[role="tab"],
			li:nth-last-child(6) ~ li button[role="tab"] {
				max-width: $acctab-button-max-width-xs;
			}
		}// End of .tab-nav


		// -------------------------------------------------------
		// No JS
		//
		// -------------------------------------------------------

		// Add margins for when JS is not enabled.
		.tab-nav,
		.title-bar {
			margin-bottom: $spacer-sm;
		}

		.tab-panel {
			margin-bottom: $spacer-lg;
		}


		// -------------------------------------------------------
		// With JS
		//
		// -------------------------------------------------------

		// Reset the styles when JS IS enabled (JS adds these roles to the HTML).
		.tab-nav[role="tablist"],
		.tab-panel[role="tabpanel"] {
			margin-bottom: 0;
		}

		.tab-panel[role="tabpanel"] {
			border: $acctab-border-width solid $acctab-border-color;
			padding: $spacer-sm;

			&[aria-hidden="true"] {
				display: none;
			}
		}

		.tab-nav[role="tablist"] + .tab-content .title-bar {
			display: none;
		}
	}// End of min-width: $breakpoint-md


	@media( min-width: $breakpoint-lg ) {
		.tab-nav {
			button {
				// Increase horizontal padding at larger screens
				padding-left: $spacer-sm;
				padding-right: $spacer-sm;
			}
		}
	}

	// -------------------------------------------------------
	// Print
	//
	// -------------------------------------------------------

	@media print {
		.tab-nav {
			display: none;
		}

		.title-bar,
		.tab-panel {
			display: block !important;
		}

		.title-bar {
			margin-bottom: $spacer-sm;
		}

		.tab-panel[role="tabpanel"] {
			border: 0;
			padding: 0;
		}
	}
}// End of .cui.tab-container
