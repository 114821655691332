/* -------------------------------------------------------
   Start of _adaptable-hero-banner.scss
   ------------------------------------------------------- */
.adaptable-hero-banner.cui {
	width: 100%;
	//border: 1px solid red;

	.comp-media {
		.background-image {
			height: $adaptable-banner-height-small-mobile;
		}
	}

	.comp-body {
		width: 100%;
		padding: $adaptable-banner-comp-body-padding-mobile;

		.title {
			font-size: $adaptable-banner-title-sm;
			margin-bottom: $spacer-xxs;
			font-weight: 900;
			line-height: 1.2;
		}

		p {
			margin-bottom: $spacer-xl;
			max-width: none;
		}

		[class*="btn-"] {
			width: 100%;

			&.primary {
				margin-bottom: $spacer-sm;
			}
		}
	}

	&.g-dark,
	&.g-light {
		.comp-media {
			position: relative;

			&::after {
				content: ""; // ::before and ::after both require content
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: $adaptable-banner-height-small-mobile;
			}
		}

		.comp-body {
			.content-container {

			}
			// max-width: none;
		}
	}

	&.g-dark {
		.comp-media {
			&::after {
				background-image: linear-gradient( 0deg, rgba( 0, 0, 0, 1 ) 20%, rgba( 255, 255, 255, 0 ) 80% );
			}
		}

		.comp-body {
			background: $black;
			color: $white;
		}
	}

	&.g-light {
		.comp-media {
			&::after {
				background-image: linear-gradient( 0deg, rgba( 255, 255, 255, 1 ) 20%, rgba( 255, 255, 255, 0 ) 80% );
			}
		}

		.comp-body {
			color: $black;
			background-color: $white;
		}
	}

	// the text will be align to left
	&.text-right,
	&.text-center {
		text-align: left !important;
	}

	@media only screen and ( min-width: $breakpoint-sm ) {
		.comp-media {
			.background-image {
				height: $adaptable-banner-height-mobile;
			}
		}

		.comp-body {
			.title {
				font-size: $adaptable-banner-title-md;
			}
		}

		&.g-dark,
		&.g-light {
			.comp-media {
				&::after {
					height: $adaptable-banner-height-mobile;
				}
			}
		}
	}

	@media only screen and ( max-width: $breakpoint-md-max ) {
		background-image: none !important;
	}

	@media only screen and ( min-width: $breakpoint-lg ) {
		@include flexbox;
		@include align-items(center);

		.comp-media {
			.background-image.mobile {
				display: none;
			}
		}

		.comp-body {
			display: flex;
			// max-width: $breakpoint-lg;
			margin: auto;
			padding: $adaptable-banner-comp-body-padding;

			.content-container {
				display: flex;
				width: 100%;
				max-width: $breakpoint-lg;
				margin: auto;
			}

			.title {
				font-size: $adaptable-banner-title-lg;
			}

			[class*="btn-"] {
				width: inherit;

				&.primary,
				&.utility-btn-solid,
				&.secondary-text {
					margin-bottom: $spacer-sm;
					margin-right: $spacer-md;
				}
			}

			.content {
				$col-size: 6;
				@include calcColWidth($col-size);
			}
		}

		&.content-right {
			.comp-body .content-container {
				@include flex-flow (row-reverse);
			}

			&.g-dark {
				.comp-body {
					background: linear-gradient( -90deg, rgba( 0, 0, 0, .8 ) 50%, rgba( 255, 255, 255, 0 ) 80% );
				}
			}

			&.g-light {
				.comp-body {
					background: linear-gradient( -90deg, rgba( 255, 255, 255, .8 ) 50%, rgba( 255, 255, 255, 0 ) 80% );
				}
			}

			&.half-half {
				@include flex-direction(row);
			}
		}

		&.full-width-text {
			.comp-body {
				@include align-items(center);

				.content {
					$col-size: 12;
					@include calcColWidth($col-size);
					// .title,
					// p {
					// 	text-shadow: 3px 3px 3px $white, -3px 2px 6px $white, 2px 0 0 $white, 0 0 0 $white, 0 0 0 $white;
					// }
				}
			}
		}

		&.sm {
			min-height: $adaptable-banner-height-sm;

			.comp-body {
				min-height: $adaptable-banner-height-sm;
			}
		}

		&.md {
			min-height: $adaptable-banner-height-md;

			.comp-body {
				min-height: $adaptable-banner-height-md;
			}
		}

		&.lg {
			min-height: $adaptable-banner-height-lg;

			.comp-body {
				min-height: $adaptable-banner-height-lg;
			}
		}

		&.half-half {
			display: block;
			background-image: none !important;

			.comp-media {
				.background-image {
					display: block;
				}
			}

			.comp-body {
				.content {
					$col-size: 12;
					@include calcColWidth($col-size);
				}
			}
		}


		&.g-dark,
		&.g-light {
			.comp-meida::after {
				display: none;
			}

			&.full-width-text {
				.comp-body {
					background: rgba( 0, 0, 0, .8 );
				}
			}
		}

		&.g-dark {
			.comp-body {
				background: linear-gradient( 90deg, rgba( 0, 0, 0, .8 ) 50%, rgba( 255, 255, 255, 0 ) 80% );
			}
		}

		&.g-light {
			.comp-body {
				background: linear-gradient( 90deg, rgba( 255, 255, 255, .8 ) 50%, rgba( 255, 255, 255, 0 ) 80% );
			}
		}

		&.text-center {
			text-align: center !important;
		}

		&.text-right {
			text-align: right !important;
		}
	}

	@media only screen and ( min-width: $breakpoint-xl ) {
		.comp-body {
			.content-container {
				max-width: $breakpoint-xl;
			}
		}

		&.half-half {
			@include flexbox;
			@include flex-direction(row-reverse);
			@include align-items(stretch);

			.comp-media {
				$col-size: 6;
				@include calcColWidth($col-size);

				.background-image.mobile {
					height: 100%;
					display: block;
				}
			}

			.comp-body {
				max-width: none;

				.content {
					$col-size: 12;
					@include calcColWidth($col-size);

					.title {
						font-size: $adaptable-banner-title-md;
					}
				}
			}
		}
	}
}
