/* -------------------------------------------------------
   Start of _block-banner
   PL1.9 Rename the pattern from 'Grid banner' to 'Block banner'
   ------------------------------------------------------- */

.cui.b-grid-section {
	p {
		margin-bottom: $spacer-xxs;
	}

	.b-grid-item {
		border: $border-thin solid $acctab-border-color;
		margin-bottom: $tiny-spacer-xs;// Spacing for mobile
		position: relative;
	}

	.b-grid-item-title {
		font-size: $base-font-size;
		margin-bottom: $tiny-spacer-sm;

		@media( min-width: $breakpoint-xl ) {
			font-size: $large-font-size;
		}
	}

	.b-grid-item-content {
		padding: $spacer-sm;
	}

	.main-container {
		.b-grid-item-title {
			font-weight: $font-weight-semi-bold;
			font-size: $larger-font-size;
			line-height: $line-height-compact;
			word-wrap: break-word;

			@media( min-width: $breakpoint-lg ) {
				font-size: $grid-banner-font-size-lg;
			}
		}
	}

	.side-container {
		.b-grid-item-title {
			line-height: $line-height-default;
			font-size: $base-font-size;
		}

		.b-grid-item-content {
			padding: $tiny-spacer-sm $spacer-xxs;
		}
	}

	.background-image {
		padding-bottom: $ratio-percent-16x9;
	}

	.video-container {
		@include video-container();
	}

	.b-grid-item-content.coc-white-opacity {
		border: $border-width-md solid $acctab-border-color;
	}

	@media( max-width: $breakpoint-sm-max ) {
		// Solid opacity background colours for smaller screens.
		// Allows for better readability as the content will not be overlaid on
		// top of the image, the content is just overlaid onto white.
		.coc-white-opacity {
			background-color: $coc-secondary-10L;
		}

		.coc-black-opacity {
			background-color: $black;
		}

		.side-container {
			.b-grid-item-content {
				padding: $spacer-sm;
			}
		}
	}

	@media( min-width: $breakpoint-md ) {
		@include renderRow( $use-gutter: true, $gutter: $tiny-spacer-xs );

		.b-grid-item {
			margin-bottom: 0;// Reset the margin as the spacing will be provided via a border.
		}

		.b-grid-item-content {
			position: absolute;
		}

		.background-image,
		.video-container {
			// Change the ratio to accomodate the content.
			padding-bottom: 80%;
		}

		.main-container,
		.side-container {
			@include renderColBasics( $gutter: $tiny-spacer-xs );
		}

		.main-container {
			// Must be set to 8, otherwise the 2 grid items in the side column
			// will not fit vertically with the main column.
			@include calcColWidth( 8 );

			.b-grid-item-content {
				bottom: $spacer-sm;
				left: $spacer-sm;
				width: 75%;
			}

			.video-container .video-thumbnail::before {
				// Adjust the postion of the video thumbnail icon.
				background-position-y: 42.5%;
			}
		}

		.side-container {
			// Must be set to 4, otherwise the 2 grid items in the side column
			// will not fit vertically with the main column.
			@include calcColWidth( 4 );
			@include flexbox();
			@include flex-direction (column);
			@include justify-content(space-between);

			.b-grid-item-content {
				bottom: 0;
				width: 100%;
			}

			.video-container .video-thumbnail::before {
				// Adjust the postion of the video thumbnail icon.
				background-position-y: 30%;
			}
		}
	}// End of $breakpoint-md

	@media( min-width: $breakpoint-lg ) {
		.background-image,
		.video-container {
			// Change the ratio to accomodate the content.
			padding-bottom: 62.5%;
		}

		.main-container {
			.background-image,
			.video-container {
				padding-bottom: calc( 62.5% + #{$border-thin} ); //correct the spacing between the containers
			}

			.video-container .video-thumbnail::before {
				// Adjust the postion of the video thumbnail icon.
				background-position-y: 45%;
			}
		}

		.side-container {
			.video-container .video-thumbnail::before {
				// Adjust the postion of the video thumbnail icon.
				background-position-y: 40%;
			}
		}
	}

	@media( min-width: $breakpoint-xl ) {
		.main-container {
			.b-grid-item-content {
				width: 65%;
			}
		}

		.side-container {
			.b-grid-item-content {
				padding: $tiny-spacer-sm $spacer-xxs;
			}
		}
	}

	// Extended version
	&.extended {
		@media( min-width: $breakpoint-lg ) {
			.main-container {
				flex: 0 0 50%;
				-webkit-box-flex: 0 0 50%;
				-webkit-flex: 0 0 50%;
				-moz-flex: 0 0 50%;
				-ms-flex: 0 0 50%;
				max-width: 50%;

				.b-grid-item-content {
					width: 65%;
				}
			}

			.side-container {
				flex: 0 0 50%;
				-webkit-box-flex: 0 0 50%;
				-webkit-flex: 0 0 50%;
				-moz-flex: 0 0 50%;
				-ms-flex: 0 0 50%;
				max-width: 50%;
				@include flex-direction(row);
				@include renderRow( $use-gutter: true, $gutter: $tiny-spacer-xs );

				.b-grid-item {
					flex: 0 0 50%;
					-webkit-box-flex: 0 0 calc( 50% - #{$border-thin * 2} ); // get rid of the border width
					-webkit-flex: 0 0 calc( 50% - #{$border-thin * 2} );
					-moz-flex: 0 0 calc( 50% - #{$border-thin * 2} );
					-ms-flex: 0 0 calc( 50% - #{$border-thin * 2} );
					max-width: calc( 50% - #{$border-thin * 2} );
					margin-right: 2px;

					@include renderColBasics( $gutter: 0 );
				}

				.b-grid-item-content {
					width: auto;
					right: $spacer-sm;
					bottom: $spacer-sm;
					left: $spacer-sm;
					padding: $spacer-xs $spacer-sm;
				}
			}

			.background-image,
			.video-container {
				// Lock down the image height to avoid the the banner getting too big on the large screen
				padding-bottom: $grid-banner-video-container-height;
			}
		}
	}// End of &.extended

	// -------------------------------------------------------
	// Print
	//
	// -------------------------------------------------------

	@media print {
		.background-image,
		.video-container {
			display: none;
		}

		.main-container,
		.side-container {
			@include calcColWidth( 12 );
		}

		.b-grid-item-content {
			margin: 0 0 $spacer-lg 0 !important;
			padding: 0 !important;
			position: relative;
			bottom: auto !important;
			left: auto !important;
			width: 100% !important;
		}
	}
}// End of .cui.b-grid-section
