$msv-modal-width: 500px;
$msv-modal-padding: 40px 60px;
$msv-modal-margin: 20px;
$msv-modal-button-padding: 5px;
$msv-modal-vertical-alignment-top: flex-start;
$msv-modal-vertical-alignment-center: center;
$msv-modal-vertical-alignment-bottom: flex-end;
$msv-modal-z-index: 1050;
$msv-modal-backdrop-opacity: 0.4;
$msv-modal-animation-duration: 0.2s;

$msv-size-m: 360px;

@keyframes modal-appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes modal-disappear {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes modal-backdrop-appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: $msv-modal-backdrop-opacity;
    }
}

@keyframes modal-backdrop-disappear {
    0% {
        opacity: $msv-modal-backdrop-opacity;
    }
    100% {
        opacity: 0;
    }
}
.modal-open {
    padding-right: 0 !important;
}
.modal-open {
    .msc-modal {
        overflow-x: hidden;
        overflow-y: auto;
    }
}

/* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
body {
    &.modal-open {
        .modal {
            z-index: 10011;
            overflow-y: auto;
        }
    }
}

.msc-modal {
    ::-webkit-scrollbar {
        background: none;
        width: 8px;
    }

    ::-webkit-scrollbar-thumb {
        background: #d5d5d5;
        width: 8px;
        border-radius: 8px;
    }

    display: none;
    height: 100%;
    left: 0;
    overflow: hidden;
    outline: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $msv-modal-z-index;

    &.show {
        animation: modal-appear $msv-modal-animation-duration;
    }

    &.fade:not(.show) {
        animation: modal-disappear $msv-modal-animation-duration;
        opacity: 0;
    }

    &__dialog {
        align-items: $msv-modal-vertical-alignment-top;
        max-width: $msv-modal-width;
        margin: auto;
        pointer-events: none;
        position: relative;
        width: auto;
        top: 50%;
        transform: translateY(-50%);

        &:focus {
            outline: 0;
        }
        /* stylelint-disable declaration-no-important -- Auto-suppressed. Please fix if possible. */
        input,
        textarea,
        select {
            margin-right: 1px;
            background-color: var(--msv-modal-bg-color) !important;
            border: 1px solid $msv-gray-300 !important;

            &:focus {
                outline: none !important;
            }
        }

        input:disabled,
        textarea:disabled,
        select:disabled {
            background-color: $msv-gray-100 !important;
            border: none !important;
        }
        /* stylelint-enable declaration-no-important -- Auto-suppressed. Please fix if possible. */

        @media (min-width: map-get($grid-breakpoints, 'md')) {
            max-width: 866px;
        }
    }

    &__content {
        background-clip: padding-box;
        background-color: var(--msv-modal-bg-color);
        border: 1px solid $msv-gray-500;
        color: var(--msv-modal-font-color);
        display: flex;
        flex-direction: column;
        outline: 0;
        pointer-events: auto;
        position: relative;
        width: 100%;
        padding: 0;
    }

    &__header {
        background: $modal-header-bg-color;
        border-bottom: $modal-header-border;
        @include flexbox();
        @include flex-shrink(0); // Do not allow the element to shrink
        padding: $modal-vert-padding $modal-horz-padding;
        .msc-modal__title {
            @include align-self(center);
            line-height: $line-height-compact;
            margin-right: $spacer-xs; // Ensure the title doesn't touch the close button
            margin-bottom: 0;
            font-size: $h2-font-size;
            @media (min-width: $breakpoint-sm) {
                font-size: $h2-font-size-xs;
            }

            @media (min-width: $breakpoint-md) {
                font-size: $h2-font-size-sm;
            }

            @media (min-width: $breakpoint-lg) {
                font-size: $h2-font-size-md;
            }
            [class*='cicon-'] {
                font-size: 80%;
                padding-right: $spacer-xxs;
            }
        }

        .msc-modal__close-button {
            position: absolute;
            right: 10px;
            top: 11px;
            text-decoration: none;
            font-size: 1rem;
            background-color: #fff;
            padding: 8px 5px 8px 10px;
            border-radius: 3px;
            height: auto;
            border: none;
            font-weight: 600;
            cursor: pointer;
            &::before {
                content: 'Close';
                color: $msv-red;
                text-decoration: underline;
            }
            &::after {
                @include cui-icon();
                content: '\e039';
                margin: 0 8px;
                line-height: 1.7;
                color: $msv-red;
                font-size: 0.8rem;
                text-decoration: underline;
            }
            &:hover {
                cursor: pointer;
                color: $msv-red;
            }
        }
    }

    .msc-fullview-modal {
        @media (min-width: map-get($grid-breakpoints, 'md')) {
            max-width: 100%;
            max-height: 772px;
            margin: 40px;
        }

        .msc-modal {
            &__content {
                padding: 0;
                background-color: $msv-white;
                background-clip: border-box;
            }

            &__close-button {
                right: 10px;
                top: 15px;
            }
        }

        .msc-fullview-modal-body.msc-modal__body {
            margin-top: 20px;
        }

        .msc-carousel__item {
            img {
                display: block;
                max-height: 600px;

                @media (max-width: $msv-breakpoint-m) {
                    min-height: 0;
                }
            }
        }
    }

    &__body {
        margin-bottom: $msv-modal-margin;
        overflow: overlay;
        max-height: 600px;
        padding: $modal-horz-padding-md;
    }

    &__footer {
        background: $modal-header-bg-color;
        border-top: $modal-header-border;
        display: flex; // BS override
        @include flex-shrink(0); // Do not allow the element to shrink
        justify-content: flex-end;
        button {
            margin-left: 5px;
        }
        p {
            margin-bottom: $spacer-xs;

            &:last-child {
                margin-bottom: 0;
            }
        }
        @media (min-width: $breakpoint-lg) {
            padding: $modal-vert-padding-md $modal-horz-padding-md;
        }
    }

    &__backdrop {
        background-color: $msv-black;
        height: 100vh;
        left: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: $msv-modal-z-index - 10;

        &.show {
            animation: modal-backdrop-appear $msv-modal-animation-duration;
            opacity: $msv-modal-backdrop-opacity;
        }

        &.fade:not(.show) {
            animation: modal-backdrop-appear $msv-modal-animation-duration;
            opacity: 0;
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .msc-modal__dialog:not(.msc-modal-input-required) {
        margin: 0;
        height: 100%;
        .msc-modal__content {
            padding-left: 20px;
            padding-right: 20px;
        }
        &.msc-search-result-modal {
            .msc-modal__content {
                padding: 0;
                height: 100%;
            }
        }
    }

    .msc-modal__dialog.msc-modal-input-required {
        height: 100%;
        max-width: none;

        .msc-modal__content {
            height: 100%;
            padding: 0;

            .msc-modal__header {
                height: 48px;
                border-bottom: 1px solid $msv-gray-300;
                padding: 9px 20px;
                margin-bottom: 0;
                flex: 0 0 auto;

                .msc-modal__title {
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: bold;
                    width: 100%;
                    text-align: center;
                }

                .msc-modal__close-button {
                    top: 12px;
                }
            }
        }

        .msc-modal__body {
            padding: 20px 20px;
            flex: 1 1 auto;
            margin-bottom: 0;
        }

        .msc-modal__footer {
            border-top: 1px solid $msv-gray-300;
            padding: 9px 20px;
            width: 100%;
            flex: 0 0 auto;
            margin-top: 0;

            button {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 12px;
            }
        }
    }
}
.modal {
    .coc-modal,
    .cui {
        &.modal-dialog {
            max-width: 800px;
            .modal-content {
                border-radius: 0;
            }
        }
        &.modal-xl {
            &.modal-dialog {
                width: 850px;
                max-width: 850px;
            }
            .modal-content {
                .modal-body {
                    max-height: none;
                }
            }
        }
        div[class^='col'] {
            padding-left: 10px;
            padding-right: 10px;
        }
        .row {
            margin-left: -10px;
            margin-right: -10px;
        }
        .modal-content {
            .spinner-wrap {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, 0.2);
                border-radius: 0px;
                margin: 0;
                .spinner-box {
                    justify-content: center;
                    display: flex;
                    flex-flow: column;
                    p {
                        font-size: 1rem;
                        padding-top: 0;
                        font-weight: 600;
                    }
                }
            }
            .modal-header {
                background: $msv-gray-200;
                border-bottom: 0.0625rem solid $msv-gray-300;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-shrink: 0;
                -moz-flex-shrink: 0;
                flex-shrink: 0;
                .modal-title {
                    -webkit-align-self: center;
                    -ms-flex-item-align: center;
                    align-self: center;
                    line-height: 1.4;
                    margin-right: 90px;
                    margin-bottom: 0;
                    color: $msv-black;
                    font-weight: 400;
                    font-size: 2rem;
                }
            }
            .modal-footer {
                background: $msv-gray-200;
                border-top: 0.0625rem solid $msv-gray-300;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-shrink: 0;
                -moz-flex-shrink: 0;
                flex-shrink: 0;
                .btn-box {
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    &.two-btn {
                        justify-content: space-between;
                    }
                }
                > p {
                    display: flex;
                    justify-content: left;
                    width: 100%;
                    font-size: 1rem;
                    margin-bottom: 15px;
                }
            }
            .modal-body {
                padding: 1.6875rem;
                &.search-modal-body {
                    overflow-y: visible;
                }
                .b-border-box {
                    border-bottom: 1px solid $msv-gray-300;
                    padding-bottom: 25px;
                    margin-bottom: 25px;
                }
                h6 {
                    font-size: 1.2rem;
                    margin-bottom: 15px;
                    font-weight: 600;
                    line-height: 1.8rem;
                    small {
                        display: block;
                    }
                }
                .form-group {
                    input[disabled] {
                        color: $msv-gray-600;
                        background-color: $msv-gray-100;
                    }
                    select[disabled] {
                        color: $msv-gray-1000;
                        background-color: $msv-gray-100;
                    }
                    textarea {
                        &.form-field {
                            height: 160px;
                        }
                    }
                }
                .property-search-wrap {
                    .form-group {
                        textarea {
                            &.form-field {
                                height: 160px;
                                &.anticipate-issue {
                                    height: 80px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.cui-search {
            .modal-body {
                overflow: visible;
                .ms-search {
                    position: relative;
                    &__autoSuggest {
                        left: 0;
                    }
                }
            }
        }
        &.md-modal {
            &.modal-dialog {
                max-width: 600px;
            }
        }
        &.modal-sm {
            .modal-content {
                font-weight: 400;
                border: none;
                font-size: 1rem;
            }
        }

        @media screen and (max-width: $msv-breakpoint-sm) {
            &.modal-dialog {
                max-width: 100% !important;
                height: 100%;
                margin: 0;
                .modal-content {
                    width: 100%;
                    height: 100%;
                    .modal-body {
                        max-height: 90vh;
                    }
                }
                &.modal-sm {
                    height: auto;
                }
            }
        }
        table {
            margin-bottom: 20px;
            width: 100%;
            td,
            th {
                padding: 5px 20px;
                &:first-child {
                    width: 45px;
                    padding-right: 0;
                }
            }
        }
        table {
            th {
                background-color: $msv-gray-100;
                padding: 10px 20px;
            }
        }
    }

    .close-btn {
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 1rem;
        background-color: $msv-gray-50;
        padding: 8px 5px 8px 10px;
        border-radius: 3px;
        text-decoration: underline;
        border: none;
        color: $msv-primary;
        &::after {
            @include cui-icon();
            content: '\e039';
            color: $msv-gray-900;
            margin: 0 8px;
            line-height: 1.7;
            color: $msv-primary;
            font-size: 0.8rem;
            text-decoration: underline;
        }
        &:focus {
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            outline: none;
        }
        &:hover {
            cursor: pointer;
            color: $msv-red;
            background-color: $msv-gray-200;
        }
    }
    .ms-header__nav-icon {
        position: absolute;
        right: 10px;
        top: 11px;
        text-decoration: none;
        font-size: 1rem;
        background-color: #fff;
        padding: 8px 5px 8px 10px;
        border-radius: 3px;
        height: auto;
        border: 2px solid #eee;
        &::before {
            content: 'Close';
            color: $msv-red;
            text-decoration: underline;
        }
        &::after {
            @include cui-icon();
            content: '\e039';
            margin: 0 8px;
            line-height: 1.7;
            color: $msv-red;
            font-size: 0.8rem;
            text-decoration: underline;
        }
        &:hover {
            cursor: pointer;
            color: $msv-red;
        }
        @include cui-focus();
    }
}
