$msc-account-welcome-title-margin-bottom: 40px;
$msc-account-welcome-title-margin-top: 32px;
$msc-account-welcome-title-border-bottom-color: $msv-gray-500;
$msc-account-welcome-title-border-bottom-style: solid;
$msc-account-welcome-title-border-bottom-thickness: 1px;
$msc-account-welcome-title-links-padding-top: 12px;
$msc-account-tile-heading-icon-padding: 5px;
$msc-generic-tile-heading-margin-bottom: 0.5rem;
$msc-link-padding-right: 12px;

// style presets
:root {
    --msv-account-landing-title-font-color: var(--msv-font-primary-color);

    // title
    --msv-account-landing-title-font-size: var(--msv-body-font-size-xl);
    --msv-account-landing-title-desktop-font-size: var(--msv-body-font-size-xxl);

    // tiles
    --msv-account-landing-tile-heading-size: var(--msv-body-font-size-l);
    --msv-account-landing-tile-heading-color: var(--msv-font-primary-color);
    --msv-account-landing-tile-text-size: var(--msv-body-font-size-m);
    --msv-account-landing-tile-text-color: var(--msv-font-primary-color);

    // link
    --msv-account-landing-link-color: #{$msv-link-color};
    --msv-account-landing-link-size: var(--msv-body-font-size-m);
}
body {
    .container {
        &.profile-tile {
            padding: 0 5px;
        }
    }
}
.ms-account {
    &-welcome-tile {
        border-bottom: $msc-account-welcome-title-border-bottom-thickness $msc-account-welcome-title-border-bottom-style
            $msc-account-welcome-title-border-bottom-color;
        color: var(--msv-account-landing-title-font-color);
        display: block;
        margin-bottom: $msc-account-welcome-title-margin-bottom;
        margin-top: $msc-account-welcome-title-margin-top;

        &__heading {
            @include font-content(var(--msv-font-weight-normal), var(--msv-account-landing-title-font-size), $msv-line-height-xl);
        }

        @media (min-width: $msv-breakpoint-m) {
            &__heading {
                @include font-content(
                    var(--msv-font-weight-normal),
                    var(--msv-account-landing-title-desktop-font-size),
                    $msv-line-height-xxl
                );
            }
        }
    }

    &-generic-tile {
        &__link {
            @include vfi();
            color: var(--msv-account-landing-link-color);
        }

        &.order-tile {
            .ms-account-generic-tile__heading {
                @include add-icon($msv-DeliveryTruck, before);

                &::before {
                    padding-right: $msc-account-tile-heading-icon-padding;
                }
            }
        }

        &.profile-tile {
            .ms-account-generic-tile__heading {
                @include add-icon($msv-Contact, before);

                &::before {
                    padding-right: $msc-account-tile-heading-icon-padding;
                }
            }
        }
    }

    &-wishlist-tile__heading {
        @include add-icon($msv-HeartFill, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-order-templates-tile__heading {
        @include add-icon($msv-file, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-address-tile__heading {
        &::before {
            @include cui-icon($icon-weight: $cui-solid-icon-weight);
            padding-right: $msc-account-tile-heading-icon-padding;
            content: '\e0aa';
        }
    }

    &-organization-users-tile__heading {
        @include add-icon($msv-OrganizationUsers, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-customer-balance-tile__heading {
        @include add-icon($msv-StoreAlt, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-invoice-tile__heading {
        @include add-icon($msv-invoice, before);

        &::before {
            padding-right: $msc-account-tile-heading-icon-padding;
        }
    }

    &-generic-tile,
    &-wishlist-tile,
    &-order-templates-tile,
    &-address-tile,
    &-loyalty-tile,
    &-organization-users-tile,
    &-customer-balance-tile,
    &-invoice-tile {
        margin-bottom: $msc-account-welcome-title-margin-bottom;

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        &__heading {
            font-weight: 600;
        }

        &__links {
            @include font-content(var(--msv-font-weight-normal), var(--msv-account-landing-link-size), $msv-line-height-m);
            padding-top: $msc-account-welcome-title-links-padding-top;
        }

        &__link {
            @include vfi();
            color: var(--msv-account-landing-link-color);
            padding-right: $msc-link-padding-right;
        }

        &__link-button {
            @include vfi();
            color: var(--msv-account-landing-link-color);
            cursor: pointer;
        }

        &__description {
            @include font-content(var(--msv-font-weight-normal), var(--msv-account-landing-tile-text-size), $msv-line-height-m);
            color: var(--msv-account-landing-tile-text-color);
        }

        &__value {
            @include font-content(var(--msv-font-weight-bold), var(--msv-account-landing-tile-text-size), $msv-line-height-m);
            color: var(--msv-account-landing-tile-text-color);

            &::before,
            &::after {
                content: ' ';
            }
        }
    }
}
.cui-heading {
    .heading.container-heading {
        font-size: 2.6rem;
        line-height: 3.4rem;
        margin: 20px 0 40px;
        font-weight: 600;
    }
}
.profile-tile {
    div[class^='col'] {
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
        > div {
            border: 1px solid $msv-gray-300;
            padding: 35px 30px;
            border-radius: 4px;
            height: 100%;
            .ms-account-generic-tile__heading,
            .ms-account-wishlist-tile__heading,
            .ms-account-order-templates-tile__heading,
            .ms-account-address-tile__heading,
            .ms-account-loyalty-tile__heading,
            .ms-account-organization-users-tile__heading,
            .ms-account-customer-balance-tile__heading,
            .ms-account-invoice-tile__heading {
                padding-top: 60px;
                position: relative;
                font-size: 2rem;
                line-height: 2.6rem;
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 2rem;
                }
            }
            > p {
                display: none;
            }
            > div {
                > a {
                    @include button-utility();
                    line-height: 1rem;
                    font-weight: 600;
                    text-decoration: none;
                }
            }
            &.order-history-tile {
                .ms-account-generic-tile__heading {
                    &::before {
                        @include cui-icon($icon-weight: $cui-solid-icon-weight);
                        content: '\e10f';
                    }
                }
            }
            &.profile-tile {
                .ms-account-generic-tile__heading {
                    &::before {
                        @include cui-icon($icon-weight: $cui-solid-icon-weight);
                        content: '\e027';
                    }
                }
            }
        }
    }
}
