/* -------------------------------------------------------
   Start of _accordions
   ------------------------------------------------------- */

/**
 * Based on the ARIA works by Heydon Pickering
 * http://heydonworks.com/practical_aria_examples/
 * 3. Progressive collapsibles
 */

.cui.accordion {
	.title-bar {
		@include accordion-title-bar;
	}

	.cui.collapsible {
		@include accordion-content;
	}

	&.accordion-single {
		.title-bar {
			button {
				background-color: $coc-secondary;
				border: 0;
				color: $coc-secondary-10L;
				font-weight: bold;
				padding: $spacer-sm;
				position: relative;

				&:hover {
					background-color: $coc-secondary-1L;
				}

				&::before {
					color: $white;
					content: '\e158';// .cicon-angle-down
					margin-right: 0;
					position: absolute;
					right: $spacer-xxs;
				}

				&[aria-expanded="true"] {
					&::before {
						content: '\e15b';// .cicon-angle-up
					}
				}
			}
		}// End of .title-bar

		.cui.collapsible {
			background: $coc-secondary-9L;
			border: 0;
		}

		&.accordion-single-light {
			.title-bar {
				button {
					background-color: $coc-secondary-9L;
					border: $acctab-border-width solid $acctab-border-color;
					color: $coc-default-color;

					&:hover {
						background-color: $coc-secondary-8L;
					}

					&::before {
						color: $coc-default-color;
					}

					&[aria-expanded="true"] {
						background-color: $white;
						border-left-color: $coc-main;
						border-bottom-color: $white;

						&:hover {
							background-color: $coc-secondary-10L;
						}
					}
				}
			}// End of .title-bar

			.cui.collapsible {
				background: $white;
				border: $acctab-border-width solid $acctab-border-color;
				border-top: 0;
			}
		}// End of &.accordion-single-light
	}// End of &.accordion-single
}// End of .cui.accordion
