$buybox-margin-top: 0;
$buybox-padding-left: 0;
$buybox-padding-right: 0;
$buybox-label-margin-bottom: 5px;
$buybox-box-top-margin: 20px;
$buybox-box-bottom-margin: 20px;
$buybox-store-description-margin-bottom: 8px;
$buybox-add-to-cart-extra-actions-margin: 8px;
$buybox-add-to-cart-extra-actions-width: 48px;
$buybox-product-status-padding-top: 18px;
$buybox-quantity-input: 5px;
$buybox-incr-decr: 5px;
$gutter-width: 40px;
$media-gallery-width: 66%;
$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;
$msv-icon-margin-right: 8px;
$buybox-custom-amount-input-width: 80px;
$buybox-custom-amount-input-padding: 5px;
$buybox-thumbnail-list-margin-bottom: 8px;

//style presets
:root {
    --msv-buybox-btn-bg: #{$msv-white};
    --msv-buybox-btn-font-color: var(--msv-font-primary-color);
    --msv-buybox-btn-border-color: var(--msv-border-color);
    --msv-buybox-btn-font-size: var(--msv-body-font-size-l);

    //title
    --msv-buybox-title-font-size: var(--msv-body-font-size-xxl);
    --msv-buybox-title-font-color: #{$cui-gray-primary};

    // rating star
    --msv-buybox-rating-star: var(--msv-font-primary-color);
    --msv-buybox-btn-extra-actions-color: var(--msv-accent-brand-color);

    // wishlist button
    --msv-buybox-btn-wishlist-color: var(--msv-accent-brand-color);

    //text
    --msv-buybox-text-font-size: var(--msv-body-font-size-m);
    --msv-buybox-text-font-color: var(--msv-font-primary-color);

    // select box
    --msv-buybox-select-box-border: #{$msv-whisper};

    // increment button
    --msv-buybox-increment-btn-border: #{$msv-whisper};

    // decrement button
    --msv-buybox-decrement-btn-border: #{$msv-whisper};

    // quantity
    --msv-buybox-quantity-box-border: #{$msv-whisper};
    // primary button
    --msv-buybox-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-buybox-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-buybox-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-buybox-secondary-btn-bg: var(--msv-bg-color);
    --msv-buybox-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-buybox-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-buybox {
    margin-top: $buybox-margin-top;
    padding-left: $buybox-padding-left;
    padding-right: $buybox-padding-right;
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    justify-content: space-between;
    &__content {
        border: 1px solid $msv-gray-400;
        width: 25%;
        flex: 0 0 25%;
        .ms-buybox__product-title {
            background-color: $msv-gray-150;
            border-bottom: 1px solid $msv-gray-400;
            font-size: 1rem;
            line-height: 1.8rem;
            font-weight: 600;
            padding: 15px;
        }
        .msc-price {
            display: flex;
            justify-content: flex-end;
            padding: 15px;
            margin-top: 20px;
            .msc-price__actual {
                font-weight: 600;
            }
        }
        @media (max-width: $msv-breakpoint-l) {
            flex: 0 0 35%;
            width: 35%;
            max-width: 35%;
        }
        @media (max-width: $msv-breakpoint-sm) {
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
        }
    }
    .buybox-left-col {
        flex: 0 0 75%;
        max-width: 75%;
        padding-top: 30px;
        .ms-buybox__product-title {
            font-size: 2rem;
            line-height: 2.8rem;
            margin-bottom: 30px;
            font-weight: 600;
        }
        @media (max-width: $msv-breakpoint-l) {
            flex: 0 0 65%;
            width: 65%;
            max-width: 65%;
            .ms-buybox__content {
                flex: 0 0 65%;
                width: 65%;
                max-width: 65%;
            }
        }
        @media (max-width: $msv-breakpoint-sm) {
            flex: 0 0 100%;
            width: 100%;
            max-width: 100%;
            .ms-buybox__content {
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
            }
        }
    }
    .buybox-product {
        display: flex;
        .ms-buybox__media-gallery {
            flex: 0 0 35%;
            max-width: 35%;
            min-width: 0;
        }
        .buybox-description {
            flex: 0 0 65%;
            max-width: 65%;
            padding-right: 30px;
            p {
                margin-top: 0;
                font-size: 1.2rem;
                line-height: 1.8rem;
                font-weight: 400;
                position: relative;
                top: -5px;
                margin-bottom: 15px;
            }
            ul {
                padding-left: 0;
                li {
                    margin-bottom: 5px;
                    font-weight: 600;
                    font-size: 1.2rem;
                }
            }
        }
        @media (max-width: $msv-breakpoint-m) {
            flex-flow: column;
            .ms-buybox__media-gallery,
            .buybox-description {
                flex: 0 0 100%;
                width: 100%;
                max-width: 100%;
                position: relative;
                padding: 0;
                .ms-media-gallery {
                    padding-top: 0;
                    padding-bottom: 20px;
                    padding-right: 30px;
                    padding-left: 30px;
                    &__carousel {
                        position: relative;
                    }
                }
            }
        }
    }
    &__inventory-info {
        margin-top: $buybox-box-top-margin;
    }

    &__product-title {
        @include font-content(var(--msv-font-weight-normal), var(--msv-buybox-title-font-size), $msv-line-height-xxl);
        color: var(--msv-buybox-title-font-color);
        margin-bottom: 8px;
    }

    &__inventory-label {
        @include font-content(var(--msv-font-weight-normal), var(--msv-body-font-size-l), $msv-line-height-m);
    }

    &__product-description {
        @include font-content(var(--msv-font-weight-light), var(--msv-buybox-text-font-size), $msv-line-height-m);
        color: var(--msv-buybox-text-font-color);
        margin-top: $buybox-box-top-margin;
        margin-bottom: $buybox-box-bottom-margin;
    }

    .msc-rating {
        &__count,
        &__star {
            color: var(--msv-buybox-rating-star);
        }

        &__half-star {
            &::after {
                color: var(--msv-buybox-rating-star);
            }
        }
    }

    .msc-price__actual {
        @include font-content-xl();
        color: var(--msv-buybox-text-font-color);
    }

    .msc-price__strikethrough {
        @include font-content-xl(var(--msv-font-weight-light));
    }

    &__configure {
        @include font-content-l(var(--msv-font-weight-normal));
        padding: 15px;
    }

    &__dropdown {
        margin-top: 20px;

        &-quantity-label {
            @include font-content-l(var(--msv-font-weight-normal));
            color: var(--msv-buybox-title-font-color);
        }
    }

    &__media-gallery {
        display: block;
        flex-basis: $media-gallery-width;
        min-width: $media-gallery-width;
        flex-grow: 1;
        flex-wrap: nowrap;
        padding-right: $gutter-width;
    }

    .msc-dropdown__select {
        @include form-control();
        border: 1px solid var(--msv-buybox-select-box-border);
    }

    .quantity {
        .quantity-input {
            @include form-control();
            width: 80px;
            display: inline-block;
            margin-left: 8px;
            margin-right: 8px;
            -moz-appearance: textfield;
            padding: $buybox-quantity-input;
            vertical-align: bottom;
            border: 1px solid var(--msv-buybox-quantity-box-border);

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }

        .quantity__controls {
            padding: $buybox-incr-decr;
            padding-left: 0;
            padding-right: 0;
            background: var(--msv-buybox-btn-bg);
            background-position: center;
            background-repeat: no-repeat;
            border: 1px solid var(--msv-buybox-btn-border-color);
            min-width: auto;
            width: 48px;
            height: 48px;
            cursor: pointer;

            &:disabled {
                color: $msv-gray-300;
            }
        }

        .increment {
            @include add-icon($msv-Add, after);
            color: var(--msv-buybox-btn-font-color);
            border: 1px solid var(--msv-buybox-increment-btn-border);

            &.disabled {
                color: $msv-gray-300;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-buybox-btn-font-color);
            }
        }

        .decrement {
            @include add-icon($msv-Remove, after);
            color: var(--msv-buybox-btn-font-color);
            border: 1px solid var(--msv-buybox-decrement-btn-border);

            &.disabled {
                color: $msv-gray-300;
            }

            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-buybox-btn-font-color);
            }
        }
    }

    &__quantity {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    &__product-quantity-label {
        @include font-content-l(var(--msv-font-weight-normal));
        color: var(--msv-buybox-title-font-color);
        display: block;
        margin-bottom: $buybox-label-margin-bottom;
    }

    .ms-buybox__key_in_price {
        margin-top: 20px;

        .ms-buybox__key_in_price-label {
            @include font-content-l(var(--msv-font-weight-light));
            color: var(--msv-buybox-title-font-color);
            display: block;
            margin-bottom: $buybox-label-margin-bottom;
        }

        .ms-buybox__key_in_price_custom-amount__input {
            @include form-control();
            -moz-appearance: textfield;
            padding: $buybox-custom-amount-input-padding;
            text-align: center;

            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }
    }

    .ms-text-block {
        margin-top: 0;
        margin-bottom: 1rem;

        @include font-content(var(--msv-font-weight-normal), var(--msv-buybox-text-font-size), $msv-line-height-m);
    }

    .product-add-to-cart {
        margin-bottom: 20px;
        padding: 15px;
        position: relative;
        .product-add-to-cart {
            padding: 0;
            margin: 0;
        }

        .buttons {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: center;
            align-content: center;

            .msc-add-to-wishlist {
                @include button-secondary-text();
                width: auto;
                height: auto;
                min-width: none;
                flex: none;
                margin: 0;
                font-size: 1rem;
                padding-left: 45px;
                padding-right: 45px;
                &::after {
                    content: 'Add to Wishlist';
                    font-family: $msv-primary-font-family;
                    font-weight: 600;
                    text-decoration: underline;
                }
            }
            .msc-add-to-cart {
                @include button-primary();
                margin-bottom: 15px;
                margin-right: 15px;
                margin-left: 15px;
            }
        }

        .ms-wishlist-items__product-status {
            width: 100%;
            padding-top: $buybox-product-status-padding-top;
        }

        .msc-add-to-cart-extra-actions {
            @include font-glyph-standard();
            @include secondary-button(
                var(--msv-buybox-secondary-btn-bg),
                var(--msv-buybox-secondary-btn-font-color),
                var(--msv-buybox-secondary-btn-border)
            );
            color: var(--msv-buybox-btn-extra-actions-color);
            padding-left: 0;
            padding-right: 0;
            position: relative;
            min-width: $buybox-add-to-cart-extra-actions-width;
            width: $buybox-add-to-cart-extra-actions-width;
            flex: 0 0 $buybox-add-to-cart-extra-actions-width;
            margin-left: $buybox-add-to-cart-extra-actions-margin;

            &:not(:disabled):hover,
            &:not(:disabled):not(.disabled):active:focus {
                color: var(--msv-buybox-btn-extra-actions-color);
                border-color: transparent;
            }
            &.msc-add-to-order-template-icon {
                position: absolute;
                left: 15px;
                top: 15px;
                border-width: 2px;
                border-radius: 5px;
                height: 45px;
                padding: 0;
                box-shadow: 0 0.125rem 0.34375rem rgba(0, 0, 0, 0.2);
                &:hover {
                    background-color: #fff;
                    border-width: 2px;
                    border-color: var(--msv-buybox-btn-extra-actions-color);
                }
            }
            @include cui-focus();
        }
    }

    &__find-in-store {
        margin-top: 20px;
        margin-bottom: 20px;
        width: auto;
    }

    &__find-in-store-heading {
        @include font-content-l(var(--msv-font-weight-normal));
    }

    &__find-in-store-description {
        margin-top: 8px;
        margin-bottom: $buybox-store-description-margin-bottom;

        @include font-content-s(var(--msv-font-weight-normal));
    }

    &__find-in-store-pickup-option {
        margin-top: 8px;
        margin-bottom: $buybox-store-description-margin-bottom;

        @include font-content-s();
        @include add-icon($msv-Checkbox-Circle-Checked, before);

        &::before {
            margin-right: 10px;
            color: --msv-buybox-primary-btn-font-color;
        }
    }

    &__minmax_label_text {
        margin-top: 12px;
        font-weight: var(--msv-font-weight-bold);

        &::before {
            @include msv-icon();
            content: $msv-InfoCircle;
            margin-right: $msv-icon-margin-right;
        }
    }

    &__find-in-store-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
            var(--msv-buybox-secondary-btn-border)
        );
        font-size: var(--msv-buybox-btn-font-size);
        line-height: $msv-line-height-l;
    }

    &__shop-similar-looks-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
            var(--msv-buybox-secondary-btn-border)
        );
        font-size: var(--msv-buybox-btn-font-size);
        line-height: $msv-line-height-l;
        margin-bottom: 20px;
    }

    &__shop-similar-description-button {
        @include button-default();
        @include secondary-button(
            var(--msv-buybox-secondary-btn-bg),
            var(--msv-buybox-secondary-btn-font-color),
            var(--msv-buybox-secondary-btn-border)
        );
        font-size: var(--msv-buybox-btn-font-size);
        line-height: $msv-line-height-l;
    }

    &__dialog {
        max-width: $msv-dialog-width;

        .msc-modal__content {
            padding: $msv-dialog-modal-content-padding;
        }

        &__header {
            .msc-modal__title {
                @include font-content-l();

                span {
                    &:nth-child(1) {
                        font-weight: var(--msv-font-weight-bold);
                    }
                }
            }
        }

        &__body {
            @include font-content-l();
            text-align: center;

            .msc-empty_image {
                @include image-placeholder($msv-dialog-msc-empty_image-size);
                padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
            }
        }

        &__image {
            margin-right: auto;
            margin-left: auto;
        }

        &__product-title {
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__product-variants {
            @include font-content(var(--msv-font-weight-normal), var(--msv-buybox-text-font-size), $msv-line-height-m);
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__product-price {
            font-weight: var(--msv-font-weight-bold);
            padding: $msv-dialog-modal-content-body-text-padding;
        }

        &__go-to-cart {
            @include primary-button(
                var(--msv-buybox-primary-btn-bg),
                var(--msv-buybox-primary-btn-font-color),
                var(--msv-buybox-primary-btn-border)
            );
            margin: $msv-dialog-modal-content-button-margin;
            width: $msv-dialog-button-width;
        }

        &__back-to-shopping {
            @include secondary-button();
            margin: $msv-dialog-modal-content-button-margin;
            width: $msv-dialog-button-width;
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        &__media-gallery {
            display: block;
            flex-basis: $media-gallery-width;
            min-width: $media-gallery-width;
            flex-grow: 1;
            flex-wrap: nowrap;
            padding-right: $gutter-width;
        }

        .ms-media-gallery {
            padding-bottom: 54px;

            .msc-carousel {
                &__indicators {
                    bottom: -36px;
                }

                &__control {
                    &__prev,
                    &__next {
                        bottom: -40px;
                        height: auto;
                        top: auto;
                        width: auto;
                    }

                    &__prev__icon {
                        @include add-icon($msv-ChevronLeft);
                        margin-left: 0;
                    }

                    &__next__icon {
                        @include add-icon($msv-ChevronRight);
                        margin-right: 0;
                    }
                }
            }
        }
    }

    //mobile vp

    @media (max-width: $msv-breakpoint-sm) {
        flex-flow: column;
    }
}
.alert-body {
    .error-messages-list {
        list-style: circle;
        padding-left: 30px;
        li.error-message-item {
            margin-bottom: 15px;
            line-height: 1.4rem;
            font-weight: 600;
            ::marker {
                color: $coc-secondary-2D;
            }
        }
    }
}

.addressBox,
.legal-des,
.legal-des-condo {
    .row {
        margin-left: -5px;
        margin-right: -5px;
    }
}
.property-search-wrap {
    .form-group {
        label {
            &[for='addressNumber'],
            &[for='streetName'],
            &[for='streetType'],
            &[for='quadrant'],
            &[for='lookFor'] {
                @include required-field();
            }
        }
    }
    p {
        font-size: 1rem;
        margin-bottom: 0.25rem;
    }
    h3,
    h6 {
        font-size: 1.2rem;
        margin-bottom: 15px;
        font-weight: 600;
        line-height: 1.8rem;
        &.req {
            @include required-field();
            background-size: 6rem 1.35em;
        }
    }
    h4 {
        font-size: 1rem;
        margin-bottom: 15px;
        font-weight: 600;
        line-height: 1.6rem;
    }
    .option-list {
        strong {
            font-size: 1rem;
            margin-bottom: 15px;
            font-weight: 600;
            line-height: 1.6rem;
            display: block;
        }
    }
    .conf-box {
        padding: 15px;
        background-color: $msv-gray-150;
        width: 100%;
    }
    .info {
        font-style: italic;
        display: inline-block;
        margin-bottom: 15px;
    }
    .option-list {
        ul {
            list-style: none;
            padding-left: 0;
            li {
                input {
                    margin-right: 5px;
                    &:focus,
                    &:focus-visible {
                        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
                        outline: none;
                    }
                }
            }
        }
    }
    .error-Summary {
        border: 2px solid $msv-primary;
        background-color: $msv-white;
        position: relative;
        padding: 5px 5px 5px 45px;
        font-weight: 600;
        margin-bottom: 5px;
        &::before {
            @include msv-icon();
            content: $msv-IncidentTriangle;
            position: absolute;
            left: 0;
            top: 0;
            width: 35px;
            height: 100%;
            background-color: $msv-primary;
            color: $msv-white;
            padding-top: 10px;
            font-size: 0.8rem;
            text-align: center;
        }
        &:empty {
            display: none;
        }
        li {
            padding: 5px 5px 5px 10px;
            a {
                @include cui-focus();
            }
        }
    }
    .result-wrap {
        background-color: transparent;
        padding: 0;
    }
    .search-instructions {
        margin-top: -15px;
        p {
            margin-bottom: 5px;
        }
        ul {
            margin: 0;
            li {
                font-weight: 600;
                ::marker {
                    color: $coc-secondary-2D;
                }
            }
        }
    }
}

.result-wrap {
    border-top: 1px solid $msv-gray-400;
    margin-top: 15px;
    padding-top: 15px;
    > strong {
        font-size: 1rem;
        display: inline-block;
        margin-bottom: 10px;
        margin-top: 15px;
    }
    > h3 {
        margin-top: 15px;
    }
    h2 {
        font-weight: bold;
        font-size: 1.5em;
    }
    p {
        font-style: italic;
        font-size: 1em;
    }
    ul {
        list-style: none;
        padding-left: 0;
        li {
            input[type='checkbox'] {
                margin-right: 5px;
            }
            label {
                border-bottom: 1px solid $msv-gray-400;
                font-weight: $msv-font-weight-heavy;
                width: 80%;
            }
            ul {
                border-bottom: 1px solid $msv-gray-400;
                margin-bottom: 20px;
                li {
                    label {
                        border-bottom: 1px solid $msv-gray-400;
                        font-weight: $msv-font-weight-400;
                        margin-bottom: 10px;
                        padding-right: 80px;
                        padding-left: 25px;
                        padding-bottom: 5px;
                        position: relative;
                        width: 80%;
                        .removeItem {
                            position: absolute;
                            right: 0;
                            color: $msv-red;
                            cursor: pointer;
                            border: none;
                            background: none;
                            @include cui-focus();
                        }
                        input[type='checkbox'] {
                            position: absolute;
                            left: 5px;
                            top: 5px;
                        }
                    }
                    &:last-child {
                        label {
                            border-bottom: none;
                        }
                    }
                }
            }
            &:last-child {
                ul {
                    border-bottom: 1px solid $msv-gray-400;
                    margin-bottom: 0;
                }
            }
        }
    }
}
.property-search-wrap {
    position: relative;
    .btn-box {
        margin-bottom: 15px;
    }
    .characterNum {
        display: flex;
        align-items: baseline;
        justify-content: end;
        .form-field {
            display: inline-block;
            border: none;
            width: 50px;
            height: auto !important;
            margin: 0 !important;
            padding: 0 !important;
        }
    }
    .address-search-instruction {
        margin-bottom: 25px;
        position: relative;
        top: -20px;
    }
}
.address-search-box {
    position: relative;
}
.address-options {
    position: absolute;
    top: 60px;
    left: 0;
    background-color: $msv-white;
    color: $cui-gray-primary;
    cursor: pointer;
    max-height: 145px;
    width: 100%;
    z-index: 10;
    border: 1px solid $msv-gray-300;
    overflow-y: scroll;
    @include cui-focus();
}
.address-options li {
    color: $cui-gray-primary;
    font-size: 1rem;
    padding: 0.8rem;
    border-bottom: 1px solid $msv-gray-300;
    @include cui-focus();
}
.address-options li:hover {
    background-color: #00000010;
    color: $cui-gray-primary;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}

.address-options .suggest-active {
    background-color: #00000010;
    color: $cui-gray-primary;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
}
