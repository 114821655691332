.b2bhome {
    .b2bhomepage-hero-banner {
        .ms-content-block {
            font-size: $msv-font-size-xl;
            font-style: $msv-font-weight-normal;
            font-weight: $msv-font-weight-400;
            line-height: $msv-line-height-xl;
            letter-spacing: 0em;
            text-align: center;
            margin-bottom: 27px;
            &__details {
                padding-left: 120px;
                margin-top: 100px;
                .ms-content-block__title {
                    color: $msv-white;
                    font-size: $msv-font-size-28;
                    margin-left: 9px;
                    font-weight: $msv-font-weight-700;
                    line-height: $msv-line-height-36;
                }
                .ms-content-block__text {
                    line-height: $msv-line-height-l;
                    color: $msv-white;
                    font-size: $msv-font-size-l;
                    margin-left: 9px;
                    font-weight: var(--msv-font-weight-heavy);
                    line-height: $msv-line-height-23;
                }
                a:nth-child(2) {
                    background-color: $msv-white;
                    color: $msv-gray-1100;
                    height: 44px;
                    font-size: $msv-font-size-l;
                    margin: 10px;
                    padding: 12px 20px;
                    font-weight: $msv-font-weight-400;
                    line-height: $msv-line-height-23;
                }
                .ms-content-block__additionaltext {
                    font-weight: $msv-font-weight-400;
                    line-height: $msv-line-height-23;
                    color: $msv-white;
                    font-size: $msv-font-size-l;
                    margin-top: 12px;
                    margin-left: 12px;
                    a:nth-child(1) {
                        color: $msv-white;
                        font-size: $msv-font-size-l;
                        margin-top: 24px;
                        font-weight: $msv-font-weight-400;
                        line-height: $msv-line-height-23;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    .ms-content-block[data-m-layout='left-right'] {
        display: flex;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto;
        margin: 96px 40px auto 40px;
        &.home-map-box {
            margin-left: 0;
            margin-right: 0;
            background-color: $msv-gray-100;
            @media (max-width: $msv-breakpoint-m) {
                flex-wrap: wrap;
                margin-top: 30px;
                margin-left: 10px;
                margin-right: 10px;
                .ms-content-block__image,
                .ms-content-block__details {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                .ms-content-block__details {
                    padding: 25px 15px;
                    margin: 0;
                }
            }
        }
        .ms-content-block__image {
            grid-column: 1 / span 1;
            grid-row: 1 / span 1;
            margin-bottom: auto;
            margin-top: auto;
            flex: 0 0 55%;
            max-width: 55%;
        }
        .ms-content-block__details {
            grid-column: 2 / span 1;
            grid-row: 1 / span 1;
            margin: auto 0px auto 30px;
            flex: 0 0 40%;
            max-width: 40%;
            .ms-content-block__title {
                font-size: $msv-font-size-28;
                line-height: $msv-line-height-37;
                color: $microsoft-hover-color;
            }
            .ms-content-block__text {
                color: $microsoft-hover-color;
            }
            .ms-content-block__cta {
                margin-top: 20px;
            }
        }
    }

    .business_solution_container {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        .row {
            display: table;
            justify-content: space-between;
            .col-md-4:nth-child(1) {
                width: 80%;
                display: table-cell;
                vertical-align: middle;
            }
            .col-md-4:nth-child(2) {
                width: 20%;
                display: table-cell;
                vertical-align: middle;
            }
            .ms-content-block {
                &__details {
                    width: auto;
                }
                &__title {
                    font-size: $msv-font-size-28;
                    font-weight: $msv-font-weight-400;
                    line-height: $msv-line-height-36;
                    color: $msv-gray-900;
                }
                &__text {
                    font-size: $msv-font-size-l;
                    font-weight: $msv-font-weight-400;
                    line-height: $msv-line-height-23;
                    color: $msv-gray-900;
                }
                &__cta {
                    margin-top: 0;
                }
            }
            .ms-carousel {
                margin-top: 44px;
                margin-bottom: 50px;
                margin-right: 55px;
                .msc-carousel {
                    &__inner {
                        box-shadow: 0px 4.8px 14.4px $msv-box-shadow-color-18, 0px 25.6px 57.6px $msv-box-shadow-color-22;

                        .ms-content-block {
                            max-width: 262px;
                            margin-bottom: 9px;
                            &__image {
                                picture {
                                    img {
                                        max-width: 262px;
                                        height: auto;
                                        margin-right: 104px;
                                    }
                                    img.lazyload,
                                    img.lazyloading {
                                        width: 262px;
                                        height: 349;
                                    }
                                }
                            }
                            &__cta {
                                padding-top: 5px;
                                padding-bottom: 20px;
                                text-align: center;
                            }
                        }
                    }

                    &__control__next {
                        top: 450px;
                        color: var(--msv-accent-brand-color);
                        background-color: $msv-box-shadow-color-0;
                        border: none;
                        margin-right: 140px;
                        width: auto;
                        &__icon {
                            font-size: $msv-font-size-21;
                        }
                    }
                    &__control__prev {
                        top: 450px;
                        color: var(--msv-accent-brand-color);
                        background-color: $msv-box-shadow-color-0;
                        border: none;
                        margin-left: 5%;
                        &__icon {
                            font-size: $msv-font-size-21;
                        }
                    }
                    &__control__prev:hover,
                    &__control__prev:focus,
                    &__control__next:hover,
                    &__control__next:focus {
                        outline: none;
                        opacity: 0.9;
                        text-decoration: none;
                        background-color: var(--msv-accent-brand-color);
                        width: 40px;
                    }
                    &__indicators {
                        display: none;
                    }
                }
            }
        }
    }
    .tile-container,
    .spacer-container {
        padding-left: 20px;
        padding-right: 0;
        margin-top: 50px;
        .ms-content-block {
            &__image {
                padding-right: 0;
                img {
                    min-width: 100%;
                }
            }
            .ms-content-block__cta {
                padding-top: 0px;
                div {
                    color: $msv-gray-1000;
                }
            }
        }

        @media (max-width: $msv-breakpoint-m) {
            padding-left: 25px;
            padding-right: 25px;
            margin: 0;
        }
    }
    @media (max-width: $msv-breakpoint-m) {
        .b2bhome .spacer-container {
            height: 0;
        }
    }
    .tile-container {
        @media (max-width: $msv-breakpoint-m) {
            div[class^='col'] {
                margin-bottom: 30px;
            }
        }
    }
    .ourBusinesspartners {
        font-size: $msv-font-size-xl;
        line-height: $msv-line-height-xl;
    }
}
