@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');

$cui-img-path: '../../../img';

$msv-primary-font-family: $font-primary;
$msv-font-assistant: $font-condensed;
$msv-font-allerta: $font-condensed;
$msv-font-cinzel: $font-condensed;
$msv-font-weight-bold: bold;
$msv-font-weight-normal: normal;
$msv-font-weight-light: 300;
$msv-font-weight-400: 400;
$msv-font-weight-heavy: 600;
$msv-font-weight-above-normal: 500;
$msv-font-weight-600: 600;
$msv-font-weight-700: 700;

$msv-heading-text-size: 20px;
$msv-heading-line-height: 28px;

$msv-font-size-s: 14px;
$msv-font-size-m: 16px;
$msv-font-size-l: 18px;
$msv-font-size-xl: 24px;
$msv-font-size-xxl: 48px;
$msv-font-size-15: 15px;
$msv-font-size-21: 21px;
$msv-font-size-28: 28px;
$msv-font-size-32: 32px;
$msv-font-size-36: 36px;
$msv-font-size-100: 100px;
$msv-font-size-160: 160px;
$msv-font-size-126: 126px;

$msv-line-height-s: 18px;
$msv-line-height-m: 21px;
$msv-line-height-23: 23.54px;
$msv-line-height-l: 24px;
$msv-line-height-26: 26px;
$msv-line-height-xl: 31px;
$msv-line-height-xxl: 63px;
$msv-line-height-46: 46px;
$msv-line-height-134: 134px;
$msv-line-height-215: 215px;
$msv-line-height-36: 36.62px;
$msv-line-height-37: 37px;
$msv-line-height-42: 42px;
$msv-line-height-47: 47px;
$msv-line-height-126: 126px;

$msv-icon-size-small: 16px;
$msv-icon-size: 24px;
$msv-text-size: $msv-font-size-m;
$msv-text-line-height: 20px;

@mixin fabrikam-font(
    $font-weight: $msv-font-weight-normal,
    $font-size: $msv-font-size-s,
    $line-height: $msv-line-height-s,
    $text-transform: none,
    $font-family: $msv-font-assistant
) {
    font-family: $font-family;
    font-weight: $font-weight;
    font-size: $font-size;
    line-height: $line-height;
    text-transform: $text-transform;
}

@mixin font-content(
    $font-weight: $msv-font-weight-normal,
    $font-size: $msv-font-size-s,
    $line-height: $msv-line-height-s,
    $text-transform: none
) {
    @include fabrikam-font($font-weight, $font-size, $line-height, $text-transform);
}

@mixin font-content-underline($font-weight: $msv-font-weight-normal, $font-size: $msv-font-size-s, $line-height: $msv-line-height-s) {
    text-decoration-line: underline;

    @include font-content($font-weight, $font-size, $msv-line-height-m);
}

@mixin font-content-s($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight);
}

@mixin font-content-s-uppercase($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight, $msv-font-size-s, $msv-line-height-s, uppercase);
}

@mixin font-content-s-height-l($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight, $msv-font-size-s, $msv-line-height-l);
}

@mixin font-content-m($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight, $msv-font-size-m, $msv-line-height-m);
}

@mixin font-content-m-underline($font-weight: $msv-font-weight-normal) {
    text-decoration-line: underline;

    @include font-content($font-weight, $msv-font-size-m, $msv-line-height-m);
}

@mixin font-content-m-strikethrough($font-weight: $msv-font-weight-normal) {
    text-decoration-line: line-through;

    @include font-content($font-weight, $msv-font-size-m, $msv-line-height-m);
}

@mixin font-content-l($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight, $msv-font-size-l, $msv-line-height-l);
}

@mixin font-content-xl($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight, $msv-font-size-xl, $msv-line-height-xl);
}

@mixin font-content-xxl($font-weight: $msv-font-weight-normal) {
    @include font-content($font-weight, $msv-font-size-xxl, $msv-line-height-xxl);
}

@mixin fabrikam-display($font-size: $msv-font-size-xl, $line-height: $msv-line-height-xl, $font-family: $msv-font-allerta) {
    @include fabrikam-font($font-weight-normal, $font-size, $line-height, uppercase, $font-family);
}

@mixin font-display-xs() {
    @include fabrikam-display();
}

@mixin font-display-s() {
    @include fabrikam-display($msv-font-size-36, $msv-title-line-height-s);
}

@mixin font-display() {
    letter-spacing: 0.05em;

    @include fabrikam-display($msv-font-size-100, $msv-title-line-height-m, $msv-font-cinzel);
}

@mixin font-display-l() {
    letter-spacing: 0.05em;

    @include fabrikam-display($msv-font-size-160, $msv-title-line-height-l, $msv-font-cinzel);
}

@mixin font-glyph-standard() {
    font-size: $msv-icon-size;
}

@mixin font-glyph-small() {
    font-size: $msv-icon-size-small;
}

@mixin font-content-heading($font-weight: $msv-font-weight-heavy) {
    @include font-content($font-weight, $msv-heading-text-size, $msv-heading-line-height);
}

@mixin required-field() {
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-size: 5rem 1.3rem;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-color: #000;
    background-image: url('#{$cui-img-path}/req-img.jpg');
    text-align: left;
    display: inline-block;
    white-space: nowrap;
    max-width: fit-content;
}

// Style presets
:root {
    // Headings
    --msv-heading-font-size-s: #{$msv-font-size-xl};
    --msv-heading-font-size-m: #{$msv-font-size-36};
    --msv-heading-font-size-l: #{$msv-font-size-100};
    --msv-heading-font-size-xl: #{$msv-font-size-160};

    // Body
    --msv-body-font-size-s: #{$msv-font-size-s};
    --msv-body-font-size-m: #{$msv-font-size-m};
    --msv-body-font-size-l: #{$msv-font-size-l};
    --msv-body-font-size-xl: #{$msv-font-size-xl};
    --msv-body-font-size-xxl: #{$msv-font-size-xxl};

    // Weight
    --msv-font-weight-bold: #{$msv-font-weight-600};
    --msv-font-weight-normal: #{$msv-font-weight-normal};
    --msv-font-weight-light: #{$msv-font-weight-light};
    --msv-font-weight-heavy: #{$msv-font-weight-heavy};
}
