// bootstrap color variables
$msv-white: $white;
$msv-white-100: rgba(255, 255, 255, 0.8);
$msv-white-200: #f3f3f3;
$msv-black: $black;
$msv-blue: $coc-secondary-blue-access;
$msv-green: $coc-secondary-green-safety;
$cui-green: $coc-secondary-green-L;
$msv-red: $coc-main;
$msv-pink: #fcdfdf;
$msv-cyan: #0776d1;
$msv-yellow: $coc-secondary-yellow-D;
$msv-snow: #f9f9f9;
$cui-blue: #0057b8;

$msv-gray-50: #fafafa;
$msv-gray-100: $coc-secondary-10L;
$msv-gray-150: #f2f2f2;
$msv-gray-200: $coc-secondary-9L;
$msv-gray-300: #dbdcdd;
$msv-gray-400: $coc-secondary-7L;
$msv-gray-500: #b7b9bb;
$msv-gray-600: #a5a7aa;
$msv-gray-700: #939599;
$msv-gray-800: #818388;
$msv-gray-900: $coc-secondary;
$msv-gray-1000: #6f7277;
$msv-gray-1100: #5d6066;
$msv-gray-1200: #6e6e6e;
$msv-gray-tint: #495057;
$cui-gray-primary: $coc-secondary-2D;
$msv-white-smoke: #f1f1f1;
$msv-whisper: #ededed;
$msv-nobel: #949494;

$cui-info-alert: $coc-secondary-yellow-D;
$msv-primary: $coc-main;
$msv-primary-hover: $coc-btn-hover;
$msv-secondary: $coc-secondary;
$msv-secondary-hover: #f2f2f2;

$msv-text-color: $cui-gray-primary;
$msv-link-color: $msv-gray-900;
$msv-divider-color: $msv-gray-300;
$msv-error-color: $msv-red;
$msv-success-color: $msv-green;
$modal-input-background: $msv-gray-100;
$msv-box-shadow-color-0: rgba(0, 0, 0, 0);
$msv-modal-overlay: rgba(0, 0, 0, 0.7);
$msv-box-shadow-color: rgba(0, 0, 0, 0.132);
$msv-box-shadow-color-18: rgba(0, 0, 0, 0.18);
$msv-box-shadow-color-22: rgba(0, 0, 0, 0.22);

$msv-selected-user-background-color: rgba(0, 0, 0, 0.04);

$msv-flyout-empty-img-border: #f3f2f1;

// Social button color variables
$facebook-bg-color: #3c3c41;
$facebook-hover-color: #1d1d1d;
$microsoft-bg-color: #3c3c41;
$microsoft-hover-color: #1d1d1d;
$social-bg-color: #3c3c41;
$social-hover-color: #1d1d1d;

$msv-unfilled-rating-color: $msv-gray-800;

// Style presets
:root {
    // Background
    --msv-bg-color: #{$msv-white};

    // Font
    --msv-font-primary-color: #{$msv-black};
    --msv-font-secondary-color: #{$msv-white};

    // Borders
    --msv-border-color: #{$msv-gray-100};

    // Brand accents
    --msv-accent-brand-color: #{$msv-primary};
    --msv-accent-brand-alt-color: #{$msv-primary-hover};
    --msv-accent-secondary-btn: #{$msv-secondary-hover};

    // Status
    --msv-error-color: #{$msv-red};
    --msv-success-color: #{$msv-green};

    // Modal
    --msv-modal-bg-color: var(--msv-bg-color);
    --msv-modal-font-color: var(--msv-font-primary-color);
    --msv-flyout-bg-color: #{$msv-white};
}
