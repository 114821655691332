/* -------------------------------------------------------
   Start of _cta-block
   ------------------------------------------------------- */

.cui.cta-block {
	padding: $spacer-sm;

	.left-col {
		// Spacing for mobile
		padding-bottom: $spacer-sm;
	}

	.details {
		color: $coc-default-color;
		font-size: $base-font-size;
		font-weight: $font-weight-normal;
		margin: 0;
		padding-top: 0;
		max-width: none;
	}

	@media( max-width: $breakpoint-xs-max ) {
		.left-col,
		.right-col {
			overflow: hidden;// Handles floats
		}

		.cui[class*="btn"] {
			float: left;

			+ .cui[class*="btn"] {
				// Ensure buttons are on their own line
				clear: left;
				// Add spacing between buttons
				margin-top: $spacer-xxs;
			}
		}
	}

	@media( min-width: $breakpoint-sm ) {
		.cui[class*="btn"] {
			margin-right: $spacer-sm;
		}
	}

	@media( min-width: $breakpoint-lg ) {
		@include align-items(center);
		@include flexbox();

		.cui[class*="btn"] {
			margin-right: $spacer-xxs;
			margin-bottom: $tiny-spacer-xs;
		}

		.left-col,
		.right-col {
			padding-top: 0;
			padding-bottom: 0;
			width: 50%;
		}

		.left-col {
			padding-right: $tiny-spacer-xs;
			padding-left: $spacer-lg;
			//text-align: right;
			width: 55%;
		}

		.right-col {
			padding-right: $spacer-lg;
			padding-left: $tiny-spacer-xs;
			width: 45%;
		}
	}

	// Add the icon in front of the text
	&.with-icon {
		.left-col {
			@include flexbox();

			[class*="cicon-"] {
				font-size: $cicon-size-lg;
				margin-top: auto;
				margin-bottom: auto;
				margin-right: $spacer-sm;
			}
		}

		@media( min-width: $breakpoint-lg ) {
			.details {
				-webkit-box-flex: 1;
				-webkit-flex: 1;
				-ms-flex: 1;
				-moz-flex: 1;
				flex: 1;// Allow the details element to take up the entire space minus the cicon
			}
		}
	}

	// The default is text alignment to left
	// Add the right-to-left will make the text aligning to right.
	// The space between the text will be adjusted accordingly
	&.right-to-left {
		@media( min-width: $breakpoint-lg ) {
			.left-col {
				padding-right: $spacer-sm;
				text-align: right;
			}

			.right-col {
				padding-left: $spacer-sm;
			}
		}
	}

	// Add the center will make the text aligning to center.
	&.center {
		@media( min-width: $breakpoint-lg ) {
			.details {
				text-align: center;
				padding-right: $spacer-lg;
			}
		}

		&.with-icon {
			@media( min-width: $breakpoint-lg ) {
				.left-col {
					padding-right: 0;
				}

				.details {
					text-align: center;
				}
			}
		}
	}

	// themes
	// The default is medium grey
	&.bg-medium {
		border: $border-thin solid $border-color-medium !important;
	}

	&.bg-transparent {
		border: $border-thin solid transparent !important;
	}
}
