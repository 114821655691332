$msv-details-text-margin-right: 5px;
$msv-details-order-information-padding-bottom: 8px;
$msv-details-order-information-padding-top: 32px;
$msv-details-order-information-spacing: 8px;
$msv-details-order-information-channel-name-margin-bottom: 12px;
$msv-details-order-information-channel-address-margin-bottom: 12px;
$msv-details-keep-shopping-btn-margin-bottom: 20px;
$msv-details-group-padding-top: 20px;
$msv-details-group-delivery-width: 340px;
$msv-details-group-delivery-padding-bottom: 28px;
$msv-details-sales-status-top-desktop: -144px;
$msv-details-sales-status-top-mobile: -174px;
$msv-details-sales-lines-width: 770px;
$msv-details-sales-lines-margin-right: 120px;
$msv-details-sale-line-spacing: 20px;
$msv-details-sale-line-padding: 20px 0;
$msv-details-sale-line-image-size: 147px;
$msv-details-sale-line-image-size-mobile: 80px;
$msv-details-sale-line-item-width: 770px;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;
$msv-details-sale-line-buy-again-btn-left: 167px;
$msv-details-sale-line-buy-again-btn-bottom: 0;
$msv-details-sale-line-buy-again-btn-padding-left: 33px;
$msv-details-sale-line-buy-again-btn-padding-right: 33px;
$msv-details-order-summary-line-earned-points-padding-top: 20px;
$msv-details-order-summary-payment-menthods-help-margin-top-mobile: 20px;
$msv-order-empty-image-width: 147px;
$msv-order-empty-image-width-mobile: 80px;
$msv-details-sales-line-padding-bottom-mobile: 40px;
$msv-details-sales-line-btn-buy-it-again-margin-left-mobile: 100px;
$msv-details-order-summary-price-padding: 5px;
$msv-details-order-summary-font-weight: 600;
$msv-details-order-summary-line-spacing: 20px;
$msv-details-order-summary-line-total-amount-margin-top: 20px;
$msv-details-order-summary-line-total-amount-margin-bottom: 20px;
$msv-details-order-summary-line-earned-points-padding-top: 20px;
$msv-details-button-margin-top: 12px;
$msv-details-help-margin-bottom: 28px;
$msv-details-help-heading-margin-bottom: 8px;
$msv-details-help-heading-margin-top: 20px;
$msv-details-payment-methods-margin-right: 20px;
$msv-details-payment-methods-heading-margin-bottom: 8px;
$msv-details-payment-methods-line-margin-bottom: 20px;
$msv-details-loyalty-padding-right: 10px;
$msv-details-loyalty-margin-top: 20px;
$msv-details-loyalty-points-margin-left: 5px;
$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;
$msv-details-footer-block-width: 768px;
$msv-details-footer-block-margin-right: 120px;
$msv-details-footer-block-padding-bottom: 20px;
$msv-details-help-width: 100%;
$msv-details-address-spacing: 20px;
$msv-msc-cart-line-width: 100%;
$msv-smaller-port-m: 320px;
$msv-order-details-order-summary-container-padding: 20px;
$msv-details-address-padding-m: 20px 0;
$msv-details-address-padding: 43px 0 20px 0;
$msv-details-address-icon-padding-right: 5px;
$msv-details-tracking-info-padding-top: 15px;
$msv-text-max-width: 80%;
$msv-order-details-group-heading-icon-padding-right: 10px;
$msv-order-details-group-multiple-heading-padding-bottom: 0;
//style presets
:root {
    --msv-order-details-border: #{$msv-gray-300};

    // heading
    --msv-order-details-heading-font-color: var(--msv-font-primary-color);
    --msv-order-details-heading-font-size: var(--msv-body-font-size-xl);

    // title
    --msv-order-details-title-font-color: var(--msv-font-primary-color);
    --msv-order-details-title-font-size: var(--msv-body-font-size-l);

    // text
    --msv-order-details-text-font-size: var(--msv-body-font-size-m);
    --msv-order-details-text-font-color: var(--msv-font-primary-color);

    // secondary button
    --msv-order-details-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-order-details-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-order-details-secondary-btn-border: var(--msv-accent-brand-color);

    // link
    --msv-order-buy-again-link-color: var(--msv-font-primary-color);
}

.ms-order-details {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;
    .top-btn-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $msv-gray-100;
        padding: 25px 60px 25px 30px;
        margin-bottom: 50px;
        flex-wrap: wrap;
        width: 100%;
        .cui[class*='btn'].primary.processing-btn {
            margin-bottom: 0;
            span.spinner-border {
                width: 1rem;
                height: 1rem;
                border: 0.175rem solid $white;
                border-top-color: $coc-main;
                position: relative;
                top: 4px;
                margin-right: 10px;
            }
        }
        p {
            font-size: 1.2rem;
            margin: 0;
        }
        @media (max-width: $msv-breakpoint-m) {
            p {
                margin-bottom: 15px;
            }
        }
        @media (max-width: $msv-breakpoint-sm) {
            padding: 25px 30px 25px 30px;
            p {
                margin-bottom: 25px;
            }
            .cui[class*='btn'].secondary {
                width: 100%;
            }
        }
    }
    &__heading {
        display: none;
    }
    &__wrapper {
        flex: 0 0 100%;
        display: flex;
        .ms-order-details__sales-line {
            padding-right: 130px;
            .ms-order-detail__product-details {
                span {
                    word-break: break-all;
                }
            }
        }
        @media (max-width: $msv-breakpoint-sm) {
            flex-wrap: wrap;
            .ms-order-details__groups {
                flex: 0 0 100%;
                padding-right: 0;
                max-width: 100%;
            }
            .ms-order-details__order-summary-container {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
    &__order-information {
        padding-bottom: 20px;
        display: flex;
        flex-flow: column;
        width: 100%;
        &-qr-code {
            display: none;
        }
        > .ms-order-details__order-information-sales-id {
            display: none;
        }
        &-channel-name {
            color: var(--msv-order-details-title-font-color);
            display: block;
            font-size: $msv-heading-text-size;
            font-weight: var(--msv-font-weight-heavy);
            line-height: $msv-heading-line-height;
            margin-bottom: $msv-details-order-information-channel-name-margin-bottom;
        }

        &-channel-address {
            display: block;
            font-size: $msv-heading-text-size;
            font-weight: var(--msv-font-weight-heavy);
            line-height: $msv-heading-line-height;
            margin-bottom: $msv-details-order-information-channel-address-margin-bottom;
            margin-top: -$msv-details-order-information-channel-name-margin-bottom;
        }

        &-channel-reference-id,
        &-sales-id,
        &-receipt-id,
        &-receipt-email {
            display: block;
        }
        &-channel-reference-id {
            font-size: 3rem;
            font-weight: 600;
            padding: 25px 0 0;
            margin-bottom: 15px;
            line-height: 3.4rem;
            order: 2;
            @media screen and (max-width: $msv-breakpoint-m) {
                line-height: 2.4rem;
                font-size: 1.8rem;
            }
        }
        &-created-date {
            font-size: 1rem;
            line-height: 1.4rem;
            text-transform: none;
            position: static;
            order: 2;
            font-weight: 600;
        }

        &-count {
            display: inline-block;
            padding-left: $msv-details-order-information-spacing;
            padding-right: $msv-details-order-information-spacing;
            border-right: 1px solid $msv-gray-900;
        }

        &-amount {
            padding-left: $msv-details-order-information-spacing;
        }

        @media screen and (max-width: $msv-smaller-port-m) {
            &-amount {
                padding-left: 0;
            }
        }
    }

    &__btn-keep-shopping {
        margin-bottom: $msv-details-keep-shopping-btn-margin-bottom;

        @include secondary-button(
            var(--msv-order-details-secondary-btn-bg),
            var(--msv-order-details-secondary-btn-font-color),
            var(--msv-order-details-secondary-btn-border)
        );
    }

    &__sales-lines {
        flex: 3;

        &:first-child {
            border-top: none;
        }

        .ms-order-details__tracking-info {
            &:not(:first-child) {
                padding-top: $msv-details-tracking-info-padding-top;
            }
        }
    }

    &__sales-status {
        color: var(--msv-success-color);
        float: right;
        position: relative;
        top: $msv-details-sales-status-top-desktop;
    }

    &__groups {
        @include font-content(var(--msv-font-weight-normal), var(--msv-order-details-text-font-size), $msv-line-height-m);
        flex: 0 0 75%;
        padding-right: 50px;
    }

    &__group {
        flex-wrap: wrap;
        padding-top: $msv-details-group-padding-top;

        &-delivery {
            padding-bottom: $msv-details-group-delivery-padding-bottom;
            padding-right: $msv-details-sale-line-spacing;

            &-heading {
                @include font-content(var(--msv-font-weight-bold), var(--msv-order-details-heading-font-size), $msv-line-height-xl);
                color: var(--msv-order-details-heading-font-color);
                margin-right: $msv-details-text-margin-right;

                .ms-order-details__sales-status {
                    color: var(--msv-order-details-heading-font-color);
                    float: left;
                    top: 0;
                }

                .pickUp-icon {
                    @include add-icon($msv-cube, before);
                    @include font-content(var(--msv-font-weight-bold), var(--msv-order-details-title-font-size), $msv-line-height-l);

                    &::before {
                        padding-right: $msv-order-details-group-heading-icon-padding-right;
                    }
                }

                .ship-icon {
                    @include add-icon($msv-DeliveryTruck, before);
                    @include font-content(var(--msv-font-weight-bold), var(--msv-order-details-title-font-size), $msv-line-height-l);

                    &::before {
                        padding-right: $msv-order-details-group-heading-icon-padding-right;
                    }
                }

                .email-icon {
                    @include add-icon($msv-DeliveryTruck, before);
                    @include font-content(var(--msv-font-weight-bold), var(--msv-order-details-title-font-size), $msv-line-height-l);

                    &::before {
                        padding-right: $msv-order-details-group-heading-icon-padding-right;
                    }
                }
            }

            &-total-items {
                @include font-content(var(--msv-font-weight-normal), var(--msv-order-details-text-font-size), $msv-line-height-m);
                color: var(--msv-order-details-heading-font-color);
                margin-right: $msv-details-text-margin-right;
            }
        }

        &:first-child {
            border-top: none;

            .ms-order-details__sales-lines {
                border-top: none;
            }
        }
    }
    &__order-summary-container {
        flex: 0 0 25%;
        flex-flow: column;
    }
    .multiple-pickUp {
        padding-bottom: $msv-order-details-group-multiple-heading-padding-bottom;
    }

    &__tracking-link {
        text-decoration: underline;
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    &__tracking-info {
        color: var(--msv-order-details-text-font-color);
        display: block;
    }

    &__sales-line {
        border-bottom: 1px solid var(--msv-cart-border);
        margin: $cartline-lines-item-margin;
        padding-bottom: $cart-cartline-item-padding-bottom;
        min-height: $cart-line-card-height;
        position: relative;
        flex: 0 0 100%;
        display: flex;
        padding-left: 90px;
        > span {
            flex: 0 0 20%;
            padding-right: 15px;
            &:first-child {
                flex: 0 0 40%;
            }
        }
        .ms-order-detail__product-details {
            > a {
                color: $msv-primary;
                font-weight: 600;
                display: block;
                margin-bottom: 10px;
                font-size: 1.2rem;
                @include cui-focus();
            }
        }
        > img {
            height: 70px;
            max-width: 70px;
            position: absolute;
            left: 0;
            top: 0;
        }
        span.download-btn-box {
            position: absolute;
            right: 0;
            top: 0;
            padding: 0;
            .procession-btn {
                border: none;
                background-color: transparent;
                color: $msv-red;
                &:disabled {
                    padding-left: 35px;
                    position: relative;
                    color: $msv-red;
                    .spinner-border:empty {
                        display: inline-block;
                        position: absolute;
                        left: 10px;
                        top: 0;
                        width: 1.25rem;
                        height: 1.25rem;
                        border-width: 3px;
                    }
                }
            }
            .ms-order-confirmation__btn-download {
                background-color: transparent;
                border: none;
                color: $msv-primary;
                @include cui-focus();
            }
        }
        > span:last-child {
            position: absolute;
            right: 0;
            bottom: 50px;
            font-weight: 600;
            font-size: 1.4rem;
        }
        .ms-order-details__sales-status {
            position: static;
        }
        button.ms-order-details__sales-line-btn-buy-it-again {
            position: absolute;
            left: 110%;
            top: 0;
            bottom: auto;
            width: 15%;
            min-width: 0;
        }
        .msc-cart-line {
            width: $msv-msc-cart-line-width;

            &__product {
                flex: none;

                &-variant {
                    &-size,
                    &-color,
                    &-style,
                    &-config {
                        .name {
                            color: var(--msv-order-details-text-font-color);
                        }
                    }
                }
            }

            &__other-charges-label,
            &__freight-label,
            .shipping-value,
            .other-charge-value {
                color: var(--msv-order-details-text-font-color);
            }

            &__product-image img {
                max-width: $msv-details-sale-line-image-size;
                width: $msv-details-sale-line-image-size;
                height: $msv-details-sale-line-image-size;
            }

            &__content {
                display: flex;
                flex: auto;
                flex-direction: column;
                position: relative;
            }

            &__product-title {
                @include font-content(var(--msv-font-weight-bold), var(--msv-order-details-title-font-size), $msv-line-height-l);
                color: var(--msv-order-details-title-font-color);
                max-width: $msv-text-max-width;
            }

            &__product-variants {
                color: var(--msv-order-details-text-font-color);
                font-size: var(--msv-order-details-text-font-size);
            }

            &__quantity {
                color: var(--msv-order-details-text-font-color);
                font-size: var(--msv-order-details-text-font-size);
                margin-left: 0;
                left: 0;
                bottom: 0;

                .quantity-label {
                    line-height: $msv-text-line-height;
                }

                .quantity-value {
                    margin-left: $msv-details-text-margin-right;
                    display: inline;
                }
            }
            // hide extra price component
            &__product-savings {
                display: none;
            }

            &__product-price {
                position: absolute;
                right: 0;
                top: 0;
                display: inline;

                .discount-value .msc-price__strikethrough {
                    display: none;
                }

                .msc-price__actual {
                    color: var(--msv-order-details-text-font-color);

                    @include font-content(var(--msv-font-weight-bold), var(--msv-order-details-title-font-size), $msv-line-height-l);
                }
            }

            &-item-product-discount {
                display: none;
            }
        }

        &-btn-buy-it-again {
            display: initial;
            left: $msv-details-sale-line-buy-again-btn-left;
            margin-top: $msv-details-sale-line-buy-again-btn-margin-top;
            position: relative;
            bottom: $msv-details-sale-line-buy-again-btn-bottom;
            width: $msv-details-sale-line-buy-again-btn-width;

            @include secondary-button(
                $bg-color: var(--msv-order-details-secondary-btn-bg),
                $color: var(--msv-order-details-secondary-btn-font-color),
                $border-color: var(--msv-order-details-secondary-btn-border)
            );

            &.msc-btn {
                padding-left: $msv-details-sale-line-buy-again-btn-padding-left;
                padding-right: $msv-details-sale-line-buy-again-btn-padding-right;
            }
        }

        &-btn-buy-it-again-link {
            @include button-link(var(--msv-order-buy-again-link-color));
            @include vfi();
            display: initial;
            left: $msv-details-sale-line-buy-again-btn-left;
            padding: 0;
            position: relative;
            width: $msv-details-sale-line-buy-again-btn-width;
            text-decoration: underline;

            &::after {
                content: '';
            }
        }
    }

    &__order-summary {
        @include font-content(var(--msv-font-weight-normal), var(--msv-order-details-title-font-size), $msv-line-height-l);
        flex: 4;

        &-line-subtotal,
        &-line-shipping,
        &-line-tax-amount {
            margin-bottom: 10px;
        }

        &-line-total-amount {
            margin-bottom: $msv-details-order-summary-line-total-amount-margin-bottom;
            margin-top: $msv-details-order-summary-line-total-amount-margin-top;

            span {
                font-weight: 600;
            }
        }

        &-line-earned-points {
            border-top: 1px solid $msv-divider-color;
            padding-top: $msv-details-order-summary-line-earned-points-padding-top;

            .ms-order-details__order-summary-label {
                font-weight: var(--msv-font-weight-bold);
            }
        }

        &-price {
            float: right;
        }
    }

    &__help {
        @include font-content(var(--msv-font-weight-normal), var(--msv-order-details-text-font-size), $msv-line-height-m);
        color: var(--msv-order-details-text-font-color);
        float: left;
        margin-bottom: $msv-details-help-margin-bottom;
        width: $msv-details-help-width;

        &-title {
            font-weight: var(--msv-font-weight-bold);
            margin-top: $msv-details-help-heading-margin-top;
            margin-bottom: $msv-details-help-heading-margin-bottom;
        }

        &-label::after {
            content: ' ';
        }

        &-content-number {
            @include vfi();
            text-decoration: underline;
        }
    }

    &__order-summary-container {
        color: var(--msv-order-details-text-font-color);
        padding: $msv-order-details-order-summary-container-padding;
        flex: 0 0 25%;
        max-width: 25%;
        border: 1px solid $msv-gray-300;
        padding: 15px;
        align-self: flex-start;
        .ms-order-details__order-summary {
            &-heading {
                font-weight: 600;
                margin-bottom: 15px;
                font-size: 1.4rem;
            }
            .ms-order-confirmation__item-count {
                border-bottom: 1px solid $msv-gray-300;
                padding-bottom: 15px;
            }
            .ms-order-confirmation__order-summary {
                width: 100%;
                padding-bottom: 0;
                padding-top: 0;
                .ms-order-confirmation__order-summary-heading {
                    font-weight: 600;
                    margin-bottom: 15px;
                    font-size: 1.4rem;
                }
            }
        }
    }

    &__payment-methods {
        @include font-content(var(--msv-font-weight-normal), var(--msv-order-details-text-font-size), $msv-line-height-m);
        color: var(--msv-order-details-text-font-color);
        flex: 1;

        &-title {
            font-weight: var(--msv-font-weight-bold);
            margin-bottom: $msv-details-payment-methods-heading-margin-bottom;
        }

        &-line {
            margin-bottom: $msv-details-payment-methods-line-margin-bottom;
        }

        &-card-amount::before,
        &-loyalty-amount::before,
        &-cash-amount::before,
        &-gift-card-amount::before,
        &-customer-account-amount::before {
            content: ' ';
        }
    }

    &__address {
        color: var(--msv-order-details-text-font-color);
        display: block;
        padding: $msv-details-address-padding-m;
        flex: 1;

        &-header {
            font-weight: bold;
            padding-bottom: 8px;
        }

        &-city,
        &-threeletterisoregionname {
            &::before {
                content: '\A';
                white-space: pre;
            }
        }

        &-name,
        &-phone {
            display: block;

            &-label {
                display: none;
            }

            &-icon {
                padding-right: $msv-details-address-icon-padding-right;

                @include add-icon($msv-Phone);
            }
        }
    }

    &__delivery-container {
        flex: 1;
    }

    &__pickup-date-timeslot {
        padding-bottom: 20px;
        padding-top: 20px;

        &-title {
            font-weight: var(--msv-font-weight-bold);
            padding-bottom: 8px;
        }
    }

    &__alert {
        display: block;
        margin-bottom: $msv-order-details-alert-margin-bottom;
        margin-top: $msv-order-details-alert-margin-top;
    }

    .msc-empty_image {
        @include image-placeholder($msv-order-empty-image-width);
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        &__order-summary-container {
            display: flex;
            justify-content: space-between;
        }

        &__order-summary,
        &__sales-lines {
            padding-right: 0;
        }

        &__sales-lines_container {
            display: flex;
            justify-content: space-between;
        }

        &__group-deliveryType-ship {
            border-top: 1px solid var(--msv-cart-border);
            padding-top: 30px;
            &.salesStatus-shipped {
                .ms-order-details__address {
                    padding: $msv-details-address-padding;
                }
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-l) {
        &__sales-lines {
            width: 100%;
        }

        &__sales-line .msc-cart-line {
            width: 100%;
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__group {
            display: block;

            &_delivery {
                padding-right: 0;
            }
        }

        &__sales-lines {
            width: 100%;
        }

        &__sales-status {
            top: $msv-details-sales-status-top-mobile;
        }

        &__order-summary,
        &__payment-methods,
        &__help {
            float: none;
            margin-top: $msv-details-order-summary-payment-menthods-help-margin-top-mobile;
            padding-right: 0;
            width: 100%;
        }

        &__btn-keep-shopping {
            width: 100%;
        }

        &__sales-line {
            padding-bottom: $msv-details-sales-line-padding-bottom-mobile;

            &-btn-buy-it-again {
                display: block;
                left: 0;
                margin-left: $msv-details-sales-line-btn-buy-it-again-margin-left-mobile;
                top: 0;
                width: auto;
            }
        }

        .msc-cart-line {
            width: 100%;

            &__product-price {
                position: relative;
                text-align: left;
                margin-left: 0;
                width: 0;
            }

            &__product-image img {
                max-width: $msv-details-sale-line-image-size-mobile;
                width: $msv-details-sale-line-image-size-mobile;
                height: $msv-details-sale-line-image-size-mobile;
            }
        }

        .msc-empty_image {
            @include image-placeholder($msv-order-empty-image-width-mobile);
        }
    }
}
