/* -------------------------------------------------------
   Start of _utilities
   ------------------------------------------------------- */
// NOTES for the display utilities
// They are located on the bootstrap grid css (css/bootstrap-grid.css)
// No need to add on this one but it is on the calgary.ca-client-side repo (_utilities.scss)

// Backgrounds
.bg-white { background: $coc-default-background !important; }
.bg-light { background: $bg-light !important; }
.bg-medium { background: $bg-medium !important; }
.bg-dark { background: $bg-dark !important; }
.bg-transparent { background: transparent !important; }

// TODO: MC.2019.03.05: This may be deprecated as part of the styling cleanup.
.bg-darkest {
	background: $bg-darkest !important;
	color: $white;
}

@media print {
	.bg-medium,
	.bg-light {
		background: transparent !important;
	}
}

// Borders
.no-border { border: none !important; }
.border-transparent { border: $border-thin solid transparent !important; }
.border-light { border: $border-thin solid $border-color-light !important; }
.border-medium { border: $border-thin solid $border-color-medium !important; }
.border-dark { border: $border-thin solid $border-color-dark !important; }

@media print {
	.border-light,
	.border-medium,
	.border-dark {
		border-color: $print-border-color !important;
	}
}

// Example usage: footer
.text-large { font-size: $large-font-size; }
.text-larger { font-size: $larger-font-size; }

// Text will only become large at the specified breakpoint
// MC.2019.01.04: The -lg suffix is to match the BS4 breakpoint, not PL's, as PL's is outdated.
@media( min-width: $breakpoint-lg ) {
	.text-large-lg {
		font-size: $large-font-size;
	}
}
// Text style
.font-italic {
	font-style: italic !important;
}

// font weight
.font-weight-normal {
	font-weight: $font-weight-normal !important;
}

.font-weight-bold {
	font-weight: $font-weight-semi-bold !important;
}

.font-weight-bolder {
	font-weight: $font-weight-bold !important;
}

// Text alignment
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }
.text-center { text-align: center !important; }
.text-justify { text-align: justify !important; }
.text-nowrap { white-space: nowrap !important; }

// vertical alignment
.vertical-top {
	vertical-align: top;
}

.vertical-center {
	vertical-align: center;
}

.vertical-bottom {
	vertical-align: bottom;
}

// Floats
.float-left { float: left !important; }
.float-right { float: right !important; }

// Clearfix
.clearfix { @include clearfix(); }

// Sets the maxwidth of the element to be consistent with all of the other max-widths applied to
// elements such as <p>, <blockquote>, and headings inside <article> tags
.set-max-width {
	@include generateBlockMaxWidth();
}

.unset-max-width {
	max-width: none !important;
}

// A util class for elements that have manually been made focusable for
// accessibility but are not a native focusable element (ex. not a <button>,
// <input>, or <a>). The element will need a tabindex attribute set.
.set-focus {
	&:focus {
		@include setOutlineGlow();
	}
}

// TODO: MC.2019.01.08: Consider using mixins to generate the following so it's easier to maintain and update.

// No margin
.m-0 { margin: 0 !important; }

// Margin-top
.mt-xxs { margin-top: $spacer-xxs !important; }
.mt-xs { margin-top: $spacer-xs !important; }
.mt-sm { margin-top: $spacer-sm !important; }
.mt-md { margin-top: $spacer-md !important; }
.mt-lg { margin-top: $spacer-lg !important; }
.mt-xl { margin-top: $spacer-xl !important; }
.mt-xxl { margin-top: $spacer-xxl !important; }
.mt-0 { margin-top: 0 !important; }

// Margin-right
.mr-xxs { margin-right: $spacer-xxs !important; }
.mr-xs { margin-right: $spacer-xs !important; }
.mr-sm { margin-right: $spacer-sm !important; }
.mr-md { margin-right: $spacer-md !important; }
.mr-lg { margin-right: $spacer-lg !important; }
.mr-xl { margin-right: $spacer-xl !important; }
.mr-xxl { margin-right: $spacer-xxl !important; }
.mr-0 { margin-right: 0 !important; }

// Margin-bottom
.mb-xxs { margin-bottom: $spacer-xxs !important; }
.mb-xs { margin-bottom: $spacer-xs !important; }
.mb-sm { margin-bottom: $spacer-sm !important; }
.mb-md { margin-bottom: $spacer-md !important; }
.mb-lg { margin-bottom: $spacer-lg !important; }
.mb-xl { margin-bottom: $spacer-xl !important; }
.mb-xxl { margin-bottom: $spacer-xxl !important; }
.mb-0 { margin-bottom: 0 !important; }

.mb-responsive {
	margin-bottom: $spacer-md !important;
	@media( min-width: $breakpoint-lg ) { margin-bottom: $spacer-lg !important; }
	@media( min-width: $breakpoint-xl ) { margin-bottom: $spacer-xl !important; }
}

// Margin-left
.ml-xxs { margin-left: $spacer-xxs !important; }
.ml-xs { margin-left: $spacer-xs !important; }
.ml-sm { margin-left: $spacer-sm !important; }
.ml-md { margin-left: $spacer-md !important; }
.ml-lg { margin-left: $spacer-lg !important; }
.ml-xl { margin-left: $spacer-xl !important; }
.ml-xxl { margin-left: $spacer-xxl !important; }
.ml-0 { margin-left: 0 !important; }

// Margin-left and margin-right
.mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.pad-xxs,
.p-xxs {
	padding: $spacer-xxs !important;
}

.pad-xs,
.p-xs {
	padding: $spacer-xs !important;
}

.pad-sm,
.p-sm {
	padding: $spacer-sm !important;
}

.pad-md,
.p-md {
	padding: $spacer-md !important;
}

.pad-lg,
.p-lg {
	padding: $spacer-lg !important;
}

.pad-xl,
.p-xl {
	padding: $spacer-xl !important;
}

.pad-xxl,
.p-xxl {
	padding: $spacer-xxl !important;
}

.p-0 { padding: 0 !important; }

.pr-0,
.px-0 { padding-right: 0 !important; }

.pb-0,
.py-0 { padding-bottom: 0 !important; }

.pl-0,
.px-0 { padding-left: 0 !important; }

.pt-0 {
	padding-top: 0 !important;
}

.pt-xxs {
	padding-top: $spacer-xxs !important;
}

.pt-xs {
	padding-top: $spacer-xs !important;
}

.pt-sm {
	padding-top: $spacer-sm !important;
}

.pt-md {
	padding-top: $spacer-md !important;
}

.pt-lg {
	padding-top: $spacer-md !important;
}

.pt-xl {
	padding-top: $spacer-md !important;
}

.py-xxs {
	padding-top: $spacer-xxs !important;
	padding-bottom: $spacer-xxs !important;
}

.py-xs {
	padding-top: $spacer-xs !important;
	padding-bottom: $spacer-xs !important;
}

.py-sm {
	padding-top: $spacer-sm !important;
	padding-bottom: $spacer-sm !important;
}

.py-md {
	padding-top: $spacer-md !important;
	padding-bottom: $spacer-md !important;
}

.py-lg {
	padding-top: $spacer-lg !important;
	padding-bottom: $spacer-lg !important;
}

.py-xl {
	padding-top: $spacer-xl !important;
	padding-bottom: $spacer-xl !important;
}

.py-xxl {
	padding-top: $spacer-xxl !important;
	padding-bottom: $spacer-xxl !important;
}

// padding right
.pr-xxs {
	padding-right: $spacer-xxs !important;
}

.pr-xs {
	padding-right: $spacer-xs !important;
}

.pr-sm {
	padding-right: $spacer-sm !important;
}

.pr-md {
	padding-right: $spacer-md !important;
}

.pr-lg {
	padding-right: $spacer-lg !important;
}

.pr-xl {
	padding-right: $spacer-xl !important;
}

.pr-xxl {
	padding-right: $spacer-xxl !important;
}

// padding left
.pl-xxs {
	padding-left: $spacer-xxs !important;
}

.pl-xs {
	padding-left: $spacer-xs !important;
}

.pl-sm {
	padding-left: $spacer-sm !important;
}

.pl-md {
	padding-left: $spacer-md !important;
}

.pl-lg {
	padding-left: $spacer-lg !important;
}

.pl-xl {
	padding-left: $spacer-xl !important;
}

.pl-xxl {
	padding-left: $spacer-xxl !important;
}

// padding bottom
.pb-xxs {
	padding-bottom: $spacer-xxs !important;
}

.pb-xs {
	padding-bottom: $spacer-xs !important;
}

.pb-sm {
	padding-bottom: $spacer-sm !important;
}

.pb-md {
	padding-bottom: $spacer-md !important;
}

.pb-lg {
	padding-bottom: $spacer-lg !important;
}

.pb-xl {
	padding-bottom: $spacer-xl !important;
}

.pb-xxl {
	padding-bottom: $spacer-xxl !important;
}

.pb-xxxl {
	padding-bottom: $spacer-xxxl !important;
}

.pb-xxxxl {
	padding-bottom: $spacer-xxxxl !important;
}


// `.cui-width-x` classes are used throughout PL documenation and image examples
.cui-width-100 { width: 100% !important; }
.cui-width-75 { width: 75% !important; }
.cui-width-50 { width: 50% !important; }
.cui-width-25 { width: 25% !important; }

// Example usage: carousel
.cui-height-100 { height: 100%; }


// -------------------------------------------------------
// Grid
//
// -------------------------------------------------------

// No gutters for the row and child columns
// `.no-gutters` is the class from BS4.
.no-gutters {
	margin-right: 0;
	margin-left: 0;

	> .col,
	> [class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
}

// Compacted gutters for the row and child columns
.row-compact {
	@extend %row-compact;
}

// MC.2018.05.23: Used for One Calgary grid and SP new grid option
// Use as child of .row, do not use with other .col-* classes
// This is a stand-alone column that is sized to be ~60-91% width and centred
.col-single-narrow {
	@include renderColBasics();

	@media( min-width: $breakpoint-sm ) {
		$col-size: 11;
		@include calcColWidth( $col-size );
		@include calcColOffset( $col-size );
	}

	@media( min-width: $breakpoint-md ) {
		$col-size: 9;
		@include calcColWidth( $col-size );
		@include calcColOffset( $col-size );
	}

	@media( min-width: $breakpoint-lg ) {
		$col-size: 8;
		@include calcColWidth( $col-size );
		@include calcColOffset( $col-size );
	}

	@media( min-width: $breakpoint-xl ) {
		$col-size: 7;
		@include calcColWidth( $col-size );
		@include calcColOffset( $col-size );
	}
}// End of .col-single-narrow

// A utility class that can be applied to anything to make it
// go all the way to the edge of the browser. Same as .container-full-width
.edge-to-edge {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}

// -------------------------------------------------------
// Lists
//
// -------------------------------------------------------

// MC.2019.02.04: There are cases when the bullets are not desired for visual purposes.
// Ex. a list of links where each link has a PDF icon. The PDF icon acts visually like the
// bullet so the bullet itself is not necessary and can be hidden.
.list-unstyled {
	@include listUnstyled();
}


// -------------------------------------------------------
// Columns
//
// -------------------------------------------------------

[class*="col-count-"] {
	column-gap: $gutter;

	li,
	dt,
	dd {
		// Prevent list items from splitting or breaking across columns
		break-inside: avoid;
	}
}

.col-count-2 {
	column-count: 2;
}

.col-count-3 {
	column-count: 3;
}

@media( min-width: $breakpoint-md ) {
	.col-count-md-2 {
		column-count: 2;
	}

	.col-count-md-3 {
		column-count: 3;
	}
}


// -------------------------------------------------------
// Background image and positions
//
// Used for elements with a background image
// -------------------------------------------------------

.background-image {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	display: block;
}

.bg-contain {
	background-size: contain !important; //Resize the background image to make sure the image is fully visible
}

.bg-auto {
	background-size: auto !important; //The background image is displayed in its original size
}

.ratio-custom-sm { height: $ratio-custom-sm; }
.ratio-custom-md { height: $ratio-custom-md; }
.ratio-custom-lg { height: $ratio-custom-lg; }

.ratio-custom-sm,
.ratio-custom-md,
.ratio-custom-lg {
	//575px
	@media( max-width: $breakpoint-xs-max ) {
		padding-bottom: $ratio-percent-16x9;
		height: 0;
	}
}

.ratio-21x9 { padding-bottom: $ratio-percent-21x9; }
.ratio-16x9 { padding-bottom: $ratio-percent-16x9; }
.ratio-4x3 { padding-bottom: $ratio-percent-4x3; }
.ratio-3x4 { padding-bottom: $ratio-percent-3x4; }
.ratio-5x6 { padding-bottom: $ratio-percent-5x6; }
.ratio-1x1 { padding-bottom: $ratio-percent-1x1; }

.bg-pos-x-left { background-position-x: left !important; }
.bg-pos-x-center { background-position-x: center !important; }
.bg-pos-x-right { background-position-x: right !important; }

.bg-pos-y-top { background-position-y: top !important; }
.bg-pos-y-center { background-position-y: center !important; }
.bg-pos-y-bottom { background-position-y: bottom !important; }


// -------------------------------------------------------
// Visibility
//
// -------------------------------------------------------

// Only display content to screen readers
// See: http://a11yproject.com/posts/how-to-hide-content
.sr-only { @include setSROnly(); }

// MC.2019.03.28: Don't think this class is used.
// Instead of using .hidden, please use .d-none (from bootstrap-grid.css).
.hidden { display: none !important; }

// Display utility classes available from bootstrap-grid.css
// -------------------------------------------------------
// Display utilities
// -------------------------------------------------------
.d-inline { display: inline !important; }
.d-inline-block { display: inline-block !important; }
.d-block { display: block !important; }
.d-flex { @include flexboxImportant(); }
.d-table { display: table !important; }
.d-none { display: none !important; }

//576px
@media( min-width: $breakpoint-sm ) {
	.d-sm-inline { display: inline !important; }
	.d-sm-inline-block { display: inline-block !important; }
	.d-sm-block { display: block !important; }
	.d-sm-flex { @include flexboxImportant(); }
	.d-sm-table { display: table !important; }
	.d-sm-none { display: none !important; }
}
//768px
@media( min-width: $breakpoint-md ) {
	.d-md-inline { display: inline !important; }
	.d-md-inline-block { display: inline-block !important; }
	.d-md-block { display: block !important; }
	.d-md-flex { @include flexboxImportant(); }
	.d-md-table { display: table !important; }
	.d-md-none { display: none !important; }
}
//992px
@media( min-width: $breakpoint-lg ) {
	.d-lg-inline { display: inline !important; }
	.d-lg-inline-block { display: inline-block !important; }
	.d-lg-block { display: block !important; }
	.d-lg-flex { @include flexboxImportant(); }
	.d-lg-table { display: table !important; }
	.d-lg-none { display: none !important; }
}
//1200px
@media( min-width: $breakpoint-xl ) {
	.d-xl-inline { display: inline !important; }
	.d-xl-inline-block { display: inline-block !important; }
	.d-xl-block { display: block !important; }
	.d-xl-flex { @include flexboxImportant(); }
	.d-xl-table { display: table !important; }
	.d-xl-none { display: none !important; }
}

