/* -------------------------------------------------------
   Start of _wordmark
   ------------------------------------------------------- */

span.wordmark-text {
	min-width: 350px !important;
}

.wordmark {
	background: $coc-secondary-9L;
	margin-bottom: $spacer-sm;
	position: relative;

	&::before,
	&::after {
		content: '';
		display: block;
		position: absolute;
	}

	&::before {
		// The red band on the left side
		background: $coc-main;
		width: $spacer-sm;
		height: 100%;
		z-index: $wordmark-left-accent-z;
	}

	&::after {
		// The top and bottom borders
		// MC.2018.04.02: The border is not applied directly to the base element as this causes an optical illusion
		// that makes the empty-right hand side appear taller than the red band and dark grey text box.
		// The illusion is due to the border appearing above the red ban and dark grey text box.
		// The fix below positions the border so it's flush with the other components.
		border-width: $border-thin 0;
		border-style: solid;
		border-color: $coc-secondary-8L;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: $wordmark-border-z;
	}

	.wordmark-text {
		color: $coc-default-color;
		display: inline-block;
		font-weight: $font-weight-semi-bold;
		height: 100%;
		padding: $tiny-spacer-xs $spacer-sm $tiny-spacer-xs $spacer-md;
		position: relative;
		text-transform: uppercase;
		z-index: $wordmark-text-z;
	}

	@media( min-width: $breakpoint-lg ) {
		.wordmark-text {
			background: $coc-secondary;
			color: $coc-secondary-10L;
			font-size: $large-font-size;
			font-weight: $font-weight-bold;
			min-width: $nav-sec-width-md;// Min-width to match the width of the secondary nav
			padding-top: $tiny-spacer-sm;
			padding-bottom: $tiny-spacer-sm;
			padding-left: $spacer-lg;
		}
	}

	// -------------------------------------------------------
	// Print
	//
	// -------------------------------------------------------

	@media print {
		border: $print-border;
		background: transparent;

		&::before {
			display: none;
		}

		&::after {
			border: 0;
		}

		.wordmark-text {
			background: transparent;
			padding-left: $spacer-sm;
		}
	}
}// End of .wordmark
