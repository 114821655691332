/* -------------------------------------------------------
   Start of _lists
   ------------------------------------------------------- */

ol,
ul {
	margin-top: 0;
	margin-bottom: $spacer-md;
	overflow: visible;
}

nav ul {
	list-style: none;
}

ol li {
	list-style: decimal;
}

ul li {
	list-style: disc;
}

ul ul {
	list-style: circle;
}

ul ul,
ul ol,
ol ul,
ol ol {
	margin-bottom: 0;
}

// List style classes
.list-style-dot li {
	list-style-type: disc !important;
}

.list-style-square li {
	list-style-type: square !important;
}

.list-style-upper-roman li {
	list-style-type: upper-roman !important;
}


.list-style-lower-alpha li {
	list-style-type: lower-alpha !important;
}

.list-style-upper-alpha li {
	list-style-type: upper-alpha !important;
}

.list-style-decimal {
	list-style-type: decimal !important;
}

// Definition Lists
dl {
	@include generateBlockMaxWidth();
	margin: 0 0 $spacer-md;
	overflow: hidden;

	dt {
		font-weight: $font-weight-bold;
	}

	dd {
		margin-bottom: $spacer-xs;
	}
}
