/* -------------------------------------------------------
   Start of _menu-mega
   ------------------------------------------------------- */
// menu close and open buttons
.cui[class*="nav-mega-btn-"] {
	@include align-items(center);
	color: $coc-main;
	height: $mega-nav-btn-height;
	@include justify-content(center);
	margin-right: $header-gutter;
	margin-left: auto;
	padding: $tiny-spacer-xs $spacer-xxs $spacer-xxs $spacer-xxs;
	transition-property: background;
	border-style: none;
	background: none;

	&:hover {
		color: $coc-default-color;
	}
}

.cui.nav-mega-btn-open {
	display: grid;
	@include flex-grow (0);
	@include flex-shrink (0);
	width: $mega-nav-btn-height; // The button will be square
	font-size: $mega-nav-menu-header-font-size-mobile-btn;

	.cicon-bars {
		font-size: $cicon-size-md;
	}
}

.cui.nav-mega-btn-close {
	// MC.2019.03.28: The vertical margins below are set in order for the close
	// button to be consistently placed with the open button AND to be vertically
	// aligned with the logo. Beginning at the small breakpoint, the logo overlaps
	// the header. However, when the menu is opened, the close button will be
	// vertically center-aligned with the logo, so the logo does not overlap the menu.
	//@include flexbox();
	display: block;
	$margin-y: ($coc-logo-height-rem - $mega-nav-btn-height) / 2;
	margin-top: $margin-y;
	margin-bottom: $margin-y;
	padding: $spacer-xxs;
	font-size: $mega-nav-default-font-size;
	text-decoration: underline;
	box-shadow: none;
	background: $bg-light;

	[class*="cicon"] {
		font-size: $cicon-size-xs;
		margin-left: $tiny-spacer-sm;
	}

	&:hover {
		background: $bg-medium;
		color: $coc-main;
	}

	&:focus {
		@include setOutlineGlow;
	}

	@media only screen and ( min-width: $breakpoint-md ) {
		$margin-y: ($coc-logo-height-sm-rem - $mega-nav-btn-height) / 2;
		margin-top: $margin-y;
		margin-bottom: $margin-y;
	}
}

@media only screen and ( min-width: $breakpoint-lg ) {
	.cui.nav-mega-btn-open,
	.cui.nav-mega-btn-close {
		display: none;
	}

	.menu-header span {
		display: none;
	}
}

// menu list
.cui.nav-mega {
	background: $bg-medium;
	position: absolute; // Position absolutely to ensure the navigation hides the logo and other heading elements on smaller screens
	top: 0;
	left: 0;
	width: 100%;
	display: none;

	&.active {
		display: block;
	}

	ul {
		@include listUnstyled();
	}

	a {
		transition-property: background, color, border;
	}

	.cui.btn-close-sub-nav-group {
		background: transparent;
		border: 0;
		border-radius: 0;
		display: none;
		padding: $tiny-spacer-sm;
		position: absolute;
		top: $spacer-xs;
		right: $spacer-xs;

		[class*="cicon"] {
			display: inline-block;
			font-size: $cicon-size-xs;
			margin-left: $tiny-spacer-sm;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.nav-menu {
		display: block;
		margin: 0;
		position: absolute;
		left: 0;
		width: 100%;
		height: 0;
	}

	.nav-item {
		> a {
			color: $black;
			display: block;
			font-weight: $font-weight-semi-bold;
			padding: $spacer-xs $spacer-sm;
			text-decoration: none;
			border-bottom: $border-width-md solid transparent;

			&:hover,
			&.open {
				border-bottom-color: $coc-main;
				color: $coc-main;
			}

			&:focus {
				// Allows for the focus box-shadow to be visible because the element is raised up 1 level
				position: relative;
				z-index: $focus-z;
			}

			[class*="cicon-angle-"] {
				font-size: $mega-nav-arrow-size-desktop;
			}

			.cicon-angle-down {
				padding: $tiny-spacer-xs;
			}
		}
	}

	// End of .nav-item

	.sub-nav {
		background: $white;
		display: none;
		position: relative;
		width: 100%;

		&.open {
			display: block;

			&:focus,
			[aria-labelledby]:focus {
				@include setOutlineGlow;
			}
		}

		ul.row.nav-group {
			max-width: 1200px;
			margin: 0 auto;
		}
	}

	.sub-nav-group {
		display: block;
		margin: 0;
		width: 100%;
	}

	.group-links {
		strong {
			font-weight: $font-weight-semi-bold;
		}
	}

	// Menu style on the mobile and tablet
	@media only screen and ( max-width: $breakpoint-md-max ) {
		.menu-header {
			span {
				@include flexbox;
				background: $bg-medium;
				border-bottom: $border-width-md solid $border-color-medium;
				color: $black;
				font-weight: $font-weight-semi-bold;
				padding: $spacer-xs $spacer-sm;
				text-decoration: none;
				font-size: $mega-nav-menu-header-font-size;
			}
		}

		.nav-item {
			> a {
				@include align-items(center);
				background: $bg-medium;
				@include flexbox();
				border-bottom: $border-width-md solid $border-color-medium;
				padding-left: $mega-nav-header-padding-sm;

				&:hover,
				&.open {
					background: $white;
				}

				&.open {
					.cicon-angle-down {
						transform: scale( -1, -1 ); // Transforms the arrow so it's upside down
					}
				}

				.cicon-angle-down {
					margin-left: auto;
				}

				[class*="cicon-angle-"] {
					font-size: $mega-nav-arrow-size-mobile;
				}
			}
		}

		// End of .nav-item

		.sub-nav-group {
			padding: 0; // Only required for mobile to allow the col-* padding to take effect for larger screens
		}

		.cui.catalogue-group {
			.group-title {
				border-bottom: $border-thin solid $border-color-medium;
				padding-top: $spacer-xxs;
				padding-bottom: $spacer-xxs;
				padding-left: $mega-nav-header-padding-md;
				display: inline-block;
				width: 100%;

				[class*="cicon-"] {
					font-size: $cicon-size-sm;
					display: inline-block;
				}
			}

			a.group-title {
				&:hover {
					background: $bg-medium;
				}

				.group-title-text {
					display: inline-block;
					font-size: $base-font-size;
					margin-bottom: 0;

					&::after {
						content: '';
						display: inline-block;
					}
				}
			}

			.group-links {
				li {
					// Remove margin-bottom added by default in
					// catalogue groups for mobile.
					margin-bottom: 0;
				}

				a {
					border-bottom: $border-thin solid $border-color-medium;
					@include flexbox();
					padding: $spacer-xxs 0 $spacer-xxs $mega-nav-header-padding-lg;

					&:hover {
						background: $bg-medium;
					}
				}

				.group-links {
					a {
						padding-left: $mega-nav-header-padding-lg;
					}
				}
			}
		}
	}// End of max-width: $breakpoint-md-max

	@media only screen and ( min-width: $breakpoint-lg ) {
		display: block;
		position: relative;

		.nav-menu {
			background: $bg-medium;
			height: auto;
			position: relative;
		}

		.nav-item {
			display: inline-block;

			> a {
				border-bottom-width: $border-width-lg;
				border-bottom-color: transparent;
			}
		}

		.sub-nav {
			border-bottom: $border-thin solid $border-color-medium;
			padding: $spacer-xl $spacer-xs $spacer-lg;
			position: absolute;
			top: 100%;
			left: 0;
		}

		.sub-nav-group {
			//border-right: $border-thin solid $border-color-medium;
			border-right: none;

			&:last-child {
				border-right: 0;
			}

			ul li {
				$col-size: 12;
				@include calcColWidth( $col-size );
				// min-width: 100%;
			}
		}

		.cui.btn-close-sub-nav-group {
			display: block;
		}

		.catalogue-group {
			.group-links {
				margin-bottom: $spacer-xl;
			}
		}
	}
}

// The backdrop for when the mega menu / secondary nav are open
// Toggled on via JavaScript
.cui.nav-backdrop {
	background-color: $black;
	display: none;
	opacity: 0.75;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $header-menu-bg-overlay-z;
	width: 100%;
}


// -------------------------------------------------------
// Print
//
// -------------------------------------------------------

@media print {
	.cui[class*="nav-mega-btn-"] {
		display: none;
	}
}
