.msc-invoice-details {
    margin-top: 40px;
    padding-right: 40%;
    @media only screen and (max-width: $msv-breakpoint-l) {
        padding-right: 15px;
        padding-left: 15px;
    }
    &__header {
        padding-bottom: 30px;
        display: flex;
        flex-flow: column;
        // border-bottom: 1px solid $msv-divider-color;
        &__invoice-id {
            font-size: $msv-font-size-l;
            font-weight: $msv-font-weight-heavy;
            line-height: $msv-line-height-l;
            margin-top: 10px;
            order: 2;
        }

        &__invoice-info-container {
            order: 1;
            @media only screen and (min-width: $msv-breakpoint-l) {
                display: flex;
                justify-content: space-between;
            }

            &__order-info-container {
                &__order-info {
                    // margin-top: 22px;
                    &__main-info {
                        display: none;
                        font-size: 18px;
                        line-height: 24px;
                        min-height: 32px;
                    }

                    &__details {
                        font-size: $msv-font-size-m;
                        line-height: $msv-line-height-m;
                        display: flex;

                        &__order-date {
                            font-size: $msv-font-size-xxl;
                            font-weight: $msv-font-weight-heavy;
                            line-height: $msv-line-height-46;
                            // min-height: 32px;
                            @media only screen and (max-width: $msv-breakpoint-m) {
                                font-size: 38px;
                            }
                        }

                        &__order-items-count {
                            display: none;
                        }
                    }
                }
            }

            &__amount-summary {
                display: none;
                @media only screen and (min-width: $msv-breakpoint-l) {
                    text-align: right;
                }

                @media only screen and (max-width: $msv-breakpoint-l) {
                    margin-top: 30px;
                }

                &__amount-due-text {
                    font-size: 18px;
                    line-height: 24px;
                    min-height: 32px;
                }

                &__amount-due-amount {
                    font-size: 36px;
                    line-height: 47px;
                    min-height: 60px;
                }

                &__amount-due-date {
                    min-height: 32px;
                    font-size: 16px;
                    line-height: 21px;
                    font-weight: $msv-font-weight-light;
                }
            }
        }

        &__actions {
            display: none;
            &__pay-invoice {
                @include button-primary();

                @media only screen and (max-width: $msv-breakpoint-l) {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }
    }

    &__products {
        padding-top: 40px;
        &__product-line {
            display: flex;
            justify-content: space-between;
            padding-bottom: 15px;
            position: relative;
            border-bottom: 1px solid $msv-gray-300;
            margin-bottom: 30px;
            &__product-info-container {
                display: flex;
                > picture {
                    width: 150px;
                    margin-right: 15px;
                }

                &__product-details {
                    padding: 0 20px;
                    width: calc(100% - 150px);
                    &__name {
                        text-decoration: none;
                        font-weight: $msv-font-weight-bold;
                        font-size: $msv-font-size-l;
                        line-height: $msv-line-height-l;
                    }

                    &__small-details {
                        font-size: $msv-font-size-m;
                        line-height: $msv-line-height-l;
                        margin-bottom: 30px;
                        padding-right: 50px;
                        word-break: break-all;
                    }
                    &__other-details {
                        font-size: $msv-font-size-m;
                        line-height: $msv-line-height-l;
                    }
                }

                &__name {
                    font-weight: $msv-font-weight-bold;
                    font-size: $msv-font-size-l;
                    line-height: $msv-line-height-l;
                }
                @media only screen and (max-width: $msv-breakpoint-m) {
                    flex-flow: column;
                    &__product-details {
                        width: 100%;
                        padding: 0;
                    }
                }
            }

            &__total-price {
                font-weight: $msv-font-weight-bold;
                font-size: $msv-font-size-l;
                line-height: $msv-line-height-l;
                position: absolute;
                right: 0;
                top: 50px;
            }
        }
    }

    .sub-title {
        font-weight: $msv-font-weight-heavy;
        margin: 30px 0 0;
    }

    &__payment-details {
        border: 1px solid $msv-divider-color;
        padding: 20px 15px;
        width: 50%;
        &__line {
            display: flex;
            justify-content: space-between;
            padding-bottom: 15px;

            &__label {
                font-size: $msv-font-size-m;
                line-height: $msv-line-height-s;
            }

            &__value {
                font-size: $msv-font-size-m;
                line-height: $msv-line-height-s;
            }

            &-total-amount {
                padding-bottom: 0;
                padding-top: 5px;
                div {
                    font-size: $msv-font-size-xl;
                    font-weight: $msv-font-weight-bold;
                    line-height: $msv-line-height-xl;
                }
            }
        }
        @media only screen and (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }
    select{
        padding: 10px;
        border-color: #c8102e;
    }
}

.hideTable{
    display: none;
}