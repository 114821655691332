/* -------------------------------------------------------
   Start of _bar-chart
   ------------------------------------------------------- */

// The max breakpoint width when the table is in 'stacked' view
$max-breakpoint-stacked: $breakpoint-md-max;

// The breakpoint width when the table goes to 'normal' view
$min-breakpoint-normal: $breakpoint-lg;

.bar-chart-title {
	//margin-bottom: $spacer-xs;
	font-size: $larger-font-size;
	font-weight: $font-weight-bold;
	text-align: left;
	margin-bottom: $spacer-md;

	@media( min-width: $breakpoint-sm ) {
		text-align: center;
	}
}

.cui.bar-chart-input {
	// -ms-flex-align: center;
	// align-items: center;
	@include flexbox();
	margin-bottom: $spacer-xxs;

	@media ( min-width: $min-breakpoint-normal ) {
		margin-bottom: $spacer-lg;
	}
}// End of .calculator

.bar-chart {
	// There are 3 colours available for the bar graph:
	// 1. Normal (light blue)
	// 2. Darker (dark blue)
	// 3. Highlight (striped orange)
	.data-bar {
		height: $data-bar-height;
	}

	.data-bar-inner {
		background: $coc-secondary-blue-L;
		height: 100%;

		&.highlight {
			background: repeating-linear-gradient( 45deg, $coc-secondary-orange-L, $coc-secondary-orange-L $bar-chart-hightlight-width, $white $bar-chart-hightlight-width, $white $spacer-xxs );
		}

		&.darker {
			background: $coc-secondary-blue-D;
		}
	}
}// End of .bar-chart

.bar-chart-table {
	width: 100%;
	font-size: $small-font-size;
	font-weight: $font-weight-normal;
	border-collapse: collapse;
	text-align: left;

	a {
		color: $coc-secondary-2D;

		&:hover,
		&:visited {
			color: $coc-secondary-2D;
		}
	}

	caption {
		@include tableCaption;
	}

	thead {
		border-bottom: $bar-chart-border-thick;
		padding: $spacer-xxs $tiny-spacer-xs;

		@media ( max-width: $max-breakpoint-stacked ) {
			// Visually hide the thead for smaller screens
			border: 0;
			margin: 0;
			overflow: hidden;
			padding: 0;
			visibility: hidden;
			width: 0;
			height: 0;

			tr,
			th {
				font-size: 0;
				width: 0;
				height: 0;
			}
		}
	}

	th { font-weight: $font-weight-bold; }

	tr {
		@include renderRow();
		border-bottom: $bar-chart-border-thin;
	}

	th,
	td {
		// Explicitly override browser defaults. Required for IE11.
		// Interactive bar chart table is not accurate of screen reader: PLPL-262
		display: inline-block;
		font-size: $small-font-size;
		padding: $tiny-spacer-xs;

		@media ( min-width: $min-breakpoint-normal ) {
			padding: $spacer-xxs;
		}
	}

	.output-data {
		display: none;

		&.show {
			display: inline-block;
		}
	}

	.action-column {
		text-align: right;

		.cui.btn-sm {
			@media ( max-width: $breakpoint-sm-max ) {
				[class*="cicon"] {
					margin-left: 0;
				}

				.text {
					@include setSROnly;
				}
			}
		}
	}

	//when details are shows on page
	.more-details.active {
		background: $coc-secondary-10L;
		border-bottom: none;

		th,
		.output-data {
			font-weight: $font-weight-bold;
		}
	}

	//show details table row
	.expand-row {
		display: none;

		&.active {
			@include flexbox();
			background: $coc-secondary-10L;
			border-bottom: none;

			th,
			.data {
				font-weight: $font-weight-normal;
			}

			th {
				@media ( min-width: $min-breakpoint-normal ) {
					// Indent the text
					padding-left: $spacer-sm;
				}
			}

			th,
			td {
				padding-top: $tiny-spacer-xs;
				padding-bottom: $tiny-spacer-xs;
			}

			.action-column {
				display: none;
			}
		}
	}

	.raw-data {
		span {
			display: inline-block;
			padding: 0;// Overrides padding from the .col-* classes applied
		}

		.data {
			font-weight: $font-weight-bold;
			font-size: $base-font-size;
		}

		.data,
		.data-bar {
			@media ( min-width: $min-breakpoint-normal ) {
				float: left;
			}
		}
	}

	.cui.btn-sm.active [class*="cicon-"] {
		transform: scale( -1, -1 );
	}
}// End of .bar-chart-table

.bar-chart-legend {
	font-size: $small-font-size;
	padding: $spacer-sm 0;
	border-bottom: $bar-chart-border-thick-light;

	.legend-title {
		text-align: center;
		font-size: $small-font-size;
		font-weight: $font-weight-semi-bold;
	}

	.data-bar-inner {
		display: block;
		width: $spacer-sm;
		height: $spacer-sm;
		margin-right: $tiny-spacer-sm;
		float: left;
		@include clearfix;
	}
}// End of .bar-chart-legend
