/* -------------------------------------------------------
   Start of _images
   ------------------------------------------------------- */

img {
	height: auto;
	max-width: 100%;

	&.float-left {
		margin-right: $spacer-xxs;
		margin-bottom: $spacer-xxs;
	}

	&.float-right {
		margin-left: $spacer-xxs;
		margin-bottom: $spacer-xxs;
	}

	@media( min-width: $breakpoint-md ) {
		&.float-left {
			margin-right: $spacer-sm;
			margin-bottom: $spacer-sm;
		}

		&.float-right {
			margin-left: $spacer-sm;
			margin-bottom: $spacer-sm;
		}
	}
}
