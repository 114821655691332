$msv-details-heading-margin-bottom: 20px;
$msv-details-heading-margin-top: 20px;
$msv-details-text-margin-right: 5px;
$msv-details-container-max-width: 794px;
$msv-details-order-information-half-width: 50%;
$msv-details-order-information-padding-bottom: 20px;
$msv-details-order-information-spacing: 8px;
$msv-details-order-information-sales-id-margin-bottom: 10px;
$msv-details-keep-shopping-btn-margin-bottom: 20px;
$msv-details-group-border: 1px solid $msv-black;
$msv-details-group-padding: 20px 0;
$msv-details-group-pickup-border: 1px solid $msv-gray-300;
$msv-details-group-delivery-items-text-color: $msv-gray-500;
$msv-details-group-delivery-padding-top: 20px;
$msv-details-group-delivery-width: 340px;
$msv-details-sale-lines-padding: 12px 0 0 0;
$msv-details-sale-line-width: 81px;
$msv-details-sale-line-price-left: 210px;
$msv-details-sale-line-spacing: 20px;
$msv-details-sale-line-margin: 0 12px 20px 0;
$msv-details-sale-line-item-width: 770px;
$msv-details-sale-line-buy-again-btn-margin-top: 20px;
$msv-details-sale-line-buy-again-btn-width: 140px;
$msv-order-empty-image-width: 81px;
$msv-details-order-summary-price-padding: 5px;
$msv-details-order-summary-spacing: 10px;
$msv-details-order-summary-heading-margin-bottom: 12px;
$msv-details-order-summary-line-spacing: 8px;
$msv-details-button-margin-top: 12px;
$msv-details-help-margin-top: 20px;
$msv-details-help-heading-margin-bottom: 4px;
$msv-details-payment-methods-margin: 20px 20px 20px 0;
$msv-details-payment-methods-margin-right: 20px;
$msv-details-payment-methods-heading-margin-bottom: 12px;
$msv-details-payment-methods-line-space: 8px;
$msv-details-payment-method-padding-m: 20px 0 10px 0;
$msv-details-default-padding: 20px 0;
$msv-details-loyalty-padding-right: 10px;
$msv-details-loyalty-margin-top: 20px;
$msv-details-loyalty-points-margin-left: 5px;
$msv-details-shipping-items-label-padding-top: 30px;
$msv-details-shipping-items-label-padding-top-mobile: 20px;
$msv-order-details-alert-margin-top: 20px;
$msv-order-details-alert-margin-bottom: 20px;
$msv-details-footer-block-width: 337px;
$msv-details-order-summary-spacing: 20px;
$msv-details-address-spacing: 20px;
$msv-main-container-margin: 30px auto;
$msv-font-weight-600: 600;
$msv-font-weight-800: 800;
$msv-font-weight-355: 355;

.ms-order-confirmation {
    width: 100%;
    margin: 30px auto;
    position: relative;
    #myProgress {
        width: 100%;
        background-color: $coc-secondary-9D;
        margin-top: 25px;
        border-radius: 4px;
        #myBar {
            width: 1%;
            height: 10px;
            background-color: $coc-secondary-green;
            border-radius: 4px;
        }
    }

    .top-btn-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $msv-gray-100;
        padding: 25px 60px 25px 30px;
        margin-bottom: 50px;
        flex-wrap: wrap;
        .cui[class*='btn'].primary.processing-btn {
            margin-bottom: 0;
            span.spinner-border {
                width: 1rem;
                height: 1rem;
                border: 0.175rem solid $white;
                border-top-color: $coc-main;
                position: relative;
                top: 4px;
                margin-right: 10px;
            }
        }
        p {
            font-size: 1.2rem;
            margin: 0;
        }
        @media (max-width: $msv-breakpoint-m) {
            p {
                margin-bottom: 15px;
            }
        }
        @media (max-width: $msv-breakpoint-sm) {
            padding: 25px 30px 25px 30px;
            p {
                margin-bottom: 25px;
            }
            .cui[class*='btn'].secondary {
                width: 100%;
            }
        }
    }
    .back-shop {
        flex: 0 0 100%;
        justify-content: left;
        border-bottom: 1px solid $msv-gray-500;
        padding: 15px 0;
        .ms-order-confirmation__btn-keep-shopping {
            display: inline-flex;
            padding: 0;
            color: $msv-primary;
            font-weight: 600;
            height: auto;
            &::before {
                @include msv-icon($icon-weight: $msv-solid-icon-weight);
                content: $msv-ChevronLeft;
                margin-right: 10px;
                position: relative;
                top: 3px;
            }
            &:hover {
                text-decoration: none;
            }
        }
    }
    .order-confirmation-wrap {
        display: flex;
        .product-item-box {
            flex: 0 0 75%;
            max-width: 75%;
            padding-right: 50px;
        }
        .order-summary-box {
            flex: 0 0 25%;
            max-width: 25%;
            border: 1px solid $msv-gray-300;
            padding: 15px;
            align-self: flex-start;
            h3 {
                font-weight: 600;
                margin-bottom: 15px;
                font-size: 1.4rem;
            }
            .ms-order-confirmation__order-summary {
                width: 100%;
                padding-bottom: 0;
                padding-top: 0;
                .ms-order-confirmation__order-summary-heading {
                    font-weight: 600;
                    margin-bottom: 15px;
                    font-size: 1.4rem;
                }
                .ms-order-confirmation__item-count {
                    border-bottom: 1px solid $msv-gray-300;
                    padding-bottom: 15px;
                }
            }
        }
        @media (max-width: $msv-breakpoint-l) {
            .product-item-box {
                flex: 0 0 65%;
                max-width: 65%;
                padding-right: 30px;
            }
            .order-summary-box {
                flex: 0 0 35%;
                max-width: 35%;
                margin-bottom: 30px;
            }
        }
        @media (max-width: $msv-breakpoint-sm) {
            flex-wrap: wrap;
            .product-item-box {
                flex: 0 0 100%;
                max-width: 100%;
                padding-right: 0;
            }
            .order-summary-box {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }
    &__heading {
        @include font-content-xl(var(--msv-font-weight-heavy));
        color: var(--msv-font-primary-color);
        margin-bottom: $msv-details-heading-margin-bottom;
        margin-top: $msv-details-heading-margin-top;
    }

    &__order-information {
        padding-bottom: $msv-details-order-information-padding-bottom;
        display: flex;
        flex-flow: column;
        &-channel-reference-id,
        &-sales-id,
        &-receipt-id,
        &-receipt-email {
            @include font-content-m();
            display: block;
            margin-bottom: $msv-details-order-information-sales-id-margin-bottom;

            span {
                font-weight: $msv-font-weight-600;
            }
        }
        &-channel-reference-id {
            font-size: 3rem;
            line-height: 3rem;
            font-weight: 600;
            padding: 25px 0 0;
            margin-bottom: 15px;
            order: 2;
            @media screen and (max-width: $msv-breakpoint-m) {
                line-height: 2.4rem;
                font-size: 1.8rem;
            }
        }
        &-qr-code,
        &-receipt-email {
            display: none;
        }
        &-created-date {
            font-size: 1rem;
            line-height: 1.4rem;
            text-transform: none;
            position: static;
            order: 2;
            font-weight: 600;
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            &-created-date {
                position: relative;
                top: 0;
                display: block;
                margin-bottom: $msv-details-order-information-sales-id-margin-bottom;
            }
        }

        &-count {
            border-right: 1px solid $msv-black;
            padding-left: $msv-details-order-information-spacing;
            padding-right: $msv-details-order-information-spacing;
        }

        &-amount {
            padding-left: $msv-details-order-information-spacing;
        }
    }

    &__btn-download-all {
        &.cui[class*='btn'].secondary {
            margin-bottom: 0;
            @include button-primary();
            font-weight: 600;
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    &__btn-download-all {
        line-height: normal !important;
    }
    &__sales-lines {
        display: flex;
        flex-wrap: wrap;
        padding: $msv-details-sale-lines-padding;

        &-shipping-items-label,
        &-picking-items-label,
        &-emailing-items-label {
            display: block;
            font-weight: $msv-font-weight-600;
        }

        &:first-child {
            border-top: none;
        }
        &-line {
            flex: 0 0 83%;
        }
        &-item {
            display: flex;
            width: 100%;
            align-items: end;
            flex: 0 0 100%;
        }
    }
    &__product-image-section {
        display: flex;
        & img {
            height: 70px;
            width: 70px;
            font-size: 0.6rem;
            line-height: 0.6rem;
        }
    }
    &__product-price-section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;
    }
    &__product-details {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        & span:first-child {
            margin-bottom: 1rem;
            word-break: break-all;
        }
    }

    &__groups {
        @include font-content-m();
        display: flex;
        flex-wrap: wrap;
        > div {
            min-width: 100%;
            &.ms-order-confirmation__group-deliveryType-ship {
                border-top: 1px solid $msv-gray-300;
                padding-top: 30px;
                > .ms-order-confirmation__sales-lines-shipping-items-label {
                    display: none;
                }
            }
        }
    }

    &__group {
        &-deliveryType-pickUp,
        &-deliveryType-ship,
        &-deliveryType-email {
            flex: 0 0 $msv-details-order-information-half-width;
            display: flex;
            flex-direction: column;
        }
        &-deliveryType-ship {
            flex: 0 0 100%;
        }

        &-delivery {
            padding-right: $msv-details-sale-line-spacing;
            padding-top: $msv-details-group-delivery-padding-top;

            &-heading {
                @include font-content-m(var(--msv-font-weight-heavy));
                margin-right: $msv-details-text-margin-right;
            }

            &-total-items {
                @include font-content-xl();
                color: $msv-details-group-delivery-items-text-color;
                margin-right: $msv-details-text-margin-right;
            }

            &-processing-label {
                display: block;
            }
        }
    }

    &__tracking-link {
        text-decoration: underline;
    }

    &__sales-line {
        position: relative;
        width: 100%;
        padding-bottom: 25px;
        min-height: 150px;
        margin-bottom: 30px;
        border-bottom: 1px solid $msv-gray-300;
        display: flex;
        justify-content: space-between;

        .ms-order-confirmation__product-price-section {
            span.download-btn-box {
                position: absolute;
                right: 0;
                top: 0;
                .procession-btn {
                    border: none;
                    background-color: transparent;
                    color: $msv-red;
                    &:disabled {
                        padding-left: 35px;
                        position: relative;
                        color: $msv-red;
                        .spinner-border:empty {
                            display: inline-block;
                            position: absolute;
                            left: 10px;
                            top: 0;
                            width: 1.25rem;
                            height: 1.25rem;
                            border-width: 3px;
                        }
                    }
                }
                .ms-order-confirmation__btn-download {
                    background-color: transparent;
                    border: none;
                    color: $msv-primary;
                    @include cui-focus();
                }
            }
            > span:last-child {
                position: absolute;
                right: 0;
                bottom: 50px;
                font-weight: 600;
                font-size: 1.4rem;
            }
        }
        .ms-order-confirmation__product-details {
            > span:first-child {
                color: $msv-primary;
                font-weight: 600;
                display: block;
                margin-bottom: 10px;
                font-size: 1.2rem;
            }
        }

        .msc-cart-line {
            display: block;
            width: $msv-details-sale-line-width;

            &__product {
                display: none;
            }

            &__product-image {
                margin: 0;
            }

            &__product-title {
                font-size: $msv-text-size;
                line-height: $msv-text-line-height;
            }

            &__quantity {
                .quantity-label {
                    font-size: $msv-font-size-s;
                    line-height: $msv-text-line-height;
                }

                .quantity-value {
                    color: $msv-gray-500;
                    display: inline;
                    font-size: $msv-font-size-s;
                    margin-left: $msv-details-text-margin-right;
                }
            }

            &__product-price,
            &__product-savings {
                display: none;
            }

            &__quantity.single-quantity {
                visibility: hidden;
            }

            &-item-product-discount {
                display: none;
                color: var(--msv-success-color);
            }
        }

        &-btn-buy-it-again {
            margin-top: $msv-details-sale-line-buy-again-btn-margin-top;
            width: $msv-details-sale-line-buy-again-btn-width;

            @include secondary-button();
        }
        @media screen and (max-width: $msv-breakpoint-m) {
            padding-right: 130px;
        }
    }

    &__order-summary {
        @include font-content-m();
        float: right;
        padding: $msv-details-default-padding;
        width: 50%;

        &::after {
            clear: both;
        }

        &-line-subtotal,
        &-line-shipping,
        &-line-tax-amount {
            margin-bottom: $msv-details-order-summary-line-spacing;
            width: 100%;
        }

        &-line-total-amount {
            margin-bottom: $msv-details-order-summary-line-spacing;
            padding-top: $msv-details-order-summary-line-spacing;
            width: 100%;
            font-weight: 600;
            .ms-order-details__order-summary-label {
                font-weight: var(--msv-font-weight-bold);
            }
        }

        &-line-earned-points {
            .ms-order-details__order-summary-label {
                font-weight: var(--msv-font-weight-bold);
            }
        }

        &-price {
            float: right;
        }
    }

    &__help {
        @include font-content-m();
        padding: $msv-details-default-padding;

        &-title {
            @include font-content-m(var(--msv-font-weight-heavy));
            margin-bottom: $msv-details-help-heading-margin-bottom;
        }

        &-content-number {
            @include vfi();
        }
    }

    &__payment-methods {
        @include font-content-m();
        padding: $msv-details-default-padding;
        width: 50%;
        float: left;

        &-title {
            @include font-content-m(var(--msv-font-weight-heavy));
            margin-bottom: $msv-details-payment-methods-heading-margin-bottom;
        }

        &-line {
            margin-bottom: $msv-details-payment-methods-line-space;
        }

        &-card-amount,
        &-loyalty-amount,
        &-gift-card-amount {
            margin-left: 4px;
        }
    }

    &__address {
        font-weight: $msv-font-weight-355;
        margin-bottom: 6px;
        margin-top: 6px;

        &-header {
            display: none;
        }

        &-city,
        &-threeletterisoregionname {
            &::before {
                content: '\A';
                white-space: pre;
            }
        }

        &-county {
            display: none;
        }

        &-name,
        &-phone {
            display: block;
        }
    }

    &__pickup-date-timeslot {
        padding-top: 30px;
        margin-bottom: 6px;

        &-title {
            font-weight: $msv-font-weight-600;
            padding-bottom: 8px;
        }

        &-content {
            font-weight: $msv-font-weight-355;
        }
    }

    &__alert {
        display: block;
        margin-bottom: $msv-order-details-alert-margin-bottom;
        margin-top: $msv-order-details-alert-margin-top;
    }

    @media screen and (min-width: $msv-container-min-width-m) {
        margin: $msv-main-container-margin;

        &__sales-lines {
            &-shipping-items-label,
            &-picking-items-label {
                padding-top: $msv-details-shipping-items-label-padding-top;
            }

            &-emailing-items-label {
                margin-top: auto;
            }
        }

        &__address {
            &-phone {
                position: absolute;
            }
        }

        &__groups {
            & > div:nth-child(2n) + div::before {
                content: '';
                display: block;
                width: 200%;
            }
        }

        &__payment-methods {
            padding: $msv-details-payment-method-padding-m;
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__groups {
            display: block;

            & > div:first-of-type {
                border: none;
            }

            &_delivery {
                padding-right: 0;
            }
        }

        &__sales-lines {
            width: 100%;

            &-shipping-items-label,
            &-picking-items-label {
                padding-top: $msv-details-shipping-items-label-padding-top-mobile;
            }
        }

        &__order-summary,
        &__payment-methods,
        &__help {
            float: none;
            padding-right: 0;
            width: 100%;
            position: relative;
            margin-left: 0;
        }

        &__help {
            border-top: 1px solid $msv-gray-900;
        }

        &__order-summary {
            &-heading,
            &-line-subtotal,
            &-line-shipping,
            &-line-tax-amount,
            &-line-total-amount {
                max-width: 100%;
            }
        }

        &__btn-keep-shopping,
        &__sales-line-btn-buy-it-again {
            width: 100%;
        }

        .msc-cart-line__product-price {
            position: unset;
        }
    }

    .msc-empty_image {
        @include image-placeholder($msv-order-empty-image-width);
        background-size: 100%;
    }
    @media (max-width: $msv-breakpoint-sm) {
        padding-right: 15px;
        padding-left: 15px;
    }
}
