//style presets
:root {
    --msv-breadcrumb-item-font-color: #{$msv-gray-900};
    --msv-breadcrumb-item-font-size: var(--msv-body-font-size-s);
}

.ms-breadcrumb {
    padding-bottom: 36px;

    .ms-breadcrumb_list {
        color: var(--msv-breadcrumb-item-font-color);
        float: left;
        list-style: none;
        padding: 0;

        .ms-breadcrumb-back-button {
            text-decoration: underline;
            cursor: pointer;
            float: left;
            margin-right: 10px;

            @include add-icon($msv-ChevronLeft, before);

            &::before {
                padding-right: 5px;
            }
        }
    }

    .ms-breadcrumb_item {
        color: var(--msv-breadcrumb-item-font-color);
        float: left;
        font-size: var(--msv-breadcrumb-item-font-size);
        position: relative;
        margin: 0;
        padding-right: 5px;
        padding-left: 5px;

        @include font-content-s();

        a[data-no-click='true'] {
            cursor: default;
            pointer-events: none;
        }
        a {
            &.ms-breadcrumb_link {
                color: $msv-primary;
                &:hover {
                    text-decoration: none;
                }
                @include cui-focus();
            }
        }
        .ms-breadcrumb_link {
            color: $msv-primary;
        }
        &.breadcrumb_item-current {
            display: none;
        }
    }
    &.custom-breadcrumbs {
        flex: 0 0 100%;
        justify-content: left;
        border-bottom: 1px solid $msv-gray-500;
        padding: 20px 0;
        overflow: hidden;
        .ms-breadcrumb_list {
            .ms-breadcrumb_item {
                a {
                    display: inline-flex;
                    padding: 0;
                    color: $msv-primary;
                    font-weight: 600;
                    height: auto;
                    text-decoration: none;
                    font-size: 1rem;
                    &::before {
                        @include msv-icon($icon-weight: $msv-solid-icon-weight);
                        content: $msv-ChevronLeft;
                        margin-right: 5px;
                        position: relative;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
                > span {
                    display: none;
                }
                &:first-child {
                    .ms-breadcrumb_link {
                        &::before {
                            @include msv-icon($icon-weight: $msv-solid-icon-weight);
                            content: $msv-ChevronLeft;
                            margin-right: 5px;
                            position: relative;
                            top: 1px;
                        }
                    }
                }
            }
            .breadcrumb_item-current {
                > div {
                    display: none;
                }
            }
        }
    }
}

.ms-breadcrumb {
    border-bottom: 1px solid $msv-gray-800;
    overflow: hidden;
    padding: 20px 0;
    .ms-breadcrumb_list {
        .ms-breadcrumb_item {
            > span {
                display: none;
            }
            .ms-breadcrumb_link {
                font-size: 1rem;
                font-weight: 600;
                text-decoration: none;
                &::before {
                    @include msv-icon($icon-weight: $msv-solid-icon-weight);
                    content: $msv-ChevronLeft;
                    margin-right: 5px;
                    position: relative;
                    top: 1px;
                    color: $msv-primary;
                }
                &:hover {
                    span {
                        text-decoration: underline;
                    }
                }
            }

            &.breadcrumb_item-current {
                .ms-breadcrumb_link {
                    text-decoration: none;
                    color: $cui-gray-primary;
                }
            }
        }
    }
}
