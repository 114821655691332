/* -------------------------------------------------------
   Start of _footer
   ------------------------------------------------------- */

.cui.coc-footer {
	// Variables
	$gutter-sm: $spacer-sm;
	$gutter-md: $gutter-sm * 2;

	background: $coc-secondary-2D;
	color: $coc-secondary-7L;
	overflow: hidden;
	padding: $gutter-md $gutter-sm;

	.footer-inner {
		margin-left: auto;
		margin-right: auto;
		max-width: $breakpoint-lg;
		@media( min-width: $breakpoint-xl ) {
			max-width: $breakpoint-xl;
		}
	}

	// For spacing out footer sections
	.footer-section {
		margin-bottom: $gutter-md;
	}

	a {
		//@include reverseLinkUnderline();
		color: $coc-secondary-10L;
	}

	// Common link wrapper styles
	.footer-links-vert,
	.footer-links-horz {
		ul {
			@include listUnstyled();
		}
	}

	// The container for the grid of various links (Quick Links, Login, Social, etc)
	.footer-links-vert {
		ul {
			margin-bottom: $gutter-md;
		}

		li {
			line-height: $line-height-default;
			margin-bottom: $spacer-xs;
		}
	}// End of .footer-links-vert

	// The horizontal links for Privacy Policy, Terms, Site Map, etc
	.footer-links-horz {
		text-align: center;

		li {
			border-right: $border-width-md solid $coc-secondary-10L;
			display: inline-block;
			font-weight: 400;
			line-height: 1;
			padding: 0 $spacer-xxs 0 $tiny-spacer-xs;

			&:last-child {
				border-right: 0;
			}
		}
	}// End of .footer-links-horz

	// Copyright text
	.footer-copyright {
		p {
			font-size: $smaller-font-size;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}
	}

	// Google translate component
	// Not used on pattern library but implemented on calgary.ca
	#google_translate_element.cui {
		border-bottom: $border-thin solid $coc-secondary-2L;
		font-size: $smaller-font-size;
		margin-bottom: $gutter-md;
		padding-bottom: $gutter-md;

		label {
			font-size: 0;
		}

		div,
		select,
		img,
		p {
			display: inline-block;
			font-size: $smaller-font-size !important;
			color: $white;
		}

		select {
			background-color: $coc-secondary-9L;
			// MC.2018.12.11: The following is the url-encoded SVG code of the specified image.
			// This allows us to embed the image directly into the CSS rather than require
			// the PL-implementer to host and refer to another external image.
			// @see: SVG optimizer: https://jakearchibald.github.io/svgomg/
			// @see: https://stackoverflow.com/a/37683760
			// @see: https://meyerweb.com/eric/tools/dencoder/
			// sort.svg is encoded below
			background-image: url( 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2256%22%20height%3D%2256%22%3E%3Cpath%20fill%3D%22%23333%22%20d%3D%22M12%2034.001c0-.537.199-1.013.6-1.4.401-.401.863-.6%201.4-.6h28c.537%200%201.013.2%201.4.6.401.401.6.863.6%201.4s-.2%201.013-.6%201.4l-14%2014c-.401.401-.863.6-1.4.6s-1.013-.2-1.4-.6l-14-14a1.888%201.888%200%200%201-.6-1.4zm0-12.002c0-.537.199-1.013.6-1.4l14-14c.401-.401.863-.6%201.4-.6s1.013.199%201.4.6l14%2014c.401.401.6.863.6%201.4s-.2%201.013-.6%201.4c-.401.401-.863.6-1.4.6H14c-.537%200-1.013-.2-1.4-.6a1.888%201.888%200%200%201-.6-1.4z%22%2F%3E%3C%2Fsvg%3E%0A' );
			background-position: 97% center;
			background-repeat: no-repeat;
			background-size: $base-font-size;
			border-radius: $default-border-radius;
			border: $border-width-md solid $coc-secondary-1L;
			color: $coc-default-color;
			@include prefix( appearance, none );
			height: $footer-translate-select-height;
			width: $footer-translate-select-width;
			margin-right: $spacer-xxs;
			padding: 0 $spacer-xxs;
		}

		a {
			font-size: $base-font-size;
		}

		// Google logo image
		img {
			height: auto;
			width: $footer-translate-image-width;
			vertical-align: middle;
		}
	}// End of #google_translate_element.cui

	// Feedback component
	// Removed from patten libray
	// Remove / clean up the code after AEM launch
	.feedback {
		background: transparent;
		border-color: $coc-secondary-2L;
		border-style: solid;
		border-width: $border-thin 0 $border-thin 0;
		padding-top: $gutter-sm;
		padding-bottom: $gutter-sm;
		text-align: center;

		p {
			margin-bottom: $spacer-sm;
			max-width: none;// Remove max-width as the text will be centred
		}
	}
	// Feedback component end

	@media( max-width: $breakpoint-xs-max ) {
		#google_translate_element.cui {
			.targetLanguage {
				display: block;
				margin-bottom: $spacer-xxs;
			}
		}
	}

	@media( min-width: $breakpoint-md ) {
		.feedback {
			border-width: $border-thin;
			padding: $spacer-sm;
			text-align: left;
		}

		.footer-copyright {
			p {
				font-size: $footer-copyright-font-size;
			}
		}
	}// End of min-width: $breakpoint-md

	// The two-col-xs class is no longer needed
	// Remove / clean up the code after AEM launch
	@media( max-width: $breakpoint-sm-max ) {
		ul.two-col-xs {
			column-count: 2;
			column-gap: $gutter;
		}
	}

	@media( max-width: $breakpoint-xs-max ) {
		ul.col-count-2,
		ul.col-count-3 {
			column-count: 1;
		}
	}
	// -------------------------------------------------------
	// Print
	//
	// -------------------------------------------------------
	@media print {
		display: none;
	}
}// End of .cui.coc-footer
