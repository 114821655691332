/* -------------------------------------------------------
   Start of _catalogue-group
   ------------------------------------------------------- */
// Currently used in the mega menu for the Programs and Services tab
.cui.catalogue-group {
	@include listUnstyled();
	border-radius: $default-border-radius;
	padding: $spacer-sm;
	width: 100%;

	ul {
		@include listUnstyled();
	}

	.group-title {
		display: block;
		line-height: inherit;// Overrides styles if the element is a heading
		margin-bottom: 0;// Overrides styles if the element is a headin

		[class*="cicon-"] {
			color: inherit;
			display: block;
			font-size: $cicon-size-lg;
			margin-bottom: $tiny-spacer-xs;
		}
	}

	// Styles specifically for if the element is a link.
	// Contains hover styles and the arrow icon.
	a.group-title {
		text-decoration: none;

		.group-title-text {
			font-size: $medium-font-size !important;
		}

		&:hover {
			.group-title-text {
				text-decoration: underline;
			}
		}
	}

	.group-title-text {
		color: inherit;
		display: inline-block;
		font-size: $larger-font-size;
		font-weight: $font-weight-semi-bold;
		line-height: $line-height-compact;
		margin-bottom: $spacer-sm;
	}

	.group-title-desc {
		color: $coc-default-color;
		display: block;
		font-size: $small-font-size;
		margin-bottom: $spacer-sm;
	}

	.group-title-text + .group-title-desc {
		// Bring the desc closer to the title text.
		margin-top: -$spacer-sm;
	}

	.group-links {
		li {
			margin-bottom: $spacer-xxs;
		}

		a {
			color: $coc-default-color;
			display: inline-block;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		&.arrowed {
			li {
				margin-bottom: 0;// Reset margin from above

				&:first-child {
					a {
						border-top-width: $border-thin;
					}
				}
			}

			a {
				// MC.2019.01.03: The border is split up like this to make it easier to
				// override the colour if a different background-colour is used for the
				// pattern. In particular, the first-child border is easily overridden.
				border-bottom-width: $border-thin;
				border-color: $border-color-medium;
				border-style: solid;
				@include flexbox();
				@include justify-content(space-between);
				padding-top: $spacer-xxs;
				padding-bottom: $spacer-xxs;
				transition-property: background;

				&:hover {
					background-color: $bg-medium;
					text-decoration: none;
				}
			}
		}
	}

	&.bg-light {
		.group-links {
			&.arrowed {
				a {
					// One level darker than $border-color-medium
					border-color: $coc-secondary-7L;
				}
			}
		}
	}
}

