/* -------------------------------------------------------
   Start of _menu-secondary
   ------------------------------------------------------- */

.nav-secondary {
	$border-style: $border-thin solid $border-color-medium;
	$a-horz-padding: $spacer-sm;
	$a-vert-padding: $spacer-xs;
	$second-horz-padding: $spacer-sm + $spacer-md;
}

.cui.nav-secondary {
	// Local variables
	$border-style: $border-thin solid $border-color-medium;
	$a-horz-padding: $spacer-sm;
	$a-vert-padding: $spacer-xs;
	$second-horz-padding: $spacer-sm + $spacer-md;
	position: relative;

	&.active {
		z-index: $nav-secondary-z;

		.nav-menu {
			display: block;
		}
	}

	.nav-menu {
		display: none;// By default, hide the menu until it is expanded
	}

	a {
		background: $white;
		color: $coc-default-color;
		display: block;
		padding: $a-vert-padding $a-horz-padding;
		text-decoration: none;
		transition-property: background;

		&:hover {
			background: $bg-medium;
		}

		&:focus {
			position: relative;
			z-index: $focus-z;
		}
	}

	ul {
		@include listUnstyled();
	}

	li {
		border-bottom: $border-style;
		display: block;

		&:first-child {
			border-top: $border-style;
		}

		// Special styling for the first menu link
		// Can only be applied to the first child
		&:first-child.first {
			border-top: $nav-sec-border-width-xl solid $coc-main;
			display: block;

			> a {
				border-bottom: $border-thin solid $coc-main;
				color: $black;
				font-weight: $font-weight-bold;
			}
		}

		&.active {
			> a {
				background: $bg-medium;
				font-weight: $font-weight-semi-bold;
			}
		}

		// For the first nested list item, add a border-top
		li:first-child {
			border-top: $border-style;
		}

		// For the last nested list item, remove the border bottom,
		// as the parent <li> will have one applied aready
		li:last-child {
			border-bottom: none;
		}

		// Nested list items
		li > a {
			// Double the padding-left for a nested list item
			// MC.2018.01.17: We are currently only supporting 2 levels of lists
			padding-left: $second-horz-padding;
		}
	}

	.nav-btn-mobile {
		@include align-items(center);
		background-color: $coc-secondary;
		border: 0;
		color: $white;
		@include flexbox();
		font-size: $base-font-size;
		padding: $spacer-xs;
		transition: background $transition-duration $transition-timing-function;
		width: 100%;

		&::after {
			content: '\e158';// .cicon-angle-down
			font-family: $font-icon;
			font-size: $cicon-size-xs;
			margin-left: auto;
		}

		&:hover {
			background-color: $coc-secondary-1L;
		}

		&:focus {
			z-index: $focus-z;
		}

		&[aria-expanded="true"] {
			background-color: $coc-secondary-1L;

			&::after {
				content: '\e15b';// .cicon-angle-up
			}

			&:hover {
				background-color: $coc-secondary-2D;
			}
		}
	}// End of .nav-btn-mobile

	@media( min-width: $breakpoint-lg ) {
		// For larger screens, the menu is expanded all the time and has a max-width
		max-width: $nav-sec-width-md;

		.nav-menu {
			display: block;
		}

		li {
			// Special styling for the first menu link
			// Can only be applied to the first child
			&:first-child.first {
				> a {
					padding-top: $a-vert-padding;
					padding-bottom: $a-vert-padding;
				}
			}
		}

		.nav-btn-mobile {
			display: none;
		}

		a {
			// Decrease the vertical padding for larger screens, for more visibility
			padding-top: $spacer-xxs;
			padding-bottom: $spacer-xxs;
		}
	}// End of min-width: $breakpoint-lg

	// -------------------------------------------------------
	// Print
	//
	// -------------------------------------------------------

	@media print {
		display: none;
	}
}// End of .cui.nav-secondary
