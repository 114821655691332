/* -------------------------------------------------------
   Start of _forms
   ------------------------------------------------------- */
// Based off Bootstrap 4.0.0
// Does not include form control sizing, custom form controls,
// form-inline (some functionality added, but not all),
// form-control-file, form-control-range

// -------------------------------------------------------
// Form controls
//
// -------------------------------------------------------

.form-control {
	background-color: $input-background-color;
	background-clip: padding-box;
	border: $input-border;
	border-radius: $input-border-radius;
	color: $input-color;
	display: block;
	font-size: $base-font-size;
	line-height: $input-line-height;
	height: $input-height;
	padding: $input-padding-y $input-padding-x;
	width: 100%;

	// Disabled and read-only inputs
	&:disabled,
	&[readonly] {
		background-color: $input-disabled-bg;
		// iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
		opacity: 1;
	}

	&:focus {
		@include setOutlineGlow;
		outline: 0;// Prevent double-up of browser outlines and our box-shadow
	}
}

select.form-control {
	&[size],
	&[multiple] {
		height: auto;
	}

	&:focus::-ms-value {
		// Suppress the nested default white text on blue background highlight given to
		// the selected option text when the (still closed) <select> receives focus
		// in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
		// match the appearance of the native widget.
		// See https://github.com/twbs/bootstrap/issues/19398.
		color: $input-color;
		background-color: $input-background-color;
	}
}

textarea.form-control {
	height: auto;
}

input[type="file"].form-control {
	height: auto !important;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
	line-height: $input-line-height;// MC.2018.03.02: Bootstrap 3 has a specific line-height for these rules; make sure we override them
}


// -------------------------------------------------------
// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.
// -------------------------------------------------------

.form-check {
	display: block;
	padding-left: $form-check-input-gutter;
	position: relative;
}

.form-check-label {
	margin-bottom: 0; // Override default <label> bottom margin
}

.form-check-input {
	margin-top: $form-check-input-margin-y;
	margin-left: -$form-check-input-gutter;
	position: absolute;

	&:disabled ~ .form-check-label {
		color: $check-disabled-color;
	}
}

label.form-check-radio-label {
	padding-left: 15px;
	text-indent: -15px;
	display: table !important;
	// display: -webkit-box !important; // old
	// display: -moz-box !important; // old
	// display: -ms-flexbox !important; // ie
	// display: -webkit-flex !important; // new
	// display: flex !important; // new
	// @include align-items(baseline);

	input {
		//margin-right: $form-check-input-margin-y;
		width: 13px;
		height: 13px;
		padding: 0;
		margin: 0;
		margin-right: $tiny-spacer-xs;
		vertical-align: bottom;
		position: relative;
		top: -6px;
		*overflow: hidden;
	}

	.form-vld-msg.error::before {
		margin-left: 26px;
	}
}

// Updated on March, 2021
// .form-check-inline Deprecated class
.form-check-inline {
	@include align-items(center);
	@include inline-flex;
	margin-right: $form-check-inline-margin-x;
	padding-left: 0; // Override base .form-check

	// Undo .form-check-input defaults and add some margin-right
	.form-check-input {
		margin-top: 0;
		margin-right: $form-check-inline-input-margin-x;
		margin-left: 0;
		position: static;
	}
}

// -------------------------------------------------------
// Rating - Styled Radio Button Star Rating Component
//
//
// -------------------------------------------------------

.star-cb-group * {
	font-size: $base-font-size;
}

.star-cb-group {
	position: relative;

	/* remove inline-block whitespace */
	font-size: 0;

	/* flip the order so we can use the + and ~ combinators */
	unicode-bidi: bidi-override;
	direction: rtl;
	display: table-cell;

	/* the hidden clearer */

	> input {
		@include setSROnly();
	}

	label.vld-star,
	> input + label {
		/* only enough room for the star */
		display: inline-block;
		overflow: hidden;
		text-indent: 9999px;
		width: $h1-font-size-sm;
		white-space: nowrap;
		cursor: pointer;
		font-size: $h1-font-size-sm;

		&::before {
			display: inline-block;
			text-indent: -9999px;
			content: "☆";
			color: $coc-secondary-3D; // same as the form text color
		}
	}

	> .star-cb-clear + label {
		text-indent: -9999px;
		width: .5em;
		margin-left: -.5em;
	}

	.star-cb-group > .star-cb-clear + label::before {
		width: .5em;
	}

	.star-cb-group:hover > input + label::before {
		content: "☆";
		color: $coc-secondary-3D;
	}

	> input:focus + label {
		@include setOutlineGlow( true );
	}


	// validation error
	.form-vld-msg.error {
		position: absolute;
		direction: ltr;
		left: 0;
		bottom: -$spacer-sm;
		width: 100%;
		min-width: $basic-space * 270rem;
	}
}

.star-cb-group > input:checked ~ label::before,
.star-cb-group > input + label:hover ~ label::before,
.star-cb-group > input + label:hover::before {
	content: "★";
	color: $coc-secondary;
}

.star-cb-group:hover > input + label:hover ~ label::before,
.star-cb-group:hover > input + label:hover::before {
	content: "★";
	color: $coc-secondary;
}

// new number style
.number-cb-group {
	@include flexbox;
	@include flex-wrap(wrap);

	label {
		padding: $spacer-xs 0;
		margin-bottom: 0;
		display: block;
		margin-right: -$border-thin;

		.label-text {
			padding: $spacer-xs $spacer-md;
			background-color: $coc-secondary-9L;
			border: $border-thin solid $input-border-color;

			&:hover {
				color: $white;
				background-color: $coc-secondary;
			}
		}

		.label-desc {
			display: block;
			position: absolute;
			line-height: 1.2;
			max-width: 65px;
			text-align: center;
			font-size: $basic-space * 12rem;
		}

		input {
			@include setSROnly();

			&[type="radio"]:checked + .label-text {
				background-color: $coc-secondary;
				color: $white;
			}

			&[type="radio"]:focus + .label-text {
				box-shadow: $focus-box-shadow, $default-base-shadow;
			}
		}

		&:hover {
			background-color: $coc-secondary;
			color: $white;
			cursor: pointer;
		}
	}
}
// -------------------------------------------------------
// Form text
//
// -------------------------------------------------------

label {
	display: inline-block;
	font-weight: $font-weight-semi-bold;
	margin-bottom: $tiny-spacer-xs;
}

// A span inside the label or legend to indicate that the field is required
.label-required {
	color: $danger;
	font-weight: $font-weight-semi-bold;
}

// Used for helper text and can be used for fieldset legends
.form-text {
	color: $coc-secondary-3D;
	display: block;
	font-size: $small-font-size;
	margin-top: $tiny-spacer-xs;
	margin-bottom: 0;// Remove margin, if applied to a <p>
}

legend.form-text {
	font-weight: $font-weight-normal;// Override the legend font-weight
}


// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
	background-color: transparent;
	border: solid transparent;
	border-width: $input-border-width 0;
	display: block;
	line-height: $input-line-height;
	padding-top: $input-padding-y;
	padding-bottom: $input-padding-y;
	width: 100%;
}


// -------------------------------------------------------
// Grouping and spacing
//
// -------------------------------------------------------

.form-section {
	margin-bottom: $spacer-lg;
}

.form-group {
	margin-bottom: $spacer-md;
}

// Updated: March, 2021
// Group checkbox and radio button style
.form-option-checkbox-block,
.form-option-radio-block,
.form-option-checkbox-inline,
.form-option-radio-inline {
	position: relative;

	.form-vld-msg.error {
		position: absolute;
		bottom: 0;
	}

	&.form-group,
	&.bordered {
		padding-bottom: $basic-space * 21rem;

		.form-vld-msg.error {
			left: $spacer-sm;
		}
	}

	&.bordered {
		.form-vld-msg.error {
			left: $spacer-sm;
		}
	}

	&.form-group {
		.form-vld-msg.error {
			left: 0;
		}

		label {
			font-weight: $font-weight-normal;
		}
	}
}

.form-option-checkbox-block,
.form-option-radio-block {
	label {
		display: block;
	}
}

.form-option-checkbox-inline,
.form-option-radio-inline {
	label {
		display: inline-block;
	}
}
//
.form-group-responsive {
	margin-bottom: $spacer-md;

	@media( min-width: $breakpoint-md ) {
		margin-bottom: 0;
	}
}

.multiple-group-btn {
	margin-top: $spacer-md;

	@media( max-width: $breakpoint-sm-max ) {
		margin-top: 0;
	}

	&.multiple-group-btn-add {
		@media( max-width: $breakpoint-sm-max ) {
			margin-top: $spacer-sm;
		}
	}
}

.form-backdrop {
	background-color: $black;
	opacity: 0.75;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $header-menu-bg-overlay-z;
	width: 100%;
}

fieldset {
	legend {
		font-size: $base-font-size;
		font-weight: $font-weight-semi-bold;
		width: auto;
	}

	&.bordered {
		border: $input-border;
		border-radius: $input-border-radius;
		padding: $fieldset-bordered-padding;

		legend {
			padding-left: $tiny-spacer-sm;
			padding-right: $tiny-spacer-sm;
		}
	}
}

@media( min-width: $breakpoint-md ) {
	.form-section {
		margin-bottom: $spacer-xxl;
	}
}


// -------------------------------------------------------
// Form grid
//
// Special replacement for the grid system's `.row` for tighter form layouts.
// -------------------------------------------------------

.form-row {
	@extend %row-compact;
}


// -------------------------------------------------------
// Optional sizing classes
//
// .form-width-x classes can be applied to `<form>` elements to set the max-width
// for the entire element
//
// .input-width-x classes can be applied to `<input>` elements to set the max-width
// on an individual basis
// -------------------------------------------------------

.form-width-md {
	max-width: $basic-space*480rem;
}

.form-width-lg {
	max-width: $basic-space*600rem;
}

.form-width-xl {
	max-width: $basic-space*720rem;
}

.input-width-sm {
	max-width: $basic-space*120rem;
}

.input-width-md {
	max-width: $basic-space*240rem;
}

.input-width-lg {
	max-width: $basic-space*360rem;
}


// -------------------------------------------------------
// Form control utility buttons
//
// Utility buttons to `clear` input and to `show password`
// Example HTML:
// <label>Label</label>
// <div class="form-control-wrapper">
//     <input type="text" class="form-control">
//     <button class="btn-form-control">
// </div>
// -------------------------------------------------------

.form-control-wrapper {
	$btn-font-size:          $smaller-font-size;
	$btn-vertical-padding:   $spacer-xxs;
	$btn-horizontal-padding: $spacer-xxs;

	position: relative;

	.form-control {
		padding-right: $basic-space*40rem;// Adjust right-padding to account for the button
	}

	.btn-form-control {
		@include setIconFontStyle();
		background: transparent;
		border: 0;
		color: $coc-secondary-4D;
		font-size: $btn-font-size;
		padding: $btn-vertical-padding $btn-horizontal-padding;
		position: absolute;
		// Take the font-size and the vertical padding, subtract it from the default input-height,
		// and then divide by 2 to vertically align the btn
		top: ($input-height - ($btn-font-size + ($btn-vertical-padding * 2)))/2;
		right: $tiny-spacer-sm;

		&:hover {
			color: $black;
		}

		&:focus {
			outline: none;
		}
	}

	.btn-form-control-clear {
		$btn-font-size: $basic-space*8rem;
		font-size: $btn-font-size;
		font-weight: $font-weight-bold;
		// Take the font-size and the vertical padding, subtract it from the default input-height,
		// and then divide by 2 to vertically align the btn
		top: ($input-height - ($btn-font-size + ($btn-vertical-padding * 2)))/2;

		&::before {
			content: '\e039';// cicon-close-thin-large
		}
	}

	.btn-form-control-show-pw {
		&::before {
			content: '\e074';// cicon-eye
		}
	}

	// Remove webkit search cancel button
	input[type="search"] {
		&::-webkit-search-cancel-button {
			-webkit-appearance: none;
		}
	}

	// Remove IE's built-in browser controls to avoid conflicts
	input::-ms-clear,
	input::-ms-reveal {
		display: none;
	}
}// End of .form-control-wrapper


// -------------------------------------------------------
// Inline forms
//
// Based off Boostrap; does not currently support form-checks
// -------------------------------------------------------

.form-inline {
	@include flexbox();
	@include flex-flow(row wrap);
	@include align-items(center);

	label {
		margin-bottom: 0;
	}

	.form-control,
	.form-control-plaintext {
		display: inline-block;
		vertical-align: middle;
		width: auto;
	}
}


// -------------------------------------------------------
// Form validation
//
// Alert and inline-alert label feedback indicators
// -------------------------------------------------------

@mixin generate-form-control-state($state, $color) {
	.form-control {
		&.#{$state} {
			border-color: $color;

			&:focus {
				border-color: $color;
				box-shadow: $focus-box-shadow-widths rgba( $color, 0.3 );
			}
		}
	}
}

// Generate the form-control states
@include generate-form-control-state( "valid", $success );
@include generate-form-control-state( "error", $danger );

form {
	// The alert banner that lists out the form errors
	// MC.2018.03.09: Only want to target .alerts inside forms for now
	// TODO: MC.2018.03.09: At some point, this can be moved into the normal _alerts scss file
	// TODO: MC.2018.09.17: Determine if the new alerts have the same style/classes
	.alert {
		$padding: $spacer-sm;
		$border-left: $spacer-lg;
		border-width: 4px 4px 4px $border-left;
		border-style: solid;
		border-radius: 0;// Reset styles from BS
		background: transparent;// Reset styles from BS
		margin-bottom: $spacer-lg;
		padding: $padding;

		&::before {
			@include setIconFontStyle();
			color: white;
			// Position the icon in the exact center of the thick left border
			// 44px = -(18px + 16px + ((36-16)/2))
			margin-left: -( $padding + $base-font-size + (($border-left - $base-font-size)/2) );
			position: absolute;
		}

		&:focus {
			@include setOutlineGlow;
			outline: 0;// Prevent double-up of browser outlines and our box-shadow
		}

		h2 {
			font-size: $base-font-size !important;
			font-weight: $font-weight-bold;
			margin-bottom: $spacer-xs;
		}

		ul {
			margin-bottom: 0;
		}
	}

	// Styling
	.alert-danger {
		border-color: $coc-secondary-red;

		&::before {
			content: "\e07d";// .cicon-exclamation-triangle
		}
	}
}// End of form

// Inline validation messages
.form-vld-msg {
	border-style: solid;
	border-width: 1px 1px 1px 4px;
	color: $black;
	display: inline-block;
	font-size: $smaller-font-size;
	font-weight: $font-weight-bold;
	line-height: $line-height-compact;
	padding: 3px 9px;
	vertical-align: baseline;
	background: transparent;// Reset styles from BS
	border-radius: 0;// Reset styles from BS
	text-align: left;// Reset styles from BS
	white-space: normal;// Reset styles from BS

	&::before {
		@include setIconFontStyle();
		display: inline-block;
		padding-right: 3px;
	}

	&.error {
		border-color: $danger;

		&::before {
			color: $danger;
			content: "\e07d";// .cicon-exclamation-triangle
		}
	}

	&.valid {
		border-color: $success;

		&::before {
			color: $success;
			content: "\e035";// .cicon-check-circle
		}
	}
}// End of .form-vld-msg

// Overlap the label with the .form-control for a seamless stack
.form-control.error {
	+ .form-vld-msg {
		position: relative;
		top: -$input-border-width;
	}
}

// Add spacing for error labels after .form-check-input
.form-check-input.error {
	+ .form-label-error {
		margin-right: $spacer-xxs;
	}
}

.form-control[disabled],
fieldset[disabled] .form-control {
	cursor: default;// Override BS V3 styles
}
