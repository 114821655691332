@mixin make-container-padding($paddings: $grid-gutter-widths, $breakpoints: $grid-breakpoints) {
    @each $breakpoint, $padding in $paddings {
        @include media-breakpoint-up($breakpoint, $breakpoints) {
            padding-left: $padding;
            padding-right: $padding;
        }
    }
}

@mixin basicTruncation {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin basic-outline-offset($outline-width: 1px, $outline-style: dashed, $outline-color: $black, $outline-offset: -1px) {
    outline: $outline-width $outline-style $outline-color;
    outline-offset: $outline-offset;
}

@mixin vfi {
    @include cui-focus();
}

@mixin vfi-psuedo($selector: after, $offset: -2px, $position: relative) {
    position: $position;

    &:focus {
        outline: 1px dashed $white;

        &:#{$selector} {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            outline: 1px dashed $black;
            outline-offset: $offset;
        }
    }
}

@mixin validation($bg-color, $border, $color) {
    background-color: $bg-color;
    border: 1px solid $border;
    color: $color;
}

@mixin validation-success($bg-color: $msv-gray-300, $border: $msv-gray-900, $color: $msv-gray-900) {
    @include validation($bg-color, $border, $color);
}

@mixin validation-error($bg-color: var(--msv-error-color), $border: var(--msv-error-color), $color: var(--msv-font-secondary-color)) {
    @include validation($bg-color, $border, $color);
}

@mixin divider($direction) {
    border-#{$direction}: 1.5px solid #{$msv-divider-color};
}
@mixin cui-shadow() {
    @include setInteractiveBoxShadow(true);
}
@mixin cui-card() {
    @include font-content-m();
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    @include setInteractiveBoxShadow(true);
    text-decoration: none;
    transition-property: box-shadow;
    border: 1px solid $msv-gray-400;
    flex: 0 0 20%;
    max-width: 20%;
    margin-right: 30px;
    margin-bottom: 30px;
    padding: 20px 25px;
    &:hover {
        box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
    }
}
@mixin cui-accordion-head-collapsed() {
    background-color: $coc-secondary-9L;
    border: 0.125rem solid $coc-secondary-7L;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    padding: 0.75rem 1.125rem;
    text-align: left;
    transition: all 0.25s ease;
    width: 100%;
    @include cui-add-icon();
}
@mixin cui-accordion-head-expended() {
    background-color: transparent;
    border: 0.125rem solid $coc-secondary-7L;
    border-left: 0.125rem solid $msv-red;
    border-bottom: 0;
    color: $msv-red;
    padding: 0.75rem 1.125rem;
    text-align: left;
    transition: all 0.25s ease;
    width: 100%;
    font-size: 1rem;
    @include cui-remove-icon();
}
