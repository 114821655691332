.b2bpdp {
    .hidden {
        display: none;
    }
    .ms-product-specification {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 58px;
        .table-bordered {
            border: none;
            max-width: 100%;
            display: block;
            > tbody {
                display: block;
            }
        }
        &__table-row {
            padding-bottom: 10px;
            display: inline-block;
            width: 100%;
            background-color: transparent;
            border: none;
            &:nth-of-type(odd) {
                background-color: transparent;
            }
        }
    }
    .ms-product-specification th {
        font-weight: 600;
        white-space: nowrap;
        padding: 0 10px 0 0;
        display: inline-block;
        font-size: 1rem;
        border: none;
    }
    .ms-product-specification td {
        display: inline-block;
        font-size: 1rem;
        border: none;
        padding: 0;
        span {
            display: inline-block;
            width: 100%;
            word-break: break-all;
        }
    }
    .material {
        .ms-content-block__image {
            margin-top: 90px;
            img {
                height: 434px;
            }
        }
    }
    .sizechartContainer {
        margin-bottom: 60px;
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
        .ms-content-block__details {
            max-width: 100%;
            .ms-content-block__text {
                max-width: 100%;
                overflow-x: auto;
            }
            .ms-content-block__title {
                font-weight: $msv-font-weight-normal;
                font-size: $msv-font-size-xl;
                line-height: $msv-line-height-xl;
                color: $msv-gray-1000;
            }
            table {
                width: 100%;
                border-spacing: 0;
                border-collapse: collapse;
                line-height: $msv-line-height-l;
                color: $msv-gray-900;
                font-weight: $msv-font-weight-light;
            }
            tr {
                border-top: 1px solid $msv-gray-300;
                td:first-child {
                    background: $msv-snow;
                    width: 89px;
                }
            }
            tr:last-child {
                border-bottom: 1px solid $msv-gray-300;
            }

            td {
                padding: 0.9rem;
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .default-container,
        .default-container-2-slots {
            > .row {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
