/* -------------------------------------------------------
   Start of _links
   ------------------------------------------------------- */

a {
	color: $coc-link;
	outline-style: none;
	text-decoration: underline;
	transition-duration: $transition-duration;
	transition-property: color;
	transition-timing-function: $transition-timing-function;

	&:visited {
		color: $coc-visited;
	}

	&:focus,
	&:hover {
		color: $coc-link-hover-color;
		text-decoration: none;
	}

	&:focus {
		@include setOutlineGlow( true );
	}
}
