/* -------------------------------------------------------
   Start of _svg-images
   ------------------------------------------------------- */

.cimg {
	display: inline-block;
	font-size: $cimg-size-default;
	vertical-align: middle;
	width: 1em;
	height: 1em;

	// MC.2018.05.10: These svg rules below are for when the svgs are using the <use> tags
	// Set the fill and stroke colour to inherit the `color` css rule
	fill: currentColor;
	stroke-width: 0;
	stroke: currentColor;

	// MC.2018.05.10: The path svg rules below are for when the svg code is input directly into the HTML
	// Ex. <svg><path ...></path></svg>
	path {
		// Set the fill and stroke colour to inherit the `color` css rule from its parent container
		fill: currentColor;
		stroke-width: 0;
		stroke: currentColor;
	}
}

.cimg-lg {
	font-size: $cimg-size-lg;
}
