/* -------------------------------------------------------
   Start of _ background-image-block (_emotive-container)
   ------------------------------------------------------- */
.background-image-block.cui {
	background-repeat: none;
	background-size: cover;

	.comp-body {
		left: 0;
		right: 0;
		@include setColours(rgba(255, 255, 255, 0.9), $black);
		margin: auto;
	}

	//991px
	@media( max-width: $breakpoint-md-max ) {
		padding-top: 0;
		padding-bottom: 0;
		background-image: none !important;

		.comp-body {
			max-width: $breakpoint-lg;
			padding-left: 0;
			padding-right: 0;
		}

		&.edge-to-edge {
			padding-left: $basic-space*21rem; // aligning with the logo
			padding-right: $basic-space*21rem;
		}
	}

	//992px
	@media( min-width: $breakpoint-lg ) {
		padding-bottom: $spacer-xxl;
		//padding-top: calc( 100vh - 40% );
		// the css calculation on AEM editor preview mode cause unexpected behaviour
		// AEM preview mode will bring the page into an iframe
		// #ContentWrapper calculate the height through js then add it to the iframe
		// use 25% instead
		padding-top: 25%;

		.comp-body {
			padding-top: $spacer-sm;
			padding-bottom: $spacer-sm;
			padding-right: $spacer-xl; // same padding as the long text banner
			padding-left: $spacer-xl;

			&.md {
				$col-size: 8;
				@include calcColWidth( $col-size );
			}

			&.lg {
				$col-size: 10;
				@include calcColWidth( $col-size );
			}
		}

		&.edge-to-edge {
			.comp-body {
				width: 100%;
				max-width: calc( #{$breakpoint-lg} + #{$spacer-xl} );//make the body div wider to match the visual design
				padding-left: $basic-space*41rem; //Anything add into the body div will have the max-width to 992px
				padding-right: $basic-space*41rem; //$gutter + $spacer-xl/2
			}
		}
	}
	//1200px
	@media( min-width: $breakpoint-xl ) {
		.comp-body {
			padding-top: $spacer-lg;
			padding-bottom: $spacer-lg;

			&.md {
				$col-size: 7;
				@include calcColWidth( $col-size );
			}

			&.lg {
				$col-size: 11;
				@include calcColWidth( $col-size );
			}
		}

		&.edge-to-edge {
			.comp-body {
				width: 100%;
				max-width: calc( #{$breakpoint-xl} + #{$spacer-xxl} ); //make the body div wider to match the visual design
				padding-left: $basic-space*50rem; //Anything add into the body div will have the max-width to 1200px
				padding-right: $basic-space*50rem; //$gutter + $spacer-xxl/2;
			}
		}
	}
}