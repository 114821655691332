$msv-address-form-item-margin-bottom: 25px;
$msv-address-detail-item-margin-right: 5px;
$msv-address-select-item-margin-top: 10px;
$msv-address-select-item-margin-bottom: 10px;
$msv-alert-border-radius: 0;
$msv-alert-padding: 0.75rem 1.25rem;
$msv-alert-margin-bottom: 1rem;
$msv-alert-icon-margin: 8px;
$msv-address-input-min-height: 32px;
$msv-address-select-item-radio-height: 24px;
$msv-address-select-item-radio-width: 24px;
$msv-address-select-item-address-detail-padding-left: 48px;
$msv-address-button-save-margin-right: 20px;
$msv-account-management-address-margin-bottom: 20px;
$msv-account-management-address-list-button-add-empty-margin-bottom: 16px;
$msv-account-management-address-list-primary-margin-top: 20px;
$msv-account-management-address-list-primary-heading-margin-bottom: 12px;
$msv-account-management-address-list-primary-list-border-color: $msv-gray-300;
$msv-account-management-address-list-primary-list-address-detail-item-margin-right: 5px;
$msv-primary-list-address-detail-item-newline-margin-right: 0;
$msv-account-management-address-list-primary-list-phone-icon-margin-right: 5px;
$msv-account-management-address-list-button-primary-margin-top: 15px;
$msv-account-management-address-textbox-height: 48px;

//style presets
:root {
    --msv-address-font-size: var(--msv-body-font-size-l);
    --msv-address-font-color: var(--msv-font-primary-color);
    --msv-address-border: #{$msv-gray-300};
    --msv-address-font-family: #{$msv-primary-font-family};
    --msv-checkout-shipping-address-font-color: var(--msv-font-primary-color);

    //heading
    --msv-address-heading-font-size: var(--msv-body-font-size-xl);
    --msv-address-heading-font-color: var(--msv-font-primary-color);
    --msv-address-primary-heading-font-size: var(--msv-body-font-size-m);
    --msv-address-primary-heading-font-color: var(--msv-font-primary-color);
    --msv-address-primary-text-font-size: var(--msv-body-font-size-s);
    --msv-address-primary-text-font-color: var(--msv-font-primary-color);

    // error
    --msv-address-error-bg: var(--msv-error-color);
    --msv-address-error-border: var(--msv-error-color);
    --msv-address-error-font-color: var(--msv-font-secondary-color);
    --msv-address-error-font-size: var(--msv-body-font-size-m);

    // link
    --msv-address-link: var(--msv-font-primary-color);
    --msv-address-link-size: var(--msv-body-font-size-m);

    // primary button
    --msv-address-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-address-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-address-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-address-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-address-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-address-secondary-btn-border: var(--msv-accent-brand-color);
}

.msc-address-form {
    &__label {
        @include form-input-label();
        &[for='shipping_addressname'],
        &[for='shipping_addressstreet'],
        &[for='shipping_addresscity'],
        &[for='shipping_addresszipcode'],
        &[for='shipping_addressthreeletterisoregionname'],
        &[for='shipping_addressstate'],
        &[for='billing_addresscity'],
        &[for='billing_addressstate'],
        &[for='billing_addresszipcode'],
        &[for='billing_addresszipcode'],
        &[for='company_addressstreet'],
        &[for='company_addressstate'],
        &[for='company_addresszipcode'],
        &[for='company_addressthreeletterisoregionname'],
        &[for='company_addresscity'] {
            @include required-field();
        }
    }

    &__item {
        margin-bottom: $msv-address-form-item-margin-bottom;
        display: flex;
        flex-flow: column;
    }

    &__item-isprimary {
        display: flex;

        .msc-address-form__label {
            margin-top: auto;
            margin-bottom: auto;
            margin-right: 10px;

            &:hover {
                cursor: pointer;
            }
        }

        .msc-address-form__input-checkbox {
            width: 20px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    &__item-county {
        display: none;
    }

    &__input-text {
        padding: 6px 8px;
    }

    .MicrosoftMap {
        position: relative;
        width: 100%;

        .as_container_search {
            width: 100%;
        }
    }

    &__input,
    &__dropdown {
        @include form-control();
    }

    &__alert {
        display: block;
        order: 4;
    }

    &__button-save {
        @include button-primary();
        margin-right: $msv-address-button-save-margin-right;
    }

    &__button-cancel {
        @include button-utility();
    }

    .address-form__item-invalid &__alert {
        @include error-alert();
    }
}

.msc-address-detail {
    padding: 30px;
    border: 1px solid $msv-gray-300;
    border-radius: 4px;
    width: 100%;
    &__item {
        margin-right: $msv-address-detail-item-margin-right;
        display: flex;
        flex-flow: column;
    }

    &__item-newline {
        margin-right: 0;

        &::after {
            content: '\A';
            white-space: pre;
        }
    }

    &__item-address-detail_Phone {
        display: block;
    }

    &__item-phone {
        display: inline;
        @include add-icon($msv-Phone);

        &-label {
            @include visually-hidden();
        }

        &::before {
            margin-right: $msv-account-management-address-list-primary-list-phone-icon-margin-right;
            transform: rotate(90deg);
        }

        &.msc-address-detail__item-empty {
            display: none;
        }
    }
}

.msc-address-select {
    &__input-radio {
        height: $msv-address-select-item-radio-height;
        width: $msv-address-select-item-radio-width;
        position: absolute;
        top: 0;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-top: $msv-address-select-item-margin-top;
        margin-bottom: $msv-address-select-item-margin-bottom;
        position: relative;

        .msc-address-detail {
            padding-left: $msv-address-select-item-address-detail-padding-left;
        }
    }

    &__input {
        @include vfi();
        @include form-input-checkbox();
        top: 30px;
        left: 15px;
    }

    &__button-add {
        @include vfi();
        @include primary-button(
            var(--msv-address-primary-btn-bg),
            var(--msv-address-primary-btn-font-color),
            var(--msv-address-primary-btn-border)
        );
    }
}

// mixin

@mixin address-button {
    cursor: pointer;
}

.ms-account-management-address {
    margin-bottom: $msv-account-management-address-margin-bottom;
    padding-top: 30px;
    .ms-address-list {
        margin-bottom: $msv-account-management-address-margin-bottom;
    }

    .msc-address-list__heading {
        font-size: 2.8125rem;
        font-weight: 600;
        color: var(--msv-address-heading-font-color);
        margin-bottom: 1.875rem;
        // font-family: $font-condensed;
    }

    .msc-address-list__add-empty {
        margin-bottom: $msv-account-management-address-list-button-add-empty-margin-bottom;
    }

    .msc-address-list__button-add {
        @include button-primary();
    }

    .msc-address-list__primary {
        border-top: 1px solid var(--msv-address-border);
        margin-top: $msv-account-management-address-list-primary-margin-top;
        padding: 20px 0;

        .msc-address-list__primary-heading {
            @include font-content(var(--msv-font-weight-bold), var(--msv-address-primary-heading-font-size), $msv-line-height-m);
            color: var(--msv-address-primary-heading-font-color);
            margin-bottom: $msv-account-management-address-list-primary-heading-margin-bottom;
        }

        .msc-address-list__primary-list {
            padding: 20px 0;
            border-bottom: 1px dashed var(--msv-address-border);

            &::nth-child(2) {
                padding-top: 0;
            }

            &::last-child {
                border-bottom: none;
                padding-bottom: 0;
            }

            .msc-address-detail {
                @include font-content(var(--msv-font-weight-normal), var(--msv-address-primary-text-font-size), $msv-line-height-s);
                color: var(--msv-address-primary-text-font-color);

                .msc-address-detail__item {
                    margin-right: $msv-account-management-address-list-primary-list-address-detail-item-margin-right;
                }

                .msc-address-detail__item-newline {
                    margin-right: $msv-primary-list-address-detail-item-newline-margin-right;

                    &::after {
                        content: '\A';
                        white-space: pre;
                    }
                }
            }

            .msc-address-list__button-primary {
                @include button-utility();
                display: flex !important;
                margin-top: $msv-account-management-address-list-button-primary-margin-top;
            }

            .msc-address-list__button-edit {
                @include button-link(var(--msv-address-link));
                @include font-content(var(--msv-font-weight-normal), var(--msv-address-link-size), $msv-line-height-m);
                text-transform: none;
                border-radius: 0;
                padding: 6px 12px 6px 0;
                text-decoration: underline;
                color: $msv-primary;
            }

            .msc-address-list__button-remove {
                @include button-link(var(--msv-address-link));
                @include font-content(var(--msv-font-weight-normal), var(--msv-address-link-size), $msv-line-height-m);
                text-transform: none;
                border-radius: 0;
                padding: 6px 12px 6px 0;
                text-decoration: underline;
                color: $msv-primary;
            }
        }

        .msc-address-list__primary-list:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
    .remove-address-screen-reader {
        display: none;
    }
}
