$msv-btn-icon-to-text-gap: 4px;
$msv-btn-border-radius: 2px;

.download-btn-box,
.top-btn-box {
    .btn-primary.disabled,
    .btn-primary:disabled {
        cursor: default;
        color: $msv-gray-300;
        background-color: $msv-gray-400;
        border-color: $msv-gray-400;
    }
}
@mixin btn-md() {
    padding: $btn-md-padding;
}
@mixin btn-xl {
    font-size: $btn-font-xl;
    font-weight: $font-weight-bold;
    padding: $btn-xl-padding;
    border-width: $btn-border-thick;
}

@mixin button-primary() {
    @extend .cui[class*='btn'];
    @extend .primary;
}
@mixin button-primary-icon() {
    @include button-primary();
    padding-right: 45px !important;
    &::after {
        @include msv-icon();
        content: $msv-ChevronRight;
        background-color: $msv-primary;
        color: $msv-white;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@mixin button-primary-text() {
    @extend .cui[class*='btn'];
    @extend .primary-text;
}
@mixin button-secondary-text() {
    @extend .cui[class*='btn'];
    @extend .secondary-text;
}

@mixin button-utility() {
    @extend .cui[class*='btn'];
    @extend .utility-btn-solid;
    display: flex;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    justify-content: center;
    text-decoration: none;
    font-weight: 600;
    height: auto;
}
@mixin button-utility-icon() {
    @include button-utility();
    display: flex !important;
    &::after {
        @include msv-icon();
        content: $msv-ChevronRight;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
    }
}

// .cui {
//     &[class*='btn'] {
//         &.primary {
//             @include button-primary();
//             &::after {
//                 @include msv-icon();
//                 content: $msv-ChevronRight;
//                 background-color: $msv-primary;
//                 color: $msv-white;
//                 position: absolute;
//                 right: 0;
//                 top: 0;
//                 height: 100%;
//                 width: 35px;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//             }
//             &.btn-sm {
//                 padding: 0.5625rem 2.8625rem 0.5625rem 0.5625rem;
//             }
//             &.btn-md {
//                 padding: 0.6625rem 3.0625rem 0.6825rem 0.8625rem;
//             }
//             &.btn-lg {
//                 font-size: 1.25rem;
//                 font-weight: 800;
//                 padding: 0.8625rem 3.5625rem 0.8825rem 1.0625rem;
//             }
//             &.btn-xl {
//                 font-size: 1.375rem;
//                 font-weight: 800;
//                 padding: 0.8625rem 3.5625rem 0.8825rem 1.0625rem;
//             }
//             &.processing-btn {
//                 margin-bottom: 20px;
//                 &:disabled {
//                     background: #fff;
//                     border: 2px solid $msv-red;
//                     color: #191919;
//                     box-shadow: 0 0.125rem 0.34375rem rgba(0, 0, 0, 0.15);
//                     overflow: hidden;
//                     border-radius: 0.25rem;
//                     line-height: 1;
//                     span {
//                         &[class*='spinner'] {
//                             color: $msv-red;
//                             margin-right: 10px;
//                             position: relative;
//                             top: 3px;
//                             margin-left: 0;
//                         }
//                     }
//                 }
//             }
//         }
//         &.secondary {
//             @include button-primary();
//             &.btn-sm {
//                 padding: 0.5625rem 0.8625rem;
//             }
//             &.btn-md {
//                 padding: 0.6625rem 0.9625rem;
//             }
//             &.btn-lg {
//                 font-size: 1.25rem;
//                 font-weight: 800;
//                 padding: 0.8625rem 1.0625rem;
//             }
//             &.btn-xl {
//                 font-size: 1.375rem;
//                 font-weight: 800;
//                 padding: 0.8625rem 1.0625rem;
//             }
//             &.processing-btn {
//                 margin-bottom: 20px;
//                 &:disabled {
//                     background: #fff;
//                     border: 2px solid $msv-red;
//                     color: #191919;
//                     box-shadow: 0 0.125rem 0.34375rem rgba(0, 0, 0, 0.15);
//                     overflow: hidden;
//                     border-radius: 0.25rem;
//                     line-height: 1;
//                     span {
//                         color: $msv-red;
//                         margin-right: 10px;
//                         span {
//                             &[class*='spinner'] {
//                                 color: $msv-red;
//                                 margin-right: 10px;
//                                 position: relative;
//                                 top: 3px;
//                                 margin-left: 0;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//         &.utility-btn {
//             @include button-utility();
//             &.btn-sm {
//                 padding: 0.5625rem 0.8625rem;
//             }
//             &.btn-md {
//                 padding: 0.6625rem 0.9625rem;
//             }
//             &.btn-lg {
//                 font-size: 1.25rem;
//                 font-weight: 800;
//                 padding: 0.8625rem 1.0625rem;
//             }
//             &.btn-xl {
//                 font-size: 1.375rem;
//                 font-weight: 800;
//                 padding: 0.8625rem 1.0625rem;
//             }
//         }
//         &.plain {
//             &::after {
//                 content: none;
//             }
//         }
//         &.primary-text {
//             @include button-secondary-text();
//             &:after {
//                 content: none;
//             }
//         }
//     }
// }
.msc-btn {
    display: inline-block;

    &:not(:disabled) {
        cursor: pointer;
    }
    .msc-cart__btn-addcarttotemplate {
        width: 100%;
    }
}
.elecSearchBtn {
    margin-top: 50px;
    .utility-btn {
        color: $msv-primary;
        width: 100%;
    }
}
