$cui-icon-font-family: $font-icon;
$cui-solid-icon-weight: 900;
$cui-outline-icon-weight: 400;

$cui-font-path: '../../../webfonts';

@font-face {
    font-family: $cui-icon-font-family;
    src: url('#{$cui-font-path}/cicon.eot?e37p92');
    src: url('#{$cui-font-path}/cicon.eot?e37p92#iefix') format('embedded-opentype'),
        url('#{$cui-font-path}/cicon.ttf?e37p92') format('truetype'), url('#{$cui-font-path}/cicon.woff?e37p92') format('woff'),
        url('#{$cui-font-path}/cicon.svg?e37p92#cicon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='cicon-'],
[class*=' cicon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $font-icon !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
@mixin cui-icon($icon-weight: $cui-solid-icon-weight) {
    font-family: $cui-icon-font-family;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-rendering: auto;
    line-height: 1;
}
@mixin cui-add-icon() {
    &::before {
        content: '\e2ea';
        font-family: $cui-icon-font-family;
        font-size: 0.8rem;
        margin-right: 5px;
    }
}
@mixin cui-remove-icon() {
    &::before {
        content: '\e2eb';
        font-family: $cui-icon-font-family;
        font-size: 0.8rem;
        margin-right: 5px;
    }
}
.cicon-home:before {
    content: '\e000';
}
.cicon-search:before {
    content: '\e001';
}
.cicon-search-plus:before {
    content: '\e002';
}
.cicon-search-minus:before {
    content: '\e003';
}
.cicon-envelope-o:before {
    content: '\e004';
}
.cicon-envelope:before {
    content: '\e005';
}
.cicon-envelope-square:before {
    content: '\e006';
}
.cicon-mailbox:before {
    content: '\e007';
}
.cicon-paper-plane-o:before {
    content: '\e008';
}
.cicon-paper-plane:before {
    content: '\e009';
}
.cicon-paper-plane-square:before {
    content: '\e00a';
}
.cicon-phone:before {
    content: '\e00c';
}
.cicon-phone-square:before {
    content: '\e00d';
}
.cicon-fax:before {
    content: '\e00e';
}
.cicon-tty:before {
    content: '\e00f';
}
.cicon-certificate:before {
    content: '\e010';
}
.cicon-square:before {
    content: '\e011';
}
.cicon-square-o:before {
    content: '\e012';
}
.cicon-circle:before {
    content: '\e013';
}
.cicon-circle-o:before {
    content: '\e014';
}
.cicon-circle-thin:before {
    content: '\e015';
}
.cicon-circle-x-thin:before {
    content: '\e016';
}
.cicon-dot-circle-o:before {
    content: '\e017';
}
.cicon-ban:before {
    content: '\e018';
}
.cicon-bullseye:before {
    content: '\e019';
}
.cicon-heart-o:before {
    content: '\e01a';
}
.cicon-heart:before {
    content: '\e01b';
}
.cicon-star-o:before {
    content: '\e01c';
}
.cicon-star-half-o:before {
    content: '\e01d';
}
.cicon-star:before {
    content: '\e01e';
}
.cicon-star-half:before {
    content: '\e01f';
}
.cicon-thumbs-o-up:before {
    content: '\e020';
}
.cicon-thumbs-o-down:before {
    content: '\e021';
}
.cicon-thumbs-up:before {
    content: '\e022';
}
.cicon-thumbs-down:before {
    content: '\e023';
}
.cicon-hand-grab-o:before {
    content: '\e280';
}
.cicon-hand-lizard-o:before {
    content: '\e281';
}
.cicon-hand-paper-o:before {
    content: '\e282';
}
.cicon-hand-peace-o:before {
    content: '\e283';
}
.cicon-hand-pointer-o:before {
    content: '\e284';
}
.cicon-hand-scissors-o:before {
    content: '\e285';
}
.cicon-hand-spock-o:before {
    content: '\e286';
}
.cicon-handshake:before {
    content: '\e38e';
}
.cicon-smile-o:before {
    content: '\e024';
}
.cicon-frown-o:before {
    content: '\e025';
}
.cicon-meh-o:before {
    content: '\e026';
}
.cicon-user:before {
    content: '\e027';
}
.cicon-user-plus:before {
    content: '\e028';
}
.cicon-user-times:before {
    content: '\e029';
}
.cicon-user-friends:before {
    content: '\e38f';
}
.cicon-users:before {
    content: '\e02a';
}
.cicon-people-in-group:before {
    content: '\e387';
}
.cicon-user-secret:before {
    content: '\e02b';
}
.cicon-user-md:before {
    content: '\e02c';
}
.cicon-male:before {
    content: '\e02d';
}
.cicon-female:before {
    content: '\e02e';
}
.cicon-child:before {
    content: '\e02f';
}
.cicon-check:before {
    content: '\e030';
}
.cicon-times:before {
    content: '\e031';
}
.cicon-check-square:before {
    content: '\e032';
}
.cicon-times-square:before {
    content: '\e033';
}
.cicon-check-square-o:before {
    content: '\e034';
}
.cicon-check-circle:before {
    content: '\e035';
}
.cicon-times-circle:before {
    content: '\e036';
}
.cicon-check-circle-o:before {
    content: '\e037';
}
.cicon-times-circle-o:before {
    content: '\e038';
}
.cicon-close-thin-large:before {
    content: '\e039';
}
.cicon-plus-thin:before {
    content: '\e2ea';
}
.cicon-minus-thin:before {
    content: '\e2eb';
}
.cicon-plus:before {
    content: '\e03a';
}
.cicon-minus:before {
    content: '\e03b';
}
.cicon-plus-circle:before {
    content: '\e03c';
}
.cicon-minus-circle:before {
    content: '\e03d';
}
.cicon-plus-square:before {
    content: '\e03e';
}
.cicon-minus-square:before {
    content: '\e03f';
}
.cicon-bars:before {
    content: '\e042';
}
.cicon-tachometer:before {
    content: '\e043';
}
.cicon-cog:before {
    content: '\e044';
}
.cicon-cogs:before {
    content: '\e045';
}
.cicon-wrench:before {
    content: '\e046';
}
.cicon-sort:before {
    content: '\e047';
}
.cicon-sort-desc:before {
    content: '\e048';
}
.cicon-sort-asc:before {
    content: '\e049';
}
.cicon-sort-alpha-asc:before {
    content: '\e04a';
}
.cicon-sort-alpha-desc:before {
    content: '\e04b';
}
.cicon-sort-amount-asc:before {
    content: '\e04c';
}
.cicon-sort-amount-desc:before {
    content: '\e04d';
}
.cicon-sort-numeric-asc:before {
    content: '\e04e';
}
.cicon-sort-numeric-desc:before {
    content: '\e04f';
}
.cicon-filter:before {
    content: '\e050';
}
.cicon-flask:before {
    content: '\e051';
}
.cicon-sitemap:before {
    content: '\e052';
}
.cicon-project-diagram:before {
    content: '\e390';
}
.cicon-crop:before {
    content: '\e053';
}
.cicon-mouse-pointer:before {
    content: '\e287';
}
.cicon-i-cursor:before {
    content: '\e288';
}
.cicon-toggle-off:before {
    content: '\e054';
}
.cicon-toggle-on:before {
    content: '\e055';
}
.cicon-download:before {
    content: '\e056';
}
.cicon-upload:before {
    content: '\e057';
}
.cicon-inbox:before {
    content: '\e058';
}
.cicon-archive:before {
    content: '\e059';
}
.cicon-retweet:before {
    content: '\e05a';
}
.cicon-repeat:before {
    content: '\e05b';
}
.cicon-undo:before {
    content: '\e05c';
}
.cicon-refresh:before {
    content: '\e05d';
}
.cicon-circle-o-notch:before {
    content: '\e05e';
}
.cicon-clock-o:before {
    content: '\e05f';
}
.cicon-history:before {
    content: '\e060';
}
.cicon-calendar:before {
    content: '\e061';
}
.cicon-calendar-o:before {
    content: '\e062';
}
.cicon-calendar-plus-o:before {
    content: '\e289';
}
.cicon-calendar-minus-o:before {
    content: '\e28a';
}
.cicon-calendar-check-o:before {
    content: '\e28b';
}
.cicon-calendar-times-o:before {
    content: '\e28c';
}
.cicon-sticky-note-o:before {
    content: '\e28d';
}
.cicon-sticky-note:before {
    content: '\e28e';
}
.cicon-clone:before {
    content: '\e28f';
}
.cicon-hourglass-1:before {
    content: '\e290';
}
.cicon-hourglass-2:before {
    content: '\e291';
}
.cicon-hourglass-3:before {
    content: '\e292';
}
.cicon-hourglass:before {
    content: '\e293';
}
.cicon-hourglass-o:before {
    content: '\e294';
}
.cicon-spinner:before {
    content: '\e063';
}
.cicon-key:before {
    content: '\e064';
}
.cicon-lock:before {
    content: '\e065';
}
.cicon-unlock-alt:before {
    content: '\e066';
}
.cicon-unlock:before {
    content: '\e067';
}
.cicon-thumb-tack:before {
    content: '\e068';
}
.cicon-flag:before {
    content: '\e069';
}
.cicon-flag-o:before {
    content: '\e06a';
}
.cicon-flag-checkered:before {
    content: '\e06b';
}
.cicon-qrcode:before {
    content: '\e06c';
}
.cicon-barcode:before {
    content: '\e06d';
}
.cicon-tag:before {
    content: '\e06e';
}
.cicon-tags:before {
    content: '\e06f';
}
.cicon-book:before {
    content: '\e070';
}
.cicon-bookmark-o:before {
    content: '\e071';
}
.cicon-bookmark:before {
    content: '\e072';
}
.cicon-clipboard-check:before {
    content: '\e391';
}
.cicon-clipboard-list:before {
    content: '\e392';
}
.cicon-id-badge:before {
    content: '\e393';
}
.cicon-print:before {
    content: '\e073';
}
.cicon-eye:before {
    content: '\e074';
}
.cicon-eye-slash:before {
    content: '\e075';
}
.cicon-bell-o:before {
    content: '\e076';
}
.cicon-bell-slash-o:before {
    content: '\e077';
}
.cicon-bell:before {
    content: '\e078';
}
.cicon-bell-slash:before {
    content: '\e079';
}
.cicon-adjust:before {
    content: '\e07a';
}
.cicon-tint:before {
    content: '\e07b';
}
.cicon-exclamation:before {
    content: '\e07c';
}
.cicon-exclamation-triangle:before {
    content: '\e07d';
}
.cicon-exclamation-diamond:before {
    content: '\e07e';
}
.cicon-exclamation-circle:before {
    content: '\e07f';
}
.cicon-question:before {
    content: '\e080';
}
.cicon-question-circle:before {
    content: '\e081';
}
.cicon-info:before {
    content: '\e082';
}
.cicon-info-circle:before {
    content: '\e083';
}
.cicon-newspaper:before {
    content: '\e236';
}
.cicon-tasks:before {
    content: '\e085';
}
.cicon-comment:before {
    content: '\e086';
}
.cicon-comment-o:before {
    content: '\e087';
}
.cicon-comment-dots:before {
    content: '\e088';
}
.cicon-comment-dots-o:before {
    content: '\e295';
}
.cicon-comments:before {
    content: '\e089';
}
.cicon-comments-o:before {
    content: '\e08a';
}
.cicon-magnet:before {
    content: '\e08b';
}
.cicon-bullhorn:before {
    content: '\e08c';
}
.cicon-suitcase:before {
    content: '\e08d';
}
.cicon-briefcase:before {
    content: '\e08e';
}
.cicon-lightbulb-o:before {
    content: '\e08f';
}
.cicon-television:before {
    content: '\e296';
}
.cicon-desktop:before {
    content: '\e090';
}
.cicon-laptop:before {
    content: '\e091';
}
.cicon-tablet:before {
    content: '\e092';
}
.cicon-mobile:before {
    content: '\e093';
}
.cicon-battery-0:before {
    content: '\e297';
}
.cicon-battery-1:before {
    content: '\e298';
}
.cicon-battery-2:before {
    content: '\e299';
}
.cicon-battery-3:before {
    content: '\e29a';
}
.cicon-battery-4:before {
    content: '\e29b';
}
.cicon-keyboard-o:before {
    content: '\e094';
}
.cicon-gamepad:before {
    content: '\e095';
}
.cicon-signal:before {
    content: '\e096';
}
.cicon-wifi:before {
    content: '\e097';
}
.cicon-calculator:before {
    content: '\e098';
}
.cicon-power-off:before {
    content: '\e099';
}
.cicon-hdd-o:before {
    content: '\e09a';
}
.cicon-server:before {
    content: '\e09b';
}
.cicon-database:before {
    content: '\e09c';
}
.cicon-cloud-download:before {
    content: '\e09d';
}
.cicon-cloud-upload:before {
    content: '\e09e';
}
.cicon-cloud:before {
    content: '\e09f';
}
.cicon-sun-o:before {
    content: '\e0a0';
}
.cicon-moon-o:before {
    content: '\e0a1';
}
.cicon-bolt:before {
    content: '\e0a2';
}
.cicon-umbrella:before {
    content: '\e0a3';
}
.cicon-quote-left:before {
    content: '\e0a4';
}
.cicon-quote-right:before {
    content: '\e0a5';
}
.cicon-copyright:before {
    content: '\e0a6';
}
.cicon-at:before {
    content: '\e0a7';
}
.cicon-registered:before {
    content: '\e29c';
}
.cicon-creative-commons:before {
    content: '\e29d';
}
.cicon-trademark:before {
    content: '\e29e';
}
.cicon-terminal:before {
    content: '\e0a8';
}
.cicon-code:before {
    content: '\e0a9';
}
.cicon-map-marker:before {
    content: '\e0aa';
}
.cicon-map-pin:before {
    content: '\e29f';
}
.cicon-layers:before {
    content: '\e0ab';
}
.cicon-map:before {
    content: '\e0ac';
}
.cicon-map2:before {
    content: '\e2a0';
}
.cicon-map2-o:before {
    content: '\e2a1';
}
.cicon-compass:before {
    content: '\e0ad';
}
.cicon-location-target:before {
    content: '\e0ae';
}
.cicon-crosshairs:before {
    content: '\e0af';
}
.cicon-location-arrow:before {
    content: '\e0b0';
}
.cicon-street-view:before {
    content: '\e0b1';
}
.cicon-map-signs:before {
    content: '\e2a2';
}
.cicon-code-fork:before {
    content: '\e0b2';
}
.cicon-puzzle-piece:before {
    content: '\e0b3';
}
.cicon-shield:before {
    content: '\e0b4';
}
.cicon-fire-extinguisher:before {
    content: '\e0b5';
}
.cicon-asterisk:before {
    content: '\e0b6';
}
.cicon-anchor:before {
    content: '\e0b7';
}
.cicon-ellipsis-h:before {
    content: '\e0b8';
}
.cicon-ellipsis-v:before {
    content: '\e0b9';
}
.cicon-rss:before {
    content: '\e0ba';
}
.cicon-rss-square:before {
    content: '\e0bb';
}
.cicon-globe:before {
    content: '\e0bc';
}
.cicon-tree:before {
    content: '\e0bd';
}
.cicon-leaf:before {
    content: '\e0be';
}
.cicon-fire:before {
    content: '\e0bf';
}
.cicon-snowflake:before {
    content: '\e38a';
}
.cicon-bug:before {
    content: '\e0c0';
}
.cicon-historical-building:before {
    content: '\e0c1';
}
.cicon-graduation-cap:before {
    content: '\e0c2';
}
.cicon-industry:before {
    content: '\e2a3';
}
.cicon-building-o:before {
    content: '\e0c3';
}
.cicon-building:before {
    content: '\e0c4';
}
.cicon-hospital-o:before {
    content: '\e0c5';
}
.cicon-medkit:before {
    content: '\e0c6';
}
.cicon-h-square:before {
    content: '\e0c7';
}
.cicon-bed:before {
    content: '\e0c8';
}
.cicon-stethoscope:before {
    content: '\e0c9';
}
.cicon-heartbeat:before {
    content: '\e0ca';
}
.cicon-wheelchair:before {
    content: '\e0cb';
}
.cicon-gavel:before {
    content: '\e0cc';
}
.cicon-language:before {
    content: '\e0cd';
}
.cicon-cube:before {
    content: '\e0cf';
}
.cicon-cubes:before {
    content: '\e0d0';
}
.cicon-recycle:before {
    content: '\e0d1';
}
.cicon-life-ring:before {
    content: '\e0d2';
}
.cicon-sliders:before {
    content: '\e0d3';
}
.cicon-bomb:before {
    content: '\e0d4';
}
.cicon-trophy:before {
    content: '\e0d5';
}
.cicon-plug:before {
    content: '\e0d6';
}
.cicon-gift:before {
    content: '\e0d7';
}
.cicon-ticket:before {
    content: '\e0d8';
}
.cicon-futbol-o:before {
    content: '\e0d9';
}
.cicon-road:before {
    content: '\e0da';
}
.cicon-binoculars:before {
    content: '\e0db';
}
.cicon-glass:before {
    content: '\e0dc';
}
.cicon-coffee:before {
    content: '\e0dd';
}
.cicon-beer:before {
    content: '\e0de';
}
.cicon-cutlery:before {
    content: '\e0df';
}
.cicon-spoon:before {
    content: '\e0e0';
}
.cicon-birthday-cake:before {
    content: '\e0e1';
}
.cicon-lemon-o:before {
    content: '\e0e2';
}
.cicon-bar-chart:before {
    content: '\e0e3';
}
.cicon-area-chart:before {
    content: '\e0e4';
}
.cicon-pie-chart:before {
    content: '\e0e5';
}
.cicon-person-pie-chart:before {
    content: '\e38b';
}
.cicon-line-chart:before {
    content: '\e0e6';
}
.cicon-shopping-bag:before {
    content: '\e2a4';
}
.cicon-shopping-cart:before {
    content: '\e0e7';
}
.cicon-cart-plus:before {
    content: '\e0e8';
}
.cicon-cart-arrow-down:before {
    content: '\e0e9';
}
.cicon-diamond:before {
    content: '\e0ea';
}
.cicon-object-group:before {
    content: '\e2a5';
}
.cicon-object-ungroup:before {
    content: '\e2a6';
}
.cicon-percent:before {
    content: '\e2a7';
}
.cicon-hashtag:before {
    content: '\e2a8';
}
.cicon-balance-scale:before {
    content: '\e2a9';
}
.cicon-venus:before {
    content: '\e0eb';
}
.cicon-mars:before {
    content: '\e0ec';
}
.cicon-mercury:before {
    content: '\e0ed';
}
.cicon-transgender:before {
    content: '\e0ee';
}
.cicon-transgender-alt:before {
    content: '\e0ef';
}
.cicon-venus-double:before {
    content: '\e0f0';
}
.cicon-mars-double:before {
    content: '\e0f1';
}
.cicon-venus-mars:before {
    content: '\e0f2';
}
.cicon-mars-stroke:before {
    content: '\e0f3';
}
.cicon-mars-stroke-v:before {
    content: '\e0f4';
}
.cicon-mars-stroke-h:before {
    content: '\e0f5';
}
.cicon-neuter:before {
    content: '\e0f6';
}
.cicon-broadcast-tower:before {
    content: '\e394';
}
.cicon-minus-thin-border:before {
    content: '\e404';
}
.cicon-covid:before {
    content: '\e396';
}
.cicon-thermometer:before {
    content: '\e405';
}
.cicon-pump-medical:before {
    content: '\e406';
}
.cicon-people-arrows:before {
    content: '\e407';
}
.cicon-hospital-bed:before {
    content: '\e408';
}
.cicon-head-mask:before {
    content: '\e409';
}
.cicon-head-cough:before {
    content: '\e40a';
}
.cicon-hands-wash:before {
    content: '\e40b';
}
.cicon-mask-only:before {
    content: '\e40c';
}
.cicon-file-signature:before {
    content: '\e40e';
}
.cicon-money-check-alt:before {
    content: '\e40d';
}
.cicon-vote-yea:before {
    content: '\e40f';
}
.cicon-gl-stn-underground:before {
    content: '\e401';
}
.cicon-gl-station:before {
    content: '\e400';
}
.cicon-ambulance:before {
    content: '\e0f7';
}
.cicon-car:before {
    content: '\e0f8';
}
.cicon-bicycle:before {
    content: '\e0f9';
}
.cicon-bus:before {
    content: '\e0fa';
}
.cicon-cab:before {
    content: '\e0fb';
}
.cicon-fighter-jet:before {
    content: '\e0fc';
}
.cicon-motorcycle:before {
    content: '\e0fd';
}
.cicon-plane:before {
    content: '\e0fe';
}
.cicon-rocket:before {
    content: '\e0ff';
}
.cicon-ship:before {
    content: '\e100';
}
.cicon-space-shuttle:before {
    content: '\e101';
}
.cicon-subway:before {
    content: '\e102';
}
.cicon-train:before {
    content: '\e103';
}
.cicon-truck:before {
    content: '\e104';
}
.cicon-file-dwt:before {
    content: '\e410';
}
.cicon-file-dst:before {
    content: '\e411';
}
.cicon-file-archive-o:before {
    content: '\e105';
}
.cicon-file-audio-o:before {
    content: '\e106';
}
.cicon-file-code-o:before {
    content: '\e107';
}
.cicon-file-excel-o:before {
    content: '\e108';
}
.cicon-file-image-o:before {
    content: '\e109';
}
.cicon-file-video-o:before {
    content: '\e10a';
}
.cicon-file-pdf-o:before {
    content: '\e10b';
}
.cicon-file-powerpoint-o:before {
    content: '\e10c';
}
.cicon-file-word-o:before {
    content: '\e10d';
}
.cicon-file-o:before {
    content: '\e10e';
}
.cicon-file-text-o:before {
    content: '\e10f';
}
.cicon-file:before {
    content: '\e110';
}
.cicon-file-text:before {
    content: '\e111';
}
.cicon-folder-o:before {
    content: '\e112';
}
.cicon-folder-open-o:before {
    content: '\e113';
}
.cicon-folder:before {
    content: '\e114';
}
.cicon-folder-open:before {
    content: '\e115';
}
.cicon-cc-amex:before {
    content: '\e116';
}
.cicon-cc-discover:before {
    content: '\e117';
}
.cicon-cc-mastercard:before {
    content: '\e118';
}
.cicon-cc-paypal:before {
    content: '\e119';
}
.cicon-cc-stripe:before {
    content: '\e11a';
}
.cicon-cc-visa:before {
    content: '\e11b';
}
.cicon-cc-jcb:before {
    content: '\e2aa';
}
.cicon-credit-card:before {
    content: '\e11c';
}
.cicon-credit-card-alt:before {
    content: '\e2ab';
}
.cicon-cc-diners-club:before {
    content: '\e2ac';
}
.cicon-money:before {
    content: '\e11d';
}
.cicon-paypal:before {
    content: '\e11e';
}
.cicon-google-wallet:before {
    content: '\e11f';
}
.cicon-btc:before {
    content: '\e120';
}
.cicon-viacoin:before {
    content: '\e20d';
}
.cicon-dollar:before {
    content: '\e121';
}
.cicon-cny:before {
    content: '\e122';
}
.cicon-eur:before {
    content: '\e123';
}
.cicon-gbp:before {
    content: '\e124';
}
.cicon-ils:before {
    content: '\e125';
}
.cicon-inr:before {
    content: '\e126';
}
.cicon-krw:before {
    content: '\e127';
}
.cicon-rouble:before {
    content: '\e128';
}
.cicon-try:before {
    content: '\e129';
}
.cicon-align-center:before {
    content: '\e12a';
}
.cicon-align-justify:before {
    content: '\e12b';
}
.cicon-align-left:before {
    content: '\e12c';
}
.cicon-align-right:before {
    content: '\e12d';
}
.cicon-dedent:before {
    content: '\e12e';
}
.cicon-indent:before {
    content: '\e12f';
}
.cicon-list-ul:before {
    content: '\e130';
}
.cicon-list-ol:before {
    content: '\e131';
}
.cicon-list:before {
    content: '\e132';
}
.cicon-list-alt:before {
    content: '\e133';
}
.cicon-columns:before {
    content: '\e134';
}
.cicon-table:before {
    content: '\e135';
}
.cicon-th-large:before {
    content: '\e136';
}
.cicon-th-list:before {
    content: '\e137';
}
.cicon-th:before {
    content: '\e138';
}
.cicon-font:before {
    content: '\e139';
}
.cicon-bold:before {
    content: '\e13a';
}
.cicon-italic:before {
    content: '\e13b';
}
.cicon-underline:before {
    content: '\e13c';
}
.cicon-strikethrough:before {
    content: '\e13d';
}
.cicon-subscript:before {
    content: '\e13e';
}
.cicon-superscript:before {
    content: '\e13f';
}
.cicon-header:before {
    content: '\e140';
}
.cicon-paragraph:before {
    content: '\e141';
}
.cicon-text-height:before {
    content: '\e142';
}
.cicon-text-width:before {
    content: '\e143';
}
.cicon-scissors:before {
    content: '\e144';
}
.cicon-files-o:before {
    content: '\e145';
}
.cicon-clipboard:before {
    content: '\e146';
}
.cicon-chain:before {
    content: '\e147';
}
.cicon-chain-broken:before {
    content: '\e148';
}
.cicon-paperclip:before {
    content: '\e149';
}
.cicon-pencil:before {
    content: '\e14a';
}
.cicon-pencil-square:before {
    content: '\e14b';
}
.cicon-pencil-square-o:before {
    content: '\e14c';
}
.cicon-paint-brush:before {
    content: '\e14d';
}
.cicon-magic:before {
    content: '\e14e';
}
.cicon-eyedropper:before {
    content: '\e14f';
}
.cicon-eraser:before {
    content: '\e150';
}
.cicon-floppy-o:before {
    content: '\e151';
}
.cicon-trash-o:before {
    content: '\e152';
}
.cicon-trash:before {
    content: '\e153';
}
.cicon-angle-double-down:before {
    content: '\e154';
}
.cicon-angle-double-left:before {
    content: '\e155';
}
.cicon-angle-double-right:before {
    content: '\e156';
}
.cicon-angle-double-up:before {
    content: '\e157';
}
.cicon-angle-down:before {
    content: '\e158';
}
.cicon-angle-left:before {
    content: '\e159';
}
.cicon-angle-right:before {
    content: '\e15a';
}
.cicon-angle-up:before {
    content: '\e15b';
}
.cicon-arrow-circle-down:before {
    content: '\e15c';
}
.cicon-arrow-circle-left:before {
    content: '\e15d';
}
.cicon-arrow-circle-right:before {
    content: '\e15e';
}
.cicon-arrow-circle-up:before {
    content: '\e15f';
}
.cicon-arrow-circle-o-down:before {
    content: '\e160';
}
.cicon-arrow-circle-o-left:before {
    content: '\e161';
}
.cicon-arrow-circle-o-right:before {
    content: '\e162';
}
.cicon-arrow-circle-o-up:before {
    content: '\e163';
}
.cicon-arrow-down:before {
    content: '\e164';
}
.cicon-arrow-left:before {
    content: '\e165';
}
.cicon-arrow-right:before {
    content: '\e166';
}
.cicon-arrow-up:before {
    content: '\e167';
}
.cicon-caret-down:before {
    content: '\e168';
}
.cicon-caret-left:before {
    content: '\e169';
}
.cicon-caret-right:before {
    content: '\e16a';
}
.cicon-caret-up:before {
    content: '\e16b';
}
.cicon-caret-square-o-down:before {
    content: '\e16c';
}
.cicon-caret-square-o-left:before {
    content: '\e16d';
}
.cicon-caret-square-o-right:before {
    content: '\e16e';
}
.cicon-caret-square-o-up:before {
    content: '\e16f';
}
.cicon-chevron-circle-down:before {
    content: '\e170';
}
.cicon-chevron-circle-left:before {
    content: '\e171';
}
.cicon-chevron-circle-right:before {
    content: '\e172';
}
.cicon-chevron-circle-up:before {
    content: '\e173';
}
.cicon-chevron-down:before {
    content: '\e174';
}
.cicon-chevron-left:before {
    content: '\e175';
}
.cicon-chevron-right:before {
    content: '\e176';
}
.cicon-chevron-up:before {
    content: '\e177';
}
.cicon-hand-o-down:before {
    content: '\e178';
}
.cicon-hand-o-left:before {
    content: '\e179';
}
.cicon-hand-o-right:before {
    content: '\e17a';
}
.cicon-hand-o-up:before {
    content: '\e17b';
}
.cicon-long-arrow-down:before {
    content: '\e17c';
}
.cicon-long-arrow-left:before {
    content: '\e17d';
}
.cicon-long-arrow-right:before {
    content: '\e17e';
}
.cicon-long-arrow-up:before {
    content: '\e17f';
}
.cicon-arrows-h:before {
    content: '\e180';
}
.cicon-arrows-v:before {
    content: '\e181';
}
.cicon-exchange:before {
    content: '\e182';
}
.cicon-random:before {
    content: '\e183';
}
.cicon-level-down:before {
    content: '\e184';
}
.cicon-level-up:before {
    content: '\e185';
}
.cicon-arrows-alt:before {
    content: '\e186';
}
.cicon-arrows:before {
    content: '\e187';
}
.cicon-expand:before {
    content: '\e188';
}
.cicon-compress:before {
    content: '\e189';
}
.cicon-external-link:before {
    content: '\e18a';
}
.cicon-external-link-square:before {
    content: '\e18b';
}
.cicon-reply-all:before {
    content: '\e18c';
}
.cicon-reply:before {
    content: '\e18d';
}
.cicon-share:before {
    content: '\e18e';
}
.cicon-share-square-o:before {
    content: '\e18f';
}
.cicon-share-square:before {
    content: '\e190';
}
.cicon-sign-in:before {
    content: '\e191';
}
.cicon-sign-out:before {
    content: '\e192';
}
.cicon-camera-retro:before {
    content: '\e193';
}
.cicon-camera:before {
    content: '\e194';
}
.cicon-picture-o:before {
    content: '\e195';
}
.cicon-music:before {
    content: '\e196';
}
.cicon-headphones:before {
    content: '\e197';
}
.cicon-video-camera:before {
    content: '\e198';
}
.cicon-film:before {
    content: '\e199';
}
.cicon-fast-backward:before {
    content: '\e19a';
}
.cicon-step-backward:before {
    content: '\e19b';
}
.cicon-backward:before {
    content: '\e19c';
}
.cicon-forward:before {
    content: '\e19d';
}
.cicon-step-forward:before {
    content: '\e19e';
}
.cicon-fast-forward:before {
    content: '\e19f';
}
.cicon-play:before {
    content: '\e1a0';
}
.cicon-pause:before {
    content: '\e1a1';
}
.cicon-stop:before {
    content: '\e1a2';
}
.cicon-play-circle-o:before {
    content: '\e1a3';
}
.cicon-play-circle:before {
    content: '\e1a4';
}
.cicon-pause-circle:before {
    content: '\e2ad';
}
.cicon-pause-circle-o:before {
    content: '\e2ae';
}
.cicon-stop-circle:before {
    content: '\e2af';
}
.cicon-stop-circle-o:before {
    content: '\e2b0';
}
.cicon-youtube-play:before {
    content: '\e1a5';
}
.cicon-volume-off:before {
    content: '\e1a6';
}
.cicon-volume-down:before {
    content: '\e1a7';
}
.cicon-volume-up:before {
    content: '\e1a8';
}
.cicon-microphone:before {
    content: '\e1a9';
}
.cicon-microphone-slash:before {
    content: '\e1aa';
}
.cicon-eject:before {
    content: '\e1ab';
}
.cicon-cc:before {
    content: '\e00b';
}
.cicon-facebook:before {
    content: '\e1ac';
}
.cicon-facebook-official:before {
    content: '\e1ad';
}
.cicon-facebook-square:before {
    content: '\e1ae';
}
.cicon-facebook-circle:before {
    content: '\e1af';
}
.cicon-twitter:before {
    content: '\e1b0';
}
.cicon-twitter-square:before {
    content: '\e1b1';
}
.cicon-twitter-circle:before {
    content: '\e1b2';
}
.cicon-linkedin:before {
    content: '\e1b3';
}
.cicon-linkedin-square:before {
    content: '\e1b4';
}
.cicon-linkedin-circle:before {
    content: '\e1b5';
}
.cicon-flickr:before {
    content: '\e1b6';
}
.cicon-flickr-circle:before {
    content: '\e1b7';
}
.cicon-instagram:before {
    content: '\e1b8';
}
.cicon-youtube:before {
    content: '\e1b9';
}
.cicon-youtube-square:before {
    content: '\e1ba';
}
.cicon-youtube-circle:before {
    content: '\e1bb';
}
.cicon-citynews-circle:before {
    content: '\e1bc';
}
.cicon-google:before {
    content: '\e1bd';
}
.cicon-google-plus:before {
    content: '\e1be';
}
.cicon-google-plus-circle:before {
    content: '\e1bf';
}
.cicon-google-plus-square:before {
    content: '\e1c0';
}
.cicon-tumblr:before {
    content: '\e1c1';
}
.cicon-tumblr-square:before {
    content: '\e1c2';
}
.cicon-tumblr-circle:before {
    content: '\e1c3';
}
.cicon-pinterest-p:before {
    content: '\e1c4';
}
.cicon-pinterest-square:before {
    content: '\e1c5';
}
.cicon-pinterest:before {
    content: '\e1c6';
}
.cicon-share-alt:before {
    content: '\e1c7';
}
.cicon-share-alt-square:before {
    content: '\e1c8';
}
.cicon-behance:before {
    content: '\e1c9';
}
.cicon-behance-square:before {
    content: '\e1ca';
}
.cicon-git:before {
    content: '\e1cb';
}
.cicon-git-square:before {
    content: '\e1cc';
}
.cicon-github-alt:before {
    content: '\e1cd';
}
.cicon-github-square:before {
    content: '\e1ce';
}
.cicon-github:before {
    content: '\e1cf';
}
.cicon-yahoo:before {
    content: '\e1d0';
}
.cicon-hacker-news:before {
    content: '\e1d1';
}
.cicon-y-combinator:before {
    content: '\e2b1';
}
.cicon-vine:before {
    content: '\e1d2';
}
.cicon-vimeo:before {
    content: '\e2b2';
}
.cicon-vimeo-square:before {
    content: '\e1d3';
}
.cicon-dropbox:before {
    content: '\e1d4';
}
.cicon-bitbucket:before {
    content: '\e1d5';
}
.cicon-bitbucket-square:before {
    content: '\e1d6';
}
.cicon-adn:before {
    content: '\e1d7';
}
.cicon-angellist:before {
    content: '\e1d8';
}
.cicon-buysellads:before {
    content: '\e1d9';
}
.cicon-codepen:before {
    content: '\e1da';
}
.cicon-connectdevelop:before {
    content: '\e1db';
}
.cicon-css3:before {
    content: '\e1dc';
}
.cicon-dashcube:before {
    content: '\e1dd';
}
.cicon-delicious:before {
    content: '\e1de';
}
.cicon-deviantart:before {
    content: '\e1df';
}
.cicon-digg:before {
    content: '\e1e0';
}
.cicon-dribbble:before {
    content: '\e1e1';
}
.cicon-drupal:before {
    content: '\e1e2';
}
.cicon-empire:before {
    content: '\e1e3';
}
.cicon-forumbee:before {
    content: '\e1e4';
}
.cicon-foursquare:before {
    content: '\e1e5';
}
.cicon-gratipay:before {
    content: '\e1e6';
}
.cicon-html5:before {
    content: '\e1e7';
}
.cicon-ioxhost:before {
    content: '\e1e8';
}
.cicon-joomla:before {
    content: '\e1e9';
}
.cicon-jsfiddle:before {
    content: '\e1ea';
}
.cicon-lastfm:before {
    content: '\e1eb';
}
.cicon-lastfm-square:before {
    content: '\e1ec';
}
.cicon-leanpub:before {
    content: '\e1ed';
}
.cicon-linux:before {
    content: '\e1ee';
}
.cicon-maxcdn:before {
    content: '\e1ef';
}
.cicon-meanpath:before {
    content: '\e1f0';
}
.cicon-medium:before {
    content: '\e1f1';
}
.cicon-openid:before {
    content: '\e1f2';
}
.cicon-pagelines:before {
    content: '\e1f3';
}
.cicon-pied-piper-alt:before {
    content: '\e1f4';
}
.cicon-pied-piper:before {
    content: '\e1f5';
}
.cicon-qq:before {
    content: '\e1f6';
}
.cicon-rebel:before {
    content: '\e1f7';
}
.cicon-reddit-square:before {
    content: '\e1f8';
}
.cicon-reddit:before {
    content: '\e1f9';
}
.cicon-reddit-alien:before {
    content: '\e2b3';
}
.cicon-renren:before {
    content: '\e1fa';
}
.cicon-sellsy:before {
    content: '\e1fb';
}
.cicon-mixcloud:before {
    content: '\e2b4';
}
.cicon-shirtsinbulk:before {
    content: '\e1fc';
}
.cicon-simplybuilt:before {
    content: '\e1fd';
}
.cicon-skyatlas:before {
    content: '\e1fe';
}
.cicon-skype:before {
    content: '\e1ff';
}
.cicon-slack:before {
    content: '\e200';
}
.cicon-slideshare:before {
    content: '\e201';
}
.cicon-soundcloud:before {
    content: '\e202';
}
.cicon-spotify:before {
    content: '\e203';
}
.cicon-stack-exchange:before {
    content: '\e204';
}
.cicon-stack-overflow:before {
    content: '\e205';
}
.cicon-steam-square:before {
    content: '\e206';
}
.cicon-steam:before {
    content: '\e207';
}
.cicon-stumbleupon:before {
    content: '\e208';
}
.cicon-stumbleupon-circle:before {
    content: '\e209';
}
.cicon-tencent-weibo:before {
    content: '\e20a';
}
.cicon-trello:before {
    content: '\e20b';
}
.cicon-twitch:before {
    content: '\e20c';
}
.cicon-vk:before {
    content: '\e20e';
}
.cicon-weixin:before {
    content: '\e20f';
}
.cicon-weibo:before {
    content: '\e210';
}
.cicon-whatsapp:before {
    content: '\e211';
}
.cicon-android:before {
    content: '\e212';
}
.cicon-apple:before {
    content: '\e213';
}
.cicon-windows:before {
    content: '\e214';
}
.cicon-wordpress:before {
    content: '\e215';
}
.cicon-xing:before {
    content: '\e216';
}
.cicon-xing-square:before {
    content: '\e217';
}
.cicon-yelp:before {
    content: '\e218';
}
.cicon-disqus-circle:before {
    content: '\e219';
}
.cicon-px:before {
    content: '\e2b5';
}
.cicon-amazon:before {
    content: '\e2b6';
}
.cicon-black-tie:before {
    content: '\e2b7';
}
.cicon-bluetooth-b:before {
    content: '\e2b8';
}
.cicon-bluetooth:before {
    content: '\e2b9';
}
.cicon-chrome:before {
    content: '\e2ba';
}
.cicon-codiepie:before {
    content: '\e2bb';
}
.cicon-contao:before {
    content: '\e2bc';
}
.cicon-expeditedssl:before {
    content: '\e2bd';
}
.cicon-fonticons:before {
    content: '\e2be';
}
.cicon-fort-awesome:before {
    content: '\e2bf';
}
.cicon-get-pocket:before {
    content: '\e2c0';
}
.cicon-gg-circle:before {
    content: '\e2c1';
}
.cicon-gg:before {
    content: '\e2c2';
}
.cicon-optin-monster:before {
    content: '\e2c3';
}
.cicon-product-hunt:before {
    content: '\e2c4';
}
.cicon-scribd:before {
    content: '\e2c5';
}
.cicon-usb:before {
    content: '\e2c6';
}
.cicon-tripadvisor:before {
    content: '\e2c7';
}
.cicon-wikipedia-w:before {
    content: '\e2c8';
}
.cicon-modx:before {
    content: '\e2c9';
}
.cicon-odnoklassniki:before {
    content: '\e2ca';
}
.cicon-odnoklassniki-square:before {
    content: '\e2cb';
}
.cicon-opencart:before {
    content: '\e2cc';
}
.cicon-houzz:before {
    content: '\e2cd';
}
.cicon-edge:before {
    content: '\e2ce';
}
.cicon-firefox:before {
    content: '\e2cf';
}
.cicon-safari:before {
    content: '\e2d0';
}
.cicon-opera:before {
    content: '\e2d1';
}
.cicon-map-square:before {
    content: '\e21a';
}
.cicon-map-square-o:before {
    content: '\e21b';
}
.cicon-map-pointer-square:before {
    content: '\e27e';
}
.cicon-map-pointer-square-o:before {
    content: '\e27f';
}
.cicon-calendar-event:before {
    content: '\e21c';
}
.cicon-cemetery:before {
    content: '\e21d';
}
.cicon-atraction:before {
    content: '\e21e';
}
.cicon-visitor-info:before {
    content: '\e21f';
}
.cicon-community-center:before {
    content: '\e220';
}
.cicon-social-dev-centre:before {
    content: '\e221';
}
.cicon-civic-partners-development:before {
    content: '\e222';
}
.cicon-casg:before {
    content: '\e223';
}
.cicon-community-safety:before {
    content: '\e224';
}
.cicon-new-central-library:before {
    content: '\e225';
}
.cicon-park-development:before {
    content: '\e226';
}
.cicon-recreation-development:before {
    content: '\e227';
}
.cicon-after-school-program:before {
    content: '\e228';
}
.cicon-library:before {
    content: '\e229';
}
.cicon-post-secondary:before {
    content: '\e22a';
}
.cicon-school:before {
    content: '\e22b';
}
.cicon-court:before {
    content: '\e22c';
}
.cicon-ems-station:before {
    content: '\e22d';
}
.cicon-fire-stn:before {
    content: '\e22e';
}
.cicon-health-clinic:before {
    content: '\e22f';
}
.cicon-police-service:before {
    content: '\e230';
}
.cicon-traffic-camera:before {
    content: '\e231';
}
.cicon-historic-resource:before {
    content: '\e232';
}
.cicon-building-permit:before {
    content: '\e233';
}
.cicon-development-permit:before {
    content: '\e234';
}
.cicon-dp-public-notices:before {
    content: '\e235';
}
.cicon-loc:before {
    content: '\e237';
}
.cicon-subdivision:before {
    content: '\e2ec';
}
.cicon-lot-dimension:before {
    content: '\e238';
}
.cicon-offleash-dog:before {
    content: '\e239';
}
.cicon-arena:before {
    content: '\e23a';
}
.cicon-art-centre:before {
    content: '\e23b';
}
.cicon-city-park:before {
    content: '\e23c';
}
.cicon-golf-course:before {
    content: '\e23d';
}
.cicon-indoor-pool:before {
    content: '\e23e';
}
.cicon-outdoor-pool:before {
    content: '\e23f';
}
.cicon-skate-park:before {
    content: '\e240';
}
.cicon-leisure-centre:before {
    content: '\e241';
}
.cicon-circle-l:before {
    content: '\e242';
}
.cicon-circle-l-x-thin:before {
    content: '\e243';
}
.cicon-construction-detours-base:before {
    content: '\e244';
}
.cicon-construction-detours:before {
    content: '\e245';
}
.cicon-construction-base:before {
    content: '\e246';
}
.cicon-construction:before {
    content: '\e247';
}
.cicon-residential-parking-polygon:before {
    content: '\e249';
}
.cicon-incidents:before {
    content: '\e24a';
}
.cicon-plow-sander:before {
    content: '\e2d2';
}
.cicon-holiday-tree-drop-off:before {
    content: '\e24b';
}
.cicon-recycle-depot:before {
    content: '\e24c';
}
.cicon-electronics-recycling:before {
    content: '\e24d';
}
.cicon-landfill:before {
    content: '\e24e';
}
.cicon-haz-drop-off:before {
    content: '\e24f';
}
.cicon-leaf-drop-off:before {
    content: '\e250';
}
.cicon-brt-stop:before {
    content: '\e252';
}
.cicon-demolished-historic-resource:before {
    content: '\e253';
}
.cicon-lot-easement:before {
    content: '\e254';
}
.cicon-community-line:before {
    content: '\e255';
}
.cicon-community-line-s:before {
    content: '\e256';
}
.cicon-ward-line:before {
    content: '\e257';
}
.cicon-ward-line-s:before {
    content: '\e258';
}
.cicon-map-dot-line:before {
    content: '\e259';
}
.cicon-map-line-thin:before {
    content: '\e25a';
}
.cicon-map-line:before {
    content: '\e25b';
}
.cicon-bikeway-line:before {
    content: '\e25c';
}
.cicon-ctrain-lines-base:before {
    content: '\e25d';
}
.cicon-ctrain-lines:before {
    content: '\e25e';
}
.cicon-map-location:before {
    content: '\e25f';
}
.cicon-map-location-o:before {
    content: '\e260';
}
.cicon-map-location-inner-circle:before {
    content: '\e261';
}
.cicon-map-pointer:before {
    content: '\e262';
}
.cicon-map-pointer-o:before {
    content: '\e263';
}
.cicon-map-pointer-inner-circle:before {
    content: '\e264';
}
.cicon-aqua-openwater:before {
    content: '\e265';
}
.cicon-wading-pool:before {
    content: '\e266';
}
.cicon-aqua-flatwater:before {
    content: '\e267';
}
.cicon-sailing:before {
    content: '\e268';
}
.cicon-rowing:before {
    content: '\e269';
}
.cicon-volleyball:before {
    content: '\e26a';
}
.cicon-tennis:before {
    content: '\e26b';
}
.cicon-tennis-in:before {
    content: '\e26c';
}
.cicon-soccer:before {
    content: '\e26d';
}
.cicon-soccer-in:before {
    content: '\e26e';
}
.cicon-spray-park:before {
    content: '\e26f';
}
.cicon-shooting-in:before {
    content: '\e270';
}
.cicon-lacrosse:before {
    content: '\e271';
}
.cicon-cricket:before {
    content: '\e272';
}
.cicon-baseball:before {
    content: '\e273';
}
.cicon-biking:before {
    content: '\e274';
}
.cicon-bowling:before {
    content: '\e275';
}
.cicon-bowling-in:before {
    content: '\e276';
}
.cicon-boxing:before {
    content: '\e277';
}
.cicon-curling:before {
    content: '\e278';
}
.cicon-gymnasium:before {
    content: '\e279';
}
.cicon-horseshoe:before {
    content: '\e27a';
}
.cicon-walking:before {
    content: '\e389';
}
.cicon-canadian-flag:before {
    content: '\e38d';
}
.cicon-311:before {
    content: '\e395';
}
.cicon-bike-closure:before {
    content: '\e402';
}
.cicon-park-ride:before {
    content: '\e403';
}
.cicon-paw:before {
    content: '\e0ce';
}
.cicon-parks-recreation:before {
    content: '\e2ef';
}
.cicon-building-planning:before {
    content: '\e2f1';
}
.cicon-environment:before {
    content: '\e2f5';
}
.cicon-bylaws-public-safety:before {
    content: '\e2f7';
}
.cicon-taxes-property-assessment:before {
    content: '\e2f9';
}
.cicon-social-services:before {
    content: '\e2fb';
}
.cicon-cleaning-services:before {
    content: '\e2fd';
}
.cicon-beauty-body-services:before {
    content: '\e2ff';
}
.cicon-production-research:before {
    content: '\e301';
}
.cicon-massage-services:before {
    content: '\e303';
}
.cicon-food-trucks:before {
    content: '\e305';
}
.cicon-yoga-pilates-circle:before {
    content: '\e311';
}
.cicon-swimming-circle:before {
    content: '\e313';
}
.cicon-sports-circle:before {
    content: '\e315';
}
.cicon-skating-hockey-circle:before {
    content: '\e317';
}
.cicon-skateboarding-circle:before {
    content: '\e319';
}
.cicon-sailing-circle:before {
    content: '\e31b';
}
.cicon-playschool-circle:before {
    content: '\e31d';
}
.cicon-nature-circle:before {
    content: '\e31f';
}
.cicon-martial-arts-circle:before {
    content: '\e321';
}
.cicon-golf-circle:before {
    content: '\e323';
}
.cicon-fitness-strenght-circle:before {
    content: '\e325';
}
.cicon-fitness-pre-postnatal-circle:before {
    content: '\e327';
}
.cicon-day-camps-circle:before {
    content: '\e329';
}
.cicon-certifications-leadership-circle:before {
    content: '\e32b';
}
.cicon-arts-visual-circle:before {
    content: '\e32d';
}
.cicon-arts-dance-circle:before {
    content: '\e32f';
}
.cicon-call-center-person:before {
    content: '\e37d';
}
.cicon-digital-thermostat:before {
    content: '\e369';
}
.cicon-infill-construction:before {
    content: '\e36b';
}
.cicon-natural-gas-burner:before {
    content: '\e36d';
}
.cicon-natural-gas-flame:before {
    content: '\e36f';
}
.cicon-overhead-electrical-services-a:before {
    content: '\e371';
}
.cicon-overhead-electrical-services-b:before {
    content: '\e373';
}
.cicon-plumbing-faucet:before {
    content: '\e375';
}
.cicon-plumbing-gas:before {
    content: '\e377';
}
.cicon-water-meter:before {
    content: '\e379';
}
.cicon-historic-city-hall:before {
    content: '\e368';
}
.cicon-solar-panel:before {
    content: '\e37b';
}
.cicon-cannabis:before {
    content: '\e38c';
}
.cicon-large-text:before {
    content: '\e33b';
}
.cicon-large-text-sq:before {
    content: '\e33c';
}
.cicon-mobility:before {
    content: '\e33d';
}
.cicon-mobility-sq:before {
    content: '\e33e';
}
.cicon-open-captioning:before {
    content: '\e33f';
}
.cicon-open-captioning-sq:before {
    content: '\e340';
}
.cicon-seeing-eye-dog:before {
    content: '\e341';
}
.cicon-seeing-eye-dog-sq:before {
    content: '\e342';
}
.cicon-sign-language:before {
    content: '\e343';
}
.cicon-sign-language-sq:before {
    content: '\e344';
}
.cicon-teletypewriter:before {
    content: '\e345';
}
.cicon-teletypewriter-sq:before {
    content: '\e346';
}
.cicon-vision-loss:before {
    content: '\e347';
}
.cicon-vision-loss-sq:before {
    content: '\e348';
}
.cicon-white-cane:before {
    content: '\e349';
}
.cicon-white-cane-sq:before {
    content: '\e34a';
}
.cicon-assisted-listening:before {
    content: '\e34b';
}
.cicon-assisted-listening-sq:before {
    content: '\e34c';
}
.cicon-braille:before {
    content: '\e34e';
}
.cicon-braille-sq:before {
    content: '\e34d';
}
.cicon-closed-captioning:before {
    content: '\e34f';
}
.cicon-closed-captioning-sq:before {
    content: '\e350';
}
.cicon-cognitive-disability:before {
    content: '\e351';
}
.cicon-cognitive-disability-sq:before {
    content: '\e352';
}
.cicon-communication-access:before {
    content: '\e353';
}
.cicon-communication-access-sq:before {
    content: '\e354';
}
.cicon-FM-Infrared-system:before {
    content: '\e355';
}
.cicon-FM-Infrared-system-sq:before {
    content: '\e356';
}
.cicon-hard-of-hearing:before {
    content: '\e357';
}
.cicon-hard-of-hearing-sq:before {
    content: '\e358';
}
.cicon-al-a:before {
    content: '\e27c';
}
.cicon-al-b:before {
    content: '\e2d3';
}
.cicon-al-c:before {
    content: '\e2d4';
}
.cicon-al-d:before {
    content: '\e2d5';
}
.cicon-al-e:before {
    content: '\e2d6';
}
.cicon-al-f:before {
    content: '\e2d7';
}
.cicon-al-g:before {
    content: '\e2d8';
}
.cicon-al-h:before {
    content: '\e27b';
}
.cicon-al-i:before {
    content: '\e2d9';
}
.cicon-al-j:before {
    content: '\e2da';
}
.cicon-al-k:before {
    content: '\e2db';
}
.cicon-al-l:before {
    content: '\e2dc';
}
.cicon-al-m:before {
    content: '\e2dd';
}
.cicon-al-n:before {
    content: '\e2de';
}
.cicon-al-o:before {
    content: '\e2df';
}
.cicon-al-p:before {
    content: '\e27d';
}
.cicon-al-q:before {
    content: '\e2e0';
}
.cicon-al-r:before {
    content: '\e2e1';
}
.cicon-al-s:before {
    content: '\e2e2';
}
.cicon-al-t:before {
    content: '\e2e3';
}
.cicon-al-u:before {
    content: '\e2e4';
}
.cicon-al-v:before {
    content: '\e2e5';
}
.cicon-al-w:before {
    content: '\e2e6';
}
.cicon-al-x:before {
    content: '\e2e7';
}
.cicon-al-y:before {
    content: '\e2e8';
}
.cicon-al-z:before {
    content: '\e2e9';
}
.cicon-newspaper-o:before {
    content: '\e084';
}
.cicon-digital-thermostat-outline:before {
    content: '\e36a';
}
.cicon-infill-construction-outline:before {
    content: '\e36c';
}
.cicon-natural-gas-burner-outline:before {
    content: '\e36e';
}
.cicon-natural-gas-flame-outline:before {
    content: '\e370';
}
.cicon-overhead-electrical-services-a-outline:before {
    content: '\e372';
}
.cicon-overhead-electrical-services-b-outline:before {
    content: '\e374';
}
.cicon-plumbing-faucet-outline:before {
    content: '\e376';
}
.cicon-plumbing-gas-outline:before {
    content: '\e378';
}
.cicon-water-meter-outline:before {
    content: '\e37a';
}
.cicon-demolition-outline:before {
    content: '\e365';
}
.cicon-growth-managment-outline:before {
    content: '\e35b';
}
.cicon-development-outline:before {
    content: '\e35a';
}
.cicon-land-use-outline:before {
    content: '\e35d';
}
.cicon-post-occupancy-outline:before {
    content: '\e361';
}
.cicon-construct-building-outline:before {
    content: '\e359';
}
.cicon-building-subdivision-outline:before {
    content: '\e362';
}
.cicon-occupancy-outline:before {
    content: '\e35e';
}
.cicon-heritage-building-Inventory-outline:before {
    content: '\e364';
}
.cicon-plans-outline:before {
    content: '\e35f';
}
.cicon-policy-outline:before {
    content: '\e360';
}
.cicon-infrastructure-outline:before {
    content: '\e35c';
}
.cicon-playground-slide-outline:before {
    content: '\e367';
}
.cicon-playground-playhouse-outline:before {
    content: '\e366';
}
.cicon-historic-city-hall-outline:before {
    content: '\e363';
}
.cicon-solar-panel-outline:before {
    content: '\e37c';
}
.cicon-effective-tool:before {
    content: '\e386';
}
.cicon-building-group:before {
    content: '\e384';
}
.cicon-building-safe:before {
    content: '\e382';
}
.cicon-calgary-city:before {
    content: '\e383';
}
.cicon-collaborative:before {
    content: '\e385';
}
.cicon-angle-double-down-outline:before {
    content: '\e332';
}
.cicon-angle-double-left-outline:before {
    content: '\e333';
}
.cicon-angle-double-right-outline:before {
    content: '\e334';
}
.cicon-angle-double-up-outline:before {
    content: '\e335';
}
.cicon-angle-down-outline:before {
    content: '\e309';
}
.cicon-angle-left-outline:before {
    content: '\e30a';
}
.cicon-angle-right-outline:before {
    content: '\e307';
}
.cicon-angle-up-outline:before {
    content: '\e308';
}
.cicon-arrow-circle-down-outline:before {
    content: '\e30e';
}
.cicon-arrow-circle-left-outline:before {
    content: '\e30c';
}
.cicon-arrow-circle-up-outline:before {
    content: '\e30d';
}
.cicon-arrow-circle-right-outline:before {
    content: '\e30b';
}
.cicon-external-link-outline:before {
    content: '\e310';
}
.cicon-animal-service-outline:before {
    content: '\e2ee';
}
.cicon-parks-recreation-outline:before {
    content: '\e2f0';
}
.cicon-environment-outline:before {
    content: '\e2f6';
}
.cicon-bylaws-public-safety-outline:before {
    content: '\e2f8';
}
.cicon-taxes-property-assessment-outline:before {
    content: '\e2fa';
}
.cicon-social-services-outline:before {
    content: '\e2fc';
}
.cicon-transportation-outline:before {
    content: '\e2f4';
}
.cicon-cleaning-services-outline:before {
    content: '\e2fe';
}
.cicon-beauty-body-services-outline:before {
    content: '\e300';
}
.cicon-production-research-outline:before {
    content: '\e302';
}
.cicon-massage-services-outline:before {
    content: '\e304';
}
.cicon-food-trucks-outline:before {
    content: '\e306';
}
.cicon-yoga-pilates-circle-outline:before {
    content: '\e312';
}
.cicon-swimming-circle-outline:before {
    content: '\e314';
}
.cicon-sports-circle-outline:before {
    content: '\e316';
}
.cicon-skating-hockey-circle-outline:before {
    content: '\e318';
}
.cicon-building-planning-outline:before {
    content: '\e2f2';
}
.cicon-skateboarding-circle-outline:before {
    content: '\e31a';
}
.cicon-sailing-circle-outline:before {
    content: '\e31c';
}
.cicon-playschool-circle-outline:before {
    content: '\e31e';
}
.cicon-nature-circle-outline:before {
    content: '\e320';
}
.cicon-martial-arts-circle-outline:before {
    content: '\e322';
}
.cicon-golf-circle-outline:before {
    content: '\e324';
}
.cicon-fitness-strenght-circle-outline:before {
    content: '\e326';
}
.cicon-fitness-pre-postnatal-circle-outline:before {
    content: '\e328';
}
.cicon-day-camps-circle-outline:before {
    content: '\e32a';
}
.cicon-certifications-leadership-circle-outline:before {
    content: '\e32c';
}
.cicon-arts-visual-circle-outline:before {
    content: '\e32e';
}
.cicon-arts-dance-circle-outline:before {
    content: '\e330';
}
.cicon-call-center-person-outline:before {
    content: '\e37e';
}
.cicon-search-outline:before {
    content: '\e30f';
}
.cicon-user-outline:before {
    content: '\e336';
}
.cicon-people-in-group-outline:before {
    content: '\e388';
}
.cicon-plus-outline:before {
    content: '\e337';
}
.cicon-minus-outline:before {
    content: '\e338';
}
.cicon-plus-circle-outline:before {
    content: '\e339';
}
.cicon-minus-circle-outline:before {
    content: '\e33a';
}
.cicon-plus-square-o:before {
    content: '\e040';
}
.cicon-minus-square-o:before {
    content: '\e041';
}
.cicon-desktop-outline:before {
    content: '\e380';
}
.cicon-shield-outline:before {
    content: '\e381';
}
.cicon-shopping-cart-outline:before {
    content: '\e331';
}
.cicon-ctrain-stn:before {
    content: '\e248';
}
.cicon-bus-stop:before {
    content: '\e251';
}
.cicon-animal-service:before {
    content: '\e2ed';
}
.cicon-transportation:before {
    content: '\e2f3';
}
.cicon-computer-desktop:before {
    content: '\e37f';
}
