// Form elements

@mixin error-message() {
    @include font-content-m($font-weight-bold);
    display: block;
    color: var(--msv-error-color);
}

@mixin form-control() {
    @extend .form-control;
}

@mixin form-input-label() {
    font-size: 1rem;
    line-height: 1.4rem;
    display: block;
    margin-bottom: 4px;
    font-weight: 600;
    color: $cui-gray-primary;
}

@mixin form-input-checkbox() {
    height: 18px;
    width: 18px;
}

@mixin form-input-radio {
    @include vfi();
    vertical-align: middle;
    height: 24px;
    width: 24px;
    padding: 0;
    margin: 0;
}

@mixin form-input-radio-label {
    @include font-content-m(var(--msv-font-weight-light));
    vertical-align: middle;
    margin-left: 20px;
}

@mixin form-input-el($bg-color: var(--msv-bg-color), $color: var(--msv-font-primary-color), $border: transparent) {
    @include vfi();
    @include font-content-l();
    height: 48px;
    padding: 4px 8px;
    background-color: $bg-color;
    color: $color;
    border-color: $border;
}

@mixin form-input-el-error() {
    border: 1px dashed var(--msv-error-color);
    border-left: 3px solid var(--msv-error-color);
}

@mixin form-input-error-message() {
    @include error-message();
    @include add-icon($msv-IncidentTriangle);
    margin-bottom: 4px;

    &::before {
        margin-right: 8px;
    }
}

@mixin form-input-alert-message() {
    @include font-content-s-height-l();
    color: $msv-text-color;
    margin-bottom: 4px;
}

@mixin alert {
    background-color: $pink;
    border: 1px solid $red;
    max-width: 512px;
    padding: 8px;
}

@mixin alert-title {
    @include error-message();
    margin-bottom: 10px;
    margin-top: 2px;

    &::before {
        @include font-awesome-icon($alert-icon);
        margin-right: 8px;
    }
}
@mixin error-alert() {
    border: 1px solid $msv-error-color;
    background-color: $msv-white;
    position: relative;
    padding: 5px 15px 5px 35px;
    font-weight: 600;
    margin-bottom: 5px;
    border-left-width: 4px;
    max-width: fit-content;
    font-size: 1rem;
    &::before {
        @include msv-icon();
        content: $msv-IncidentTriangle;
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 100%;
        background-color: transparent;
        color: $msv-red;
        padding-top: 10px;
        font-size: 1rem;
        text-align: center;
    }
}
@mixin error-alert-box() {
    border: 2px solid $msv-error-color;
    background-color: $msv-white;
    position: relative;
    padding: 10px 15px 10px 45px;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 20px;
    border-left-width: 4px;
    width: 100%;
    display: block;
    font-size: 1rem;
    &::before {
        @include msv-icon();
        content: $msv-IncidentTriangle;
        position: absolute;
        left: 0;
        top: 0;
        width: 30px;
        height: 100%;
        background-color: $msv-red;
        color: $white;
        padding-top: 10px;
        font-size: 1rem;
        text-align: center;
    }
}
@mixin cui-focus() {
    &:focus,
    &:focus-visible {
        box-shadow: $focus-box-shadow;
        outline: none;
    }
}
@mixin error-box() {
    border: 2px solid $msv-primary;
    background-color: $msv-white;
    position: relative;
    padding: 5px 5px 5px 50px;
    font-weight: 600;
    margin-bottom: 5px;
    color: $cui-gray-primary;
    .msi-exclamation-triangle {
        background-color: $msv-primary;
        color: $msv-white;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 35px;
        text-align: center;
        padding: 5px;
    }
    span {
        color: $cui-gray-primary;
    }
    strong {
        display: block;
        margin-bottom: 5px;
    }
}
@mixin info-box() {
    border: 2px solid $cui-blue;
    background-color: $msv-white;
    position: relative;
    padding: 5px 5px 5px 45px;
    font-weight: 600;
    margin-bottom: 5px;
    &::before {
        @include msv-icon();
        content: $msv-InfoCircle;
        position: absolute;
        left: 0;
        top: 0;
        width: 35px;
        height: 100%;
        background-color: $cui-blue;
        color: $msv-white;
        padding-top: 10px;
        font-size: 0.8rem;
        text-align: center;
    }
}
.info-box-alert {
    border: 2px solid $cui-info-alert;
    background-color: $msv-white;
    position: relative;
    padding: 5px 5px 5px 45px;
    margin-bottom: 5px;
    &::before {
        @include msv-icon();
        content: $msv-IncidentTriangle;
        position: absolute;
        left: 0;
        top: 0;
        width: 35px;
        height: 100%;
        background-color: $cui-info-alert;
        color: $cui-gray-primary;
        padding-top: 10px;
        font-size: 0.8rem;
        text-align: center;
    }
}
.error-box-alert {
    border: 2px solid $msv-primary;
    background-color: $msv-white;
    position: relative;
    padding: 5px 5px 5px 45px;
    font-weight: 600;
    margin-bottom: 5px;
    &::before {
        @include msv-icon();
        content: $msv-IncidentTriangle;
        position: absolute;
        left: 0;
        top: 0;
        width: 35px;
        height: 100%;
        background-color: $msv-primary;
        color: $msv-white;
        padding-top: 10px;
        font-size: 0.8rem;
        text-align: center;
    }
}
.error-alert {
    @include error-alert();
    margin-top: -15px;
    &:empty {
        display: none;
    }
}
.coc-dropdown {
    &__select {
        @include form-control();
        border: 1px solid var(--msv-buybox-select-box-border);
    }
}
.form-group {
    position: relative;
    div[class^='col'] {
        padding-left: 10px;
        padding-right: 10px;
    }
    .row {
        margin-left: -10px;
        margin-right: -10px;
    }
    .cui.alert-small {
        margin-top: 5px;
        width: fit-content;
        display: block;
        font-size: 1rem;
        &:empty {
            display: none;
        }
        &::before {
            margin-right: 8px;
        }
    }
    label {
        @include form-input-label();
        .staric {
            color: $msv-red;
            padding-left: 5px;
        }
        small {
            display: block;
        }
    }
    .form-field {
        @include form-control();
        width: 100%;
        display: inline-block;
        -moz-appearance: textfield;
        padding: 5px;
        vertical-align: bottom;
        margin-bottom: 15px;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
        &.error {
            border-color: $msv-red;
        }
        + .cui.alert-small {
            margin-top: -15px;
        }
        &::placeholder {
            font-style: italic;
            opacity: 0.5;
        }
    }
    textarea {
        &.form-field {
            height: 60px;
        }
    }
}
form.form-wrap {
    margin-bottom: 15px;
    padding-bottom: 5px;
    padding-top: 15px;
    display: inline-block;
    width: 100%;
}
label.form-wrap {
    input[type='checkbox'],
    input[type='radio'] {
        margin-right: 5px;
        @include cui-focus();
    }
}
.coc-dropdown__select {
    @include form-control();
}
.form-check-input {
    @include cui-focus();
}
.error {
    border-color: $msv-red !important;
    &:focus {
        box-shadow: 0 0 0.1875rem 0.1875rem rgba(166, 25, 46, 0.3) !important;
    }
}
@mixin cui-spinner() {
    margin: auto;
    margin-bottom: 0.5625rem;
    height: 2.25rem;
    width: 2.25rem;
    border: 0.375rem solid #dbdcdd;
    border-radius: 50%;
    border-top-color: $msv-red;
    animation: rotate 1s ease infinite;
}
@mixin cui-btn-spinner() {
    height: 1.325rem;
    width: 1.325rem;
    border: 0.175rem solid #fff;
    border-radius: 50%;
    border-top-color: $msv-red;
    animation: rotate 1s ease infinite;
}
.form-group {
    span.cui-inline-spinner {
        position: absolute;
        right: 0;
        width: 20px;
        height: 20px;
        margin: 10px 10px;
        color: transparent;
        overflow: hidden;
        &::before {
            content: '';
            margin-bottom: 0.5625rem;
            height: 1.25rem;
            width: 1.25rem;
            border: 0.175rem solid #dbdcdd;
            border-radius: 50%;
            border-top-color: $msv-red;
            animation: rotate 1s ease infinite;
            display: inline-block;
        }
    }
}
.alert-banner {
    .list-style-dot {
        padding-left: 20px;
        margin-top: 15px;
        li {
            margin-bottom: 5px;
        }
    }
}
