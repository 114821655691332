// -------------------------------------------------------
// Start of _mixins
// -------------------------------------------------------

// mixin: clearfix
@mixin clearfix() {
    &::after {
        clear: both;
        content: '';
        display: table;
    }
}

// mixin: prefix
//  desc: Adds vendor prefixes.
@mixin prefix($property, $value, $vendors: webkit moz ms o) {
    @if $vendors {
        @each $vendor in $vendors {
            #{"-" + $vendor + "-" + $property}: #{$value};
        }
    }
    #{$property}: #{$value};
}

@mixin make-row($gutter: $grid-gutter-width) {
    display: flex;
    display: -webkit-box; // old
    display: -moz-box; // old
    display: -ms-flexbox; // ie
    display: -webkit-flex; // new
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    margin-right: -$gutter / 2;
    margin-left: -$gutter / 2;
}

// reset box-decoration-break
@mixin box-decoration-break($value) {
    -webkit-box-decoration-break: $value;
    -ms-box-decoration-break: $value;
    -o-box-decoration-break: $value;
    box-decoration-break: $value;
}

// reset flexbox
@mixin flexbox() {
    display: -webkit-box; // old
    display: -moz-box; // old
    display: -ms-flexbox; // ie
    display: -webkit-flex; // new
    display: flex; // new
}

@mixin flexboxImportant() {
    display: -webkit-box !important; // old
    display: -moz-box !important; // old
    display: -ms-flexbox !important; // ie
    display: -webkit-flex !important; // new
    display: flex !important; // new
}

@mixin inline-flex {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    //-ms-flex: $values;
    -webkit-flex: $values;
    flex: $values;
}

@mixin flex-direction($values) {
    -webkit-flex-direction: $values; // old
    -moz-flex-direction: $values; // old
    -ms-flex-direction: $values; // ie
    flex-direction: $values; // new
}

@mixin flex-grow($int: 1) {
    -webkit-box-flex: $int;
    -moz-box-flex: $int;
    -webkit-flex-grow: $int;
    //-ms-flex: $int;
    flex-grow: $int;
}

@mixin flex-shrink($int: 0) {
    -webkit-flex-shrink: $int;
    -moz-flex-shrink: $int;
    //-ms-flex: $int;
    flex-shrink: $int;
}

@mixin flex-wrap($value: nowrap) {
    // No Webkit/FF Box fallback.
    -webkit-flex-wrap: $value;
    @if $value == nowrap {
        -ms-flex-wrap: none;
    } @else {
        -ms-flex-wrap: $value;
    }
    flex-wrap: $value;
}

@mixin flex-flow(
    $values: (
        row nowrap
    )
) {
    // No Webkit/FF Box fallback.
    -webkit-flex-flow: $values;
    -ms-flex-flow: $values;
    flex-flow: $values;
}

@mixin flex-basis($value: auto) {
    -webkit-flex-basis: $value;
    flex-basis: $value;
}

@mixin justify-content($value: flex-start) {
    @if $value == flex-start {
        -webkit-box-pack: start;
        -moz-box-pack: start;
        -ms-flex-pack: start;
    } @else if $value == flex-end {
        -webkit-box-pack: end;
        -moz-box-pack: end;
        -ms-flex-pack: end;
    } @else if $value == space-between {
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
    } @else if $value == space-around {
        -ms-flex-pack: distribute;
    } @else {
        -webkit-box-pack: $value;
        -moz-box-pack: $value;
        -ms-flex-pack: $value;
    }
    -webkit-justify-content: $value;
    justify-content: $value;
}

@mixin align-items($value: stretch) {
    @if $value == flex-start {
        -webkit-box-align: start;
        -moz-box-align: start;
        -ms-flex-align: start;
    } @else if $value == flex-end {
        -webkit-box-align: end;
        -moz-box-align: end;
        -ms-flex-align: end;
    } @else {
        -webkit-box-align: $value;
        -moz-box-align: $value;
        -ms-flex-align: $value;
    }
    -webkit-align-items: $value;
    align-items: $value;
}

@mixin align-self($value: auto) {
    // No Webkit Box Fallback.
    -webkit-align-self: $value;
    @if $value == flex-start {
        -ms-flex-item-align: start;
    } @else if $value == flex-end {
        -ms-flex-item-align: end;
    } @else {
        -ms-flex-item-align: $value;
    }
    align-self: $value;
}

@mixin align-content($value: stretch) {
    // No Webkit Box Fallback.
    -webkit-align-content: $value;
    @if $value == flex-start {
        -ms-flex-line-pack: start;
    } @else if $value == flex-end {
        -ms-flex-line-pack: end;
    } @else {
        -ms-flex-line-pack: $value;
    }
    align-content: $value;
}

@mixin transition($transition...) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
}

@mixin transition-property($property...) {
    -moz-transition-property: $property;
    -o-transition-property: $property;
    -webkit-transition-property: $property;
    transition-property: $property;
}

@mixin transition-duration($duration...) {
    -moz-transition-property: $duration;
    -o-transition-property: $duration;
    -webkit-transition-property: $duration;
    transition-property: $duration;
}

@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function: $timing;
    -o-transition-timing-function: $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function: $timing;
}

@mixin transition-delay($delay...) {
    -moz-transition-delay: $delay;
    -o-transition-delay: $delay;
    -webkit-transition-delay: $delay;
    transition-delay: $delay;
}

// mixin: gradient-x
//  desc: Generates a horizontal gradient from left to right (From Bootstrap 4.0.0)
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gradient-x($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
    background-image: linear-gradient(to right, $start-color $start-percent, $end-color $end-percent);
    background-repeat: repeat-x;
}

// mixin: gradient-y
//  desc: Generates a vertical gradient from top to bottom (From Bootstrap 4.0.0)
//
// Creates two color stops, start and end, by specifying a color and position for each color stop.
@mixin gradient-y($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
    background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent);
    background-repeat: repeat-x;
}

// mixin: flex-align-items-center
// usage: Use on a flex container to vertically align all direct children to the center
// @mixin flex-align-items-center() {
// 	-ms-flex-align: center;
// 	align-items: center;
// }

// mixin: flex-align-self-center
// usage: Use on a flex item to vertically align itself to the center of its flex parent
// @mixin flex-align-self-center() {
// 	-ms-flex-item-align: center;
// 	align-self: center;
// }

// mixin: flex-align-self-stretch
// usage: Use on a flex item to stretch itself to match the full height of its flex parent
// @mixin flex-align-self-stretch() {
// 	-ms-flex-item-align: stretch;
// 	align-self: stretch;
// }

// mixin: generateBlockMaxWidth()
// desc: Sets the max-width for any element.
@mixin generateBlockMaxWidth() {
    max-width: $block-max-width;
}

// mixin: setIconFontStyle()
//  desc: Allows the element to utilize the icon font style
// usage: Can be used for setting the content for :before and :after psuedo elements to contain an icon
@mixin setIconFontStyle() {
    font-family: $font-icon !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    // Better Font Rendering ===========
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// mixin: setSROnly()
//  desc: Makes the element visible to screen reads only
//   see: http://a11yproject.com/posts/how-to-hide-content
@mixin setSROnly() {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

// mixin: setOutlineGlow()
//  desc: Provides the styles to set the outline glow on interactive elements
// usage: Menu items, buttons, inputs, etc
@mixin setOutlineGlow($removeOutline: false) {
    box-shadow: $focus-box-shadow;

    @if $removeOutline {
        outline: 0; // Remove outlines (from browser or other stylesheets)
    }
}

// mixin: setInteractiveBoxShadow()
//  desc: Sets the hover and active box shadows, along with the
//        focus:hover, and focus:active styles on interactive elements
//        The base-shadow is the shadow applied to the base element
//        (not on hover, active, focus).
// usage: Use on <a> and <button> tags
@mixin transform-scale() {
    transform: scale(1.01);
    -moz-transform: scale(1.01);
    -webkit-transform: scale(1.01);
    -o-transform: scale(1.01);
    -ms-transform: scale(1.01);
}
@mixin transform-scale-important() {
    transform: scale(1.01) !important;
    -moz-transform: scale(1.01) !important;
    -webkit-transform: scale(1.01) !important;
    -o-transform: scale(1.01) !important;
    -ms-transform: scale(1.01) !important;
}

// When the pattern use the thin border (1px or no border), use the setInteractiveBoxShadow
// such as card
@mixin setInteractiveBoxShadow(
    $use-base-shadow: false,
    $base-shadow: $default-base-shadow,
    $hover-shadow: $default-hover-shadow,
    $active-shadow: $default-active-shadow,
    $focus-shadow: $focus-box-shadow
) {
    @if $use-base-shadow == true {
        box-shadow: $base-shadow;
    }

    &:hover {
        box-shadow: $hover-shadow;
        transition: transform 0.1s ease-in-out;
        transition-property: background, box-shadow;
    }

    &:active {
        box-shadow: $active-shadow;
    }

    @if $use-base-shadow == true {
        &:focus {
            box-shadow: $focus-shadow, $base-shadow;
        }
    }

    &:focus:hover {
        box-shadow: $focus-shadow, $hover-shadow;
    }

    &:focus:active {
        box-shadow: $focus-shadow, $active-shadow;
    }
}

// new setting
// added for slider only
// will use this set for global update later
// @mixin setInteractiveBoxShadowUpdate( $use-base-shadow: false, $base-shadow: $default-base-shadow-update, $hover-shadow: $default-hover-shadow-update, $active-shadow: $default-active-shadow-update, $focus-shadow: $focus-box-shadow ) {
// 	@if $use-base-shadow == true {
// 		box-shadow: $base-shadow;
// 	}

// 	&:hover {
// 		box-shadow: $hover-shadow;
// 		transition: transform 0.1s ease-in-out;
// 		transition-property: background, box-shadow;
// 	}

// 	&:active {
// 		box-shadow: $active-shadow;
// 	}

// 	@if $use-base-shadow == true {
// 		&:focus {
// 			box-shadow: $focus-shadow, $base-shadow;
// 		}
// 	}

// 	&:focus:hover {
// 		box-shadow: $focus-shadow, $hover-shadow;
// 	}

// 	&:focus:active {
// 		box-shadow: $focus-shadow, $active-shadow;
// 	}
// }
// When the pattern use the thick border (border is min 2px), use the setInteractiveBoxShadow
// such as buttons
// @mixin setBtnBoxShadow( $use-base-shadow: false, $base-shadow: $btn-base-shadow, $hover-shadow: $btn-hover-shadow, $active-shadow: $btn-active-shadow, $focus-shadow: $focus-box-shadow ) {
// 	@if $use-base-shadow == true {
// 		box-shadow: $base-shadow;
// 	}

// 	&:hover {
// 		box-shadow: $hover-shadow;
// 		transition: transform 0.1s ease-in-out;
// 		transition-property: background, box-shadow;
// 	}

// 	&:active {
// 		box-shadow: $active-shadow;
// 	}

// 	@if $use-base-shadow == true {
// 		&:focus {
// 			box-shadow: $focus-shadow, $base-shadow;
// 		}
// 	}

// 	&:focus:hover {
// 		box-shadow: $focus-shadow, $hover-shadow;
// 	}

// 	&:focus:active {
// 		box-shadow: $focus-shadow, $active-shadow;
// 	}
// }

// mixin: listUnstyled()
//  desc: Makes the list element unstyled: no margins, paddings, or list styles
//        Use on <ul> or <ol>s
@mixin listUnstyled() {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

// mixin: reverseLinkUnderline()
//  desc: Reverses the display of underlines on links
//        Default styling for links has them underlined on default, then no underline on hover
//        This mixin reverses that -- links will NOT be underlined by default, and WILL have the underline on hover
// usage: Apply this mixin to <a> tags
@mixin reverseLinkUnderline() {
    text-decoration: none;

    &:hover,
    &:active {
        text-decoration: underline;
    }
}

// mixin: styleBreadcrumbIcon()
//  desc: Centralized styles for breadcrumb icons
// usage: Apply to breadcrumbs and breadcrumb-like links
//    ex: Breadcrumb, application page-context-link
@mixin styleBreadcrumbIcon() {
    @include setIconFontStyle();
    color: $bcrumb-icon-color;
    display: inline-block;
    font-size: $bcrumb-icon-font-size;
}

// -------------------------------------------------------
// Colour mixins
//
// -------------------------------------------------------

// mixin: setColours()
//  desc: Sets the colours for an element where the $main-color is
//        the background / main color and the $text-color is the
//        foreground / text color
// usage: Used in colours.scss
@mixin setColours($main-color, $text-color) {
    background-color: $main-color;
    color: $text-color;

    &[class*='cicon-'] {
        background-color: transparent;
        color: $main-color;
    }

    a:not([class*='btn-']) {
        color: $text-color;
    }
}

// -------------------------------------------------------
// Grid column mixins
//
// -------------------------------------------------------

@mixin containerFluid() {
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacer-sm;
    padding-left: $spacer-sm;
    width: 100%;

    @media (min-width: $breakpoint-xl) {
        padding-right: $spacer-lg;
        padding-left: $spacer-lg;
    }
}

// @mixin: renderRow()
//  @desc: Renders out all of the basic styles for a Bootstrap row
// @usage: For custom Bootstrap rows.
// @param: $use-gutter: flag to indicate whether to add the gutter spacing.
// @param: $gutter: The gutter spacing to use.
@mixin renderRow($use-gutter: false, $gutter: $gutter) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    @if $use-gutter == true {
        margin-left: -($gutter / 2);
        margin-right: -($gutter / 2);
    }
}

// @mixin: renderColBasics()
// @desc: Renders out all of the basic styles for a Boostrap column
// @usage: For custom Boostrap columns
@mixin renderColBasics($gutter: $gutter) {
    min-height: 1px;
    padding-right: $gutter/2;
    padding-left: $gutter/2;
    position: relative;
    width: 100%;
}

// @mixin: calcColWidth()
//  @desc: Calculates the column width percentage for a Bootstrap column
// @usage: For custom Boostrap column sizing
@mixin calcColWidth($col-size, $col-total: $col-total) {
    $width: $col-size / $col-total * 100%;
    -webkit-box-flex: 0 0 $width;
    -webkit-flex: 0 0 $width;
    -moz-flex: 0 0 $width;
    -ms-flex: 0 0 $width;
    flex: 0 0 $width;
    max-width: $width;
}

// @mixin: calcColOffset()
//  @desc: Calculates the offset percentage for a Bootstrap column
// @usage: For custom Boostrap column offsetting
@mixin calcColOffset($col-size, $col-total: $col-total) {
    margin-left: ($col-total - $col-size) / 2 / $col-total * 100%;
}

// -------------------------------------------------------
// Tooltip arrow mixins
//
// -------------------------------------------------------

@mixin tooltipArrow($direction) {
    // Map all of the directions and their opposites
    $directions: (
        top: bottom,
        right: left,
        bottom: top,
        left: right
    );

    // Get the opposite of the direction provided
    $opposite: map-get($directions, $direction);

    #{ $opposite }: $tooltip-border-width;

    &::before,
    &::after {
        border-#{ $opposite }: 0;
    }

    &::before {
        border-#{ $direction }-color: $tooltip-border-color;
    }

    &::after {
        border-#{ $direction }-color: $tooltip-background;
        #{ $direction }: -($tooltip-border-width);
    }
}

// -------------------------------------------------------
// Table caption mixins
//
// -------------------------------------------------------

@mixin tableCaption() {
    @include generateBlockMaxWidth();
    caption-side: top; // Overrides BS4
    color: $coc-secondary-4D;
    margin-bottom: $spacer-xxs;
    font-weight: $font-weight-semi-bold;
    text-align: left;
    font-size: $h3-font-size;

    @media (min-width: $breakpoint-sm) {
        font-size: $h3-font-size-xs;
    }

    @media (min-width: $breakpoint-md) {
        font-size: $h3-font-size-sm;
    }

    @media (min-width: $breakpoint-lg) {
        font-size: $h3-font-size-md;
    }
}

@mixin tableResponsive($tableMinWidth: '') {
    display: block;
    overflow-x: auto;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    @if $tableMinWidth != '' {
        > table {
            min-width: $tableMinWidth;
        }
    }
}

@mixin tableStacked() {
    // Hide the thead and its children
    thead,
    thead tr,
    thead th {
        border: 0;
        margin: 0;
        overflow: hidden;
        padding: 0;
        visibility: hidden;
        width: 0;
        height: 0;
    }

    tr {
        border-bottom: $border-thin solid $coc-secondary-8D;
        display: block;
        padding: $tiny-spacer-sm;
        width: 100%;
        @include clearfix();
    }

    td,
    th {
        border: 0;
        display: block;
        float: left;
        width: 100%;
    }

    .table-label {
        display: inline;
    }

    &.bordered tr {
        border: $border-thin solid $coc-secondary-8D;
        margin-bottom: -$border-thin;
    }

    &.bordered td,
    &.bordered th,
    &.striped td,
    &.striped th {
        border: none !important;
    }
}

// -------------------------------------------------------
// Accordions
//
// -------------------------------------------------------

// MC.2018.12.14: Used for both accordions (for all screen sizes) and tabs in mobile view
@mixin accordion-title-bar() {
    margin: 0;

    button {
        background-color: $acctab-link-bg;
        border: $acctab-border-width solid $acctab-border-color;
        cursor: pointer;
        display: block;
        font-size: $base-font-size;
        padding: $spacer-xs $spacer-sm;
        text-align: left;
        transition: background $transition-duration $transition-timing-function;
        width: 100%;

        &:hover {
            background-color: $acctab-link-hover-bg;
        }

        &:focus {
            background-color: $acctab-link-focus-bg;
            position: relative;
            z-index: 1;
        }

        &::before {
            content: '\e2ea'; // .cicon-plus-thin
            font-family: $font-icon;
            font-size: $cicon-size-xs; // Balance the size
            margin-right: $tiny-spacer-sm;
            padding: $tiny-spacer-xs;
        }

        &[aria-expanded='true'] {
            background-color: transparent;
            border-left: $acctab-border-width solid $coc-accent-color;
            border-bottom: 0;
            color: $coc-link;

            &::before {
                content: '\e2eb'; // .cicon-minus-thin
            }
        }
    }

    ~ .title-bar {
        margin-top: -$acctab-border-width;
    }

    @media print {
        // For print, the width can use the thin style.
        button {
            background: transparent;
            border-width: $print-border-width;
        }

        ~ .title-bar {
            margin-top: -$print-border-width;
        }
    }
} // End of accordion-title-bar()

@mixin accordion-content() {
    background-color: $white;
    border: $acctab-border-width solid $acctab-border-color;
    border-top: 0;
    padding: $spacer-sm;

    &[aria-hidden] {
        display: none;
    }

    &[aria-hidden='false'] {
        display: block;
    }

    @media print {
        border-width: $print-border-width;
    }
}

// -------------------------------------------------------
// Video container
//
// -------------------------------------------------------

// MC.2018.12.10: Used for the video thumbnail and iframe
// interactions for the the cards and banner grids.
@mixin video-container() {
    display: flex; // Required to resolve a spacing issue
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    height: 0;
    // The ratio is applied to this parent element to ensure the thumbnail and iframe are
    // sized the same.
    padding-bottom: $ratio-percent-16x9;
    position: relative;

    // Button containing the .video-thumbnail and play icon
    .btn-video {
        background: transparent;
        border: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
    }

    // Has the video thumbnail image set inline as the background-image
    .video-thumbnail {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;

        // The element displaying the play icon
        &::before {
            // MC.2018.12.11: The following is the url-encoded SVG code of the specified image.
            // This allows us to embed the image directly into the CSS rather than require
            // the PL-implementer to host and refer to another external image.
            // @see: SVG optimizer: https://jakearchibald.github.io/svgomg/
            // @see: https://stackoverflow.com/a/37683760
            // @see: https://meyerweb.com/eric/tools/dencoder/
            // caret-right-opacity.svg is encoded below
            background-image: url('data:image/svg+xml,%3Csvg%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20448%22%3E%3Cstyle%3E.st1%7Bfill%3A%23fff%7D%3C%2Fstyle%3E%3Cpath%20d%3D%22M360%20224c0-24.7-6.1-47.4-18.2-68.3s-28.7-37.3-49.5-49.5C271.4%2094.1%20248.7%2088%20224%2088c-24.7%200-47.4%206.1-68.3%2018.2s-37.3%2028.7-49.5%2049.5S88%20199.3%2088%20224c0%2024.7%206.1%2047.4%2018.2%2068.3s28.7%2037.3%2049.5%2049.5C176.5%20354%20199.3%20360%20224%20360c24.7%200%2047.4-6.1%2068.3-18.2%2020.8-12.2%2037.3-28.7%2049.5-49.5S360%20248.7%20360%20224z%22%20opacity%3D%22.8%22%2F%3E%3Cpath%20class%3D%22st1%22%20d%3D%22M206.2%20267.3v-86.5c0-1.7.6-3.1%201.9-4.3%201.2-1.2%202.7-1.9%204.3-1.9s3.1.6%204.3%201.9l43.3%2043.3c1.2%201.2%201.9%202.7%201.9%204.3s-.6%203.1-1.9%204.3l-43.3%2043.3c-1.2%201.2-2.7%201.9-4.3%201.9s-3.1-.6-4.3-1.9c-1.3-1.3-1.9-2.8-1.9-4.4z%22%2F%3E%3Cpath%20class%3D%22st1%22%20d%3D%22M360%20224c0-24.7-6.1-47.4-18.2-68.3s-28.7-37.3-49.5-49.5C271.4%2094.1%20248.7%2088%20224%2088c-24.7%200-47.4%206.1-68.3%2018.2s-37.3%2028.7-49.5%2049.5S88%20199.3%2088%20224c0%2024.7%206.1%2047.4%2018.2%2068.3s28.7%2037.3%2049.5%2049.5C176.5%20354%20199.3%20360%20224%20360c24.7%200%2047.4-6.1%2068.3-18.2%2020.8-12.2%2037.3-28.7%2049.5-49.5S360%20248.7%20360%20224zm30%200c0%2030.1-7.4%2057.9-22.3%2083.3-14.8%2025.4-35%2045.6-60.4%2060.4-25.4%2014.9-53.2%2022.3-83.3%2022.3s-57.9-7.4-83.3-22.3c-25.4-14.8-45.6-35-60.4-60.4S58%20254.1%2058%20224c0-30.1%207.4-57.9%2022.3-83.3s35-45.6%2060.4-60.4S193.9%2058%20224%2058c30.1%200%2057.9%207.4%2083.3%2022.3%2025.4%2014.8%2045.6%2035%2060.4%2060.4%2014.9%2025.4%2022.3%2053.2%2022.3%2083.3z%22%2F%3E%3C%2Fsvg%3E%0A');
            background-position: center;
            background-repeat: no-repeat;
            background-size: $basic-space * 72rem $basic-space * 72rem;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
        }
    }

    iframe {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
// -------------------------------------------------------
// background image
//
// -------------------------------------------------------
@mixin bg-img-fluid($heightValue) {
    width: 100%;
    height: $heightValue;
}

// -------------------------------------------------------
// Text on image
//
// -------------------------------------------------------

@mixin comp-body-position() {
    position: absolute;

    &.top-left {
        top: 0;
        left: 0;
    }

    &.top-right {
        top: 0;
        right: 0;
    }

    &.bottom-left {
        bottom: 0;
        left: 0;
    }

    &.bottom-right {
        bottom: 0;
        right: 0;
    }

    &.center-left {
        left: 0;
        align-self: center;
    }

    &.center-right {
        right: 0;
        align-self: center;
    }
}
