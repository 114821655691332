/* -------------------------------------------------------
   Start of _text-on-image
   ------------------------------------------------------- */
.cui.text-on-image {
	margin-bottom: $spacer-xl;
	position: relative;

	.comp-body {
		padding-top: $spacer-sm;
	}

	.comp-media {
		width: 100%;
	}

	.background-image {
		&.ratio-16x9,
		&.ratio-4x3,
		&.ratio-3x4,
		&.ratio-1x1 {
			padding-bottom: 75%;
		}
	}

	.context-title {
		color: $context-title-color;
		display: block;
		letter-spacing: $text-image-title-letter-space;//1px
		margin-bottom: $spacer-xxs;
		text-transform: uppercase;
	}

	.title,
	.sub-title p {
		margin-bottom: $spacer-sm;
	}

	p:last-child {
		margin-bottom: 0;// Remove bottom whitespace to prevent double whitespacing with .comp-body
	}

	@media( min-width: $breakpoint-md ) {
		.comp-body {
			background: $white;
			max-height: 100%;// Limit the height so it does not exceed the bounds of the background image
			overflow: hidden;
			padding: $spacer-md;
			width: 60%;
			@include comp-body-position;
		}
	}

	// JZ: April,2019 Text on image - colour text block
	// Only has the background applied on the text
	&.simple {
		@include flexbox;
		// The image are 100% width based on the grid container
		.comp-media .bg-img-fluid {
			@include bg-img-fluid($basic-space*325rem); //image height on mobile: 325px
		}

		.comp-body {
			background-color: transparent;
			width: 100%;
			@include comp-body-position;
			padding: 2px $text-image-title-padding-sm 3px $text-image-title-padding-sm;

			// text length
			&.w-long {
				@include calcColWidth (12);
			}

			&.w-medium {
				@include calcColWidth (8);
			}

			&.w-short {
				@include calcColWidth (4);
			}

			&.top-right,
			&.bottom-right,
			&.center-right {
				text-align: right;
			}
		}

		.sub-title {
			display: inline;
			margin-bottom: 0;
			background: transparent;
			line-height: $text-image-title-line-height;
			padding-top: $text-image-title-padding-sm;
			padding-bottom: $text-image-title-padding-sm;

			// 2 different options for background color
			&.bg-red {
				box-shadow: $text-image-title-padding-sm 0 0 $coc-link, -$text-image-title-padding-sm 0 0 $coc-link;
				background-color: $coc-link;
				color: $white;
				@include box-decoration-break(clone);

				a:not([class*="btn-"]) {
					color: $white;
				}
			}

			&.bg-white {
				box-shadow: $text-image-title-padding-sm 0 0 $white, -$text-image-title-padding-sm 0 0 $white;
				background-color: $white;
				color: $coc-link;
				@include box-decoration-break(clone);
			}

			&.bg-darkest {
				box-shadow: $text-image-title-padding-sm 0 0 $bg-darkest, -$text-image-title-padding-sm 0 0 $bg-darkest;
				background-color: $bg-darkest;
				color: $white;
				@include box-decoration-break(clone);
			}

			&.bg-transparent {
				background-color: transparent;
				color: $black;
				@include box-decoration-break(clone);
			}
		}

		.title {
			display: inline;
			margin-bottom: 0;
			background: transparent;
			line-height: $text-image-title-line-height;
			font-size: $text-image-title-font-size-sm;
			font-weight: $font-weight-semi-bold;
			padding-top: $text-image-title-padding-sm;
			padding-bottom: $text-image-title-padding-sm;

			// 4 different options for background color
			&.bg-red {
				box-shadow: $text-image-title-padding-sm 0 0 $coc-link, -$text-image-title-padding-sm 0 0 $coc-link;
				background-color: $coc-link;
				color: $white;
				@include box-decoration-break(clone);

				a:not([class*="btn-"]) {
					color: $white;
				}
			}

			&.bg-white {
				box-shadow: $text-image-title-padding-sm 0 0 $white, -$text-image-title-padding-sm 0 0 $white;
				background-color: $white;
				color: $coc-link;
				@include box-decoration-break(clone);
			}

			&.bg-darkest {
				box-shadow: $text-image-title-padding-sm 0 0 $bg-darkest, -$text-image-title-padding-sm 0 0 $bg-darkest;
				background-color: $bg-darkest;
				color: $white;
				@include box-decoration-break(clone);
			}

			&.bg-transparent {
				background-color: transparent;
				color: $black;
				@include box-decoration-break(clone);
			}
		}
		//Only change the font weight when user choose to use h1 tag
		h1.title { font-weight: $font-weight-bold; }

		@media( min-width: $breakpoint-sm ) {
			.comp-media .bg-img-fluid {
				&.lg {
					height: $text-image-bg-height-lg; //650px
				}

				&.md {
					height: $text-image-bg-height-md; //450px
				}

				&.sm {
					height: $text-image-bg-height-sm; //350px
				}

				&.xs {
					height: $text-image-bg-height-xs; //250px
				}
			}

			.comp-body {
				&.bottom-left,
				&.top-left,
				&.center-left {
					padding-left: $text-image-title-padding;
				}

				&.top-right,
				&.bottom-right,
				&.center-right {
					padding-right: $text-image-title-padding;
				}
			}

			.title {
				font-size: $text-image-title-font-size-md;
				max-width: none;
				padding-top: $text-image-title-padding;
				padding-bottom: $text-image-title-padding;

				// 4 different options for background color
				&.bg-red {
					box-shadow: $text-image-title-padding 0 0 $coc-link, -$text-image-title-padding 0 0 $coc-link;
				}

				&.bg-white {
					box-shadow: $text-image-title-padding 0 0 $white, -$text-image-title-padding 0 0 $white;
				}

				&.bg-darkest {
					box-shadow: $text-image-title-padding 0 0 $bg-darkest, -$text-image-title-padding 0 0 $bg-darkest;
				}
			}

			.sub-title {
				max-width: none;

				// 4 different options for background color
				&.bg-red {
					box-shadow: $text-image-title-padding 0 0 $coc-link, -$text-image-title-padding 0 0 $coc-link;
				}

				&.bg-white {
					box-shadow: $text-image-title-padding 0 0 $white, -$text-image-title-padding 0 0 $white;
				}

				&.bg-darkest {
					box-shadow: $text-image-title-padding 0 0 $bg-darkest, -$text-image-title-padding 0 0 $bg-darkest;
				}
			}
		}

		@media( min-width: $breakpoint-lg ) {
			.comp-body {
				width: 85%;
			}

			.title {
				font-size: $text-image-title-font-size-lg;
			}
		}
	}

	// -------------------------------------------------------
	// Print
	//
	// -------------------------------------------------------

	@media print {
		.background-image {
			display: none;
		}

		.comp-body,
		&.simple .comp-body {
			border: $print-border;
			position: relative;
			width: 100%;
		}
	}
}// End of .cui.text-on-image
