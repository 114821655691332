/* -------------------------------------------------------
   Start of _media-object.scss
   Icon subject layout pattern
   ------------------------------------------------------- */

.media {
	@include flexbox();
	@include align-items(flex-start);
	padding: $spacer-sm;
	background-color: $coc-default-background;

	.md {
		width: $img-md;
		height: auto;

		&[class*="cicon-"] {
			font-size: $cicon-size-sm !important;
			width: auto;
		}
	}

	.lg {
		width: $img-lg;
		height: auto;

		&[class*="cicon-"] {
			font-size: $cicon-size-lg !important;
			width: auto;
		}
	}

	.media-body {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		-moz-flex: 1;
		flex: 1;
	}

	.media-left {
		padding-right: $spacer-sm;

		&[class*="cicon-"] {
			&.md {
				padding-top: $tiny-spacer-xs;
				padding-right: $tiny-spacer-sm;
			}

			&.lg {
				padding-top: 0;
				padding-right: $spacer-xs;
			}
		}
	}

	@media( min-width: $breakpoint-md ) {
		.media-left {
			padding-right: $spacer-md;
		}
	}

	// remove the border when the bg is white or transparent
	&.bg-transparent,
	&.bg-white {
		border: $border-thin solid transparent !important;
	}

	&.bg-light,
	&.bg-medium {
		border: $border-thin solid $border-color-medium;
	}
}
