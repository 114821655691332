// -------------------------------------------------------
// Start of _placeholders
// -------------------------------------------------------

%row-compact {
	@include flexbox ();
	@include flex-wrap(wrap);
	margin-right: -$gutter-compact/2;
	margin-left: -$gutter-compact/2;

	> .col,
	> [class*="col-"] {
		padding-right: $gutter-compact/2;
		padding-left: $gutter-compact/2;
	}
}
