/* -------------------------------------------------------
   Start of _buttons
   ------------------------------------------------------- */

button,
a {
    &.cui {
        &.primary {
            @include justify-content(center);

            .btn-wrapper {
                @include inline-flex;
                @include justify-content(center);
                width: 100%;

                .btn-text {
                    @include inline-flex;
                    @include justify-content(center);
                    width: 100%;
                    @include align-items(center);
                }
            }
        }
    }
}
.cui {
    // The following SCSS generates a ton of bloated CSS.
    // In addition, the class names should be consistent.
    // E.g. .primary, .utility, .secondary, .mega
    &[class*='btn'] {
        border-style: solid;
        border-radius: $default-border-radius;
        border-width: $btn-border-thin;
        color: $btn-text-color;
        cursor: pointer;
        display: inline-block;
        font-family: $font-primary;
        font-weight: $font-weight-semi-bold;
        font-size: $base-font-size;
        line-height: $line-height-default;
        padding: $spacer-xxs $spacer-xxs;
        text-align: center;
        text-decoration: none;
        user-select: none;
        vertical-align: middle;
        white-space: normal;
        position: relative;

        // Primary
        &.primary {
            background: $btn-primary-bg;
            border-color: $coc-main;
            color: $btn-primary-color;
            @include setInteractiveBoxShadow($use-base-shadow: true);
            overflow: hidden;
            @include inline-flex;

            [class*='cicon'] {
                &.right,
                &.left {
                    background: $btn-primary-icon-color;
                    color: $white;
                    position: relative;
                    @include inline-flex;
                    @include align-items(center);
                }
            }

            &:hover,
            &:active {
                border-color: $coc-btn-hover;
                color: $btn-primary-hover-color;

                [class*='cicon'] {
                    color: $coc-btn-hover;
                    background: $white;

                    &.right,
                    &.left {
                        background: $coc-btn-hover;
                        color: $white;
                    }
                }
            }

            span {
                &::before {
                    @include align-items(center);
                }
            }
        }

        // Utility
        &.utility-btn,
        &.utility-btn-solid {
            background: $btn-utility-bg-solid;
            border-color: $btn-utility-border-color;
            color: $btn-utility-color;
            @include setInteractiveBoxShadow();
            position: relative;

            [class*='cicon'] {
                position: relative;

                &.left {
                    padding-right: 0;
                }

                &.right {
                    padding-left: 0;
                }
            }

            &:hover,
            &:active {
                background: $btn-utility-bg-solid;
                border-color: $btn-utility-hover-border-color;
                color: $btn-utility-hover-color;
            }
        } // End of &.utility-btn

        // Text / 'Secondary'
        &.primary-text,
        &.secondary-text {
            background: $coc-secondary-10L;
            border-color: transparent;
            padding-left: $spacer-xxs;
            padding-right: $spacer-xxs;
            text-decoration: underline;

            [class*='cicon'] {
                display: inline-block;
            }

            &:hover,
            &:active {
                background: $coc-secondary-9L;
                text-decoration: none;
            }
        }

        // Primary text
        &.primary-text {
            color: $btn-primary-text-color;

            &:hover,
            &:active {
                color: $btn-primary-text-color;
            }
        }

        // Secondary text
        &.secondary-text {
            color: $coc-default-color;

            &:hover,
            &:active {
                color: $coc-default-color;
            }
        }

        // Icons inside buttons
        &.primary {
            [class*='cicon'] {
                color: $coc-main;
                background: transparent;
                font-size: 100%; // MC.2018.06.19: The font-size of the icon will match the font-size of the button
                top: $basic-space * 1rem;
            }
        }

        // Icon-only button style will be same as the primary button style
        // icon-only.primary class is only used on the search button in search bar
        &.icon-only.primary {
            background-color: $btn-icon-only-bg;
            font-size: 0;
            padding: 0;
            display: inline-block;

            [class*='cicon'] {
                font-size: $cicon-size-sm;
            }

            &:hover,
            &:active {
                background: $btn-icon-only-bg;
            }

            &.btn-sm {
                width: $btn-icon-only-sm;
                height: $btn-icon-only-sm;
            }

            &.btn-md {
                width: $btn-icon-only-md;
                height: $btn-icon-only-md;

                [class*='cicon'] {
                    font-size: $cicon-size-md;
                }
            }

            &.btn-lg {
                width: $btn-icon-only-lg;
                height: $btn-icon-only-lg;

                [class*='cicon'] {
                    font-size: $cicon-size-lg;
                }
            }
        } // End of &.icon-only.primary

        // Mega buttons with cimg/icons
        &.btn-mega {
            padding: $spacer-sm;
            display: inline-grid;

            .cimg {
                display: block;
                margin-left: auto;
                margin-right: auto;
            }

            .top {
                margin-bottom: $spacer-xxs;
            }
            .bottom {
                margin-top: $spacer-xxs;
            }

            [class*='icon'] {
                display: block !important;
                font-size: $cicon-size-lg;
            }
        } // End of .btn-mega

        // Disabled state
        &[disabled],
        &.disabled {
            background: $btn-disabled-bg;
            border-color: $btn-disabled-border-color;
            color: $btn-disabled-color;
            cursor: not-allowed;
            box-shadow: none;
            text-decoration: none;

            &:hover,
            &:active {
                // Ensure the hover and active states override the other buttons
                background: $btn-disabled-bg;
                border-color: $btn-disabled-border-color;
                color: $btn-disabled-color;
                box-shadow: none;

                [class*='cicon'] {
                    color: $btn-disabled-color;
                }
            }

            [class*='cicon'] {
                color: $btn-disabled-color;
            }
            .primary span[class*='cicon'] {
                background-color: $btn-disabled-border-color;
            }
        } // End of [disabled]
    } // End of &[class*="btn"]

    &.btn-block,
    &.btn-fluid {
        width: 100%;

        &.primary {
            margin-bottom: $spacer-xs !important;

            .btn-text {
                width: 100%;
                @include justify-content(center);
            }
        }

        [class*='cicon'] {
            &.left {
                @include justify-content(left);
                @include align-content(left);
            }

            &.right {
                @include justify-content(right);
                @include align-content(right);
            }
        }
    }

    &.btn-sm {
        padding: $btn-sm-padding;

        &.primary {
            [class*='cicon'] {
                // Apply .left or .right to the icons to get appropriate spacing
                &.left,
                &.right {
                    margin-top: -$basic-space * 3rem; //3px
                    margin-bottom: -$basic-space * 1rem; //1px
                }

                &.left {
                    left: -$spacer-xxs - 0.1rem;
                    padding: 0 $tiny-spacer-sm;
                }

                &.right {
                    right: -$spacer-xxs - 0.1rem;
                    padding: 0 $tiny-spacer-sm;
                }
            }
        }

        [class*='cicon'] {
            // Apply .left or .right to the icons to get appropriate spacing
            &.left,
            &.right {
                padding: $tiny-spacer-sm;
            }

            &.left {
                left: -$spacer-xxs;
            }

            &.right {
                right: -$spacer-xxs;
            }
        }
    }

    &.btn-md {
        padding: $btn-md-padding;

        &.primary {
            [class*='cicon'] {
                // Apply .left or .right to the icons to get appropriate spacing
                &.left,
                &.right {
                    margin-top: -$tiny-spacer-sm - 0.15rem;
                    margin-bottom: -$tiny-spacer-xs - 0.15rem;
                }

                &.left {
                    left: -$spacer-xxs - 0.1rem;
                }

                &.right {
                    right: -$spacer-xxs - 0.1rem;
                }
            }
        }

        [class*='cicon'] {
            // Apply .left or .right to the icons to get appropriate spacing
            &.left {
                padding: $spacer-xxs;
                left: -$spacer-xxs;
            }

            &.right {
                padding: $spacer-xxs;
                right: -$spacer-xxs;
            }
        }
    }

    &.btn-md,
    &.btn-lg,
    &.btn-xl {
        border-width: $btn-border-thick;
    }

    &.btn-lg {
        font-size: $btn-font-lg;
        font-weight: $font-weight-bold;
        padding: $btn-lg-padding;
        border-width: $btn-border-thick;

        &.primary {
            [class*='cicon'] {
                // Apply .left or .right to the icons to get appropriate spacing
                &.left,
                &.right {
                    margin-top: -$basic-space * 9.44em;
                    margin-bottom: -$basic-space * 6.4rem;
                }

                &.left {
                    left: -$spacer-xs - 0.2rem;
                }

                &.right {
                    right: -$spacer-xs - 0.2rem;
                }
            }
        }

        [class*='cicon'] {
            // Apply .left or .right to the icons to get appropriate spacing
            &.left,
            &.right {
                padding: $spacer-xs;
            }

            &.left {
                left: -$spacer-xs;
            }

            &.right {
                right: -$spacer-xs;
            }
        }
    }

    &.btn-xl {
        font-size: $btn-font-xl;
        font-weight: $font-weight-bold;
        padding: $btn-xl-padding;
        border-width: $btn-border-thick;

        &.primary {
            [class*='cicon'] {
                // Apply .left or .right to the icons to get appropriate spacing
                &.left,
                &.right {
                    margin-top: -$spacer-xs - 0.1rem;
                    margin-bottom: -$spacer-xs;
                }

                &.left {
                    left: -$spacer-sm - 0.2rem;
                }

                &.right {
                    right: -$spacer-sm - 0.2rem;
                }
            }
        }

        [class*='cicon'] {
            // Apply .left or .right to the icons to get appropriate spacing
            &.left,
            &.right {
                padding: $spacer-sm;
            }

            &.left {
                left: -$spacer-sm;
            }

            &.right {
                right: -$spacer-sm;
            }
        }
    }

    // Sized and styled to match `.form-control` elements
    &.btn-form-control {
        border-width: $btn-border-thick !important;
        font-size: $base-font-size;
        padding: $spacer-xxs;
    }
} // End of .cui

@media print {
    .cui.primary .btn-wrapper .cicon-angle-right {
        &::before,
        &::after {
            color: $white !important;
        }
    }
}
