/* -------------------------------------------------------
   Start of _duration-block
   ------------------------------------------------------- */

.duration-block.cui {
	padding: $duration-block-border-spacing-sm;
	border-radius: $duration-block-border-radius;

	p {
		margin-bottom: 0;
		max-width: none;
		font-weight: $font-weight-semi-bold;
	}

	.time-header-block,
	.time-footer-block {
		padding-left: $duration-block-border-spacing-sm;
		padding-right: $duration-block-border-spacing-sm;
	}

	.time-body-block {
		border-radius: $duration-block-clock-border-radius;
		padding: 0 $duration-block-border-spacing-sm;
		color: $black;

		&.unit-full-word {
			.year-title::after {
				content: 'year';
			}

			.years-title::after {
				content: 'years';
			}

			.month-title::after {
				content: 'month';
			}

			.months-title::after {
				content: 'months';
			}

			.week-title::after {
				content: 'week';
			}

			.weeks-title::after {
				content: 'weeks';
			}

			.day-title::after {
				content: 'day';
			}

			.days-title::after {
				content: 'days';
			}

			.hour-title::after {
				content: 'hour';
			}

			.hours-title::after {
				content: 'hours';
			}

			.minute-title::after {
				content: 'minute';
			}

			.minutes-title::after {
				content: 'minutes';
			}

			.second-title::after {
				content: 'second';
			}

			.seconds-title::after {
				content: 'seconds';
			}
		}

		&.unit-abbr {
			.year-title::after,
			.years-title::after {
				content: 'y';
			}

			.month-title::after,
			.months-title::after {
				content: 'm';
			}

			.week-title::after,
			.weeks-title::after {
				content: 'w';
			}

			.day-title::after,
			.days-title::after {
				content: 'd';
			}

			.hour-title::after,
			.hours-title::after {
				content: 'h';
			}

			.minute-title::after,
			.minutes-title::after {
				content: 'm';
			}

			.second-title::after,
			.seconds-title::after {
				content: 's';
			}
		}

		&.unit-hide {
			.year-title::after,
			.years-title::after,
			.month-title::after,
			.months-title::after,
			.week-title::after,
			.weeks-title::after,
			.day-title::after,
			.days-title::after,
			.hour-title::after,
			.hours-title::after,
			.minute-title::after,
			.minutes-title::after,
			.second-title::after,
			.seconds-title::after {
				content: '';
			}
		}
	}

	.holder {
		padding: $duration-block-border-spacing-sm; //9px

		span {
			display: block;
			font-size: $duration-block-clock-number-font-size-sm;
			font-weight: $font-weight-bold;
		}
	}

	// layout: Horizontal, vertical and single-line
	// vertical is the default
	&.horizontal,
	&.single-line {
		@include flexbox;
		flex-wrap: wrap;
		align-items: center;

		.time-header-block,
		.time-footer-block {
			flex-grow: 1;
			// flex-wrap: wrap;
		}

		.time-body-block {
			flex-grow: 2;
			text-align: center;
			flex-wrap: wrap;
			@include flexbox;

			.holder {
				flex-grow: 1;
				padding: 0;
				margin-right: $basic-space * 9rem;
				flex-wrap: wrap;

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.time-header-block {
			text-align: right;
		}
	}

	&.horizontal {
		.holder {
			display: block;
			text-align: center;
		}
	}

	&.single-line {
		.time-body-block {
			.holder span {
				display: inline;
			}
		}
	}

	// The vertical version width: full width
	&.vertical {
		@include make-row;
		text-align: center;
		margin-right: 0;
		margin-left: 0;

		.time-body-block {
			flex-wrap: wrap;
			@include flexbox;
			@include justify-content(center);
		}

		.time-header-block {
			text-align: center;
			margin-bottom: $duration-block-border-spacing-sm; //9px
		}

		.time-footer-block {
			text-align: center;
			margin-top: $duration-block-border-spacing-sm; //9px
		}

		.time-header-block,
		.time-body-block,
		.time-footer-block {
			@include calcColWidth(12);
			padding-left: 0;
			padding-right: 0;
		}

		.holder {
			flex-basis: 0;
			flex-grow: 1;
			max-width: 100%;
		}
	}

	// themes: light gray, dark gray and transparent available
	&.highlight.bg-medium {
		border: $border-thin solid $border-color-medium;

		.time-body-block {
			background-color: $coc-secondary-8L;
			border: $border-thin solid $border-color-dark;
		}
	}

	&.highlight.bg-transparent {
		.time-body-block {
			border: $border-thin solid $border-color-medium;
			background-color: $bg-light;
		}
	}

	&.highlight.bg-light {
		border: $border-thin solid $border-color-medium;

		.time-body-block {
			border: $border-thin solid $border-color-dark;
			background-color: $white;
		}
	}

	// 576px
	@media( min-width: $breakpoint-md ) {

		// size: small medium and large
		// small is the default size no class needed
		&.md {
			padding: $duration-block-border-spacing-md;

			.time-header-block,
			.time-footer-block {
				padding-left: $duration-block-border-spacing-md;
				padding-right: $duration-block-border-spacing-md;

				p {
					font-size: $duration-block-font-size-md;
				}
			}

			.time-body-block {
				padding: 0 $duration-block-border-spacing-md;
			}

			.holder span {
				font-size: $duration-block-clock-number-font-size-md;
			}
		}

		&.lg {
			padding: $duration-block-border-spacing-lg;

			.time-header-block,
			.time-footer-block {
				font-size: $duration-block-font-size-lg;
				padding-left: $duration-block-border-spacing-lg;
				padding-right: $duration-block-border-spacing-lg;

				p {
					font-size: $duration-block-font-size-lg;
				}
			}

			.time-body-block {
				padding: 0 $duration-block-border-spacing-lg;
			}

			.holder span {
				font-size: $duration-block-clock-number-font-size-lg;
			}

			&.horizontal .time-body-block,
			&.single-line .time-body-block {
				.holder {
					margin-right: $basic-space * 15rem;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}
}
