/* -------------------------------------------------------
   Start of _content-block
   ------------------------------------------------------- */

// Flexbox-based two column layout to display content with an image
.content-block-flex {
	background: $content-block-bg;
	border: $border-thin solid $content-block-border-color;
	margin-bottom: $content-block-ex-spacing;

	&.light {
		background: $content-block-bg-light;
		border-color: $content-block-border-color-light;
	}

	&.transparent {
		background: transparent;
		border-color: transparent;

		@media( max-width: $breakpoint-xs-max ) {
			.content-block-body {
				padding-right: 0;
				padding-bottom: 0;
				padding-left: 0;
			}
		}
	}

	// The img.mobile-image will display in mobile and the .background-image
	// will be hidden until the layout becomes 2 columns
	.mobile-image {
		width: 100%;
	}

	.background-image {
		display: none;
	}

	.content-block-body {
		padding: $content-block-col-body-padding;
	}

	// MC.2018.05.14: This subtitle probably belongs in headings.scss so it could be used globally
	.subtitle {
		display: block;
		font-weight: $font-weight-semi-bold;
		margin-bottom: $spacer-md;
	}

	h2 + .subtitle,
	h3 + .subtitle {
		margin-top: -$spacer-xxs;// Space the subtitle closer to the h2, if directly after the h2
	}

	// Mobile Only
	@media( max-width: $breakpoint-xs-max ) {
		// In mobile, the media column will always be first
		// and the body column will always be second
		.content-block-media { order: 1; }
		.content-block-body { order: 2; }
	}

	// Extra Small & Up
	@media( min-width: $breakpoint-sm ) {
		@include align-items(center);// Align the columns vertically center

		&.fixed-image-height {
			.background-image {
				height: $content-block-img-height-sm;// Default height

				&.sm { height: $content-block-img-height-sm; }
				&.md { height: $content-block-img-height-md; }
				&.lg { height: $content-block-img-height-lg; }
			}
		}

		&.flexible-image-height {
			min-height: $content-block-img-height-sm;

			.content-block-media {
				position: relative;
			}

			// The image is sized to match the height of the flex parent
			.background-image {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
		}

		.content-block-media {
			@include flexbox();
			@include align-self(stretch); // Ensure the column is stretched to 100% height of the parent
		}

		// Hide the mobile image and display the background-image
		.mobile-image {
			display: none;
		}

		.background-image {
			display: block;
			width: 100%;// Required to render properly within the flex context
			@include align-self(center); // Align the background-image ot the center of the column
		}

		.content-block-body {
			padding: $content-block-col-body-padding-xs;
		}
	}// End of min-width: $breakpoint-sm

	// -------------------------------------------------------
	// Print
	//
	// -------------------------------------------------------

	@media print {
		background: transparent !important;

		.content-block-media {
			display: none;
		}

		.content-block-body {
			flex: 0 0 100%;
			-webkit-box-flex: 0 0 100%;
			-webkit-flex: 0 0 100%;
			-moz-flex: 0 0 100%;
			-ms-flex: 0 0 100%;
			max-width: none;
		}
	}
}// End of .content-block-flex
