/* -------------------------------------------------------
   START OF _image-viewer
   ------------------------------------------------------- */

// -------------------------------------------------------
// Thumbnail
//
// -------------------------------------------------------

.col-imgviewer-thumb {
	margin-bottom: $gutter;
}

.imgviewer-thumb {
	position: relative;

	button {
		background: transparent;
		border: 0;
		cursor: pointer;// Fallback for IE11
		cursor: zoom-in;
		text-align: left;
		width: 100%;

		&:hover {
			.background-image {
				box-shadow: $default-hover-shadow;
			}
		}

		&:focus {
			box-shadow: none;// Remove the default shadow given to all buttons
			outline: 0;

			.background-image {
				@include setOutlineGlow();
			}
		}
	}

	.background-image {
		border: $border-thin solid $border-color-medium;
		transition: box-shadow $transition-duration $transition-timing-function;
	}

	.caption {
		display: inline-block;
		padding-top: $tiny-spacer-sm;
	}

	.caption-details {
		display: none;
	}
}// End of .imgviewer-thumb

// -------------------------------------------------------
// Modal
//
// -------------------------------------------------------

body.imgviewer-modal-open {
	overflow: hidden;

	.imgviewer-modal {
		@include flexbox();
	}
}

.imgviewer-modal {
	background: $imgviewer-modal-overlay-bg-color;
	display: none;
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: $imgviewer-modal-z;
}

.imgviewer-modal-inner {
	position: relative;
	width: 100%;
	height: 100%;
}

.imgviewer-modal-item {
	cursor: move;
	height: 100%;
	overflow: hidden;

	img {
		display: block;
		margin-right: auto;
		margin-left: auto;
		max-width: 90%;
		position: relative;
	}
}

.imgviewer-modal-pan-wrapper {
	display: inline-block;
	position: relative;
	text-align: center;
	width: 100%;
	height: 100%;
}


// -------------------------------------------------------
// Modal header and footer
//
// -------------------------------------------------------

.imgviewer-modal-header,
.imgviewer-modal-footer {
	background: $imgviewer-modal-header-bg-color;
	position: fixed;
	right: 0;
	left: 0;
	z-index: $imgviewer-modal-header-z;
}

.imgviewer-modal-header {
	border-bottom: $imgviewer-modal-border;
	height: $imgviewer-modal-header-height;
	padding: $spacer-xs $spacer-sm;
	top: 0;
}

.imgviewer-modal-footer {
	bottom: 0;
}

.imgviewer-modal-footer-inner {
	border-top: $imgviewer-modal-border;
	display: none;
	margin-right: auto;
	margin-left: auto;
	max-width: 100%;
	overflow: auto;
	padding: $spacer-sm;
}

.imgviewer-modal-count {
	font-size: $small-font-size;
}


// -------------------------------------------------------
// Modal buttons
//
// -------------------------------------------------------

.imgviewer-modal-close,
.imgviewer-modal-toggle-caption {
	[class*="cicon"] {
		display: inline-block;// Ensures the icon does not have an underline
		margin-left: $tiny-spacer-sm;
	}
}

.imgviewer-modal-close {
	position: absolute !important;
	top: $tiny-spacer-sm;
	right: $tiny-spacer-sm;

	.cicon-close-thin-large {
		font-size: $cicon-size-xs;// Balance the size
	}
}

.imgviewer-modal-toggle-caption {
	border-bottom-right-radius: 0;// Remove the border-radii on the bottom for a flush look
	border-bottom-left-radius: 0;
	height: $imgviewer-modal-toggle-caption-height;
	position: absolute !important;
	top: -$imgviewer-modal-toggle-caption-height;
	right: $imgviewer-modal-padding;
}

.imgviewer-modal-control {
	@include setIconFontStyle();
	background: $imgviewer-btn-control-bg-color;
	border: $imgviewer-btn-control-border;
	border-radius: $default-border-radius;
	color: $coc-secondary;
	line-height: $imgviewer-btn-control-size;
	position: fixed;
	z-index: $imgviewer-modal-control-z;
	transition: all $transition-duration $transition-timing-function;
	width: $imgviewer-btn-control-size;
	height: $imgviewer-btn-control-size;

	&:hover {
		background: $white;
	}

	&.zoom-in,
	&.zoom-out,
	&.right {
		right: $imgviewer-modal-padding;
	}

	&.left,
	&.right {
		border-radius: 50%;
		margin-top: -( $imgviewer-btn-control-size / 2 );// Adjust the offset so the button is exactly centred
		top: 50%;

		&::before {
			position: relative;
		}
	}

	&.left {
		left: $imgviewer-modal-padding;

		&::before {
			content: "\e159";// .cicon-angle-left
			left: -$basic-space*1rem;// Balance the position
		}
	}

	&.right {
		&::before {
			content: "\e15a";// .cicon-angle-right
			left: $basic-space*1rem;// Balance the position
		}
	}

	&.zoom-in,
	&.zoom-out {
		font-size: 140%;// Balance the size
	}

	&.zoom-in {
		top: $imgviewer-modal-zoom-in-top;

		&::before {
			content: "\e002";// .cicon-search-plus
		}
	}

	&.zoom-out {
		top: $imgviewer-modal-zoom-out-top;

		&::before {
			content: "\e003";// .cicon-search-minus
		}
	}
}// End of .imgviewer-modal-control

@media( max-height: $breakpoint-sm-height ) {
	// Adjust the position of the zoom-in and zoom-out buttons for
	// smaller mobile devices in landscape mode
	.imgviewer-modal-control.zoom-out {
		top: $imgivewer-modal-zoom-out-top-mob-landscape;
		right: $imgivewer-modal-zoom-out-right-mob-landscape;
	}
}

@media( min-width: $breakpoint-md ) {
	.imgviewer-modal-toggle-caption,
	.imgviewer-modal-control.zoom-in,
	.imgviewer-modal-control.zoom-out,
	.imgviewer-modal-control.right {
		right: $imgviewer-modal-padding-sm;
	}

	.imgviewer-modal-control.left {
		left: $imgviewer-modal-padding-sm;
	}

	.imgviewer-modal-control {
		&.zoom-in {
			top: $imgviewer-modal-zoom-in-top-sm;
		}

		&.zoom-out {
			top: $imgviewer-modal-zoom-out-top-sm;
		}
	}
}

@media( min-width: $breakpoint-xl ) {
	.imgviewer-modal-footer-inner {
		max-width: 50%;
	}
}

// -------------------------------------------------------
// Print
//
// -------------------------------------------------------

@media print {
	.imgviewer-thumb {
		.background-image {
			// Tell the browser to print the background image;
			// if the browser supports the rule.
			-webkit-print-color-adjust: exact !important; // Chrome, Safari
			// MC.2018.12:10: Firefox on MacOs doesn't display the images properly;
			// therefore commenting out the following until support is better.
			// color-adjust: exact !important;// Firefox
		}
	}
}
