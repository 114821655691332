/* -------------------------------------------------------
   Start of _headings
   ------------------------------------------------------- */

// stylelint-disable selector-list-comma-newline-after

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-weight: $font-weight-normal;
	line-height: $line-height-compact;
	margin-top: 0;// Ensure we override BS
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	display: block;
}

h1,
.h1 {
	font-size: $h1-font-size;
	margin-bottom: $spacer-md;

	@media( min-width: $breakpoint-sm ) {
		font-size: $h1-font-size-xs;
	}

	@media( min-width: $breakpoint-md ) {
		font-size: $h1-font-size-sm;
	}

	@media( min-width: $breakpoint-lg ) {
		font-size: $h1-font-size-md;
		margin-bottom: $basic-space*62rem;
	}
}

h2,
.h2 {
	font-size: $h2-font-size;
	margin-bottom: $spacer-md;

	@media( min-width: $breakpoint-sm ) {
		font-size: $h2-font-size-xs;
	}

	@media( min-width: $breakpoint-md ) {
		font-size: $h2-font-size-sm;
	}

	@media( min-width: $breakpoint-lg ) {
		font-size: $h2-font-size-md;
	}
}

h3,
.h3 {
	font-size: $h3-font-size;
	margin-bottom: $spacer-sm;

	@media( min-width: $breakpoint-sm ) {
		font-size: $h3-font-size-xs;
	}

	@media( min-width: $breakpoint-md ) {
		font-size: $h3-font-size-sm;
	}

	@media( min-width: $breakpoint-lg ) {
		font-size: $h3-font-size-md;
	}
}

h4,
.h4 {
	font-size: $h4-font-size;
	margin-bottom: $spacer-sm;
}

h5,
.h5,
h6,
.h6 {
	font-size: $base-font-size;
	margin-bottom: $spacer-xs;
}

h5,
.h5 {
	color: $coc-secondary-3D;
}

h6,
.h6 {
	color: $coc-secondary-4D;
}

article {
	h1,
	.h1,
	h2,
	.h2,
	h3,
	.h3,
	h4,
	.h4,
	h5,
	.h5,
	h6,
	.h6 {
		@include generateBlockMaxWidth();
	}
}

// TODO: MC.2019.03.28: For future optimization, a class could be applied to the
// heading tags only (instead of the current structure: <div><heading><hr>).
// The current <div> and <hr>s are redundant as all styling could be handled
// on the heading class.
.subtitle-block.cui {
	display: block;
	margin-bottom: $spacer-xs;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $coc-default-color;
		font-size: $h2-font-size;
		font-weight: $font-weight-semi-bold;
		margin-bottom: $tiny-spacer-sm;
	}

	hr {
		background: $subtitle-hr-color;
		border: 0;
		height: $subtitle-hr-height;
		margin: 0;
	}

	@media( min-width: $breakpoint-sm ) {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: $h2-font-size-xs;
		}
	}

	@media( min-width: $breakpoint-md ) {
		margin-bottom: $spacer-md;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: $h2-font-size-sm;
		}

		hr {
			height: $subtitle-hr-height-sm;
		}
	}

	@media( min-width: $breakpoint-lg ) {
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: $h2-font-size-md;
		}
	}
	// new design
	&.red-underline {
		padding: $heading-underline-padding*2 0 0 0;
		//margin-bottom: $spacer-md;

		hr {
			width: $basic-space*100rem;
			border: $heading-border;
			background: $coc-main;
			height: $subtitle-hr-height-md;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			text-transform: initial;
			font-weight: $font-weight-bold;
		}

		&.text-center {
			text-align: center;

			hr {
				margin-left: auto;
				margin-right: auto;
			}
		}

		&.long-line {
			hr {
				width: $basic-space*200rem;
			}
		}
	}
}
