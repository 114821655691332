$msv-checkout-button-margin: 15px auto;
$msv-checkout-button-text-align: center;
$msv-checkout-button-align-items: center;
$msv-checkout-button-justify-content: center;
$msv-checkout-button-width: auto;
$msv-checkout-button-border-radius: 2px;
$divider-vertical-margin: 20px;
$cart-items-right-margin: 40px;
$cart-empty-image-width: 183px;
$cart-empty-image-width-m: 80px;
$summary-min-width: 320px;
$summary-max-width: 480px;
$cart-heading-bottom: 20px;
$cart-heading-top: 32px;
$cart-cartline-wrapper-grp-padding: 32px;
$cart-cartline-wrapper-margin-right: 40px;
$cart-cartline-wrapper-margin-bottom: 1.5rem;
$cart-cartline-line-padding-right: 20px;
$cart-content-margin-left: 115px;
$cart-cartline-line-product-padding-bottom: 10px;
$cart-cartline-line-product-price-padding-top: 10px;
$cart-cartline-line-product-price-margin-left: 0;
$cart-cartline-item-content-margin-left-m: 100px;
$cart-promo-code-input-width-m: 170px;
$cart-order-summary-padding-m: 20px;
$cart-promo-code-input-height: 50px;
$msv-promotion-icon-right-margin: 8px;
$cart-promotion-padding: 20px;
$cart-promotion-margin-bottom: 5px;
$cart-promotion-popup-close-button-padding: 5px;
$cart-lines-group-heading-padding-top: 12px;
$cart-lines-group-heading-padding-left: 76px;
$cart-line-group-extra-action-margin-top: 75px;
$cart-line-group-extra-action-margin-left: 200px;

//style presets
:root {
    // heading
    --msv-cart-heading-font-size: var(--msv-heading-font-size-s);
    --msv-cart-heading-font-color: var(--msv-font-primary-color);
}

.msc-cart__btn-checkout,
.msc-promo-code__apply-btn {
    @include button-primary();
    align-items: $msv-checkout-button-align-items;
    display: flex;
    justify-content: $msv-checkout-button-justify-content;
    width: auto;
    min-width: 200px;
    font-weight: 600;
}
.msc-cart__btn-checkout {
    @include button-primary-icon();
    padding-right: 35px;
}
.msc-cart__btn-checkout,
.msc-cart__btn-guestcheckout,
.msc-cart__btn-backtoshopping {
    margin: $msv-checkout-button-margin;
    width: $msv-checkout-button-width;
    font-weight: 600;
}

.msc-cart__btn-guestcheckout,
.msc-cart__btn-addcarttotemplate {
    @include button-secondary-text();
    min-width: 200px;
    text-align: center;
}
.msc-cart__btn-addcarttotemplate {
    width: 100%;
}
.msc-cart__btn-backtoshopping {
    @include button-link(var(--msv-cart-btn-link-color));
}

.ms-cart {
    display: flex;
    flex-wrap: wrap;
    .back-shop {
        flex: 0 0 100%;
        justify-content: left;
        border-bottom: 1px solid $cui-gray-primary;
        .msc-cart__btn-backtoshopping {
            display: inline-flex;
            padding: 0;
            color: $msv-primary;
            font-weight: 600;
            height: auto;
            text-decoration: none;
            &::before {
                @include msv-icon($icon-weight: $msv-solid-icon-weight);
                content: $msv-ChevronLeft;
                margin-right: 5px;
                position: relative;
                top: 4px;
            }
            &:hover {
                text-decoration: underline;
                &::before {
                    text-decoration: none;
                }
            }
        }
    }
    .msc-cart__heading {
        @include font-content();
        color: var(--msv-cart-heading-font-color);
        flex: 0 0 100%;
        margin-bottom: $cart-heading-bottom;
        margin-top: $cart-heading-top;
        font-size: 3rem;
        line-height: 4rem;
        font-weight: 600;
        @media screen and (max-width: $msv-breakpoint-m) {
            margin-top: 15px;
            font-size: 2rem;
            line-height: 3rem;
        }
    }

    .msc-cart__promotion {
        width: 100%;
        background-color: $msv-white;
        padding: $cart-promotion-padding;
        margin-bottom: $cart-promotion-margin-bottom;

        &_link {
            margin-left: 8px;
            text-decoration: underline;
            cursor: pointer;
        }

        .msi-promotion-icon {
            margin-right: $msv-promotion-icon-right-margin;

            @include add-icon($msv-DollarSign, before);
            display: none;
        }

        &-popup {
            position: absolute;
            width: auto;
            height: auto;
            z-index: 2;
            background-color: $msv-white;
            left: 0;

            &_content {
                color: var(--msv-font-primary-color);
                background-clip: padding-box;
                background-color: var(--msv-bg-color);
                border: 1px solid $msv-gray-400;
                outline: 0;
                pointer-events: auto;
                position: relative;
                width: 400px;
                min-width: 335px;
                padding: 20px 10px;

                .msc-cart__promotion-popup_title {
                    @include font-content-xl();
                    margin: 0;
                    text-transform: none;
                }

                .msc-cart__promotion-popup_close-button {
                    @include add-icon($msv-x-shape);
                    @include vfi();
                    background-color: transparent;
                    border: 0;
                    color: var(--msv-font-primary-color);
                    font-size: 20px;
                    padding: $cart-promotion-popup-close-button-padding;
                    position: absolute;
                    right: 15px;
                    text-shadow: 0 1px 0 var(--msv-bg-color);
                    top: 22px;
                }

                ul {
                    list-style: none;
                    padding: 0;

                    .msc-cart__promotion-popup_name {
                        display: block;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        .msc-cart__promotion {
            &-popup {
                &_content {
                    width: 335px;
                }
            }
        }
    }

    .msc-cartline-wraper {
        border-top: 1px solid var(--msv-cart-border);
        flex: 1;
        margin-bottom: $cart-cartline-wrapper-margin-bottom;
        margin-right: $cart-cartline-wrapper-margin-right;
        @media screen and (max-width: $msv-breakpoint-m) {
            margin-right: 0;
        }
    }

    .msc-cart-line {
        &__product-image {
            position: absolute;
            width: 100px;
            .msc-empty_image {
                @include image-placeholder($cart-empty-image-width);
                height: 140px;
            }
            @media (max-width: $msv-breakpoint-m) {
                position: relative;
            }
        }

        &__content,
        &__bopis-container {
            margin-left: $cart-content-margin-left;
        }
        &__extra-actions {
            position: absolute;
            right: 0;
            top: 0;
            .msc-cart-line__add-to-wishlist {
                display: none;
            }
            .msc-cart-line__remove-item {
                height: auto;
                color: $msv-primary;
                text-transform: lowercase;
                text-decoration: none;
                margin: 0;
            }
        }
    }
    .search-key {
        margin-left: 115px;
        @media (min-width: $msv-breakpoint-m) {
            width: calc(100% - 200px);
        }
    }
    .msc-cart-lines-item > .msc-cart-line__add-to-wishlist ~ .msc-cart-line__remove-item {
        margin-left: auto;
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-cart-lines-item > .msc-cart-line__remove-item,
    .msc-cart-lines-item > .msc-cart-line__add-to-wishlist {
        margin-left: $cart-content-margin-left;
    }
    .msc-cart-lines-item > .msc-cart-line__remove-item {
        margin: 0;
        position: absolute;
        top: 0;
        right: 0;
        height: auto;
        color: $msv-primary;
        text-decoration: none;
        text-transform: lowercase;
        font-weight: 400;
        &:hover {
            text-decoration: underline;
        }
    }
    .msc-promo-code {
        &__input-box {
            height: $cart-promo-code-input-height;
        }
    }

    .msc-cart-lines-group {
        flex: 1;
        margin-right: $cart-cartline-wrapper-margin-right;
        padding-top: $cart-cartline-wrapper-grp-padding;
    }

    .msc-cart-lines-group-wraper {
        background-color: $msv-white;
        flex: 1;
        margin-bottom: $cart-cartline-wrapper-margin-bottom;

        .msc-cart-line-group__extra-actions {
            .msc-cart-line__remove-item {
                margin-right: 0;
            }
        }
    }
    .spinner-wrap {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 9;
        .spinner-border {
            margin: auto;
            margin-bottom: 0.5625rem;
            height: 2.25rem;
            width: 2.25rem;
            border: 0.375rem solid #dbdcdd;
            border-radius: 50%;
            border-top-color: #a80000;
            animation: rotate 1s ease infinite;
            top: 50%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

@media screen and (max-width: $msv-breakpoint-m) {
    .ms-cart {
        display: block;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 30px;
        .msc-cartline-wraper .msc-cart-lines-group-wraper {
            margin-right: 0;
            width: 100%;
        }

        .msc-cart-lines-group {
            margin-right: 0;
        }

        .msc-cart-lines-item > .msc-cart-line__add-to-wishlist,
        .msc-cart-lines-item > .msc-cart-line__remove-item {
            margin-left: $cart-cartline-item-content-margin-left-m;
        }

        .msc-cart-lines-item {
            .msc-cart-line {
                &__product-image {
                    .msc-empty_image {
                        @include image-placeholder($cart-empty-image-width-m);
                    }
                }

                &__content {
                    display: block;
                    margin-left: 0;
                    margin-top: 15px;
                }

                &__bopis-container,
                &__extra-actions {
                    margin-left: $cart-cartline-item-content-margin-left-m;
                }

                &__product {
                    padding-bottom: $cart-cartline-line-product-padding-bottom;

                    &-quantity {
                        padding-bottom: $cart-cartline-line-product-padding-bottom;
                    }

                    &-savings {
                        margin-left: 0;
                        text-align: left;
                    }

                    &-price {
                        margin-left: $cart-cartline-line-product-price-margin-left;
                        padding-top: $cart-cartline-line-product-price-padding-top;
                        text-align: left;
                    }
                }

                // &-group {
                //     &__extra-actions {
                //         margin-left: $cart-cartline-item-content-margin-left-m;
                //         margin-top: $cart-line-group-extra-action-margin-top;
                //     }
                // }
            }
        }
        .msc-promo-code {
            &__input-box {
                width: $cart-promo-code-input-width-m;
            }

            &__apply-btn {
                min-width: auto;
            }
        }

        .msc-order-summary-wrapper {
            padding: $cart-order-summary-padding-m;
            width: 100%;
        }

        .msc-order-summary__items {
            @include font-content-s();
        }
        .search-key {
            margin-left: 0;
            margin-right: 60px;
            font-weight: 400;
            margin-top: 10px;
        }
    }
}
