/* -------------------------------------------------------
   Start of _search
   ------------------------------------------------------- */

.cui.search-box {
	display: block;
	max-width: $search-max-width;
	position: relative;

	// When the class is applied to a <button>; or button.cui.search-box
	@at-root button#{&} {
		// When the element is the button element (faux wrapper)
		// Reset default button styles
		background: transparent;
		border: 0;
		border-radius: $default-border-radius;
		width: 100%;
		outline: 0;
		@include setInteractiveBoxShadow (false);

		.search-box-btn.icon-only {
			&:hover,
			&:active {
				background-color: transparent;

				[class*="cicon"] {
					background-color: transparent;
					color: $coc-main;
				}
			}
		}
	}

	// End of & button

	.search-box-input {
		height: $input-height; // MC.2018.06.21: Set an explicit height here as the height will not be what is expected when the input is a <span>
		padding-right: $input-height;
		position: relative;
	}

	// The button sit on top of the input, positioned to the right
	// Because the height of the input is 50px and the button is 40px,
	// the top and right offsets are 5px
	.search-box-btn.icon-only {
		position: absolute;
		top: $search-btn-margin;
		right: $search-btn-margin;
		width: $search-btn-width;
		height: $search-btn-width;
		border: none;
		box-shadow: none;
		@include align-items(center);
		@include justify-content(center);
		background: transparent;
		color: $coc-main;

		[class*="cicon"] {
			color: $coc-main;
		}

		&:hover,
		&:active {
			background-color: $coc-main;
			transition: none;
		}

		&:hover [class*="cicon"],
		&:active [class*="cicon"] {
			color: $coc-default-background;
			background: $coc-main;
		}

		&:focus {
			box-shadow: $focus-box-shadow;
		}
	}


	//=============================================================================
	// Print Styles
	//=============================================================================

	@media print {
		display: none !important;
	}
}

// Adjust the space for the text on the sub-search button
.sub-search-btn.btn-md {
	padding-top: $spacer-xxs;
	padding-bottom: $spacer-xxs;
	border-width: $btn-border-thin;
}

// Search input with the search suggestion box
.cui.search-container {
	position: relative;

	.toggled-search {
		position: absolute;
		top: $input-height;
		display: block;
		width: 100%;
		max-width: $search-max-width;
		box-shadow: $default-base-shadow;
		border: $input-border;
		border-radius: $input-border-radius;
		z-index: $toggled-search-z;
	}

	.toggle-search-body {
		margin: $spacer-xs $spacer-md $spacer-md $spacer-md;

		.search-toggle-btn {
			margin-top: -$spacer-sm;
			margin-right: -$spacer-sm;
			float: right;
			@include setInteractiveBoxShadow($use-base-shadow: true);
			@include clearfix;
		}
	}

	.text-large {
		font-weight: $font-weight-bold;
	}

	.src-btn {
		position: relative;
		border-radius: $search-suggest-btn-border-radius;
		border: $border-thin solid $search-suggest-btn-border-color;
		margin: 0 $spacer-xs $spacer-xs 0;
		font-size: $search-suggest-btn-font-size;
		box-shadow: $default-base-shadow;
		color: $search-suggest-btn-font-color;
		background: $coc-default-background;
		@include setInteractiveBoxShadow($use-base-shadow: true);
	}
}
