/* -------------------------------------------------------
   Start of _menu-mega-simplified
   ------------------------------------------------------- */

// MC.2018.08.13: Styling for a simplified dropdown subnav
// The scss is extremely nested below for consistency with the original mega-menu styles. At some point, all of this will be cleaned up better
// stylelint-disable-next-line no-duplicate-selectors
.cui.nav-mega {
	.nav-item.has-dropdown-simple {
		position: relative;

		.sub-nav {
			border-width: 0 $border-thin $border-thin;
			border-style: solid;
			border-color: $border-color-medium;
			padding: 0;
			width: 100%;

			&.open {
				z-index: $focus-z;// Ensure the z-index is higher up in case of layout wrapping (e.g. when browser font-size is extra large)
			}

			ul {
				@include flex-direction (column);
			}

			li {
				position: relative;

				&:last-child {
					a {
						border-bottom: 0;
					}
				}
			}

			a {
				border-bottom: $border-thin solid $border-color-medium;
				color: $coc-default-color;
				display: block;
				padding: $spacer-xxs $spacer-xs $spacer-xxs $mega-nav-header-padding-md; // Add the text indent
				position: relative;
				text-decoration: none;

				&:hover {
					background: $bg-light;
					text-decoration: underline;
				}
			}

			@media( min-width: $breakpoint-lg ) {
				min-width: $basic-space*200rem;

				a {
					padding-left: $spacer-xs;// Reduce padding-left
				}
			}
		}// End of .sub-nav
	}// End of .nav-item.has-dropdown-simple
}// End of .cui.nav-mega
