.coc-alertBanner {
    .inline-notification-banner {
        margin-left: 2%;
        margin-right: 2%;
    }
}

.cui.alert-banner.inline.banner-left {
    border-left-width: 2.25rem !important;
}

.cui.alert-banner.inline.banner-top {
    border-left-width: 0.25rem !important;
}

.cui.alert-banner.banner-top .alert-header {
    padding: 0.5625rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
}