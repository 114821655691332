/* -------------------------------------------------------
   Start of _spinner icon
   ------------------------------------------------------- */
@mixin cui-spinner() {
    margin: auto;
    margin-bottom: $spacer-xxs;
    height: $spacer-lg;
    width: $spacer-lg;
    border: $preload-icon-border solid $coc-secondary-8L;
    border-radius: 50%;
    border-top-color: $coc-main;
    animation: rotate 1s ease infinite;
}
.preload-spinner-container {
    position: relative;
    height: $spacer-xxl;
    width: $spacer-xxl;

    p {
        text-align: center;
        font-weight: 600;
        margin-bottom: 0;
    }
}

.preload-spinner {
    @include cui-spinner();
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
