// -------------------------------------------------------
// Start of _variables-theme
//
// Purpose: Set the raw colour values and logo dimensions here
// -------------------------------------------------------
// Assumes the browser default, typically '16px'
// 1rem = 16px, 1px = 0.0625rem (1/16)
$basic-space: 0.0625; //1px

$coc-main: #c8102e !default;
$coc-main-lighter: rgba($coc-main, 0.8) !default;

$coc-accent-color: $coc-main !default;

// Link colours
$coc-link: $coc-main !default;
$coc-visited: #780519 !default;
$coc-link-hover-color: lighten($coc-link, 5) !default;

// Button hover
$coc-btn-hover: #da1132; //#af0f29; updated on April,2021

// Site-wide colours
$white: #fff;
$black: #000;

$focus-glow-color: #60bbfe; // Medium blue

$coc-secondary: #4b4f55;

// Secondary colour: lighter shades
$coc-secondary-1L: #5d6066;
$coc-secondary-2L: #6f7277;
$coc-secondary-3L: #818388;
$coc-secondary-4L: #939599;
$coc-secondary-5L: #a5a7aa;
$coc-secondary-6L: #b7b9bb;
$coc-secondary-7L: #c9cacc;
$coc-secondary-8L: #dbdcdd;
$coc-secondary-9L: #ededee;
$coc-secondary-10L: #f6f6f6; // Lightest shade

// Secondary colour: darker shades
$coc-secondary-1D: #191919; // Darkest shade
$coc-secondary-2D: #333;
$coc-secondary-3D: #4c4c4c;
$coc-secondary-4D: #666;
$coc-secondary-5D: #7f7f7f;
$coc-secondary-6D: #999;
$coc-secondary-7D: #b2b2b2;
$coc-secondary-8D: #ccc;
$coc-secondary-9D: #e5e5e5;

// Secondary palette
$coc-secondary-red: #a6192e;
$coc-secondary-orange: #e57200;
$coc-secondary-yellow: #ffc600;
$coc-secondary-green: #4c8c2b;
$coc-secondary-blue: #0085ad;
$coc-secondary-dark-blue: #003865;
$coc-secondary-purple: #642f6c;
$coc-secondary-pink: #ac145a;

// Secondary palette: additional shades
$coc-secondary-orange-L: #ed8b00;
$coc-secondary-orange-D: #dc4405;
$coc-secondary-yellow-L: #fedb00;
$coc-secondary-yellow-D: #f2a900;
$coc-secondary-green-L: #78be20;
$coc-secondary-green-D: #44693d;
$coc-secondary-blue-L: #00a3e0;
$coc-secondary-blue-D: #005670;
$coc-secondary-dark-blue-L: #006298;
$coc-secondary-dark-blue-D: #041e42;
$coc-secondary-purple-L: #93328e;
$coc-secondary-purple-D: #3c1053;
$coc-secondary-pink-L: #ce0f69;
$coc-secondary-pink-D: #6c1d45;

// Safety and metallic colours
$coc-secondary-green-safety: #009639;
$coc-secondary-green-pedestrian: #c4d600;
$coc-secondary-blue-access: #0057b8;

// Map icon colours
$mp-bl: #1a84c6;
$mp-d-bl: #243e8b;
$mp-br: #552f25;
$mp-rd-br: #8a3f46;
$mp-gn: #00853e;
$mp-gld: #ba8e0c;
$mp-pk: #b92c92;
$mp-pu: #6c276a;
$mp-rd: #e82b30;
$mp-or: #f58220;
$mp-yl: #ffea00;

// Accessibility blue, used for accessibility icons
$access-blue: #005589;

// Social media and brand colours
// Brand colours last updated 2018.07.24
$twitter: #1da1f2;
$facebook: #4267b2;
$pinterest: #bd081c;
$youtube: #f00;
$linkedin: #0077b5;
$google-plus: #db4437;
$instagram: #000;
$vimeo: #1ab7ea;
$tumblr: #000;
$vine: #00b489;
$wordpress: #32373c;
$drupal: #2aa9e0;
$skype: #137ad4;
$rss: #f26522;
$evernote: #2dbe60;
$disqus: #2e9fff;

// -------------------------------------------------------
// Logo
//
// Logo dimensions
// -------------------------------------------------------

// COC logo dimensions for various breakpoints
// Pixel values are used as the logo does not need to scale up with the font-size
// if the browser's default font size is increased. (Related to PLPS-264)
$coc-logo-width: 58px !default;
$coc-logo-height: 74px !default;
$coc-logo-width-sm: 144px !default;
$coc-logo-height-sm: 68px !default;
$coc-logo-width-lg: 217px !default;
$coc-logo-height-lg: 103px !default;

// The followoing variables are intended to match the px values above in rem format.
$coc-logo-height-rem: $basic-space * 74rem !default;
$coc-logo-height-sm-rem: $basic-space * 68rem !default;

// -------------------------------------------------------
// trafic line color, took from the map
//
// -------------------------------------------------------

$bikewayAndSharedLine: #0095d3;
$bicycleLane: #0063a4;
$cycleTrack: #006fff;
$cycleTrackBorder: #002474;
$fishCreekPark: #de70ff;
$freeFlow: #07c607;
$regionalPathway: #e64d00;
$moderate: #ff2;
$stopAndGo: #b40707;
$slow: #ff8e07;
