$msv-dialog-width: 700px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;

.msc-add-to-cart {
    @include primary-button();
    @include vfi();

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }
}

.msc-modal {
    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-add-to-cart {
        &__dialog {
            max-width: $msv-dialog-width;
            width: 100%;
            .msc-modal__content {
                padding: 0;
            }

            &__header {
                background: $msv-gray-200;
                border-bottom: 0.0625rem solid $msv-gray-300;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-flex-shrink: 0;
                -moz-flex-shrink: 0;
                flex-shrink: 0;
                padding: 1rem 1rem;
                .msc-modal__title {
                    -webkit-align-self: center;
                    -ms-flex-item-align: center;
                    align-self: center;
                    line-height: 1.3;
                    margin-right: 90px;
                    margin-bottom: 0;
                    color: $msv-black;
                    font-weight: 600;
                }
            }

            &__body {
                @include font-content-l();
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                padding: 20px 120px 20px 20px;
                position: relative;
                .msc-empty_image {
                    @include image-placeholder($msv-dialog-msc-empty_image-size);
                    padding-bottom: $msv-dialog-modal-content-empty-image-padding-bottom;
                    position: absolute;
                    width: 80px;
                    height: 80px;
                    right: 20px;
                    top: 10px;
                }
                > picture {
                    position: absolute;
                    width: 80px;
                    height: 80px;
                    right: 20px;
                    top: 10px;
                }
            }

            &__image {
                margin-right: auto;
                margin-left: auto;
            }

            &__product-title {
                padding: 0;
                flex: 0 0 100%;
                text-align: left;
            }

            &__product-variants {
                @include font-content-m();
                padding: $msv-dialog-modal-content-body-text-padding;
            }

            &__product-price,
            &__product-unitOfMeasure {
                font-weight: var(--msv-font-weight-bold);
                padding: $msv-dialog-modal-content-body-text-padding;
                flex: 0 0 100%;
                text-align: left;
            }

            &__go-to-cart {
                @include button-primary();
            }

            &__back-to-shopping {
                @include button-utility();
                margin-left: 15px;
            }
            @media (max-width: $msv-breakpoint-m) {
                margin: 0 !important;
                &__body {
                    padding-right: 20px;
                }
                &__product-price,
                &__product-unitOfMeasure,
                &__product-title {
                    padding-right: 120px;
                }
                &__back-to-shopping,
                &__go-to-cart {
                    width: 100%;
                    margin-right: 0;
                }
                .msc-modal__content {
                    padding: 0 !important;
                }
            }
        }
    }
}
