/* -------------------------------------------------------
   Start of _card-slider
   ------------------------------------------------------- */
.swiper-container.cui {
	margin-right: auto;
	margin-left: auto;
	position: relative;
	overflow: hidden;
	list-style: none;
	z-index: $card-sider-z;
	width: 100%;
	height: 100%;

	.swiper-wrapper {
		position: relative;
		height: 100%;
		z-index: $card-sider-z;
		width: 100%;
		padding-bottom: $spacer-xxs;
		@include transition-property(transform);
		@include flexbox();
		@include flex-wrap (nowrap);
		-webkit-box-sizing: content-box;
		box-sizing: content-box;
		//padding-top: $basic-space * 16rem;
	}

	// 'reset' class has to be added with 'swiper-container'
	// Reset the slider layout to fit 'swiper-container' when no addition card
	&.reset {
		width: 100%;

		.swiper-wrapper {
			width: 100%;
			padding-top: 0;
		}

		&.swiper-slide-1 {
			padding-right: 0;
		}

		&.swiper-slide-2 {
			padding-right: $spacer-sm;
		}

		&.swiper-slide-3 {
			padding-right: $spacer-lg;
		}

		&.swiper-slide-4 {
			padding-right: $spacer-xl;
		}

		//hide the pagination and the arrow when not active
		.swiper-pagination,
		.swiper-button-next,
		.swiper-button-prev {
			display: none;
		}
	}

	.swiper-slide {
		position: relative;
		font-size: $base-font-size;
		background: $white;
		@include flexbox;
		@include justify-content (center);
		flex-shrink: 0;
		transition-property: transform;
	}

	// pagination
	// &.swiper-container-horizontal > .swiper-pagination-bullets {
	// 	position: relative;
	// 	width: calc( 100% + #{$slide-spacing} );
	// 	bottom: 0;
	// 	margin: 0 $tiny-spacer-xs;
	// }

	&.swiper-container-horizontal > .swiper-pagination-progressbar {
		width: $tiny-spacer-xs;
		height: 100%;
		left: 0;
		top: 0;
	}

	.swiper-pagination {
		text-align: center;
		transition: opacity .3s;
		transform: translateZ( 0 );
		z-index: 10;

		// .swiper-pagination-bullet {
		// 	cursor: pointer;
		// 	width: $spacer-xxs;
		// 	height: $spacer-xxs;
		// 	display: inline-block;
		// 	border-radius: 100%;
		// 	background-color: $coc-secondary-7L;
		// 	margin-right: $tiny-spacer-sm;
		// }

		// .swiper-pagination-bullet-active {
		// 	background-color: $coc-secondary;

		// 	&:active,
		// 	&:focus {
		// 		@include setOutlineGlow( true );
		// 	}
		// }

		&.swiper-pagination-progressbar {
			background: rgba( 0, 0, 0, .25 );
			position: relative;
			width: 100%;
			height: $tiny-spacer-xs;
			left: 0;
			top: 0;

			.swiper-pagination-progressbar-fill {
				background: $coc-secondary-2L;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				transform: scale( 0 );
				transform-origin: left top;
			}
		}
	}

	//
	.swiper-notification {
		position: absolute;
		left: 0;
		top: 0;
		pointer-events: none;
		opacity: 0;
		z-index: -1000;
	}

	// Additional style fix
	.card.cui {
		margin-bottom: 0;
		box-shadow: none;

		.card-media {
			width: 100%;
		}
	}

	a.card.cui,
	button.card.cui {
		@include setInteractiveBoxShadow(true);

		&:hover {
			cursor: pointer;
		}
	}

	button {
		text-align: left;
	}

	// slider prev and next button
	.swiper-button-container {
		width: 100%;
		text-align: right;
		margin-bottom: $basic-space * 16rem;

		[class*='swiper-button-'] {
			margin-top: auto;
			margin-left: $tiny-spacer-sm;

			&::after {
				font-family: $font-icon;
				font-size: $cicon-size-md;
				line-height: $line-height-default;
			}
		}

		.swiper-button-next::after {
			content: "\e15a";
		}

		.swiper-button-prev::after {
			content: "\e159";
		}

		.swiper-button-next.swiper-button-disabled,
		.swiper-button-prev.swiper-button-disabled {
			opacity: .35;
			cursor: auto;
			pointer-events: none;
		}
	}

	// Custom layout on desktop view
	// 992
	@media( min-width: $breakpoint-lg ) {
		.swiper-wrapper {
			//padding-bottom: $spacer-md;
		}

		// slider view
		// The custom class has to be added with the class swiper-container
		// choose 1 card display on the desktop
		// 1 and 1/4 cards show on the desktop view
		&.swiper-slide-1 .swiper-slide {
			-webkit-box-flex: 0 0 80%;
			-webkit-flex: 0 0 80%;
			-moz-flex: 0 0 80%;
			-ms-flex: 0 0 80%;
			flex: 0 0 80%;
			max-width: 80%;
			height: auto !important;
		}

		// choose 2 card display on the desktop
		// 2 and 1/4 cards show on the desktop view
		&.swiper-slide-2 .swiper-slide {
			-webkit-box-flex: 0 0 45%;
			-webkit-flex: 0 0 45%;
			-moz-flex: 0 0 45%;
			-ms-flex: 0 0 45%;
			flex: 0 0 45%;
			max-width: 45%;
		}

		// choose 3 card display on the desktop
		// 3 and 1/4 cards show on the desktop view
		&.swiper-slide-3 .swiper-slide {
			-webkit-box-flex: 0 0 30%;
			-webkit-flex: 0 0 30%;
			-moz-flex: 0 0 30%;
			-ms-flex: 0 0 30%;
			flex: 0 0 30%;
			max-width: 30%;
		}

		// choose 4 card display on the desktop
		// 4 and 1/4 cards show on the desktop view
		&.swiper-slide-4 .swiper-slide {
			-webkit-box-flex: 0 0 22%;
			-webkit-flex: 0 0 22%;
			-moz-flex: 0 0 22%;
			-ms-flex: 0 0 22%;
			flex: 0 0 22%;
			max-width: 22%;
		}

		// 'reset' class has to be added with 'swiper-container'
		&.reset {
			&.swiper-slide-1 .swiper-slide {
				$col-size: 12;
				@include calcColWidth($col-size);
			}

			&.swiper-slide-2 .swiper-slide {
				$col-size: 6;
				@include calcColWidth($col-size);
			}

			&.swiper-slide-3 .swiper-slide {
				$col-size: 4;
				@include calcColWidth($col-size);
			}

			&.swiper-slide-4 .swiper-slide {
				$col-size: 3;
				@include calcColWidth($col-size);
			}
		}
	}

	@media( max-width: $breakpoint-md-max ) {
		// Any layout if the breakpoint < 992,
		//only one full card will be displayed on the screen
		&.swiper-slide-1.reset .swiper-slide {
			$col-size: 12;
			@include calcColWidth($col-size);
		}

		&.swiper-slide-1 .swiper-slide,
		&.swiper-slide-2 .swiper-slide,
		&.swiper-slide-3 .swiper-slide,
		&.swiper-slide-4 .swiper-slide,
		&.swiper-slide-5 .swiper-slide {
			$col-size: 8;
			@include calcColWidth($col-size);
		}
	}
}
