/* -------------------------------------------------------
   Start of _cicon
   ------------------------------------------------------- */

[class^="cicon-"],
[class*=" cicon-"] {
	// Default size
	font-size: $cicon-size-sm;
}

.cicon-lg {
	font-size: $cicon-size-lg;
}

a.cui.icon-link-text {
	text-decoration: none;

	.left {
		margin-right: $tiny-spacer-sm;
	}

	.right {
		margin-left: $tiny-spacer-sm;
	}

	.link-text {
		text-decoration: underline;
	}

	&:hover {
		.link-text {
			text-decoration: none;
		}
	}
}

// use on the old site
// Code can be clean up once the program service icons class name updated to cicon-
.program-service-animal-service-icon::before {
	content: "\e0ce"; //"\e2ed"
}

.program-service-parks-recreation-icon::before {
	content: "\e2ef";
}

.program-service-building-planning-icon::before {
	content: "\e2f1";
}

.program-service-transportation-icon::before {
	content: "\e0fa"; //"\e2f3"
}

.program-service-environment-icon::before {
	content: "\e2f5";
}

.program-service-bylaws-publicSafety-icon::before {
	content: "\e2f7";
}

.program-service-taxes-propertyAssessment-icon::before {
	content: "\e2f9";
}

.program-service-social-services-icon::before {
	content: "\e2fb";
}

.program-service-cleaning-services-icon::before {
	content: "\e2fd";
}

.program-service-beauty-body-services-icon::before {
	content: "\e2ff";
}

.program-service-production-research-icon::before {
	content: "\e301";
}

.program-service-massage-services-icon::before {
	content: "\e303";
}

.program-service-food-trucks-icon::before {
	content: "\e305";
}

.program-service-yoga-pilates-circle-icon::before {
	content: "\e311";
}

.program-service-swimming-circle-icon::before {
	content: "\e313";
}

.program-service-sports-icon::before {
	content: "\e315";
}

.program-service-skating-hockey-icon::before {
	content: "\e317";
}

.program-service-skateboarding-icon::before {
	content: "\e319";
}

.program-service-sailing-icon::before {
	content: "\e31b";
}

.program-service-playschool-icon::before {
	content: "\e31d";
}

.program-service-nature-icon::before {
	content: "\e31f";
}

.program-service-martial-arts-icon::before {
	content: "\e321";
}

.program-service-golf-icon::before {
	content: "\e323";
}

.program-service-fitness-strenght-icon::before {
	content: "\e325";
}

.program-service-fitness-pre-postnatal-icon::before {
	content: "\e327";
}

.program-service-certifications-leadership-icon::before {
	content: "\e32b";
}

.program-service-arts-visual-icon::before {
	content: "\e32d";
}

.program-service-arts-dance-icon::before {
	content: "\e32f";
}

.historic-city-hall::before {
	content: "\e368";
}

.digital-thermostat::before {
	content: "\e369";
}

.infill-construction::before {
	content: "\e36b";
}

.natural-gas-burner::before {
	content: "\e36d";
}

.natural-gas-flame::before {
	content: "\e36f";
}

.overhead-electrical-services-a::before {
	content: "\e371";
}

.overhead-electrical-services-b::before {
	content: "\e373";
}

.plumbing-faucet::before {
	content: "\e375";
}

.plumbing-gas::before {
	content: "\e377";
}

.water-meter::before {
	content: "\e379";
}

.solar-panel::before {
	content: "\e37b";
}

.call-center-person::before {
	content: "\e37d";
}

.computer-desktop::before {
	content: "\e37f";
}

.user::before {
	content: "\e027";
}

.people-in-group::before {
	content: "\e387";
}

.shield::before {
	content: "\e0b4";
}

// Below icon size only used on program service icons
// can be cleanup once the icon class updated to the cicon-
.cicon-sm {
	font-size: $cicon-size-sm; //16px solid icon
	width: $cicon-size-sm;
	height: $cicon-size-sm;
}

// Responsive Icons
// The outline version decided to removed from icon font
.cicon-responsive {
	font-size: $cicon-size-sm; //16px solid icon

	@media( min-width: $breakpoint-md ) {
		font-size: $cicon-size-lg; //32px solid icon
	}
}// End of .cicon-responsive

// Accessibility Icons responsive list
.access-icon {
	color: $access-blue !important;// Accessibility icons should be always blue. Do not change the colour value.

	&.cicon-responsive {
		font-size: $cicon-size-lg !important;
	}
}

// --------------------------------------
// Additional CSS for function and theme
// /icons/icons-usage.php#map-icons
// included: map icons, social media icons ...;
// --------------------------------------
.icon-stack {
	position: relative;
	display: inline-block;
	vertical-align: middle;

	[class*="cicon-"] {
		position: absolute;
		left: 0;
		width: 100%;
		text-align: center;
	}
}

// Icon colours
.cl {
	// Social media colours
	&[class*="cicon-twitter"] {
		color: $twitter;
	}

	&[class*="cicon-facebook"] {
		color: $facebook;
	}

	&[class*="cicon-pinterest"] {
		color: $pinterest;
	}

	&[class*="cicon-youtube"] {
		color: $youtube;
	}

	&[class*="cicon-linkedin"] {
		color: $linkedin;
	}

	&[class*="cicon-google-plus"] {
		color: $google-plus;
	}

	&[class*="cicon-instagram"] {
		color: $instagram;
	}

	&[class*="cicon-vimeo"] {
		color: $vimeo;
	}

	&[class*="cicon-rss"] {
		color: $rss;
	}

	&[class*="cicon-tumblr"] {
		color: $tumblr;
	}

	&[class*="cicon-evernote"] {
		color: $evernote;
	}

	&[class*="cicon-wordpress"] {
		color: $wordpress;
	}

	&[class*="cicon-drupal"] {
		color: $drupal;
	}

	&[class*="cicon-vine"] {
		color: $vine;
	}

	&[class*="cicon-skype"] {
		color: $skype;
	}

	&[class*="cicon-disqus"] {
		color: $disqus;
	}

	&.map-border {
		.cicon-map-pointer-square {
			color: $white;
		}

		&.cicon-cemetery,
		&.cicon-lot-dimension,
		.cicon-construction-detours {
			color: $black;
		}

		&.cicon-calendar-event.health,
		&.cicon-school.public,
		&.cicon-ems-station,
		&.cicon-indoor-pool,
		&.cicon-outdoor-pool,
		&.cicon-traffic-camera.traffic {
			color: $mp-bl;
		}

		&.cicon-electronics-recycling,
		&.cicon-al-p.park-ride-lot {
			color: $mp-d-bl;
		}

		&.cicon-calendar-event.home-business,
		&.cicon-offleash-dog {
			color: $mp-br;
		}

		&.cicon-development-permit,
		&.cicon-building-permit,
		&.cicon-development-permit,
		&.cicon-dp-public-notices,
		&.cicon-loc,
		&.cicon-historic-resource,
		&.cicon-secondary-suite {
			color: $mp-rd-br;
		}

		&.cicon-calendar-event.environment,
		&.cicon-school.separate,
		&.cicon-al-a.athletic-park,
		&.cicon-city-park,
		&.cicon-golf-course,
		&.cicon-skate-park {
			color: $mp-gn;
		}

		&.cicon-calendar-event.education,
		&.cicon-post-secondary {
			color: $mp-gld;
		}

		&.cicon-calendar-event.art {
			color: $mp-pk;
		}

		&.cicon-calendar-event.recreation {
			color: $mp-pu;
		}

		&.cicon-calendar-event.important,
		&.cicon-school.private {
			color: $mp-rd;
		}

		&.cicon-calendar-event.engagement,
		.cicon-construction-detours-base {
			color: $mp-or;
		}

		&.cicon-incidents {
			color: $black;
		}

		&.cicon-calendar-event.festival {
			color: $mp-yl;
		}

		.cicon-civic-partners-development,
		.cicon-casg,
		.cicon-community-safety,
		.cicon-new-central-library,
		.cicon-park-development,
		.cicon-recreation-development {
			color: #00acc8;
		}

		&.cicon-ward-line,
		&.cicon-ward-line-s {
			color: #b25932;
		}

		&.cicon-map-line-thin.quadrant {
			color: #fab383;
		}

		&.cicon-community-line,
		&.cicon-community-line-s {
			color: #c7bfa3;
		}

		&.cicon-map-line.police-district {
			color: #006bb6;
		}

		&.cicon-map-line.police-zone {
			color: #4e8845;
		}

		&.cicon-police-service {
			color: #21497b;
		}

		&.cicon-fire-stn {
			color: #ee2e24;
		}

		&.cicon-traffic-camera.safety {
			color: #c20430;
		}

		&.cicon-lot-easement {
			color: #58585a;
		}

		.cicon-bus-stop {
			color: #085b85;
		}

		.cicon-circle-l-x-thin.city-parking {
			color: #fdba31;
		}

		.cicon-circle-l.city-parking {
			color: #007f7b;
		}

		.cicon-al-p.city-parking {
			color: #ceebec;
		}

		&.cicon-map-line-thin.bus-route {
			color: #2e3192;
		}

		&.cicon-map-line-thin.sweep-route-completed,
		&.cicon-map-dot-line.sweep-route-scheduled {
			color: #8c6339;
		}

		&.cicon-map-line.sweep-parking-ban {
			color: #782056;
		}

		&.cicon-map-line.snow-route-parking-ban {
			color: #f58020;
		}

		&.cicon-map-line.snow-route-temp-no-parking {
			color: #ed0875;
		}

		&.cicon-map-line.snic-route-priority1 {
			color: #d51f3c;
		}

		&.cicon-map-line.snic-route-priority2 {
			color: #4c266f;
		}

		&.cicon-map-line.snic-snow-route-exemption {
			color: #6abd46;
		}

		&.cicon-residential-parking-polygon {
			color: #7e3092;
		}

		&.cicon-brt-stop {
			color: #db2032;
		}

		&.cicon-bikeway-line {
			color: #606c7d;
		}

		.cicon-ctrain-lines-base {
			color: #f15f44;
		}

		.cicon-ctrain-lines {
			color: #251a06;
		}
	}// End of .map-border

	&.map-solid {
		[class^="cicon-"] {
			color: $white;
		}

		.cicon-construction {
			color: #000;
		}

		&.cicon-library,
		&.cicon-health-clinic,
		&.cicon-al-h.hospital {
			background-color: $mp-bl;
		}

		&.waste-recycling {
			background-color: $mp-d-bl;
		}

		&.cicon-community-center,
		&.cicon-social-dev-centre,
		&.cicon-court {
			background-color: $mp-rd-br;
		}

		&.cicon-arena,
		&.cicon-art-centre,
		&.cicon-leisure-centre,
		&.cicon-after-school-program {
			background-color: $mp-pk;
		}

		&.cicon-atraction,
		&.cicon-visitor-info {
			background-color: $mp-pu;
		}

		.cicon-construction-base {
			color: $mp-or;
		}

		&.cicon-civic-partners-development,
		&.cicon-casg,
		&.cicon-community-safety,
		&.cicon-new-central-library,
		&.cicon-park-development,
		&.cicon-recreation-development {
			background-color: #00acc8;
		}

		&.cicon-ctrain-stn,
		&.cicon-bus-stop {
			background-color: #085b85;
		}
	}// End of .map-solid
}// End of .cl

.mapicon {
	.cicon-minus-thin-border.cycle-lane-border {
		color: $cycleTrackBorder;
	}

	.cicon-minus-thin.cycle-lane {
		color: $cycleTrack;
	}

	.cicon-minus-thin.cycle-lane,
	.cicon-minus-thin-border.cycle-lane-border {
		font-size: 100% !important;
		padding: 0 !important;
	}

	.cicon-map-square,
	.cicon-map-pointer-square,
	.cicon-gl-station,
	.cicon-gl-stn-underground,
	.cicon-bike-closure,
	.cicon-park-ride {
		color: $white;

		&.black {
			color: $black;
		}
	}

	.cicon-map-square-o,
	.cicon-map-pointer-square-o,
	.cicon-cemetery,
	.cicon-lot-dimension,
	.cicon-construction-detours,
	.cicon-construction {
		color: $black;
	}

	.cicon-calendar-event.health,
	.cicon-school.public,
	.cicon-ems-station,
	.cicon-indoor-pool,
	.cicon-outdoor-pool,
	.cicon-traffic-camera.traffic {
		color: $mp-bl;
	}

	.cicon-electronics-recycling,
	.cicon-al-p.park-ride-lot {
		color: $mp-d-bl;
	}

	.cicon-calendar-event.home-business,
	.cicon-offleash-dog {
		color: $mp-br;
	}

	.cicon-development-permit,
	.cicon-building-permit,
	.cicon-development-permit,
	.cicon-dp-public-notices,
	.cicon-loc,
	.cicon-historic-resource,
	.cicon-secondary-suite {
		color: $mp-rd-br;
	}

	.cicon-calendar-event.environment,
	.cicon-school.separate,
	.cicon-al-a.athletic-park,
	.cicon-city-park,
	.cicon-golf-course,
	.cicon-skate-park {
		color: $mp-gn;
	}

	.cicon-calendar-event.education,
	.cicon-post-secondary {
		color: $mp-gld;
	}

	.cicon-calendar-event.art {
		color: $mp-pk;
	}

	.cicon-calendar-event.recreation {
		color: $mp-pu;
	}

	.cicon-calendar-event.important,
	.cicon-school.private {
		color: $mp-rd;
	}

	.cicon-calendar-event.engagement,
	.cicon-construction-detours-base,
	.cicon-construction-base {
		color: $mp-or;
	}

	.cicon-incidents {
		color: $black;
	}

	.cicon-calendar-event.festival {
		color: $mp-yl;
	}

	.cicon-civic-partners-development,
	.cicon-casg,
	.cicon-community-safety,
	.cicon-new-central-library,
	.cicon-park-development,
	.cicon-recreation-development {
		color: #00acc8;
	}

	.cicon-ward-line,
	.cicon-ward-line-s {
		color: #b25932;
	}

	.cicon-map-line-thin.quadrant {
		color: #fab383;
	}

	.cicon-community-line,
	.cicon-community-line-s {
		color: #c7bfa3;
	}

	.cicon-map-line.police-district {
		color: #006bb6;
	}

	.cicon-map-line.police-zone {
		color: #4e8845;
	}

	.cicon-police-service {
		color: #21497b;
	}

	.cicon-fire-stn {
		color: #ee2e24;
	}

	.cicon-traffic-camera.safety {
		color: #c20430;
	}

	.cicon-lot-easement {
		color: #58585a;
	}

	.cicon-bus-stop {
		color: #085b85;
	}

	.cicon-circle-l-x-thin.city-parking {
		color: #fdba31;
	}

	.cicon-circle-l.city-parking {
		color: #007f7b;
	}

	.cicon-al-p.city-parking {
		color: #ceebec;
	}

	.cicon-map-line-thin.bus-route {
		color: #2e3192;
	}

	.cicon-map-line-thin.sweep-route-completed,
	.cicon-map-dot-line.sweep-route-scheduled {
		color: #8c6339;
	}

	.cicon-map-line.sweep-parking-ban {
		color: #782056;
	}

	.cicon-map-line.snow-route-parking-ban {
		color: #f58020;
	}

	.cicon-map-line.snow-route-temp-no-parking {
		color: #ed0875;
	}

	.cicon-map-line.snic-route-priority1 {
		color: #d51f3c;
	}

	.cicon-map-line.snic-route-priority2 {
		color: #4c266f;
	}

	.cicon-map-line.snic-snow-route-exemption {
		color: #6abd46;
	}

	.cicon-residential-parking-polygon {
		color: #7e3092;
	}

	.cicon-brt-stop {
		color: #db2032;
	}

	.cicon-bikeway-line {
		color: #606c7d;
	}

	.cicon-ctrain-lines-base {
		color: #f15f44;
	}

	.cicon-ctrain-lines {
		color: #251a06;
	}

	.cicon-circle.coc-secondary-green,
	.cicon-circle.coc-secondary-2L,
	.cicon-circle.coc-secondary-yellow-D,
	.cicon-circle.coc-secondary-3L {
		font-size: 100% !important;
		padding: 0 !important;
	}

	.cicon-gl-station,
	.cicon-gl-stn-underground {
		font-size: 55% !important;
		padding: 25% 0 !important;
	}

	.cicon-bike-closure {
		font-size: 55% !important;
		padding: 25% 0 !important;
	}

	&.cicon-lg {
		.cicon-school {
			font-size: 93% !important;
			top: -3px;

			i {
				display: block;
				color: white;
				position: absolute;
				z-index: 1;
				top: 19px;
				font-size: 9px;
			}

			i:last-child {
				left: 4.5px;
			}

			i:first-child {
				left: -4.5px;
			}
		}
	}

	.cicon-school {
		font-size: 83% !important;

		i {
			display: none;

			/*	color: white;
   			position: absolute;
			z-index: 1;
    		top: 8px;
    		font-size: 5px;
		}

		i:last-child {
    		left: 2.5px;
		}
		i:first-child {
   			left: -2.5px; */
		}
	}

	&.cl-ba {
		[class^="cicon-"] {
			color: $white;
		}

		.library,
		.health-clinic,
		.hospital {
			color: $mp-bl;
		}

		.waste-recycling {
			color: $mp-d-bl;
		}

		.community-center,
		.social-dev-centre,
		.court {
			color: $mp-rd-br;
		}

		.arena,
		.art-centre,
		.leisure-centre,
		.after-school-program {
			color: $mp-pk;
		}

		.atraction,
		.visitor-info {
			color: $mp-pu;
		}

		.comm-investment {
			color: #00acc8;
		}

		.ctrain-stn,
		.bus-stop {
			color: #085b85;
		}

		.plow-sander {
			color: $mp-or;
		}
	}

	&.map-border {
		[class^="cicon-"] {
			background: $white;
		}
	}
}// End of .mapicon

.cicon-stack {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	// MC.2018.05.11: The width and height need to be applied here for proper rendering
	// Previously, the width/height came from cicon-sm and cicon-lg; however they have been removed
	// in favour of more vanilla code
	width: $cicon-size-sm;
	height: $cicon-size-sm;

	&.cicon-lg {
		font-size: $cicon-size-lg;
		width: $cicon-size-lg;
		height: $cicon-size-lg;
	}

	[class*="cicon-"] {
		position: absolute;
		left: 0;
		width: 100%;
		text-align: center;
	}

	&.mapicon {
		> [class^="cicon-"] {
			font-size: 75%;
			padding: 12.5% 0 12.5% 0;
		}

		.cicon-map-square,
		.cicon-map-square-o,
		[class*="-line"],
		.cicon-lot-easement,
		.cicon-construction-base,
		.cicon-construction {
			font-size: 100%;
			padding: 0;
		}

		.cicon-map-pointer-square,
		.cicon-map-pointer-square-o {
			font-size: 126%;
			padding: 0;
			margin-left: -13%;
		}

		.cicon-circle-l.city-parking {
			font-size: 62%;
			padding: 19% 0;
		}

		.cicon-al-p.city-parking {
			font-size: 46%;
			padding: 27% 0;
		}
	}
}// End of .cicon-stack

.cl.map-border[class^="cicon-"],
.cl.map-solid[class^="cicon-"] {
	position: relative;
	border-radius: .15em;
	width: 1em;
	height: 1em;
	line-height: .8em;
	text-align: center;
	display: inline-block;

	&::before {
		font-size: .8em;
		margin: 0 auto;
	}

	&[class*="-line"],
	[class*="-lines"] {
		line-height: .88em;
	}

	&[class*="-line"]::before,
	[class*="-lines"]::before {
		font-size: .94em;
	}
}

.map-border[class^="cicon-"] {
	border: .04em solid black;

	&.small {
		border: .08em solid black;
		line-height: .75em;

		&::before {
			font-size: .75em;
		}
	}
}
