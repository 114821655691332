/* -------------------------------------------------------
   Start of _storytelling-footer
   ------------------------------------------------------- */

.cui.story-footer {
	background: $bg-medium;
	border: $border-thin solid $border-color-medium;
	margin-top: $spacer-lg;
	margin-bottom: $spacer-lg;
	padding: $spacer-sm;

	[class*="btn"] {
		vertical-align: middle;
		padding: $btn-sm-padding;
		border-width: $btn-border-thin;
	}

	.text-label {
		display: block;
		font-weight: $font-weight-semi-bold;
		margin-top: $tiny-spacer-sm;
		margin-bottom: $spacer-sm;
	}

	.col-next {
		text-align: right;
	}

	@media( min-width: $breakpoint-sm ) {
		padding: $spacer-md;
	}

	@media( min-width: $breakpoint-md ) {
		[class*="btn"] {
			padding: $btn-md-padding;
			border-width: $btn-border-thick;
		}

		.text-label {
			margin-bottom: 0;
		}
	}

	@media( min-width: $breakpoint-xl ) {
		.text-label {
			display: inline-block;
			margin-top: 0;
		}

		.col-next,
		.col-prev {
			@include align-items(center);
			@include flexbox();
			@include flex-direction (row);
		}

		.col-prev {
			[class*="btn"] {
				margin-right: $spacer-xs;
			}
		}

		.col-next {
			@include justify-content(flex-end);
			text-align: right;

			.text-label {
				order: 1; // Move the text label so it displays first
			}

			[class*="btn"] {
				margin-left: $spacer-xs;
				order: 2; // Move the button so it displays last
			}
		}
	}
}

// End of .cui.story-footer
