/* -------------------------------------------------------
   Start of _ search-image-block-container (_emotive-container small)
   ------------------------------------------------------- */

.search-image-block-container {
	@include flexbox;
	@include align-items(flex-end);
	background-size: cover;
	background-repeat: no-repeat;
	min-height: $search-image-block-container-height-sm;

	.comp-body {
		$col-size: 12;
		@include calcColWidth( $col-size );
	}

	.key-message-container {
		margin-top: $search-image-block-container-title-padding;
		margin-bottom: $search-image-block-container-title-margin-lg;
		padding-left: $search-image-block-container-title-padding-sm;
		padding-right: $search-image-block-container-title-padding-sm;

		&.center-left {
			text-align: left;
		}

		&.center-right {
			text-align: right;
		}

		p {
			display: inline;
			background: transparent;
			line-height: $line-height-default;
			font-size: $search-image-block-container-title-font-size-sm;
			font-weight: $font-weight-bold;

			&.bg-red {
				box-shadow: $search-image-block-container-title-padding-sm 0 0 $coc-link, -$search-image-block-container-title-padding-sm 0 0 $coc-link;
				background-color: $coc-link;
				color: $white;
				@include box-decoration-break(clone);

				a:not([class*="btn-"]) {
					color: $white;
				}
			}
		}
	}

	.search-container {
		margin-bottom: $spacer-lg; //36px
		margin-left: 5%;
		margin-right: 5%;

		.cui.search-box,
		.toggled-search {
			max-width: none;
		}

		.toggled-search {
			width: 100%;
		}
	}

	@media( min-width: $breakpoint-sm ) {
		.key-message-container {
			margin-bottom: $search-image-block-container-title-margin-md;

			p.bg-red {
				font-size: $search-image-block-container-title-font-size-md;
			}
		}

		.search-container {
			margin-left: 15%;
			margin-right: 15%;
		}
	}

	//768px
	@media( min-width: $breakpoint-md ) {
		min-height: $search-image-block-container-height-md;

		.key-message-container {
			margin-bottom: $search-image-block-container-title-margin-lg;
		}

		.search-container {
			margin-left: 25%;
			margin-right: 25%;
		}
	}

	//992px
	@media( min-width: $breakpoint-lg ) {
		.key-message-container p.bg-red {
			font-size: $search-image-block-container-title-font-size-lg;
		}

		.search-container {
			margin-left: 30%;
			margin-right: 30%;
			margin-bottom: $spacer-xl;
		}
	}

	//1200px
	@media( min-width: $breakpoint-xl ) {
		.search-container {
			margin-bottom: $spacer-xxl;
		}
	}
}

// -------------------------------------------------------
// Print
//
// -------------------------------------------------------
@media print {
	.search-image-block-container {
		display: none;
	}
}
