.modal-open {
  top: 0px;
  right: 17px;
  left: 0px;
  position: static !important;
}

.b2bsignin {
  .msc-modal.fade.show {
    display: none !important;
  }
  .msc-modal__backdrop {
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1040;
    width: 0;
  }
  .signin-container .ms-text-block,
  .ourBuisnessPartner .ms-text-block,
  .otherBuisnessPartner .ms-text-block {
    color: $msv-gray-900;
    font-size: $msv-font-size-xl;
    font-style: $msv-font-weight-normal;
    font-weight: $msv-font-weight-400;
    line-height: $msv-line-height-xl;
    letter-spacing: 0em;
  }
 .signin-container .ms-text-block {
    text-align: left;
    padding-left: 5px;
    margin-left: 60px;
    margin-bottom: 22px;
  }
  .ourBuisnessPartner .ms-text-block {
    text-align: center;
    margin-top: 70px;
  }
  .otherBuisnessPartner .ms-text-block {
    letter-spacing: 0em;
    text-align: center;
  }

  .b2bsignin-hero-banner {
    margin-left: 60px;
    margin-right: 60px;
    .ms-content-block__details {
      background-color: $msv-black;
      width: 100%;
      opacity: 0.7;
      height: 70%;
      display: flex;
      justify-content: center;
      margin-top: 70px;
      .ms-content-block__title {
        font-size: $msv-font-size-28;
        font-style: $msv-font-weight-normal;
        font-weight: $msv-font-weight-700;
        line-height: $msv-line-height-37;
        letter-spacing: 0em;
        text-align: left;
        color: $msv-white;
      }
      .ms-content-block__text {
        font-size: $msv-font-size-l;
        font-style: $msv-font-weight-normal;
        font-weight: $msv-font-weight-heavy;
        line-height: $msv-line-height-l;
        color: $msv-white;
        letter-spacing: 0em;
        text-align: left;
      }
      .ms-content-block__additionaltext {
        font-weight: $msv-font-weight-400;
        line-height: $msv-line-height-23;
        color: $msv-white;
        font-size: $msv-font-size-l;
        margin-top: 12px;
        margin-left: 3px;
        font-style: $msv-font-weight-normal;
        letter-spacing: 0em;
        a {
          color: $msv-white;
          font-size: $msv-font-size-l;
          font-weight: $msv-font-weight-400;
          line-height: $msv-line-height-l;
          letter-spacing: 0em;
          font-style: $msv-font-weight-normal;
          margin-top: 0px;
          text-decoration: underline;
        }
      }
    }
  }
  .signin-container {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;

    .ms-sign-in {
      width: 540px;
      bottom: 50px;
      position: absolute;
      right: 60px;
      background-color: $msv-white;
      border-radius: 5px;
      margin-right: 49px;
      &__container {
        display: flex;
        margin: 18px 72px;
        width: 490px;
        margin-bottom: 0px;
        margin-left: 88px;
        .ms-sign-in__sign-in-section {
          margin-right: 130px;
          margin-top: 3px;
          .ms-sign-in__sign-in-heading {
            color: $msv-gray-900;
            font-size: $msv-font-size-32;
            font-style: $msv-font-weight-normal;
            font-weight: $msv-font-weight-heavy;
            line-height: $msv-line-height-42;
            letter-spacing: 0em;
            text-align: left;
          }
          .ms-sign-in__account-item-input {
            font-size: $msv-font-size-l;
            font-style: $msv-font-weight-normal;
            font-weight: $msv-font-weight-400;
            line-height: $msv-line-height-l;
            letter-spacing: 0em;
            text-align: left;
            color: $msv-gray-900;
            border: 1px solid $msv-gray-300;
            background: $msv-white;
          }
          .ms-sign-in__forget-password {
            font-size: $msv-font-size-m;
            font-style: $msv-font-weight-normal;
            font-weight: $msv-font-weight-light;
            line-height: $msv-line-height-m;
            letter-spacing: 0em;
            text-align: left;
            color: $msv-gray-900;
          }
          .ms-sign-in__sign-in-disclaimer {
            font-size: $msv-font-size-s;
            font-style: $msv-font-weight-normal;
            font-weight: $msv-font-weight-400;
            line-height: $msv-line-height-s;
            letter-spacing: 0em;
            text-align: left;
            color: $msv-gray-500;
            margin-top: 10px;
          }
        }
      }
    }
  }
  .adventurework-container {
    .ms-content-block {
      &__image {
        img {
          margin: auto;
        }
      }

      &__text {
        font-size: $msv-font-size-32;
        font-style: $msv-font-weight-normal;
        font-weight: $msv-font-weight-400;
        line-height: $msv-line-height-42;
        letter-spacing: 0em;
        text-align: center;
        display: flex;
        .left-doublequotes,
        .right-doublequotes {
          font-size: $msv-line-height-126;
          line-height: $msv-line-height-126;
          color: $msv-primary;
          opacity: 0.6;
          margin-left: 165px;
        }
        .left-doublequotes {
          margin-right: 40px;
        }
        .right-doublequotes {
          margin-left: 23px;
          margin-right: 152px;
        }
      }
      &__cta {
        text-align: center;
        line-height: 42px;
        padding-top: 15px;
        margin-bottom: 109px;
        a:nth-child(1) {
          text-decoration: none;
          font-size: $msv-font-size-s;
          color: $msv-gray-500;
          display: inline-block;
          border-right: 1px solid $msv-gray-300;
          padding-right: 15px;
        }
        a:nth-child(2) {
          text-decoration: none;
          font-size: $msv-font-size-s;
          color: $msv-gray-500;
          padding-left: 15px;
        }
      }
    }
  }
}
