/* -------------------------------------------------------
Start of _map
------------------------------------------------------- */
.map-container,
.esri-embed-container {
	border: $border-thin solid $border-color-light;
}

.map-data {
	overflow-y: scroll;
	height: $map-height;
	line-height: $line-height-default;
}

.map-data-button {
	margin: $map-space-sm;
}

.map-data-all div {
	margin: $map-space-sm;
	padding: $map-space-sm;
	border: $border-thin solid $border-color-light;

	&:focus {
		background-color: $coc-secondary-9L;
	}
}

.map {
	padding: 0;
	margin: 0;
	height: $map-height;
}

.map-custom-map-point-instructions {
	display: none;
	border: $map-border solid $black;
	padding: $map-space-xxs;
}

a.map-skip {
	@include setSROnly;
	z-index: $map-skip-z;
	word-wrap: normal;
}

a:active.map-skip,
a:focus.map-skip {
	position: relative;
	top: $map-skip-position;
	width: auto;
	height: auto;
	background-color: white;
	border-color: black;
	border-width: $border-thin;
	border-style: solid;
	color: black;
	padding: $map-skip-position;
	z-index: $map-skip-z-f;
}

@media only screen and ( max-width: $breakpoint-xs-max ) {
	.map {
		height: $map-height-md;
	}

	.map-data {
		height: $map-height-data;
	}
}

.esri-widget--button {
	height: $map-esri-btn;
	width: $map-esri-btn;
}


/*
position being set to absolute makes the map not visible in IE 11
setting to relative fixes this
*/
.esri-view-surface {
	position: relative;
}

.esri-embed-container object,
.esri-embed-container iframe {
	width: 100%;
	height: 100%;
}

.esri-embed-map .esri-embed-container small {
	display: block;
	text-align: center;
}

.esri-embed-map small a {
	color: $coc-link !important;
	font-size: $base-font-size;
	text-align: center;
	display: inline-block;

	//991px
	@media( max-width: $breakpoint-md-max ) {
		padding: $spacer-xxs;
	}
}

.google-map-custom-controls {
	position: relative !important;
	left: $basic-space*8rem !important;
	top: $basic-space*75rem !important;
	width: $basic-space*32rem;
}

.google-map-custom-controls div {
	margin-bottom: $basic-space*2rem;
	background-color: $coc-default-background;
	width: $map-esri-btn;
	height: $map-esri-btn;
	padding: $tiny-spacer-sm;
	text-align: center;
	font-size: $cicon-size-sm;
}
