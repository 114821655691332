/* -------------------------------------------------------
   Start of _text
   ------------------------------------------------------- */

p {
	@include generateBlockMaxWidth();
	font-size: $base-font-size;
	line-height: $line-height-default;
	margin-bottom: $spacer-lg;
}

p.text-center,
.text-center p {
	margin-right: auto;
	margin-left: auto;
}

blockquote {
	@include generateBlockMaxWidth();
	border-left: $basic-space*3rem solid $coc-main;
	color: $blockquote-color;
	font-style: $blockquote-font-style;
	margin-bottom: $spacer-md;
	padding-left: $spacer-sm;

	cite {
		display: block;
		text-align: right;
		font-weight: $font-weight-bold;
		font-size: $smaller-font-size;
		font-style: normal;
		margin-top: $spacer-xxs;
		text-transform: uppercase;

		&::before {
			content: '\2013\2009';// en dash and thin-space
		}
	}

	ul,
	ol,
	p {
		margin-bottom: initial;
	}

	@media( min-width: $breakpoint-md ) {
		font-size: $medium-font-size;
	}

	@media( min-width: $breakpoint-lg ) {
		font-size: $large-font-size;
	}

	&.lg {
		border-left: 0;// Reset base styling
		font-size: $blockquote-lg-font-size;
		font-style: $blockquote-lg-font-style;
		margin-bottom: $blockquote-lg-ex-spacing;
		padding-left: 0;// Reset base styling

		&::before {
			@include setIconFontStyle();
			content: "\e0a4";// .cicon-quote-left
			color: $blockquote-lg-icon-color;
			display: block;
			font-size: $blockquote-lg-icon-size;
			margin-bottom: $spacer-xxs;
		}

		.quote {
			p {
				font-size: $blockquote-lg-font-size;
				//line-height: 2;
			}

			&::after {
				content: '\201D';// &rdquo;
			}

			p:last-child {
				display: inline;
			}
		}

		@media( min-width: $breakpoint-sm ) {
			padding-left: $blockquote-lg-icon-size-xs + $spacer-sm;
			position: relative;

			&::before {
				font-size: $blockquote-lg-icon-size-xs;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}// End of &.lg
}// End of blockquote

.lead {
	font-size: $large-font-size;
	font-weight: normal;// Override BS styles

	@media( min-width: $breakpoint-md ) {
		font-size: $larger-font-size;
	}
}

.hero {
	font-size: $large-font-size;
	font-weight: $font-weight-bold;

	@media( min-width: $breakpoint-md ) {
		font-size: $h2-font-size-md;
	}
}

.block-cta.cui {
	background: $coc-secondary-9L;
	border: $border-thin solid $coc-secondary-8L;
	border-left: $border-width-lg solid $coc-main; //4px
	color: $coc-default-color;
	margin: 0;
	padding: $spacer-xxs;

	a {
		font-weight: $font-weight-semi-bold;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.small {
	font-size: 87.5%;
}

ins {
	text-decoration: underline;
}

del {
	text-decoration: line-through;
}

mark {
	background: yellow;
}

abbr[title],
dfn[title] {
	border-bottom: $border-thin dotted $black;
	cursor: help;
}

hr {
	background: $coc-secondary-8D;
	border: 0;
	border-top: $border-thin solid $coc-secondary-8D;
	height: $basic-space*1rem;
	margin: $spacer-md 0;
	box-sizing: border-box;

	&.row-divider {
		background: $coc-secondary-9L;
		border: $border-width-lg solid $coc-secondary-9L; //4px
		padding-top: 0;
		padding-bottom: 0;
	}
}

// -------------------------------------------------------
// Print
//
// -------------------------------------------------------

@media print {
	blockquote {
		border-left-color: $print-border-color;
	}

	.block-cta.cui {
		background: transparent;
		border-left-color: $print-border-color;
		border-width: 0 0 0 $border-width-lg; //4px
		padding-top: 0;
		padding-bottom: 0;
	}
}
