/* -------------------------------------------------------
   Start of _modal
   ------------------------------------------------------- */

// TODO: MC.2018.09.17: Clean up the padding variables below so they are aligned with the usage
.modal-open {
    overflow: hidden; // Remove scroll on the body

    .modal {
        overflow: hidden;
    }
}

.modal-backdrop {
    background-color: $modal-overlay-bg-color;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $modal-overlay-z;

    &.show {
        opacity: 0.7;
    }
}

.modal {
    > .cui {
        padding-right: 0 !important; // MC.2018.09.10: Do not allow BS's JS to inject padding-right for the scrollbar space as we do not need this in PL
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $modal-z;
        outline: 0;
        overflow: hidden;

        .modal-content {
            background: $modal-bg-color;
            border: 0; // BS4 override
            border-radius: 0; // BS4 override
            @include flexbox();
            @include flex-direction(column);
            margin-left: auto;
            margin-right: auto;
            max-height: 100%; // Takes up max-height of 100% of the screen
            position: relative;
            top: 50%; // Vertically centre the modal part 1/2
            transform: translateY(-50%); // Vertically centre the modal part 2/2
            width: 100%;
        }

        .modal-header,
        .modal-footer {
            // TODO: MC.2018.06.21: Rename these variables b/c these are for the modal-header and modal-footer only
            padding: $modal-vert-padding $modal-horz-padding;
        }

        .modal-header {
            background: $modal-header-bg-color;
            border-bottom: $modal-header-border;
            @include flexbox();
            @include flex-shrink(0); // Do not allow the element to shrink
        }

        .modal-title {
            @include align-self(center);
            line-height: $line-height-compact;
            margin-right: $spacer-xs; // Ensure the title doesn't touch the close button
            margin-bottom: 0;

            [class*='cicon-'] {
                font-size: 80%;
                padding-right: $spacer-xxs;
            }
        }

        .btn-modal-close {
            @include align-self(flex-start);
            margin-left: auto; // Ensures the button is positioned to the right
            white-space: nowrap; // Ensures the text doesn't wrap onto a second line

            &::after {
                @include setIconFontStyle();
                content: '\e039'; // cicon-close-thin-large
                display: inline-block; // Ensures the icon does not have an underline
                font-size: $cicon-size-xs; // Balance the size
                margin-left: $tiny-spacer-sm;
            }
        }

        .modal-body {
            -webkit-box-flex: 1 1 auto;
            -webkit-flex: 1 1 auto;
            -ms-flex: 1 1 auto;
            -moz-flex: 1 1 auto;
            flex: 1 1 auto;
            overflow-y: auto; // Becomes scrollable if content becomes too long
            padding: $modal-horz-padding;
            width: 100%;
            height: 100%;
        }

        .modal-footer {
            background: $modal-header-bg-color;
            border-top: $modal-header-border;
            display: block; // BS override
            @include flex-shrink(0); // Do not allow the element to shrink

            p {
                margin-bottom: $spacer-xs;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        // -------------------------------------------------------
        // Sizing
        //
        // -------------------------------------------------------
        &.modal-dialog {
            height: 100%; // BS4 overrride
            margin: 0 auto; // BS4 overrride

            &.modal-sm {
                max-width: $modal-sm;
            }

            // Modifier class to ensure the modal is fullscreen for mobile-sized screens
            &.modal-fs-mobile {
                @media (max-width: $breakpoint-sm-max) {
                    max-width: none;

                    .modal-content {
                        height: 100%;
                    }
                }
            }

            // Modifier class to ensure the modal is fullscreen all the time
            &.modal-fs {
                max-width: none;

                .modal-content {
                    height: 100%;
                    max-height: none; // Override the max-height for all screen sizes
                }
            }

            @media (min-width: $breakpoint-sm) {
                max-width: $modal-md;
            }

            @media (min-width: $breakpoint-md) {
                &.modal-lg {
                    max-width: $modal-lg;
                }
            }
        }

        @media (min-width: $breakpoint-md) {
            .modal-content {
                max-height: 90%; // Take up max-height of 90% of the screen, for some breathing room
            }
        }

        @media (min-width: $breakpoint-lg) {
            .modal-header,
            .modal-footer {
                padding: $modal-vert-padding-md $modal-horz-padding-md;
            }

            .modal-body {
                padding: $modal-horz-padding-md;
            }

            .modal-footer {
                padding-bottom: $modal-horz-padding-md;
            }
        }

        &.modal-form {
            .btn-modal-close {
                &::after {
                    content: ''; // cicon-close-thin-large
                }
            }
        }
    }
} // End of .cui.modal

// -------------------------------------------------------
// IE11 workarounds
//
// -------------------------------------------------------

// stylelint-disable-next-line selector-type-no-unknown
_:-ms-fullscreen,
:root .modal-content {
    // MC.2018.06.14: IE11 hack. IE11 needs an explicit height on the flex
    // element, otherwise it will not render properly. This will force the
    // modals to be 100% height all the time, even if it should be shorter.
    height: 100%;
}

// -------------------------------------------------------
// Print
//
// -------------------------------------------------------

@media print {
    .modal-open {
        overflow: auto;

        .modal-backdrop {
            background: transparent;
        }

        .cui.modal {
            margin-bottom: $spacer-lg;
            position: relative;

            .modal-dialog {
                max-width: $block-max-width + $spacer-xl;
            }

            .modal-content {
                border: $print-border;
                top: auto;
                transform: none;
            }

            .modal-header,
            .modal-footer {
                background: transparent;
                border-color: $print-border-color;
            }
        }

        .btn-modal-close {
            display: none;
        }
    }
}
