$waiting-color-default-foreground: var(--msv-accent-brand-color);
$waiting-background-opacity: 0.5;
$waiting-color-background: $msv-gray-300;

$waiting-circle-transition-time: 0.75s;
$waiting-circle-stroke-width: 3px;
$waiting-circle-sizes: (
    'sm': 2.25rem,
    'md': 2.25rem,
    'lg': 2.25rem
);

$waiting-linear-transition-time: 1.5s;
$waiting-linear-sizes: (
    'sm': 4px,
    'md': 8px,
    'lg': 12px
);

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes track {
    0% {
        right: 100%;
        left: 0%;
    }

    20% {
        right: 80%;
        left: 0%;
    }

    80% {
        right: 0%;
        left: 80%;
    }

    100% {
        right: 0%;
        left: 100%;
    }
}

.msc-waiting {
    &-circular {
        &::before {
            margin: auto;
            margin-bottom: 0.5625rem;
            height: 2.25rem;
            width: 2.25rem;
            border: 0.375rem solid #dbdcdd;
            border-radius: 50%;
            border-top-color: $coc-main;
            animation: rotate 1s ease infinite;
            content: '';
            display: block;
        }
        &::after {
            content: 'Loading...';
            width: 100%;
            text-align: center;
            display: block;
            font-weight: 600;
            padding-top: 5px;
        }
        &.msc-waiting-lg {
            width: 100% !important;
            height: auto !important;
        }
    }

    @each $color, $value in $theme-colors {
        &-circular#{&}-#{$color} {
            border-color: rgba(mix($value, $waiting-color-background, 25%), $waiting-background-opacity);
            border-top-color: $value;
        }
    }

    @each $key, $value in $waiting-circle-sizes {
        &-circular#{&}-#{$key} {
            width: $value;
            height: $value;
        }
    }

    &-linear {
        width: 100%;
        position: relative;
        border-color: rgba($waiting-color-background, $waiting-background-opacity);
        border-style: solid;
        border-width: 0;
        border-top-width: map-get($waiting-linear-sizes, 'md') / 2;
        border-bottom-width: map-get($waiting-linear-sizes, 'md') / 2;

        &::before {
            position: absolute;
            top: calc(-#{map-get($waiting-linear-sizes, 'md')} / 2);

            content: '';

            border-color: theme-color('primary');
            border-style: solid;
            border-width: map-get($waiting-linear-sizes, 'md');
            border-top-width: map-get($waiting-linear-sizes, 'md') / 2;
            border-bottom-width: map-get($waiting-linear-sizes, 'md') / 2;

            animation: track $waiting-linear-transition-time linear infinite;
        }
    }

    @each $color, $value in $theme-colors {
        &-linear#{&}-#{$color} {
            border-color: rgba(mix($value, $waiting-color-background, 25%), $waiting-background-opacity);

            &::before {
                border-color: $value;
            }
        }
    }

    @each $key, $value in $waiting-linear-sizes {
        &-linear#{&}-#{$key} {
            border-top-width: $value / 2;
            border-bottom-width: $value / 2;

            &::before {
                top: -$value / 2;

                border-top-width: $value / 2;
                border-bottom-width: $value / 2;
            }
        }
    }
}
