//spacing
$header-container-spacing-desktop: 40px;
$header-container-spacing-mobile-left: 6px;
$header-container-spacing-mobile-right: 12px;
$header-container-spacing-mobile: 0;
$signin-info-spacing-left: 2px;
$navigation-spacing-left: 8px;
$cart-icon-spacing-left: 20px;
$heading-padding-top: 0;
$signin-info-padding: 12px 14px;
$header-height: 48px;
$header-mobile-height: 50px;
$header-icon-width: 50px;
$account-link-height: 35px;
$account-links-max-width: 276px;
$signin-link-padding: 0 20px;
$signin-link-height: 48px;
$header_container-max-width: $msv-breakpoint-xl;
$header-myaccount-margin-left: 8px;
$header-logo-margin-right: 20px;
$header-logo-margin: auto auto auto 12px;
$header-icon-padding-right: 10px;
$header-icon-padding-left: 10px;
$header-cart-icon-margin-left: 4px;
$header-list-item-link-padding: 0 20px;
$popover-menu-item-height: 48px;
$msv-default-container-padding-bottom: 25px;
$divider-margin-left: 8px;
$divider-margin-right: 8px;
$divider-margin-width: 1px;
$divider-margin-height: 20px;
$divider-padding-m: 10px 0;
$nav-padding-top-m: 20px;

//style presets
:root {
    --msv-header-bg: #{$msv-gray-100};
    --msv-header-border: var(--msv-border-color);
    --msv-header-font-color: #{$msv-black};
    --msv-header-font-size: var(--msv-body-font-size-m);
}

header {
    background-color: var(--msv-header-bg);
    .default-container {
        > .row {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.ms-header {
    background-color: var(--msv-header-bg);
    color: var(--msv-header-font-color);
    border: none;
    margin-left: auto;
    margin-right: auto;
    padding-top: $heading-padding-top;
    position: relative;
    z-index: 1;
    &.approval-header {
        .header-right-col,
        a.msc-cart-icon,
        .ms-search {
            display: none !important;
        }
    }
    .top-bar {
        background-color: #fff;
        display: flex;
        justify-content: flex-end;
        border-bottom: 1px solid $cui-gray-primary;
        padding: 15px 0;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        @media (max-width: $msv-breakpoint-l) {
            width: 100%;
        }
    }
    &__container {
        padding-left: $header-container-spacing-mobile;
        padding-right: $header-container-spacing-mobile;
        .ms-header__logo {
            margin-right: $header-logo-margin-right;
            width: 214px;
            height: 107px;
            overflow: hidden;
            padding: 5px;
            margin-top: -5px;
            img {
                width: 285px;
                height: auto;
                max-width: none;
            }
            a {
                @include vfi();
                display: block;

                &:focus {
                    outline-offset: -2px;
                }
            }
        }

        .ms-header__collapsible-hamburger {
            .ms-header__divider {
                background: var(--msv-header-bg);
                border-bottom: 1px solid $msv-gray-300;
                padding: $divider-padding-m;
            }

            .ms-nav.mobile-vp {
                padding-top: $nav-padding-top-m;
            }
        }
    }

    .ms-header__preferred-store-text {
        color: var(--msv-header-font-color);
        font-size: var(--msv-header-font-size);
        margin-left: $header-myaccount-margin-left;
    }

    .ms-header__preferred-store-btn {
        @include add-icon($msv-Shop-Address);
    }

    .ms-header__preferred-store-btn,
    .ms-search__icon,
    .ms-header__signin-button,
    .msc-wishlist-icon,
    .msc-cart-icon,
    .ms-header__profile-button,
    .ms-search__form-cancelSearch {
        background-color: transparent;
        color: var(--msv-header-font-color);

        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
        justify-content: center;
        border: none;
        &:focus {
            outline-offset: -2px;
        }
    }
    .ms-search__icon {
        flex-flow: column;
        &.msc-btn {
            font-size: 1rem;
            background-color: rgba(255, 255, 255, 0.518);
            border-radius: 5px;
            width: 76px;
            height: 73px;
            @include setInteractiveBoxShadow();
            &::before {
                color: $msv-red;
                font-size: 1.4rem;
            }

            @media (max-width: $breakpoint-lg) {
                font-size: 1rem;
                &::before {
                    margin-bottom: 2px;
                }
            }
        }
    }
    .ms-search__form-cancelSearch {
        color: var(--msv-header-font-color);

        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
        justify-content: center;
    }

    .ms-search__form-control,
    .ms-search__form-cancelSearch {
        &:focus {
            outline-offset: -1px;
        }
    }

    // hiding the header icons from login page
    &.hide-header-options {
        .ms-header__nav-icon,
        .ms-header__account-info.account-desktop,
        .ms-header__account-info.account-mobile,
        .msc-cart-icon,
        .ms-header__divider,
        .msc-wishlist-icon {
            display: none;
        }
    }

    .msc-btn {
        height: $header-height;
    }

    .ms-header__topbar {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        span.infoTxt {
            display: inline-block;
            width: 100%;
            margin-bottom: 5px;
            .info-ico {
                display: inline-block;
                position: relative;
                border: none;
                background-color: transparent;
                margin-left: 25px;
                padding: 0;
                .iconInfo {
                    &::before {
                        @include msv-icon($icon-weight: $msv-solid-icon-weight);
                        content: $msv-InfoCircle;
                        color: $msv-gray-1200;
                        font-size: 1.2rem;
                        position: relative;
                        top: 1px;
                    }
                }
                &:hover,
                &:focus {
                    &:after {
                        display: -webkit-flex;
                        display: flex;
                        -webkit-justify-content: center;
                        justify-content: center;
                        background: #fff;
                        border-radius: 8px;
                        content: attr(data-tooltip);
                        margin: 0px auto 0;
                        padding: 13px;
                        width: 230px;
                        position: absolute;
                        bottom: auto;
                        top: 30px;
                        right: -20px;
                        z-index: 1;
                        border: 1px solid #ccc;
                        font-size: 0.8rem;
                    }
                    &:before {
                        border: solid;
                        border-color: #fff transparent;
                        border-width: 12px 6px 0 6px;
                        content: '';
                        right: 5px;
                        top: 20px;
                        position: absolute;
                        transform: rotate(180deg);
                        z-index: 2;
                    }
                }
                @include cui-focus();
            }
        }
        .site-option-desktop {
            padding: 15px 0;
        }
        .site-option-desktop,
        .site-option-mobile {
            button.ms-site__btn {
                border: none;
                background: none;
                color: $msv-red;
                padding: 0;
                text-decoration: underline;
                font-size: 0.9rem;
                @include cui-focus();
                &:hover {
                    text-decoration: none;
                    background-color: $msv-gray-200;
                }
            }
        }
        .header-left-col,
        .header-right-col {
            display: flex;
            @media (max-width: $msv-breakpoint-l) {
                border-bottom: 1px solid $msv-gray-1000;
                align-items: center;
                .ms-cart-icon {
                    margin-left: 15px;
                    margin-right: 15px;
                }
                .ms-header__nav-icon {
                    color: $msv-red;
                    background: $msv-gray-50;
                    height: 48px;
                    flex-wrap: wrap;
                    border: none;
                    font-weight: 600;
                    padding: 5px;
                    &::after {
                        content: 'Menu';
                        font-size: 0.8rem;
                    }
                }
            }
        }
        @media (max-width: $msv-breakpoint-l) {
            .header-right-col {
                padding: 15px;
            }
        }
    }

    // .ms-search {
    //     display: none;
    // }

    .ms-header__desktop-view {
        display: none;
    }

    .ms-header__account-info {
        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
        justify-content: flex-end;
        margin-left: $signin-info-spacing-left;

        &__account-link-container {
            height: $account-link-height;
        }
        .ms-header__profile-button {
            padding: 5px 5px 5px 40px;
            position: relative;
            height: auto;
            &::before {
                @include msv-icon();
                content: $msv-Contact;
                border: 1px solid $msv-primary;
                color: $msv-primary;
                position: absolute;
                left: 0;
                top: 0px;
                height: 30px;
                width: 30px;
                border-radius: 0.2rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        a {
            align-items: center;
            display: flex;
            height: 100%;
            font-size: $msv-text-size;
            line-height: $msv-text-line-height;
            padding: $signin-link-padding;
            height: $signin-link-height;
            margin-bottom: 5px;
            &:hover {
                text-decoration: none;
            }
        }

        .ms-header__signin-button {
            @include button-utility();
            &:after {
                content: '(My ID)';
                margin-left: 5px;
                font-family: $msv-primary-font-family;
            }
        }

        .msc-popover {
            background-color: var(--msv-header-bg);
            background-clip: padding-box;
            border: 1px solid $msv-gray-500;
            display: block;
            left: 0;
            max-width: $account-links-max-width;
            position: absolute;
            top: 0;
            word-wrap: break-word;
            z-index: 1060;

            .msc-btn {
                color: var(--msv-header-font-color);
                font-weight: var(--msv-font-weight-normal);
                height: $popover-menu-item-height;
            }

            .msc-arrow {
                display: block;
                height: 0.5rem;
                margin: 0 0.3rem;
                position: absolute;
                top: calc((0.5rem + 1px) * -1);
                width: 1rem;

                &::before,
                &::after {
                    border-color: transparent;
                    border-style: solid;
                    border-width: 0 0.5rem 0.5rem 0.5rem;
                    content: '';
                    display: block;
                    position: absolute;
                }

                &::before {
                    border-bottom-color: $msv-gray-500;
                    top: 0;
                }

                &::after {
                    border-bottom-color: var(--msv-border-color);
                    top: 1px;
                }
            }
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-wishlist-icon {
        &:hover {
            text-decoration: none;
        }
    }

    .ms-header__wishlist-desktop {
        align-items: center;
        display: none;
        height: $header-height;
        width: $header-icon-width;

        @include add-icon($msv-Heart, before, 400);

        &::before {
            font-size: var(--msv-header-font-size);
            text-align: center;
            vertical-align: text-bottom;
            width: $header-icon-width;
            color: $msv-black;
        }

        .msc-wishlist-icon__text {
            display: none;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .ms-header__nav-icon {
        align-items: center;
        background: var(--msv-header-bg);
        display: flex;
        height: $header-height;
        justify-content: center;
        width: $header-icon-width;

        &:focus {
            outline-offset: -1px;
        }

        &::before {
            @include msv-icon();
            content: $msv-GlobalNavButton;
            font-size: var(--msv-header-font-size);
            width: $header-icon-width;
            vertical-align: text-bottom;
            text-align: center;
        }

        &:hover {
            text-decoration: none;
        }
    }

    /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
    .msc-cart-icon {
        font-size: 1rem;
        background-color: rgba(255, 255, 255, 0.518);
        border-radius: 5px;
        width: 76px;
        height: 73px;
        display: flex;
        align-items: center;
        flex-flow: column;
        padding: 0;
        text-decoration: none;
        &::before {
            @include msv-icon();
            content: $msv-ShoppingCart;
            font-size: var(--msv-header-font-size);
            width: $header-icon-width;
            vertical-align: text-bottom;
            text-align: center;
            margin-bottom: 5px;
            color: $msv-red;
        }

        @include setInteractiveBoxShadow();

        &__count {
            @include font-content-s();
            margin-left: $header-cart-icon-margin-left;
            font-size: 1rem;
            @media (max-width: $msv-breakpoint-l) {
                font-size: 1rem;
            }
        }
    }

    @media (min-width: $msv-breakpoint-xs) {
        .ms-nav.desktop-vp {
            display: none;
        }

        .msc-cart-icon {
            &::before {
                width: $header-icon-width / 2;
                font-size: 1.2rem;
                @media (max-width: $msv-breakpoint-l) {
                    font-size: 1.2rem;
                    margin-bottom: 3px;
                }
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        padding-left: $header-container-spacing-mobile-left;
        padding-right: $header-container-spacing-mobile-right;

        .msc-wishlist-icon {
            @include add-icon($msv-Heart, before, 400);

            &::before {
                padding-right: $header-icon-padding-right;
            }
        }

        &__container {
            .ms-header__topbar {
                justify-content: space-between;

                .ms-header__logo {
                    margin: $header-logo-margin;
                }
            }

            .ms-header__collapsible-hamburger {
                .ms-nav {
                    .ms-nav__drawer {
                        .drawer__button {
                            @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                            color: var(--msv-header-font-color);
                            background: transparent;
                            text-align: left;
                            width: 100%;

                            .ms-nav__drawer-open {
                                @include add-icon($msv-ChevronUp, after);
                            }

                            .ms-nav__drawer-close {
                                @include add-icon($msv-ChevronDown, after);
                            }

                            .drawer__glyph__end {
                                position: absolute;
                                right: 10px;
                            }
                        }

                        .msc-btn {
                            outline-offset: -1px;
                        }
                    }

                    .ms-nav__list__item__link {
                        @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                        align-items: center;
                        display: flex;
                        padding: $header-list-item-link-padding;
                        @include cui-focus();
                    }
                }
            }
        }

        .ms-header__account-info.account-mobile {
            display: unset;
            margin-left: 0;

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .drawer__button {
                @include font-content(var(--msv-font-weight-normal), var(--msv-header-font-size), $msv-line-height-m);
                @include add-icon($msv-Contact, before, 400);

                &::before {
                    padding-right: $header-icon-padding-right;
                }
                background: transparent;
                text-align: left;
                width: 100%;

                .ms-header__drawer-open {
                    @include add-icon($msv-ChevronUp, after);
                }

                .ms-header__drawer-close {
                    @include add-icon($msv-ChevronDown, after);
                }

                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                .drawer__glyph__end {
                    position: absolute;
                    right: $header-icon-padding-right;
                }
            }

            .ms-header__signin-button {
                display: flex;
                justify-content: flex-start;

                @include add-icon($msv-Contact, before, 400);

                &::before {
                    padding-right: $header-icon-padding-right;
                }
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .msc-btn {
                outline-offset: -1px;
            }
        }

        .ms-header__collapsible-hamburger {
            background: var(--msv-header-bg);
            left: 0;
            margin-top: 2px;
            position: absolute;
            width: 100%;
            z-index: 1012;

            .ms-header__wishlist-mobile {
                text-align: left;
                width: 100%;
            }

            .ms-search {
                justify-content: flex-start;
                display: flex;

                .ms-search__label {
                    display: flex;
                    width: 100%;

                    .ms-search__icon {
                        display: flex;
                        justify-content: flex-start;
                        width: 100%;
                        flex-direction: column;
                        color: $msv-red;
                    }

                    .ms-search__icon-text {
                        display: unset;
                        padding-left: $header-icon-padding-left;
                    }
                }
            }
        }
    }
    @media (min-width: $msv-breakpoint-xs) {
        &__profile-button {
            color: var(--msv-header-font-color);
            font-weight: var(--msv-font-weight-normal);
            background: var(--msv-header-bg);

            &::after {
                @include msv-icon();
                content: $msv-ChevronDown;
                font-size: var(--msv-header-font-size);
                margin-left: $header-myaccount-margin-left;
                text-align: center;
                vertical-align: text-bottom;
            }
        }

        &__profile-button[aria-expanded='true'] {
            &::after {
                content: $msv-ChevronUp;
            }
        }

        &__profile-button[aria-expanded='false'] {
            &::after {
                content: $msv-ChevronDown;
            }
        }
    }
    @media (min-width: $msv-breakpoint-l) {
        .ms-header__desktop-view {
            display: block;
            position: relative;
            z-index: 0;
        }

        .ms-nav.desktop-vp {
            display: flex;
            margin-left: -40px;
            margin-right: -40px;
            margin-top: 15px;
            width: auto;
            padding: 0 30px;
        }
        &__container {
            padding-left: $header-container-spacing-desktop;
            padding-right: $header-container-spacing-desktop;
        }
        &__modal {
            display: none;
        }

        &__divider {
            background: $msv-divider-color;
            height: $divider-margin-height;
            margin-left: $divider-margin-left;
            margin-right: $divider-margin-right;
            width: $divider-margin-width;
            display: none;
        }

        .ms-header__account-info.account-desktop {
            display: block;
        }

        .ms-header__collapsible-hamburger {
            display: none;

            .ms-header__wishlist-mobile {
                text-align: left;
                width: 100%;
            }
        }

        .ms-search {
            display: flex;
            align-items: center;
            &__icon {
                align-items: center;
                color: var(--msv-header-font-color);
                font-weight: normal;

                &:focus {
                    outline-offset: -2px;
                }
            }
        }

        .ms-header__wishlist-desktop {
            display: flex;
        }

        .ms-header__nav-icon {
            display: none;
        }

        .ms-cart-icon {
            padding-left: $cart-icon-spacing-left;
            width: unset;
            display: flex;
            align-items: center;
            color: $msv-red;
            &::before {
                width: unset;
                margin-right: 4px;
            }
        }
    }
    @media (max-width: $msv-breakpoint-l) {
        &__container {
            .ms-header__topbar {
                display: block;
                .ms-header__logo {
                    width: 255px;
                    height: 107px;
                    overflow: hidden;
                    padding: 5px;
                    margin-top: -5px;
                }
                .siteOption {
                    width: calc(100% - 80px);
                    justify-content: center;
                }
            }
        }
    }
    @media (max-width: $breakpoint-md) {
        &__container {
            .ms-header__topbar {
                .ms-header__logo {
                    width: 82px;
                    overflow: hidden;
                    height: 100px;
                    margin-left: 0;
                    padding: 0;
                    margin-top: 0;
                    picture {
                        max-width: 100%;
                        img {
                            max-width: none;
                            max-height: 100%;
                            height: 100px;
                            display: inline;
                            width: 302px;
                            position: relative;
                            left: -225px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: $msv-breakpoint-l) {
        padding-left: 0;
        padding-right: 0;
    }
}
@media (max-width: $msv-breakpoint-l) {
    .modal {
        .ms-header__collapsible-hamburger {
            &.modal-dialog {
                margin: 0;
                left: -50%;
                top: 10%;
                transform: translate(-50%, 0) !important;
                transition: all 0.3s;
                .modal-content {
                    border-radius: 0;
                    background-color: $msv-gray-100;
                    .ms-header__mobile-hamburger-menu-links {
                        display: none;
                    }
                    .btn-secondary,
                    .ms-nav__list__item__link {
                        color: $cui-gray-primary;
                        border: none;
                        background-color: transparent;
                        border-color: transparent;
                        text-align: left;
                        padding: 10px 0;
                        font-size: 1rem;
                        text-decoration: none;
                        border-bottom: 1px solid $msv-gray-400;
                        line-height: 48px;
                        height: auto;
                        @include cui-focus();
                    }
                    .collapse {
                        .ms-nav__list__item__link {
                            padding-left: 25px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                            background-color: $msv-gray-50;
                            height: auto;
                            line-height: 2rem;
                        }
                    }
                    .ms-nav__drawer {
                        &:last-child {
                            .btn-secondary {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
        &.show {
            .ms-header__collapsible-hamburger {
                &.modal-dialog {
                    left: 50%;
                    top: 10%;
                    transform: translate(-50%, 0%) !important;
                    transition: all 0.3s;
                }
            }
        }
    }
}
