$tile-padding-bottom-mobile: 48px;
$tile-details-padding-top: 20px;
$tile-first-margin-left: 0;
$tile-last-margin-right: 0;
$tile-last-padding-bottom-mobile: 0;
$tile-link-margin-left-right: 24px;
$tile-link-padding-top: 10px;

:root {
    // link
    --msv-content-block-link-color: var(--msv-font-primary-color);
    --msv-content-block-link-font-size: var(--msv-body-font-size-m);
}

.ms-content-block {
    &[data-m-layout='tile'],
    &[data-m-layout='coc-tile'] {
        margin: auto;
        width: fit-content;
        margin-right: 30px;
        background: $coc-default-background;
        border: $card-border;
        border-radius: $default-border-radius;
        @include flexbox();
        @include flex-direction(column);
        @include setInteractiveBoxShadow(true);
        text-decoration: none;
        transition-property: box-shadow;
        height: 100%;
        min-width: calc(100% - 30px);
        .ms-content-block__link {
            display: block;
            @include cui-focus();
        }

        .msc-cta__primary {
            @include button-link(var(--msv-content-block-link-color));
            @include font-content(var(--msv-font-weight-normal), var(--msv-content-block-link-font-size), $msv-line-height-m);
            @include cui-focus();
        }

        .ms-content-block__details {
            padding-top: $tile-details-padding-top;
        }

        .ms-content-block__details,
        &.textplacement__left .ms-content-block__details {
            position: relative;
            text-align: left;
            padding: 30px 15px 60px 15px;
            display: flex;
            flex-flow: column;
            height: 100%;
            .ms-content-block__title {
                font-weight: 600;
                margin-bottom: 20px;
                color: $msv-red;
            }
            .ms-content-block__text {
                p {
                    font-size: 1rem;
                    margin-bottom: 0;
                }
            }

            .ms-content-block__cta {
                padding-top: $tile-link-padding-top;
                position: absolute;
                bottom: 25px;
                width: calc(100% - 30px);
                a[class^='msc-cta__'] {
                    margin-left: 0;
                    text-decoration: underline;

                    &:first-child {
                        margin-left: $tile-first-margin-left;
                    }
                }
            }
        }

        &.textplacement__right .ms-content-block__details {
            text-align: right;

            .ms-content-block__cta {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                a[class^='msc-cta__'] {
                    margin-right: $tile-link-margin-left-right;

                    &:last-child {
                        margin-right: $tile-last-margin-right;
                    }
                }
            }
        }

        &.textplacement__center .ms-content-block__details {
            text-align: center;

            .ms-content-block__cta {
                /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
                a[class^='msc-cta__'] {
                    margin-left: $tile-link-margin-left-right / 2;
                    margin-right: $tile-link-margin-left-right / 2;

                    &:first-child {
                        margin-left: $tile-first-margin-left;
                    }

                    &:last-child {
                        margin-right: $tile-last-margin-right;
                    }
                    > div {
                        white-space: normal;
                    }
                }
            }
        }

        @media (max-width: $msv-breakpoint-m) {
            padding-bottom: 0;
            width: 100%;
            margin-bottom: 30px;
            margin-right: 0;
            & .ms-content-block__image img {
                width: 100%;
            }
        }
        &.link-tile {
            position: relative;
            width: auto;
            .ms-content-block__details {
                position: static;
                padding-bottom: 30px;
                .ms-content-block__cta {
                    position: static;
                }
            }
            .msc-cta__primary {
                &:empty {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
            }
        }
    }
}

.col-sm:first-child .ms-content-block[data-m-layout='tile']:first-child,
.col-sm:first-child .ms-content-block[data-m-layout='coc-tile']:first-child,
.col-md:first-child .ms-content-block[data-m-layout='tile']:first-child {
    margin-left: $tile-first-margin-left;
}

.col-sm:last-child .ms-content-block[data-m-layout='tile']:last-child,
.col-sm:last-child .ms-content-block[data-m-layout='coc-tile']:last-child,
.col-md:last-child .ms-content-block[data-m-layout='tile']:last-child {
    margin-right: $tile-last-margin-right;
}

@media (max-width: $msv-breakpoint-m) {
    &.col-sm:last-child .ms-content-block[data-m-layout='tile']:last-child,
    &.col-sm:last-child .ms-content-block[data-m-layout='coc-tile']:last-child {
        padding-bottom: $tile-last-padding-bottom-mobile;
    }
}
