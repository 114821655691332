/* -------------------------------------------------------
   Start of _tooltip
   ------------------------------------------------------- */

.cui {
	&.btn-tooltip {
		// The tooltip button is purposely designed to NOT look like the rest
		// of the buttons as we typically want visual users to trigger the
		// tooltip on hover rather than on click.
		background: $tooltip-btn-background;
		border-color: $tooltip-btn-border-color;
		color: $tooltip-btn-color;
		vertical-align: middle;

		&:hover {
			background: $tooltip-btn-hover-background;
		}

		.icon-tooltip {
			&::before {
				content: '\e083';// .cicon-info-circle
				font-family: $font-icon;
			}

			&.right {
				// Same spacing as icons within normal buttons
				margin-left: $tiny-spacer-sm;
			}
		}
	}

	&.tooltip-wrapper {
		display: inline;
	}

	&.tooltip {
		display: none;
		position: absolute;
		z-index: $tooltip-z;

		&.show {
			display: block;
			opacity: 1;// Override BS
		}

		.tooltip-inner {
			background: $tooltip-background;
			border: $tooltip-border-width solid $tooltip-border-color;
			border-radius: $tooltip-border-radius;
			box-shadow: $default-hover-shadow;
			color: $tooltip-color;
			display: block;
			font-size: $tooltip-font-size;
			font-weight: $font-weight-normal;
			max-width: $tooltip-max-width;
			padding: $tooltip-padding-y $tooltip-padding-x;
			text-align: left;
			// Allow breaking very long words so they don't overflow the
			// tooltip's bounds
			word-wrap: break-word;
		}

		.arrow {
			display: block;
			position: absolute;

			&::before,
			&::after {
				border: ($tooltip-arrow-width / 2) solid transparent;
				content: '';
				position: absolute;
				width: 0;
				height: 0;
			}
		}

		&[x-placement^="top"],
		&[x-placement^="bottom"] {
			padding: $tooltip-arrow-height 0;

			.arrow {
				width: $tooltip-arrow-width;
				height: $tooltip-arrow-height;
			}
		}

		&[x-placement^="left"],
		&[x-placement^="right"] {
			padding: 0 $tooltip-arrow-height;

			.arrow {
				top: calc( 50% - #{$tooltip-arrow-width / 2} );
				// Reverse the dimensions as the arrow is now 'rotated'
				width: $tooltip-arrow-height;
				height: $tooltip-arrow-width;
			}
		}

		&[x-placement^="top"] {
			.arrow {
				@include tooltipArrow( $direction: top );
			}
		}

		&[x-placement^="right"] {
			.arrow {
				@include tooltipArrow( $direction: right );
			}
		}

		&[x-placement^="bottom"] {
			.arrow {
				@include tooltipArrow( $direction: bottom );
			}
		}

		&[x-placement^="left"] {
			.arrow {
				@include tooltipArrow( $direction: left );
			}
		}
	}// End of &.tooltip
}// End of .cui
