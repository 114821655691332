/* -------------------------------------------------------
   Start of _print
   ------------------------------------------------------- */

@media print {
	body {
		// Provides a more consistent printing size across
		// various browsers. Copied from BS4.
		min-width: $print-body-min-width !important;
	}
	// Default printer is setup as A4 paper and using the mobile layout
	// Sometimes make the page extra longer
	// user the code to print page with the desktop design
	@page {
		size: 330mm 427mm;
		margin: 14mm;
	}

	.printarea {
		visibility: visible;
	}

	.no-print {
		display: none;
	}

	*,
	*::before,
	*::after {
		color: $black !important;// Black prints faster: h5bp.com/s
		box-shadow: none !important;
		text-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]::after {
		content: none;
	}

	thead {
		display: table-header-group;// h5bp.com/t
	}

	tr,
	img,
	pre {
		page-break-inside: avoid;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	img {
		max-width: 100% !important;
	}

	abbr[title]::after {
		content: " (" attr( title ) ")";
	}
}
