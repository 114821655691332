/* -------------------------------------------------------
   Start of _page-title-block
   ------------------------------------------------------- */

.cui.app-page-title-block {
	background: $coc-secondary-9L;
	border-top: $border-thin solid $coc-secondary-7L;
	border-bottom: $border-thin solid $coc-secondary-8L;
	margin-bottom: $spacer-lg;
	padding-top: $spacer-md;
	padding-bottom: $spacer-md;
	@include containerFluid();

	h1 {
		margin-bottom: 0;// Override default value

		.context,
		.subtitle {
			display: block;
			font-size: 60%;
		}

		.context {
			margin-bottom: $tiny-spacer-xs;
		}

		.subtitle {
			margin-top: $tiny-spacer-xs;
		}
	}

	// A breadcrumb-like link
	.page-context-link {
		@include reverseLinkUnderline();

		&::before {
			@include styleBreadcrumbIcon();
			content: '\e159';// .cicon-angle-left
			padding-right: $spacer-xxs;
			position: relative;
			top: -$basic-space*1rem;// For visual balance, otherwise the icon appears to be sitting below the baseline
		}
	}

	.col-action {
		margin-top: $spacer-sm;
	}

	@media( min-width: $breakpoint-sm ) {
		.col-action {
			margin-top: 0;
			text-align: right;
		}
	}

	@media( min-width: $breakpoint-md ) {
		padding-top: $spacer-lg;
	}

	@media( min-width: $breakpoint-xl ) {
		padding-top: $spacer-lg + $spacer-xs;// Additional padding for visual balancing
		padding-bottom: $spacer-lg;
	}

	// -------------------------------------------------------
	// Print
	//
	// -------------------------------------------------------

	@media print {
		background: transparent;
		border: transparent;
		padding: 0;

		.container-fluid {
			padding: 0;
		}
	}
}
