/* -------------------------------------------------------
   Start of _pagination
   ------------------------------------------------------- */

// Example HTML:
// <ul class="pagination">
//	<li class="page-item">
//		<a class="page-link" href="">Text</a>
//	</li>
// </ul>
.pagination {
	@include listUnstyled;
	@include flexbox();
	@include flex-wrap(wrap);
	margin-top: $pagination-my-spacing;
	margin-bottom: $pagination-my-spacing;

	.page-item {
		@include align-self(center);
		margin-right: $pagination-gutter;
		margin-bottom: $pagination-gutter;

		&.active .page-link,
		&.text-only .page-text {
			background-color: transparent;
			border-color: transparent;
			color: $pagination-active-color;

			&:hover {
				box-shadow: none;
			}

			&:active {
				border-color: $pagination-active-border-color;
			}

			&:focus:hover {
				box-shadow: $focus-box-shadow, $default-hover-shadow;
			}

			&:focus:active {
				box-shadow: $focus-box-shadow, $default-active-shadow;
			}
		}

		&.active .page-link {
			font-weight: $font-weight-semi-bold;
		}

		&.text-only .page-text {
			// For text-only items, the spacing is reduced for visual balance
			padding-left: $pagination-page-text-px;
			padding-right: $pagination-page-text-px;
		}

		&:first-child {
			.page-link {
				// Override BS4's border radii
				border-top-left-radius: $default-border-radius;
				border-bottom-left-radius: $default-border-radius;
			}
		}

		&:last-child {
			.page-link {
				// Override BS4's border radii
				border-top-right-radius: $default-border-radius;
				border-bottom-right-radius: $default-border-radius;
			}
		}
	}// End of .page-item

	.page-link {
		background-color: $pagination-bg;
		border: $pagination-border-width solid $pagination-border-color;
		border-radius: $default-border-radius;
		color: $pagination-color;
		display: block;
		line-height: $pagination-line-height;
		padding: $pagination-padding-y $pagination-padding-x;
		position: relative;
		text-align: center;
		text-decoration: none;
		transition: all $transition-duration $transition-timing-function;
		@include setInteractiveBoxShadow();

		&:hover {
			background-color: $pagination-hover-bg;
			border-color: $pagination-hover-border-color;
			color: $pagination-hover-color;
			text-decoration: none;
		}

		&:active {
			border-color: $pagination-active-border-color;
			color: $pagination-active-color;
		}

		[class*="cicon"] {
			font-size: 80%;

			&.left {
				margin-right: $tiny-spacer-sm;
			}

			&.right {
				margin-left: $tiny-spacer-sm;
			}
		}
	}// End of .page-link

	// Pagination with input field variant
	.form-inline {
		.form-control,
		.form-control-plaintext {
			margin-right: $pagination-gutter;
		}

		.form-control {
			text-align: center;
			width: 80px;
		}
	}


	.mobile-hide-text.justify-content-center {
		min-width: 255px;
	}

	@media( max-width: $breakpoint-xs-max ) {
		// By default, hide the inactive and text-only items to show only the basics
		.page-item {
			&.inactive,
			&.text-only {
				display: none;
			}
		}

		// Hide the text for the prev and next links; if desired
		&.mobile-hide-text {
			.page-item {
				&.prev,
				&.next {
					.text {
						display: none;
					}

					[class*="cicon"] {
						// Remove margins on icons
						&.left {
							margin-right: 0;
						}

						&.right {
							margin-left: 0;
						}
					}
				}
			}
		}// End of &.mobile-hide-text

		// Show everything that's hidden by default; if desired
		&.mobile-show-all {
			.page-item {
				&.inactive,
				&.text-only {
					display: block;
				}
			}
		}
	}// End of max-width: $breakpoint-xs-max

	@media( min-width: $breakpoint-md ) {
		.page-item {
			margin-right: $pagination-gutter-sm;
			margin-bottom: $pagination-gutter-sm;
		}
	}

	// -------------------------------------------------------
	// Print
	//
	// -------------------------------------------------------

	@media print {
		.page-link {
			border-width: $border-thin;
		}
	}
}// End of .pagination
