/* -------------------------------------------------------
   Start of _base
   ------------------------------------------------------- */

// stylelint-disable selector-list-comma-newline-after

// Taken from HTML5 CSS Reset
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
audio,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
mark,
menu,
meter,
nav,
output,
progress,
section,
summary,
time,
video {
	border: 0;
	margin: 0;
	outline: 0;
	padding: 0;
}

html {
	box-sizing: border-box;
	font-family: $font-primary;
	// Set font-size to 62.5% to reset rem units to match 10 pixels (e.g. 1.3em = 13px, 1rem == 10px)
	// Bootstrap has moved away from this method of font sizing. It makes 1rem - 16px.
	// font-size: 62.5%;
}

*,
*::before,
*::after {
	// Add more flexibilty to the box-sizing model
	box-sizing: inherit;
}

body {
	background-color: $coc-default-background;
	color: $coc-default-color;
	font-family: $font-primary;
	font-size: $base-font-size;
	font-weight: $font-weight-normal;
	line-height: $line-height-default;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

article,
aside,
dialog,
figure,
footer,
header,
hgroup,
nav,
section,
blockquote {
	display: block;
}

button,
[role=button] {
	cursor: pointer;

	&:focus {
		@include setOutlineGlow( $removeOutline: true );
	}
}

input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

input,
button {
	margin: 0;
	padding: 0;
}

input[type="search"] {
	outline-offset: -2px; // Correct the outline style in Safari.
	-webkit-appearance: none;

	&::-webkit-search-cancel-button {
		// Allow the search cancel button to appear for Chrome + Safari
		-webkit-appearance: searchfield-cancel-button;
	}
}
