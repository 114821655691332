/* -------------------------------------------------------
   Start of _tile
   Deprecated pattern
   ------------------------------------------------------- */

// Equal height tiles
// @dependency: Tiles must be contained in bootstrap rows and columns
// Apply the following class to the .row wrapper element
.row-equal-height-tiles {
	// Added these flex styles to ensure this pattern can be used even in
	// non-flexbox Boostrap grids
	@include flexbox();
	@include flex-wrap(wrap);

	> [class*="col-"] {
		@include flexbox();// Sets the equal height
	}

	.cui.tile {
		// Display the tile as a flex item to allow for the .tile-footer to align at the bottom
		.tile-footer {
			margin-top: auto;// The footer will align to the bottom of the card
		}
	}
}

.cui.tile {
	@include flexbox();
	@include flex-direction(column);
	margin-bottom: $gutter;
	padding: $spacer-lg;
	width: 100%;

	.tile-title {
		display: block;
		font-size: $tile-title-size;
		margin-bottom: $tile-title-margin-bottom;

		@media( min-width: $breakpoint-sm ) {
			font-size: $tile-title-size-xs;
		}

		@media( min-width: $breakpoint-md ) {
			font-size: $tile-title-size-sm;
		}

		@media( min-width: $breakpoint-lg ) {
			font-size: $tile-title-size-md;
		}
	}

	p {
		margin-bottom: $tile-p-margin-bottom;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.tile-footer {
		padding-top: $spacer-sm;
	}
}// End of .cui.tile

a.cui.tile {
	@include setInteractiveBoxShadow();
	background: $bg-light;
	border: $border-thin solid $border-color-medium;
	padding: $spacer-sm;
	text-decoration: none;
	transition-property: background, border, box-shadow;
	user-select: none;

	&:hover {
		background: $white;
	}

	&.light {
		background: $white;
		border-color: $border-color-light;

		&:hover {
			border-color: $border-color-medium;
		}
	}

	&.with-icon {
		.tile-icon {
			color: $coc-main;
			font-size: $cicon-size-md;
		}

		@media( max-width: $breakpoint-xs-max ) {
			@include flex-direction(row);
			@include flex-wrap(nowrap);

			.tile-media {
				-webkit-box-flex: 0 0 auto;
				-webkit-flex: 0 0 auto;
				-ms-flex: 0 0 auto;
				-moz-flex: 0 0 auto;
				flex: 0 0 auto;
				padding-right: $spacer-xs;
				padding-top: $tiny-spacer-xs;// Applied for vertical balance
			}

			.tile-body {
				@include flex-grow (1);
			}
		}

		@media( min-width: $breakpoint-md ) {
			.tile-icon {
				font-size: $cicon-size-lg;
			}
		}
	}// End of &.with-icon

	&.with-image {
		.background-image {
			background-size: contain;
			margin-bottom: $spacer-xxs;
			padding-bottom: 37.25%;

			@media( min-width: $breakpoint-md ) {
				padding-bottom: 56.25%;
			}
		}
	}// End of &.with-image

	.cimg {
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: $spacer-sm;
	}

	.tile-title {
		color: $coc-link;
		font-weight: $font-weight-semi-bold;
	}

	p {
		color: $coc-default-color;
	}
}// End of a.cui.tile


// -------------------------------------------------------
// Data tiles
//
// -------------------------------------------------------

.cui.tile-data {
	background: $bg-light;
	border: $border-thin solid $border-color-light;
	margin-bottom: $gutter;
	padding: $spacer-sm;
	width: 100%;

	.name {
		display: block;
		font-weight: $font-weight-bold;

		[class*="cicon-lg"] {
			margin-right: $spacer-xxs;
			vertical-align: middle;
		}
	}

	.value {
		color: $coc-default-color;
		display: block;
		font-size: 300%;
	}

	p {
		margin-bottom: $spacer-xs;

		&:last-child {
			margin-bottom: 0;
		}
	}

	@media( min-width: $breakpoint-md ) {
		.value {
			font-size: 320%;
		}
	}
}// End of .cui.tile-data

// Adjustments when the element is a link (making the entire tile clickable)
a.cui.tile-data {
	@include setInteractiveBoxShadow( $use-base-shadow: true );
	border-color: $coc-main;
	border-width: $btn-border-thin;
	color: inherit;// Inherit the colour from parent elements, and not from the default a styles
	display: block;
	text-decoration: none;
	transition: box-shadow $transition-duration $transition-timing-function, background $transition-duration $transition-timing-function;

	.value {
		color: $coc-main;
	}

	&:hover {
		background: $white;
	}
}

// -------------------------------------------------------
// Print
//
// -------------------------------------------------------

@media print {
	.cui.tile,
	.cui.tile-data {
		// MC.2018.03.06: Team decided to make the width 100% and adding a border-bottom
		// as a divider
		background: transparent;
		border: 0;
		border-bottom: $print-border;
		page-break-inside: avoid;
		padding: 0 0 $spacer-md 0;
	}

	.cui.tile {
		&.with-image {
			.background-image {
				display: none;
			}
		}
	}
}
