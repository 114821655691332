/* -------------------------------------------------------
   Start of _banners
   ------------------------------------------------------- */
.cui.coc-jumbotron {
	@include align-items(center);
	@include flexbox();
	@include flex-wrap(wrap);
	position: relative;
	padding: $spacer-sm;

	.background-image {
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		padding-top: 50%;// Dynamically size the element so the ratio is 2:1 (width:height)
		width: 100%;

		h1 {
			font-size: $h1-font-size-md;
		}
	}

	.cta-background {
		border-bottom: $border-thin solid $coc-secondary-8L;
		margin: auto;
		padding: $spacer-sm;
		text-align: center;
		width: 100%;
		z-index: $hero-banner-body-z;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin-bottom: $spacer-xs;
	}

	p {
		// Everything inside .cta-background is centered, however <p> tags
		// have a max-width applied to them universally so the block itself
		// needs to be centered as well
		margin-left: auto;
		margin-right: auto;
	}

	@media( min-width: $breakpoint-sm ) and ( max-width: $breakpoint-sm-max ) {
		.cta-background {
			// This sets the width of the content to be 75%,
			// because we still want the full element to be 100% across,
			// particularly for the black-opacity version
			$width: 75%;
			$side-padding: (100 - $width) / 2;
			padding-left: $side-padding;
			padding-right: $side-padding;
		}
	}

	@media( min-width: $breakpoint-md ) {
		min-height: $hero-banner-image-height; // 350px
		height: auto;

		.cta-background {
			border-bottom: none;
			padding-left: $spacer-sm;
			padding-right: $spacer-sm;
			width: 50%;

			h1 {
				font-size: $hero-banner-title-font-size;
			}

			&.cta-left {
				margin-right: auto;
				margin-left: 0;
				text-align: left;
			}

			&.cta-right {
				margin-right: 0;
				margin-left: auto;
				text-align: left;
			}

			&.cta-left,
			&.cta-right {
				p {
					// Reset the margins
					margin-left: 0;
					margin-right: 0;
				}
			}
		}

		.background-image {
			padding-top: 0;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}

	@media( min-width: $breakpoint-xl ) {
		.cta-background {
			width: 48%;
		}
	}
}

// Hero banner with long text
.cui.hero-banner-long-text {
	.mobile-image {
		width: 100%;
	}

	.background-image {
		display: none;
	}

	.hero-body-inner {
		background: $white;
		padding-top: $spacer-sm;
		padding-bottom: $spacer-lg;
		max-width: $breakpoint-xl;
		margin-left: $grid-gutter-width/2;
		margin-right: $grid-gutter-width/2;

		p {
			margin-left: auto;
			margin-right: auto;

			&:last-child {
				margin-bottom: 0;// Remove bottom whitespace to prevent double whitespacing with .hero-body-inner
			}
		}

		&.lead-paragraph {
			p:first-of-type {
				font-size: $large-font-size;
			}
		}
	}

	.title {
		text-align: center;
	}

	h1.title {
		margin-bottom: $spacer-lg;
	}

	.context-title {
		color: $context-title-color;
		display: block;
		font-size: $base-font-size;
		letter-spacing: $context-title-letter-space; //1.5px
		text-transform: uppercase;
	}

	@media( min-width: $breakpoint-sm ) {
		.mobile-image {
			display: none;
		}

		.background-image {
			display: block;
			height: $c-tch-bg-sm;// Default

			&.sm,
			&.md,
			&.lg,
			&.xl {
				height: $c-tch-bg-sm;
			}
		}

		.hero-body-inner {
			margin-top: -$spacer-xxl;
			margin-left: auto;
			margin-right: auto;
			padding: $spacer-lg $spacer-xl;
		}

		.context-title {
			margin-bottom: $spacer-xxs;
		}
	}

	@media( min-width: $breakpoint-md ) {
		.background-image {
			&.md,
			&.lg,
			&.xl {
				min-height: $c-tch-bg-md;
			}
		}

		.hero-body-inner {
			&.lead-paragraph {
				p:first-of-type {
					font-size: $larger-font-size;
				}
			}
		}

		.context-title {
			font-size: $large-font-size;
		}
	}

	@media( min-width: $breakpoint-lg ) {
		.background-image {
			&.lg {
				min-height: $c-tch-bg-lg;
			}

			&.xl {
				min-height: $c-tch-bg-xl;
			}
		}
	}
}

// -------------------------------------------------------
// Print
//
// -------------------------------------------------------
@media print {
	.cui.coc-jumbotron {
		height: auto;

		.background-image {
			display: none;
		}

		.cta-background {
			border-bottom: 0;
			margin: 0 !important;
			padding: 0;
			text-align: left;
			width: 100%;
		}

		p {
			margin-left: 0;
			margin-right: 0;
		}
	}

	.cui.hero-banner-long-text {
		.background-image {
			display: none;
		}

		.title {
			text-align: left;
		}

		.hero-body-inner {
			margin: 0 0 $spacer-lg 0;
			padding: 0;

			p {
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}
