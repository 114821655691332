/* -------------------------------------------------------
   Start of _breadcrumb
   ------------------------------------------------------- */
// 3 types of nav links are available
// Regular breadcrumb, navigation links, navigation inline links
.breadcrumb-container,
.navlinks-container {
	position: relative;

	.bg-overlay {
		position: absolute;
		top: 0;
		width: $spacer-lg;
		bottom: 0;
	}
}

.cui.breadcrumb {
	@include listUnstyled();
	background: transparent;// MC.2018.11.30: Overrides BS
	@include flexbox();
	@include flex-wrap(nowrap);
	margin-bottom: $spacer-md;
	overflow-x: auto;
	overflow-y: hidden;
	position: relative;
	white-space: nowrap;
	width: 100%;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;

	li {
		display: inline-block;

		&:last-child {
			padding-right: $spacer-lg;
		}

		+ li::before {
			@include styleBreadcrumbIcon();
			content: "\e15a";// .cicon-angle-right
			padding: 0 $tiny-spacer-sm;
		}

		&.active {
			a {
				color: $coc-default-color;
				cursor: default;
				text-decoration: none;
			}
		}
	}
}

// Normal breadcrumbs
.breadcrumb-container {
	.bg-overlay {
		@include gradient-x( $bcrumb-gradient-color-opaque, $bcrumb-gradient-color-transparent );
		left: 0;
	}

	// Specific breadcrumb styles
	.cui.breadcrumb {
		a {
			font-size: $smaller-font-size;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

// Navigation links
.navlinks-container {
	.bg-overlay {
		@include gradient-x( $bcrumb-gradient-color-transparent, $bcrumb-gradient-color-opaque );
		right: 0;
	}

	// Specific breadcrumb styles
	.cui.breadcrumb {
		li {
			+ li::before {
				content: '\e2d9';// .cicon-al-i
				font-size: $cicon-size-xs;
			}

			&.active {
				a {
					&:hover {
						text-decoration: none;
					}

					[class*="cicon-"] {
						color: $coc-default-color;
					}
				}
			}
		}

		a {
			text-decoration: none;

			&:hover {
				text-decoration: underline;

				[class*="cicon-"] {
					// Prevent underline on icon
					display: inline-block;
					text-decoration: none;
				}
			}

			[class*="cicon-"] {
				color: $coc-main;
				margin-right: $tiny-spacer-xs;
			}
		}
	}

	// Links should wrap to the next line and remain within the content area to adapt to any sized mobile view
	.inline-nav-link {
		width: 100%;
		text-align: center;
		padding-left: 0;

		li {
			display: inline-block;

			&:last-child {
				padding-right: $spacer-lg;
			}

			+ li::before {
				@include styleBreadcrumbIcon();
				content: '\e2d9';
				padding: 0 $tiny-spacer-sm;
			}

			&.active {
				a {
					color: $coc-default-color;
					cursor: default;
					text-decoration: none;
				}
			}
		}
	}
}

// -------------------------------------------------------
// Print
//
// -------------------------------------------------------

@media print {
	.breadcrumb-container,
	.navlinks-container {
		// MC.2018.03.06: Team decided to hide navigation and breadcrumbs from print view.
		display: none;
	}
}
