.b2bsigninhome {
    .b2bsignedinhome {
        padding: 46px 60px 20px 60px;
        .col-auto.col-sm-auto.col-md-auto.col-lg-8 {
            flex: 0 0 60%;
            max-width: 60%;
            .ms-content-block {
                &__title {
                    font-size: $msv-font-size-36;
                    color: $msv-white;
                    line-height: $msv-line-height-47;
                    font-weight: $msv-font-weight-bold;
                }
                &__text {
                    font-size: $msv-heading-text-size;
                    color: $msv-white;
                    line-height: 150%;
                    font-weight: $msv-font-weight-heavy;
                }
                &__cta {
                    margin-top: 18px;
                    .msc-cta__primary {
                        margin: 0px;
                    }
                }
            }
        }
        .col-auto.col-sm-auto.col-md-auto.col-lg-4 {
            flex: 0 0 40%;
            max-width: 40%;
        }
        .ms-content-block img {
            height: 344px;
        }
    }
    .top-sellers-text-block {
        h2 {
            font-size: $msv-font-size-xl;
            line-height: $msv-line-height-xl;
            font-weight: $msv-font-weight-normal;
            color: $msv-gray-900;
        }
        p {
            font-size: $msv-font-size-l;
            color: $msv-gray-900;
            padding-top: 12px;
        }
    }
    .default-container {
        .ms-tab {
            &-tabs-section {
                margin-left: 60px;
                border-radius: 100px;
                background-color: $msv-white-smoke;
                width: fit-content;
                &-li {
                    border-radius: 100px;
                    margin-right: 0px;

                    &[aria-selected='true'] {
                        background-color: $msv-primary;
                        ::before {
                            background-color: $msv-white;
                        }
                        .ms-tab-item-header {
                            color: $msv-white;
                            font-size: $msv-font-size-15;
                            font-weight: $msv-font-weight-above-normal;
                            line-height: $msv-text-line-height;
                        }
                    }
                    &[aria-selected='false'] {
                        background-color: $msv-white-smoke;
                        .ms-tab-item-header {
                            color: $msv-gray-1200;
                        }
                    }
                }
                .ms-tab-tabs-section-li:focus {
                    outline: none;
                }
            }
        }
        .ms-product-collection {
            padding-left: 40px;
            padding-right: 40px;
            margin-bottom: 0px;
            .msc-price {
                font-size: $msv-font-size-l;
                line-height: $msv-line-height-m;
                color: $msv-gray-900;
            }
            .msc-no-ratings {
                display: none;
            }
            .msc-flipper {
                display: none;
            }
        }
        .ms-tab-content-section {
            margin-top: 0px;
        }
    }
    .ms-text-block.top-sellers-text-block.alignment__center {
        padding-top: 50px;
    }

    .winterCollection {
        padding-left: 60px;
        padding-right: 60px;
        padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        max-width: 1440px;
        .ms-carousel {
            img {
                height: 475px;
            }
        }
        .col-md-6:first-child {
            flex: 0 0 65%;
            max-width: 65%;
        }
        .col-md-6:last-child {
            flex: 0 0 35%;
            max-width: 35%;
        }
        .ms-content-block {
            &__details {
                width: 100%;
                padding-right: 0px;
                .msc-cta__primary {
                    background-color: $msv-primary;
                    border-radius: 2px;
                }
            }
            &__title {
                font-size: $msv-heading-line-height;
                line-height: $msv-line-height-37;
                color: $msv-gray-900;
            }
            &__text {
                line-height: $msv-line-height-l;
                color: $msv-gray-900;
                ul {
                    margin-left: -15px;
                }
            }
        }
        .msc-carousel__indicators {
            display: none;
        }
    }
    .adventureWorks {
        .ms-text-block.alignment__center {
            font-size: $msv-font-size-xl;
            line-height: $msv-line-height-xl;
            padding-top: 73px;
        }
        .ms-content-block {
            &__details {
                text-align: center;
                padding-left: 130px;
                padding-right: 130px;
            }
            &__text {
                font-size: $msv-font-size-32;
                line-height: $msv-line-height-42;
                display: flex;
                color: $msv-gray-900;
            }

            &__cta {
                line-height: $msv-line-height-l;
                padding-top: 0px;
                margin-bottom: 109px;
                a:nth-child(1) {
                    display: inline-block;
                    border-right: 1px solid $msv-gray-300;
                    padding-right: 24px;
                    text-decoration: none;
                    font-size: $msv-font-size-l;
                    color: $msv-gray-500;
                }
                a:nth-child(2) {
                    text-decoration: none;
                    font-size: $msv-font-size-l;
                    color: $msv-gray-500;
                }
                a:hover {
                    text-decoration: underline;
                }
                .msc-cta__secondary {
                    margin-left: 48px;
                }
            }
            &__image {
                img {
                    margin: auto;
                }
            }
            .left-doublequotes,
            .right-doublequotes {
                font-size: $msv-font-size-126;
                line-height: $msv-line-height-126;
                color: $msv-primary;
                opacity: 0.6;
                margin-top: 40px;
            }
            .left-doublequotes {
                margin-right: 40px;
            }
            .right-doublequotes {
                margin-left: 40px;
            }
        }
    }
}
