/* -------------------------------------------------------
   Start of _widget.scss
   Other navigation pattern
   In page navigation
   ------------------------------------------------------- */
.cui.list-widget {
	.group-list-title {
		font-size: $h3-font-size-md;
		font-weight: $font-weight-semi-bold;
		// When the heading become a link, the circle arrow icon will be shows up
		a {
			text-decoration: none;
			display: inline-block;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	@include listUnstyled();
	display: block;

	ul {
		@include listUnstyled();
	}

	li {
		margin-bottom: $spacer-xxs;
	}

	a {
		color: $coc-default-color;
		display: inline-block;
	}

	//The arrow on the in page navigation list
	&.arrowed {
		border-radius: $default-border-radius;
		padding: $spacer-sm;
		width: 100%;
	}

	// in page navigation title
	.group-title {
		display: block;
		line-height: inherit;// Overrides styles if the element is a heading
		margin-bottom: 0;// Overrides styles if the element is a heading

		[class*="cicon-"] {
			color: inherit;
			font-size: $cicon-size-lg;
			margin-bottom: $tiny-spacer-xs;
		}
	}

	// Styles specifically for if the element is a link.
	// Contains hover styles and the arrow icon.
	a.group-title {
		text-decoration: none;
		color: $coc-main;

		&:hover {
			.group-title-text {
				text-decoration: underline;
			}
		}
	}

	.group-title-text {
		color: inherit;
		display: inline-block;
		font-size: $h3-font-size-md;
		font-weight: $font-weight-semi-bold;
		line-height: $line-height-compact;
		margin-bottom: $spacer-sm;
	}

	.group-title-desc {
		color: $coc-default-color;
		display: inline-block;
		font-size: $small-font-size;
		margin-bottom: $spacer-sm;
	}

	.group-title-text + .group-title-desc {
		// Bring the desc closer to the title text.
		margin-top: -$spacer-sm;
	}

	.group-links {
		li {
			margin-bottom: $spacer-xxs;
		}

		a {
			color: $coc-default-color;
			display: inline-block;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}

		&.arrowed {
			padding: 0;

			li {
				margin-bottom: 0;// Reset margin from above

				&:first-child a {
					border-top-width: $border-thin;
				}
			}

			a {
				// MC.2019.01.03: The border is split up like this to make it easier to
				// override the colour if a different background-colour is used for the
				// pattern. In particular, the first-child border is easily overridden.
				border-bottom-width: $border-thin;
				border-color: $border-color-medium;
				border-style: solid;
				@include flexbox();
				@include justify-content(space-between);
				// padding-top: $spacer-xxs;
				// padding-bottom: $spacer-xxs;
				transition-property: background;
				padding: $spacer-sm $spacer-xs;

				&:hover {
					background-color: $bg-medium;
					text-decoration: none;
				}
			}
		}
	}
}
