.business_partner {
    .business_partner_container {
        padding-left: 60px;
        padding-right: 60px;
        .ms-text-block {
            margin-bottom: 50px;
            font-size: $msv-font-size-s;
            font-weight: $msv-font-weight-400;
            line-height: $msv-line-height-s;
            color: $msv-gray-1000;
            font-style: $msv-font-weight-normal;
        }
        #text-block__0 {
            font-size: $msv-font-size-xl;
            font-weight: $msv-font-weight-400;
            line-height: $msv-line-height-xl;
            color: $msv-gray-900;
            font-style: $msv-font-weight-normal;
            margin-bottom: 0;
            h1 {
                font-size: 2.8125rem;
                line-height: 3.875rem;
                font-weight: 600;
                color: var(--msv-cart-heading-font-color);
                margin-bottom: 0;
                strong {
                    font-weight: 600;
                }
            }
        }
        .ms-business-sign-up {
            &__heading {
                font-weight: $msv-font-weight-bold;
                font-size: $msv-heading-text-size;
                line-height: $msv-line-height-26;
                font-style: $msv-font-weight-normal;
                color: $msv-gray-900;
            }
            &__cancel-button {
                display: none;
            }
        }
        .sign-up-item-signupToReceiveEmail {
            width: 100%;
            padding-top: 16px;
            color: $msv-gray-900;
            input[type='checkbox'] {
                margin-right: 5px;
                @include cui-focus();
            }
        }
    }
}
