$content-block-min-height: 150px;
$hero-body-max-length: 30em;
$hero-body-top-margin: 8px;
$hero-content-text-margin-top: 16px;
$hero-content-button-margin-top: 32px;
$hero-content-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.108), 0 1.6px 3.6px rgba(0, 0, 0, 0.132);
$hero-heading-color: $black;
$hero-heading-font-size: 2.8125rem;
$hero-heading-line-height: 3.875rem;
$hero-heading-max-length: 30em;
$hero-link-to-link-spacing: 16px;
$hero-links-top-margin: 32px;
$hero-links-secondary-padding: 12px 20px;

:root {
    // heading
    --msv-content-block-heading-font-size: #{$hero-heading-font-size};
    --msv-content-block-heading-font-color: var(--msv-font-primary-color);

    // Text theme
    --msv-content-block-textheme-dark: var(--msv-font-primary-color);
    --msv-content-block-textheme-light: #{$msv-gray-100};
    --msv-content-block-text-font-size: var(--msv-body-font-size-m);

    // primary button
    --msv-content-block-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-content-block-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-content-block-primary-btn-border: var(--msv-accent-brand-color);
}

@mixin overlay-text-on-image {
    .ms-content-block__details {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        // Add Height Auto because the height getting s0 from some other styles
        height: auto !important;
        left: 0;
        padding: 48px 60px 60px 60px;
        // Position changed from Absolute to Static for New Content Block
        position: static;
        right: 0;
        top: 0;
    }
}

.ms-content-block {
    min-height: $content-block-min-height;
    img {
        &[height='1px'] {
            width: 1px;
            height: 1px;
        }
    }
    &[data-m-layout='full-width'] {
        position: relative;

        .ms-content-block__image,
        .ms-content-block__image img {
            width: 100%;
            &[height='1px'] {
                width: 1px;
            }
        }

        .ms-content-block__link {
            width: 100%;
        }
        &.cui-heading {
            min-height: 55px;
            margin-top: 50px;
            .ms-content-block__details {
                padding: 0;
                height: auto !important;
                position: relative;
                .ms-content-block__title {
                    padding-bottom: 20px;
                    position: relative;
                    font-size: 2.2rem;
                    font-weight: 600;
                    margin-bottom: 0;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        height: 5px;
                        width: 50%;
                        background-color: $msv-red;
                        max-width: 200px;
                    }
                    @media (max-width: $msv-breakpoint-m) {
                        font-size: 1.6rem;
                        padding-bottom: 10px;
                    }
                }
            }
            @media (max-width: $msv-breakpoint-m) {
                margin-top: 25px;
                margin-bottom: 30px;
            }
        }
        .ms-content-block__details,
        &.textplacement__left .ms-content-block__details {
            text-align: left;
            align-items: flex-start;
            @media (max-width: $msv-breakpoint-m) {
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        &.textplacement__center .ms-content-block__details {
            align-items: center;
            text-align: center;
        }

        &.textplacement__right .ms-content-block__details {
            text-align: right;
            align-items: flex-end;
        }

        .ms-content-block__title {
            color: var(--msv-content-block-heading-font-color);
            font-weight: var(--msv-font-weight-normal);
            font-size: var(--msv-content-block-heading-font-size);
            font-weight: 600;
            max-width: $hero-heading-max-length;
            line-height: $hero-heading-line-height;
        }

        .ms-content-block__text {
            @include font-content(var(--msv-font-weight-normal), var(--msv-content-block-text-font-size), $msv-line-height-m);
            color: var(--msv-content-block-textheme-dark);
            max-width: auto;
        }

        &.texttheme__light .ms-content-block__title,
        &.texttheme__light .ms-content-block__text {
            color: var(--msv-content-block-textheme-light);
            text-shadow: $hero-content-shadow;
        }

        &.texttheme__dark .ms-content-block__title,
        &.texttheme__dark .ms-content-block__text {
            color: var(--msv-content-block-textheme-dark);
        }

        /* body should have top margin only when there's something above it */
        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        * + .ms-content-block__text {
            margin-top: $hero-content-text-margin-top;
        }

        .ms-content-block__cta {
            align-items: flex-end;
            justify-content: center;
        }

        /* links should have top margin only when there's something above it */
        * + .ms-content-block__cta {
            margin-top: $hero-content-button-margin-top;
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .ms-content-block__details {
            @include msv-layout-page-side-padding;
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;

            .msc-cta__primary {
                @include button-primary();
                margin: 0;
                display: inline-block;
            }

            .msc-cta__secondary {
                margin: $hero-link-to-link-spacing / 2;
                padding: $hero-links-secondary-padding;
            }
        }
        &.category-top-banner {
            padding-bottom: 250px;
            margin-bottom: 50px;
            .ms-content-block__title {
                font-weight: 600;
                width: 100%;
                max-width: 100%;
                text-align: center;
                margin-bottom: 30px;
            }
            .ms-content-block__details {
                width: 50%;
                height: auto;
                top: auto;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                background-color: $msv-white;
                padding: 100px;
                position: absolute;
            }
            @media screen and (max-width: $msv-breakpoint-xl) {
                .ms-content-block__details {
                    padding: 50px;
                    width: 75%;
                }
            }
            @media screen and (max-width: $msv-breakpoint-l) {
                padding-bottom: 0px;
                padding-left: 15px;
                padding-right: 15px;
                margin-bottom: 0;
                .ms-content-block__details {
                    padding: 50px;
                    position: static;
                    transform: none;
                    width: 100%;
                    text-align: center;
                    .ms-content-block__title {
                        text-align: center;
                        margin-bottom: 30px;
                        font-size: 2rem;
                        max-width: 100%;
                        width: 100%;
                    }
                }
            }
            @media screen and (max-width: $msv-breakpoint-m) {
                .ms-content-block__details {
                    padding: 30px;
                }
            }
        }

        /**
    * When the image is not added in content block.
    */
        div.ms-content-block__image:empty {
            + div.ms-content-block__details {
                position: relative;
                height: unset;
            }
        }

        /**
    * Text over image by default only in modern browsers.
    * We must reflow the text to bottom in IE 11 because it doesn't support srcset and the image will be too short to put text over it.
    */

        @supports (object-fit: cover) {
            .ms-content-block__image {
                object-fit: cover;
            }

            @include overlay-text-on-image;
        }

        /**
    * When the image is tall enough for IE 11 to display text on top of it.
    */

        @media screen and (min-width: $msv-breakpoint-l) {
            @include overlay-text-on-image;
        }

        @media screen and (min-width: $msv-breakpoint-m) {
            .ms-content-block__details,
            &.textplacement__left .ms-content-block__details,
            &.textplacement__center .ms-content-block__details,
            &.textplacement__right .ms-content-block__details {
                width: 100%;
                height: 0;
                overflow: inherit;
            }

            &.textplacement__center .ms-content-block__details {
                margin: 0 auto;
            }

            &.textplacement__right .ms-content-block__details {
                margin-left: auto;
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            .ms-content-block__cta {
                display: flex;
            }
        }
    }
}
