/* -------------------------------------------------------
   Start of _colours
   ------------------------------------------------------- */

// Internal placeholder:
// Sets the utility button styles for dark backgrounds
%btn-utility-on-dark-bg {
	.cui.utility-btn {
		color: $white !important;

		&:hover,
		&:active {
			color: $btn-utility-color !important;
		}
	}

	.cui.utility-btn,
	.cui.utility-btn-solid {
		border-color: $coc-secondary-5L !important;

		@media print {
			// For print, change the colours back to normal
			border-color: $btn-utility-border-color !important;
			color: $btn-utility-color !important;
		}
	}
}

.coc-transparent {
	background-color: transparent;

	&:hover {
		background: $white;
		color: $coc-default-color;
	}

	&:focus {
		background: $white;
	}
}

.coc-white {
	// MC.2018.02.07: Not using the mixin here because we do not want to change the colours for the <a> and .cicons
	background-color: $white;
	color: $coc-default-color;

	&[class*="cicon-"] {
		background-color: transparent;
		color: $white;
	}
}

.coc-white-opacity {
	@include setColours(rgba(255, 255, 255, 0.9), $black);
}

.coc-black-opacity {
	@include setColours(rgba(0, 0, 0, 0.8), $white);
}

.coc-main {
	@include setColours($coc-main, $white);
}

.coc-secondary-red {
	@include setColours($coc-secondary-red, $white);
}

.coc-secondary-green-safety {
	@include setColours($coc-secondary-green-safety, $black);

	// MC.2018.02.07: Please add notes on the use-case for specifying the different colour below
	// MC.2019.02.22: It appears that larger text (greater than 24px) can be white on safety
	// green and pass contrast rules.
	h1,
	h2,
	.h1,
	.h2 {
		color: $white !important;
	}
}

.coc-secondary-green-pedestrian {
	@include setColours($coc-secondary-green-pedestrian, $black);
}

.coc-secondary-blue-access {
	@include setColours($coc-secondary-blue-access, $white);
}

.coc-secondary {
	@include setColours($coc-secondary, $coc-secondary-10L);
	@extend %btn-utility-on-dark-bg;
}

.coc-secondary-1L {
	@include setColours($coc-secondary-1L, $coc-secondary-10L);
	@extend %btn-utility-on-dark-bg;
}

.coc-secondary-2L {
	@include setColours($coc-secondary-2L, $white);
	@extend %btn-utility-on-dark-bg;
}

.coc-secondary-3L {
	@include setColours($coc-secondary-3L, $black);
}

.coc-secondary-4L {
	@include setColours($coc-secondary-4L, $black);
}

.coc-secondary-5L {
	@include setColours($coc-secondary-5L, $black);
}

.coc-secondary-6L {
	@include setColours($coc-secondary-6L, $black);
}

.coc-secondary-7L {
	@include setColours($coc-secondary-7L, $coc-secondary-2D);
}

.coc-secondary-8L {
	@include setColours($coc-secondary-8L, $coc-secondary-2D);
}

.coc-secondary-9L {
	// JZ.02.26.2020 Not using the mixin here because we do not want to change the colours for the <a> and .cicons
	// coc-secondary-9L used as the medium background with lots of patterns such as contact info, data display wiget, image viewer ...
	background-color: $coc-secondary-9L;
	color: $coc-default-color;

	&[class*="cicon-"] {
		background-color: transparent;
		color: $coc-secondary-9L;
	}
}

.coc-secondary-10L {
	// JZ.02.26.2020 Not using the mixin here because we do not want to change the colours for the <a> and .cicons
	// coc-secondary-10L used as the medium background with lots of patterns such as contact info, data display wiget, image viewer ...
	background-color: $coc-secondary-10L;
	color: $coc-default-color;

	&[class*="cicon-"] {
		background-color: transparent;
		color: $coc-secondary-10L;
	}
}

.coc-black {
	@include setColours($black, $coc-secondary-10L);
	@extend %btn-utility-on-dark-bg;
}

.coc-secondary-1D {
	@include setColours($coc-secondary-1D, $coc-secondary-10L);
	@extend %btn-utility-on-dark-bg;
}

.coc-secondary-2D {
	@include setColours($coc-secondary-2D, $coc-secondary-10L);
	@extend %btn-utility-on-dark-bg;
}

.coc-secondary-3D {
	@include setColours($coc-secondary-3D, $coc-secondary-10L);
	@extend %btn-utility-on-dark-bg;
}

.coc-secondary-4D {
	@include setColours($coc-secondary-4D, $coc-secondary-10L);
	@extend %btn-utility-on-dark-bg;
}

.coc-secondary-5D {
	@include setColours($coc-secondary-5D, $black);
}

.coc-secondary-6D {
	@include setColours($coc-secondary-6D, $black);
}

.coc-secondary-7D {
	@include setColours($coc-secondary-7D, $black);
}

.coc-secondary-8D {
	@include setColours($coc-secondary-8D, $coc-secondary-2D);
}

.coc-secondary-9D {
	// JZ.02.26.2020 Not using the mixin here because we do not want to change the colours for the <a> and .cicons
	background-color: $coc-secondary-9D;
	color: $coc-default-color;

	&[class*="cicon-"] {
		background-color: transparent;
		color: $coc-secondary-9D;
	}
}

.coc-secondary-orange {
	@include setColours($coc-secondary-orange, $black);
}

.coc-secondary-orange-L {
	@include setColours($coc-secondary-orange-L, $black);
}

.coc-secondary-orange-D {
	@include setColours($coc-secondary-orange-D, $black);
}

.coc-secondary-yellow {
	@include setColours($coc-secondary-yellow, $black);
}

.coc-secondary-yellow-L {
	@include setColours($coc-secondary-yellow-L, $black);
}

.coc-secondary-yellow-D {
	@include setColours($coc-secondary-yellow-D, $black);
}

.coc-secondary-green {
	@include setColours($coc-secondary-green, $black);
}

.coc-secondary-green-L {
	@include setColours($coc-secondary-green-L, $black);
}

.coc-secondary-green-D {
	@include setColours($coc-secondary-green-D, $white);
}

.coc-secondary-blue {
	@include setColours($coc-secondary-blue, $black);
}

.coc-secondary-blue-L {
	@include setColours($coc-secondary-blue-L, $black);
}

.coc-secondary-blue-D {
	@include setColours($coc-secondary-blue-D, $white);
}

.coc-secondary-dark-blue {
	@include setColours($coc-secondary-dark-blue, $white);
}

.coc-secondary-dark-blue-L {
	@include setColours($coc-secondary-dark-blue-L, $white);
}

.coc-secondary-dark-blue-D {
	@include setColours($coc-secondary-dark-blue-D, $white);
}

.coc-secondary-purple {
	@include setColours($coc-secondary-purple, $white);
}

.coc-secondary-purple-L {
	@include setColours($coc-secondary-purple-L, $white);
}

.coc-secondary-purple-D {
	@include setColours($coc-secondary-purple-D, $white);
}

.coc-secondary-pink {
	@include setColours($coc-secondary-pink, $white);
}

.coc-secondary-pink-L {
	@include setColours($coc-secondary-pink-L, $white);
}

.coc-secondary-pink-D {
	@include setColours($coc-secondary-pink-D, $white);
}

// Print styles
@media print {
	[class*="coc-"] {
		background-color: transparent !important;
		color: $black !important;

		a:not([class*="btn-"]) {
			color: $black !important;
		}
	}
}
