/* -------------------------------------------------------
   Start of _parallax-banner
   ------------------------------------------------------- */

.parallax {
	position: relative;
	padding: 0;
	// There may be CSS performance issues as background-attachment is an expensive
	// CSS rule. See possible solutions to fix the performance issues here:
	// https://www.fourkitchens.com/blog/article/fix-scrolling-performance-css-will-change-property/
	// and here:
	// https://medium.com/vehikl-news/fixed-background-image-performance-issue-6b7d9e2dbc55
	// Requires separating the background image onto its own layer however that
	// cannot easily be done with inline SCSS in the HTML. JS or another solution is required.
	// The video will only playing on the desktop (>992px)
	video { display: none; }

	.background-image,
	.mobile-video-background-image {
		background-repeat: repeat-y;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.parallax-inner {
		position: relative;
		width: 100%;
		z-index: 1;
		padding-right: $spacer-sm;
		padding-left: $spacer-sm;
	}
	// The body text will be align to the left on the mobile
	.body,
	.video-body {
		padding: $spacer-lg;
		text-align: left !important;
	}

	.body {
		margin: 30px 15px;
	}

	.video-body {
		margin: 30px 15px;
	}

	h1,
	h2,
	h3 {
		font-size: $h2-font-size;
		margin-bottom: $spacer-md;

		@media( min-width: $breakpoint-sm ) {
			font-size: $h2-font-size-xs;
		}

		@media( min-width: $breakpoint-md ) {
			font-size: $h2-font-size-sm;
		}

		@media( min-width: $breakpoint-lg ) {
			font-size: $h2-font-size-md;
		}
	}

	p:last-child {
		margin-bottom: 0;
	}

	@media( min-width: $breakpoint-sm ) {
		.body,
		.video-body {
			margin: auto;
			&.text-right { text-align: right !important; }
			&.text-left { text-align: left !important; }
			&.text-center { text-align: center !important; }
		}
	}

	@media( min-width: $breakpoint-md ) {
		.parallax-inner {
			padding-right: $spacer-xl;
			padding-left: $spacer-xl;
		}

		.body {
			margin-top: 20vh;
			margin-bottom: 20vh;
			margin-left: auto;
			margin-right: auto;
		}
	}

	@media( min-width: $breakpoint-lg ) {
		.body {
			margin-top: 40vh;
			margin-bottom: 40vh;
			margin-left: auto;
			margin-right: auto;
		}

		.video-body {
			margin-top: 25%;
			margin-bottom: 80%;
			margin-left: auto;
			margin-right: auto;
		}

		.parallax-inner.video {
			//min-height: 50vh;
		}

		.video-body {
			//margin: -80vh auto;
		}

		video {
			display: block;
			width: 100%;
			position: absolute;
			top: 0;
			z-index: $parallax-video-z;

			source {
				min-width: 100%;
				top: 0;
				z-index: -9999;
			}
		}

		.mobile-video-background-image {
			display: none;
		}
	}
}// End of .parallax
