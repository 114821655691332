/* -------------------------------------------------------
   Start of _alert-banner
   ------------------------------------------------------- */

// Internal mixin:
// Sets the accent and overlay (text) colours for the alerts
@mixin setAlertBannerVariants( $accentColour, $overlayColour ) {
	border-color: $accentColour;

	.alert-header {
		background: $accentColour;
		color: $overlayColour;
	}

	.alert-icon {
		color: $overlayColour;
	}
}

@mixin setAlertSmallVariants( $accentColour, $icon, $iconColour: $accentColour ) {
	border-color: $accentColour;

	&::before {
		color: $iconColour;
		content: $icon;
	}
}

.cui.alert-small {
	// MC.2019.03.21: See PLPS-280 for more information.
	border-style: solid;
	border-width: $border-thin;
	border-left-width: $alert-border-left-width;
	display: inline-block;
	font-weight: $font-weight-semi-bold;
	padding: $tiny-spacer-xs $spacer-xxs;

	&::before {
		font-family: cicon;
		margin-right: $tiny-spacer-xs;
	}

	&.error,
	&.warning {
		@include setAlertSmallVariants(
			$alert-error-color,
			'\e07d'// cicon-exclamation-triangle
		);
	}

	&.caution {
		@include setAlertSmallVariants(
			$alert-caution-color,
			'\e07d',// cicon-exclamation-triangle
			$coc-default-color
		);
	}

	&.information {
		@include setAlertSmallVariants(
			$alert-info-color,
			'\e07f'// cicon-exclamation-circle
		);
	}

	&.success {
		@include setAlertSmallVariants(
			// MC.2019.03.21: Team (Peter, Akiko, Melissa) decided to change
			// the green from $alert-success-color to pass contrast, as the original
			// is too light for this style of alert.
			$coc-secondary-green,
			'\e035'// cicon-check-circle
		);
	}
}

.cui.alert-banner {
	background: $white;
	border: $alert-border-width-mobile solid transparent;
	margin-bottom: $spacer-md;
	width: 100%;

	.alert-title {
		font-size: $base-font-size;
		font-weight: $font-weight-semi-bold;
		margin-bottom: 0;
	}

	.alert-type {
		font-weight: $font-weight-semi-bold;
	}

	.alert-body {
		padding: $alert-padding;

		p {
			margin-bottom: $spacer-xxs;
			max-width: none;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&.global {
		.alert-header {
			@include flexbox();
			@include align-items(baseline);
			padding: $alert-padding;
		}

		.alert-icon,
		.alert-title {
			margin-right: $alert-padding;
		}

		.alert-action-btn {
			//background: transparent;
			border: 0;
			color: inherit;
			margin-left: auto;
			padding: $tiny-spacer-xs;
			@include setInteractiveBoxShadow( $use-base-shadow: false );

			[class*="cicon"][class*="thin"] {
				font-size: $cicon-size-xs;// Reduce size for visual balance
			}
		}
	}

	&.inline {
		border-left-width: $alert-inl-border-left-width;

		.alert-icon {
			float: left;
			margin-left: -( $alert-inl-border-left-width + $alert-padding );
			text-align: center;
			width: $alert-inl-border-left-width;
		}

		.alert-title {
			+ p {
				margin-top: $spacer-xxs;
			}
		}
	}

	&.error,
	&.warning {
		@include setAlertBannerVariants( $alert-error-color, $alert-error-text-color );

		.alert-action-btn {

			background: $alert-error-color;

			&:hover,
			&:active {
				background: $alert-error-color-hover;
			}
		}
	}

	&.caution {
		@include setAlertBannerVariants( $alert-caution-color, $alert-caution-text-color );

		.alert-action-btn {

			background: $alert-caution-color;

			&:hover,
			&:active {
				background: $alert-caution-color-hover;
			}
		}
	}

	&.information {
		@include setAlertBannerVariants( $alert-info-color, $alert-info-text-color );

		.alert-action-btn {

			background: $alert-info-color;

			&:hover,
			&:active {
				background: $alert-info-color-hover;
			}
		}
	}

	&.success {
		@include setAlertBannerVariants( $alert-success-color, $alert-success-text-color );

		.alert-action-btn {

			background: $alert-success-color;

			&:hover,
			&:active {
				background: $alert-success-color-hover;
			}
		}
	}

	@media( max-width: $breakpoint-xs-max ) {
		&.global {
			.alert-action-btn {
				// Hide the text for the alert action buttons (close, minimize, etc)
				// and show the icon for small mobile screens
				.text {
					display: none;
				}

				.left {
					margin-right: 0;
				}

				.right {
					margin-left: 0;
				}
			}
		}
	}

	@media( min-width: $breakpoint-md ) {
		border: $alert-border-width solid transparent;

		.alert-title {
			font-weight: $font-weight-bold;
		}

		.alert-body {
			padding: $alert-padding-sm;
		}

		.alert-type {
			font-weight: $font-weight-bold;
			display: inline;
		}

		&.global {
			.alert-header {
				padding-left: $alert-padding-sm;
				padding-right: $alert-padding-sm;
			}

			.alert-icon,
			.alert-title {
				margin-right: $alert-padding-sm;
			}
		}

		&.inline {
			.alert-icon {
				margin-left: -( $alert-inl-border-left-width + $alert-padding-sm );
			}
		}
	}

	@media( min-width: $breakpoint-xl ) {
		&.global {
			.alert-body {
				margin-left: auto;
				margin-right: auto;
				max-width: $breakpoint-lg;
			}
		}
	}

	// -------------------------------------------------------
	// Print
	//
	// -------------------------------------------------------

	@media print {
		&.global {
			border-color: $print-border-color;

			.alert-header {
				background: transparent;
				padding-top: $spacer-sm;
			}

			.alert-body {
				max-width: none;
				padding-top: 0;
			}

			.alert-action-btn {
				display: none;
			}

			.alert-icon {
				margin-right: $spacer-xxs;
			}
		}

		&.inline {
			border-left-width: $alert-border-width;
			border-color: $print-border-color;

			.alert-icon {
				margin-left: 0;
				margin-right: $spacer-xxs;
				width: auto;
			}
		}
	}
}// End of .cui.alert-banner
