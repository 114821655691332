$msv-order-summary-font: $msv-primary-font-family;
$msv-order-summary-padding: 15px;
$msv-order-summary-heading-padding-bottom: 7px;
$msv-order-summary-items-border-radius: 2px;
$msv-order-summary-label-line-height: 20px;
$msv-order-summary-line-padding: 5px 0;

//style presets
:root {
    --msv-order-summary-bg: var(--msv-bg-color);
    --msv-order-summary-font-color: var(--msv-font-primary-color);
    --msv-order-summary-font-size: var(--msv-body-font-size-l);
    --msv-order-summary-border: var(--msv-font-primary-color);

    // heading
    --msv-order-summary-heading-font-color: var(--msv-font-primary-color);
    --msv-order-summary-heading-font-size: var(--msv-body-font-size-xl);
}

.msc-order-summary {
    &-wrapper {
        align-items: center;
        background-color: var(--msv-order-summary-bg);
        display: flex;
        flex: 0 0 368px;
        flex-direction: column;
        margin: 0 auto;
        padding: $msv-order-summary-padding;
        height: fit-content;
        border: 1px solid var(--msv-cart-border);
    }

    @media screen and (max-width: $msv-breakpoint-s) {
        margin: unset;
    }

    &__heading {
        @include font-content(var(--msv-font-weight-heavy), var(--msv-order-summary-heading-font-size), $msv-line-height-xl);
        color: var(--msv-order-summary-heading-font-color);
        font-style: $msv-order-summary-font;
        padding-bottom: $msv-order-summary-heading-padding-bottom;
        width: 100%;
        border-bottom: 1px solid var(--msv-cart-border);
        padding-bottom: 15px;
    }

    &__items {
        color: var(--msv-order-summary-font-color);
        border-radius: $msv-order-summary-items-border-radius;

        @include font-content(var(--msv-font-weight-normal), var(--msv-order-summary-font-size), $msv-line-height-l);
        width: 100%;
        margin-bottom: 15px;
        .msc-promo-code {
            &__group {
                display: flex;
                padding-top: 10px;
            }

            &__input-box {
                flex: 0 0 70%;
                margin-right: 10px;
                padding-left: 8px;
                @include form-control();
            }
            &__apply-btn {
                flex: 0 0 25%;
                min-width: auto;
                @include button-utility();
                height: 47px;
            }
        }
        .msc-promo-code-heading,
        .msc-promo-code__form-container {
            p {
                margin: 0;
            }
        }
        > p {
            margin-bottom: 0;
            padding: 5px 0;
            &.msc-order-summary__line-total {
                margin-top: 10px;
            }
        }
    }

    &__line {
        &-net-price,
        &-tax-amount,
        &-shipping,
        &-loyalty,
        &-gift-card,
        &-other-charges,
        &-total-discounts {
            padding: 10px 0 10px;
        }

        &-total {
            @include font-content(var(--msv-font-weight-bold), var(--msv-order-summary-font-size), $msv-line-height-l);
            padding: 10px 0 20px;
            .msc-order-summary__value {
                font-weight: 600;
            }
        }
    }

    &__value {
        float: right;
        text-align: right;
        font-weight: var(--msv-font-weight-normal);
    }
}
.msc-order-summary-wrapper {
    .btn-box {
        flex: 0 0 100%;
        width: 100%;
        text-align: right;
        .msc-cart__btn-checkout.msc-btn {
            display: inline-flex;
        }
    }
    .spinner-wrap {
        text-align: center;
        p {
            font-weight: 600;
            font-size: 1rem;
            position: absolute;
            left: calc(50% + 20px);
            top: calc(50% + 60px);
            transform: translate(-50%, -50%);
        }
    }
}
.ms-checkout {
    .msc-order-summary-wrapper {
        padding-bottom: 0;
    }
    .msc-order-summary {
        padding-bottom: 0;
        &__items-accordion {
            margin: 0 -15px;
            min-width: calc(100% + 30px);
            .card-header {
                background-color: $msv-gray-200;
                padding: 20px 25px;
                font-size: 1rem;
                border-bottom: 1px solid $msv-gray-900;
                border-top: 1px solid transparent;
                cursor: pointer;
                margin-bottom: 0;
                font-weight: 600;
                position: relative;
                &::after {
                    @include msv-icon();
                    content: $msv-ChevronDown;
                    position: absolute;
                    right: 15px;
                    top: 25px;
                }
                &.active {
                    border-top-color: $msv-gray-900;
                    border-bottom-color: transparent;
                    background-color: transparent;
                    &::after {
                        transform: rotate(180deg);
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 3px;
                        background-color: $msv-primary;
                    }
                }
                @include cui-focus();
            }
            .card {
                border: none;
                .card-body {
                    padding-top: 0;
                }
            }
        }
    }
}

.ms-cart {
    .msc-order-summary {
        &__heading {
            border-bottom: none;
        }
        &__item-count {
            border-bottom: 1px solid var(--msv-cart-border);
            width: 100%;
            display: inline-block;
            padding-bottom: 10px;
            font-size: 1rem;
            margin-bottom: 0;
        }
    }
}
@media screen and (min-width: $msv-breakpoint-l) {
    .msc-order-summary-wrapper {
        margin: 0 0 30px 0;
    }
}
