/* -------------------------------------------------------
   Start of _card
   ------------------------------------------------------- */
// Equal height cards
// @dependency: Cards must be contained in bootstrap rows and columns
// Apply the following class to the .row wrapper element
.row-equal-height-cards {
	// Added these flex styles to ensure this pattern can be used even in
	// non-flexbox Boostrap grids
	@include flexbox();
	@include flex-wrap(wrap);

	> [class*="col-"] {
		@include flexbox();// Sets the equal height
	}

	.cui.card {
		.card-media {
			img {
				@include flex-shrink (0);// MC.2018.03.28: For IE11, required to prevent improper aspect ratio behavior
			}
		}
	}
}

.media {
	.media-body {
		overflow-wrap: break-word;
		word-wrap: break-word;
	}
}

.cui.card {
	background: $coc-default-background;
	border: $card-border;
	border-radius: $default-border-radius;
	@include flexbox();
	@include flex-direction(column);
	margin-bottom: $card-ex-spacing;
	width: 100%;

	&.small {
		flex-direction: row;
		min-width: 32.4%;

		.card-media {
			border-bottom: 0;
			position: relative;
			$col-size: 4;
			@include calcColWidth( $col-size );
			flex-grow: 1;
			flex-shrink: 1;
			flex-basis: auto;

			.background-image {
				border-top-left-radius: $default-border-radius - $border-thin;
				border-top-right-radius: 0;
				border-bottom-left-radius: $default-border-radius - $border-thin;
				border-bottom: 0;
			}
		}

		.card-body {
			$col-size: 8;
			@include calcColWidth( $col-size );
			padding: $spacer-xs !important;

			.card-title {
				font-size: $base-font-size !important;
				color: $coc-link !important;
			}
		}
	}

	&.article-btn {
		position: relative;
		cursor: pointer;
		text-align: left;
		border: 0;
		padding: 0;

		.card-body {
			p {
				font-weight: normal;
			}
		}
	}

	&.light {
		background: $bg-light;
	}

	&.with-icon {
		[class*="cicon-"] {
			font-size: $cicon-size-md;

			@media( min-width: $breakpoint-md ) {
				font-size: $cicon-size-lg;
			}
		}

		.card-body {
			padding-top: $spacer-xxs;
		}
	}

	&.with-data {
		.name {
			display: block;
			font-weight: $font-weight-bold;

			[class*="cicon-"] {
				font-size: $cicon-size-lg;
				margin-right: $spacer-xxs;
				vertical-align: middle;
			}
		}

		.value {
			color: $coc-default-color;
			display: block;
			font-size: 300%;
			line-height: $card-title-line-height;
		}

		.card-body {
			padding-top: $spacer-xxs;
		}
	}

	.card-media {
		@include flex-shrink (0);// Do not allow the media to shrink. Required for IE11.

		&.spaced {
			// Spaced class provides whitespace for cicons, cimgs, and data.
			padding: $card-body-padding $card-body-padding 0;
		}
	}

	.background-image,
	.video-container {
		// The border is used to add visual constract for images that may have light edges.
		border-bottom: $card-border;
	}

	.background-image,
	.btn-video {
		// MC.2019.03.27: Moving the overflow: hidden and border-radius styles here because
		// if overflow: hidden is applied to the card, it clips off the focus styles when the
		// card contains the video button.
		// The border-radius is reduced here because the default (0.4) isn't visually correct
		// as the card already has a border.
		border-top-left-radius: $default-border-radius - $border-thin;
		border-top-right-radius: $default-border-radius - $border-thin;
		overflow: hidden;// Ensures images do not protrude over the border-radius.
	}

	.background-image {
		position: relative;
	}

	// New style added on May, 2021
	// .background-image-container .cui.label-text {
	// 	position: absolute;
	// 	border-radius: 0;

	// 	&.top-left {
	// 		top: 0;
	// 		left: 0;
	// 	}

	// 	&.top-right {
	// 		top: 0;
	// 		right: 0;
	// 	}

	// 	&.bottom-left {
	// 		bottom: 0;
	// 		left: 0;
	// 	}

	// 	&.bottom-right {
	// 		bottom: 0;
	// 		right: 0;
	// 	}
	// }

	.video-container {
		@include video-container();
	}

	img:not(.cimg) {
		width: 100%;
	}

	.card-body {
		-webkit-box-flex: 1 1 auto;
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		-moz-flex: 1 1 auto;
		flex: 1 1 auto;
		padding: $card-body-padding;
		overflow-wrap: break-word;
		word-wrap: inherit;
	}

	.card-context-title {
		color: $context-title-color;
		display: block;
		font-weight: $font-weight-normal;
		font-size: $small-font-size;
		margin-bottom: $spacer-xxs;
	}

	.card-title {
		color: $coc-default-color;
		font-size: $card-title-size;
		font-weight: $font-weight-semi-bold;
		line-height: $card-title-line-height;
		margin-bottom: $spacer-xxs;
		word-break: inherit;

		@media( min-width: $breakpoint-sm ) {
			font-size: $card-title-size-xs;
		}

		@media( min-width: $breakpoint-md ) {
			font-size: $card-title-size-sm;
		}

		@media( min-width: $breakpoint-lg ) {
			font-size: $card-title-size-md;
		}
	}

	p {
		margin-bottom: $card-p-margin-bottom;
		word-break: inherit;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.card-footer {
		// BS overrides
		background: transparent;
		border: 0;
		padding: 0 $card-body-padding $card-body-padding;
	}
}// End of .cui.card

a.cui.card,
button.cui.card {
	@include setInteractiveBoxShadow(true);
	//box-shadow: $default-base-shadow;
	color: inherit;// Inherit the colour from parent elements, and not from the default <a> styles
	text-decoration: none;
	transition-property: box-shadow;

	.card-title,
	&.with-data .value {
		color: $coc-link;
		transition: color $transition-duration $transition-timing-function;
	}

	&.light {
		transition-property: background, box-shadow;

		&:hover,
		&:active {
			background: $white;
		}
	}

	&.with-data {
		border-color: $coc-main;
		border-width: $btn-border-thin;

		&:hover {
			border-width: $btn-border-thin;
			border-color: $coc-main;
		}
	}

	&:hover,
	&:active {
		border: $card-border-hover;

		.card-title,
		&.with-data .value {
			color: $coc-link-hover-color;
		}
	}

	&.with-icon,
	&.with-data {
		[class*="cicon-"] {
			color: $coc-main;
		}
	}
}// End of a.cui.card

// -------------------------------------------------------
// Print
//
// -------------------------------------------------------

@media print {
	.cui.card {
		border: 0;
		border-bottom: $print-border;
		border-radius: 0;
		padding: 0 0 $card-body-padding 0;

		.card-media {
			border-bottom: 0;

			img {
				margin-right: $card-body-padding;
				width: 200px;
			}
		}

		.card-body {
			padding: 0;
		}

		.card-footer {
			padding: 0;
			margin-top: $spacer-xxs;
		}

		.video-container,
		.background-image {
			display: none;
		}
	}
}
