/* -------------------------------------------------------
   Start of _contact-info-block.scss test
   ------------------------------------------------------- */
.contact-info {
	width: 100%;
	border-radius: $default-border-radius;
	background-color: $coc-default-background;
	border: $border-thin solid $border-color-light;

	// [class*="col-count-"],
	.contact-body,
	.contact-footer {
		padding: $contact-info-space-xxs;
	}

	.contact-body {
		.label-text {
			margin-bottom: $tiny-spacer-xs;
			background: $coc-secondary-9L;
		}

		.contact-title {
			font-size: $h2-font-size;
			margin-bottom: $contact-info-space-xs;

			@media( min-width: $breakpoint-sm ) {
				font-size: $h2-font-size-xs;
			}

			@media( min-width: $breakpoint-md ) {
				font-size: $h2-font-size-sm;
			}

			@media( min-width: $breakpoint-lg ) {
				font-size: $h2-font-size-md;
			}
		}

		.subtitle {
			font-weight: $font-weight-bold;
		}

		// The icon label group dispaly on the content area
		.icon-label-group {
			line-height: $line-height-compact;
			margin-bottom: $contact-info-space-sm;
			max-width: none;

			.detail {
				word-break: break-word;
				//overflow-wrap: break-word;  //the new name is not supported by IE, Edge, or Firefox
				//tested on edge: prevent long word e.g. email will cut off by the end
				a[href^='mailto:'] {
					word-break: break-all;
				}// only break the words for the email
			}

			ul,
			ol,
			p {
				margin-bottom: 0;
			}
		}

		// Placed information without using the bootstrap grid
		[class*="col-count-"] {
			word-break: break-word;
		}
	}

	address {
		font-style: normal;
	}

	.contact-footer {
		border-top: $border-thin solid $border-color-light;
	}

	// themes
	&.bg-light {
		border: $border-thin solid $border-color-medium !important;

		.label-text {
			background: $coc-secondary-8L;
			color: $black;
		}

		.contact-footer {
			border-top: $border-thin solid $border-color-light;
		}
	}

	&.bg-medium {
		border: $border-thin solid $border-color-medium !important;

		.label-text {
			background: $coc-secondary-7L;
			color: $black;
		}

		.contact-footer {
			border-top: $border-thin solid $white;
		}
	}

	&.bg-transparent {
		border: $border-thin solid $border-color-light !important;

		.label-text {
			background: $coc-secondary-9L;
			color: $black;
		}

		.contact-footer {
			border-top: $border-thin solid $border-color-light;
			//background: $bg-light;
		}
	}

	// style for the access icon display inside of the icon-label-group
	// Applied on the access icon group
	.hide-icon.icon-label-group {
		.access-icon {
			display: block;
			margin-right: $contact-info-space-xxxs;
		}

		.access-icon-group {
			padding-left: 0;
		}
	}

	.access-icon-group {
		display: block;
		padding-left: $contact-info-space-md;
		margin-top: $contact-info-space-s;

		li {
			display: inline-block;
			list-style: none;
		}

		.access-icon {
			position: relative !important;
			padding: 0;
		}

		.icon-label {
			margin-right: $contact-info-space-xxxs;
			margin-bottom: $contact-info-space-xxxs;
			height: $cicon-size-lg;
			width: $cicon-size-lg;
			display: inline;
			min-width: auto;
		}

		.icon-descript {
			display: inline-block;
			margin-left: $contact-info-space-xxs;
		}
	}

	@media( max-width: $breakpoint-xs-max ) {
		.contact-footer {
			.cui.btn-md {
				display: inline-block;
				margin-bottom: $tiny-spacer-sm;
			}
		}
	}
	// 767px
	@media( max-width: $breakpoint-sm-max ) {
		[class*="col-count-"] {
			column-count: 1;
		}
	}
	// 576px
	@media( min-width: $breakpoint-sm ) {
		.background-image {
			margin: $contact-info-space-s 0 $contact-info-space-s $contact-info-space-s;
		}
		// [class*="col-count-"],
		.contact-body,
		.contact-footer {
			padding: $contact-info-space-s;
		}

		.contact-footer {
			.cui.btn-md {
				margin-right: $contact-info-space-s;
				margin-bottom: $tiny-spacer-sm;
			}
		}
	}

	@media( min-width: $breakpoint-md ) {
		.background-image {
			margin: $contact-info-space-md 0 $contact-info-space-md $contact-info-space-md;
		}

		.contact-body,
		.contact-footer {
			padding: $contact-info-space-md;
		}
	}

	@media( min-width: $breakpoint-lg ) {
		.background-image {
			margin: $contact-info-space-lg 0 $contact-info-space-lg $contact-info-space-lg;
		}

		.contact-body,
		.contact-footer {
			padding: $contact-info-space-lg;
		}
	}

	// vertical alignment
	&.constrained {
		.contact-media,
		.contact-body,
		.contact-footer {
			max-width: 100% !important;
			flex: 0 0 100% !important;
			-webkit-box-flex: 0 0 100% !important;
			-webkit-flex: 0 0 100% !important;
			-moz-flex: 0 0 100% !important;
			-ms-flex: 0 0 100% !important;
			width: 100%;
		}

		[class*="col-count-"] {
			column-count: 1 !important;
		}

		@media( min-width: $breakpoint-sm ) {
			.background-image {
				margin: $contact-info-space-s;
			}
		}
		@media( min-width: $breakpoint-md ) {
			.background-image {
				margin: $contact-info-space-md;
			}
		}
		@media( min-width: $breakpoint-lg ) {
			.background-image {
				margin: $contact-info-space-lg;
			}
		}
	}

	// compact version
	&.compact {
		.contact-body {
			.icon-label-group,
			p {
				margin-bottom: $spacer-xxs;
			}

			.icon-descript span {
				display: inline;
			}
		}

		.contact-footer {
			padding: $spacer-xxs;
		}

		@media( min-width: $breakpoint-md ) {
			.background-image {
				margin: $contact-info-space-s 0 $contact-info-space-s $contact-info-space-s;
			}

			.contact-body,
			.contact-footer {
				padding: $contact-info-space-s;
			}
		}

		@media( min-width: $breakpoint-lg ) {
			.background-image {
				margin: $contact-info-space-s 0 $contact-info-space-s $contact-info-space-s;
			}

			.contact-body,
			.contact-footer {
				padding: $contact-info-space-s;
			}
		}
	}

	@media print {
		.contact-media {
			display: none;
		}
	}
}

// contact block equal height layout
// use the same class .row-equal-height-cards from card layout
.row-equal-height-cards .contact-info,
.row-equal-height-cards .contact-info.constrained,
.row-equal-height-cards .contact-info.compact {
	@include flex-direction(column);

	.contact-media,
	.contact-body,
	.contact-footer {
		flex-basis: auto !important;
		-webkit-flex-basis: auto !important;
		-webkit-box-flex: initial !important;
		-moz-box-flex: initial !important;
		-webkit-flex-grow: initial !important;
		flex-grow: initial !important;
	}

	.content-media {
		flex-shrink: 0 !important;
		-webkit-flex-shrink: 0 !important;
		-moz-flex-shrink: 0 !important;
	}

	.contact-body {
		flex: (1 1 auto) !important;
		-webkit-box-flex: (1 1 auto) !important;
		-moz-box-flex: (1 1 auto) !important;
		-webkit-flex: (1 1 auto) !important;
	}
}
