;
@import '07-cui-lib/coc-styles.scss';
@import '00-settings/index';
@import '01-tools/index';
@import '02-generic/index';
@import '03-components/index';
@import '04-modules/index';
@import '06-pages/index';
@import 'bootstrap/scss/bootstrap';
@import '07-cui-lib/patterns.scss';
@import '07-cui-lib/variables.scss';
html {
    overflow: hidden;
    height: 100%;
    body {
        .btn-primary:not(:disabled):not(.disabled):active,
        .btn-primary:not(:disabled):not(.disabled).active,
        .show > .btn-primary.dropdown-toggle {
            background-color: transparent;
            color: $coc-secondary-2D;
        }
        strong {
            font-weight: 600;
        }
    }
}
div#subheader {
    .spinner-wrap {
        display: none;
    }
}

.spinner-wrap {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 99999;
    left: 0;
    top: 0;
    .spinner-box {
        display: flex;
        justify-content: center;
        flex-flow: column;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .preload-spinner-container {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }
}
main#main {
    position: relative;
    .default-container.approval-loader {
        .spinner-wrap {
            display: flex;
            justify-content: center;
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.9);
            z-index: 99999;
            left: 0;
            top: 0;
            .spinner-box {
                display: flex;
                justify-content: center;
                flex-flow: column;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
.page-load {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: rgba(255, 255, 255, 1);
    justify-content: center;
    align-items: center;
    display: flex;
    .spinner-box {
        display: flex;
        flex-flow: column;
    }
}
body {
    overflow-x: hidden;
    overflow-y: auto !important;
    height: 100% !important;
    &.modal-open {
        overflow: hidden !important;
    }
    @media (min-width: $breakpoint-lg) {
        &.modal-open {
            padding-right: 17px !important;
        }
    }
    ::marker {
        color: transparent;
    }
    .list-style-dot {
        ::marker {
            color: $coc-secondary-2D;
        }
    }
    .btn-link {
        color: $msv-primary;
        &:hover {
            color: $msv-primary;
        }
    }
    .alert-box {
        &-blue {
            flex: 0 0 100%;
            display: inline-flex;
            flex-wrap: wrap;
            border: 2px solid $cui-blue;
            margin-bottom: 15px;
            .box-header {
                width: 100%;
                flex: 0 0 100%;
                background-color: $cui-blue;
                position: relative;
                padding: 10px;
                h3 {
                    color: $msv-white;
                    position: relative;
                    padding-left: 35px;
                    font-size: 1.2rem;
                    margin: 0;
                    font-weight: 600;
                    &::before {
                        @include msv-icon($icon-weight: $msv-solid-icon-weight);
                        content: $msv-InfoCircle;
                        font-size: 1.2rem;
                        position: absolute;
                        left: 0;
                        top: 2px;
                    }
                }
                button.btn-close {
                    position: absolute;
                    right: 10px;
                    top: 0;
                    font-size: 1rem;
                    padding: 8px 5px 8px 10px;
                    border-radius: 3px;
                    border: none;
                    background-color: transparent;
                    color: $msv-white;
                    &::after {
                        @include cui-icon();
                        content: '\e039';
                        margin: 0 8px;
                        line-height: 1.7;
                        font-size: 0.8rem;
                    }
                    @include cui-focus();
                }
                @media (max-width: $msv-breakpoint-m) {
                    padding-right: 90px;
                }
            }
            .alert-body {
                padding: 25px 45px;
                p {
                    display: flex;
                    font-size: 1rem;
                    margin-bottom: 5px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &-green {
            flex: 0 0 100%;
            display: inline-flex;
            flex-wrap: wrap;
            border: 3px solid $cui-green;
            margin-bottom: 15px;
            min-width: 100%;
            position: relative;
            .alert-body {
                padding: 25px 25px 25px 65px;
                position: relative;
                p {
                    font-size: 1rem;
                    font-weight: 600;
                }
            }
            &::before {
                @include msv-icon($icon-weight: $msv-solid-icon-weight);
                content: $msv-Checkbox-Circle-Checked;
                font-size: 1.2rem;
                position: absolute;
                left: 0;
                top: 0;
                width: 40px;
                height: 100%;
                text-align: center;
                padding-top: 30px;
                background-color: $cui-green;
            }
        }
    }
    span.starick {
        color: $msv-red;
        padding-left: 5px;
    }
    .spinner-border {
        @include cui-spinner();
    }
    @keyframes rotate {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    input[type='radio'],
    input[type='checkbox'] {
        @include cui-focus();
    }
    .splash-page {
        .ms-content-block[data-m-layout='full-width'] {
            .ms-content-block__details {
                min-height: 70vh;
                .ms-content-block__title {
                    font-weight: 600;
                    @media (max-width: $msv-breakpoint-l) {
                        font-size: 2rem;
                    }
                }
            }
        }
    }
}
a {
    color: $msv-primary;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
        color: $msv-primary-hover;
    }
}
@media (max-width: $msv-breakpoint-m) {
    div[data-exp-event-id='Help.PageView'],
    div[data-exp-event-id='help.PageView'],
    .static-container {
        main {
            > .default-container {
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}
