/* -------------------------------------------------------
// Start of _credits
// -------------------------------------------------------
// -------------------------------------------------------
// Icon and SVG Design Credits | Last Update: April 1, 2020
// -------------------------------------------------------
//
// Icon Name: cimg-cover-your-cough
// Icon URL: https://www.iconfinder.com/iconsets/minimal-disease
// Designer: PictureWindow
// Designers URL: https://www.iconfinder.com/agoehlert
// License: Attribution-ShareAlike 3.0 Unported (CC BY-SA 3.0)
// Lisense URL: https://creativecommons.org/licenses/by-sa/3.0/
//
// -------------------------------------------------------
//
// Icon Names: cimg-avoid-contact, cimg-avoid-crowds, cimg-avoid-touching-face, cimg-clipboard-list, cimg-hand-sanitizer, cimg-hand-washing, cimg-hospital-bed, cimg-mask, cimg-physical-distancing, cimg-symptom-coughing, cimg-symptom-fever, cimg-symptom-headache
// Icon URL: https://www.iconfinder.com/iconsets/stop-virus-outline-iconset
// Designer: LAFS
// Designers URL: https://www.iconfinder.com/agoehlert
// License: Attribution-ShareAlike 3.0 Unported (CC BY-SA 3.0)
// Lisense URL: https://creativecommons.org/licenses/by-sa/3.0/
//
// ------------------------------------------------------- */
