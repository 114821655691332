// Addition animations

// Ainmation delay millisecond

.animate__animated.animate__delay-900ms {
	-webkit-animation-delay: 900ms;
	-moz-animation-delay: 900ms;
	-ms-animation-delay: 900ms;
	-o-animation-delay: 900ms;
	animation-delay: 900ms;
}

// delay .8s
.animate__animated.animate__delay-800ms {
	-webkit-animation-delay: 800ms;
	-moz-animation-delay: 800ms;
	-ms-animation-delay: 800ms;
	-o-animation-delay: 800ms;
	animation-delay: 800ms;
}

// delay .7s
.animate__animated.animate__delay-700ms {
	-webkit-animation-delay: 700ms;
	-moz-animation-delay: 700ms;
	-ms-animation-delay: 700ms;
	-o-animation-delay: 700ms;
	animation-delay: 700ms;
}


// delay .6s
.animate__animated.animate__delay-600ms {
	-webkit-animation-delay: 600ms;
	-moz-animation-delay: 600ms;
	-ms-animation-delay: 600ms;
	-o-animation-delay: 600ms;
	animation-delay: 600ms;
}

// delay .5s
.animate__animated.animate__delay-500ms {
	-webkit-animation-delay: 500ms;
	-moz-animation-delay: 500ms;
	-ms-animation-delay: 500ms;
	-o-animation-delay: 500ms;
	animation-delay: 500ms;
}

// delay .4s
.animate__animated.animate__delay-400ms {
	-webkit-animation-delay: 400ms;
	-moz-animation-delay: 400ms;
	-ms-animation-delay: 400ms;
	-o-animation-delay: 400ms;
	animation-delay: 400ms;
}

// delay .3s
.animate__animated.animate__delay-300ms {
	-webkit-animation-delay: 300ms;
	-moz-animation-delay: 300ms;
	-ms-animation-delay: 300ms;
	-o-animation-delay: 300ms;
	animation-delay: 300ms;
}

// delay .2s
.animate__animated.animate__delay-200ms {
	-webkit-animation-delay: 200ms;
	-moz-animation-delay: 200ms;
	-ms-animation-delay: 200ms;
	-o-animation-delay: 200ms;
	animation-delay: 200ms;
}

// -------------------------------------------- //
// ------Update the value on animate__faster------- //
// ------From .5s to .4s------- //
// -------------------------------------------- //
.animate__animated.animate__faster {
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
	-webkit-animation-duration: calc( var( --animate-duration ) / 2 );
	animation-duration: calc( var( --animate-duration ) / 2 );
}
