$quickView-margin-top: 36px;
$quickView-label-margin-bottom: 5px;
$quickView-box-top-margin: 20px;
$quickView-box-bottom-margin: 20px;
$quickView-box-left-margin: 60px;
$quickView-store-description-margin-bottom: 8px;
$quickView-add-to-cart-extra-actions-margin: 8px;
$quickView-add-to-cart-extra-actions-width: 48px;
$quickView-product-status-padding-top: 18px;
$quickView-quantity-input: 5px;
$quickView-incr-decr: 5px;
$gutter-width: 40px;
$media-gallery-width:60%;
$msv-dialog-width: 400px;
$msv-dialog-modal-content-padding: 20px;
$msv-dialog-modal-content-body-text-padding: 4px 0;
$msv-dialog-modal-content-empty-image-padding-bottom: 10px;
$msv-dialog-modal-content-button-margin: 6px 0;
$msv-dialog-modal-content-background-color:#F8F7F6;
$msv-dialog-button-width: 100%;
$msv-dialog-msc-empty_image-size: 240px;
$msv-icon-margin-right: 8px;
$quickView-custom-amount-input-width: 80px;
$quickView-custom-amount-input-padding:5px;
$quickView-custom-btn-color: #000000DE;
$quickView-custom-hover-btn-color: #000000;

//style presets
:root {
    --msv-quickView-btn-bg: #{$msv-white};
    --msv-quickView-btn-font-color: var(--msv-font-primary-color);
    --msv-quickView-btn-border-color: var(--msv-border-color);
    --msv-quickView-btn-font-size: var(--msv-body-font-size-l);

    //title
    --msv-quickView-title-font-size: var(--msv-body-font-size-xxl);
    --msv-quickView-title-font-color: var(--msv-font-primary-color);

    // rating star
    --msv-quickView-rating-star: var(--msv-font-primary-color);
    --msv-quickView-btn-extra-actions-color: var(--msv-accent-brand-color);

    //text
    --msv-quickView-text-font-size: var(--msv-body-font-size-m);
    --msv-quickView-text-font-color: var(--msv-font-primary-color);

    // primary button
    --msv-quickView-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-quickView-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-quickView-primary-btn-border: var(--msv-accent-brand-color);

    // secondary button
    --msv-quickView-secondary-btn-bg: var(--msv-secondary-button-background-color);
    --msv-quickView-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-quickView-secondary-btn-border: var(--msv-accent-brand-color);
}

.ms-quickView {
    position: absolute;
    top: 240px;
    left: 0;
    text-align: center;
    right: 0;
    overflow: hidden;
    padding: 12px 35px 12px 15px;
    display: flex;
    flex-flow: row;

    &__content {
        width: 45%;
    }

    &__header {
        margin-bottom: 0;
    }

    &__button {
        @include primary-button(var(--msv-quickView-primary-btn-bg),
        var(--msv-quickView-primary-btn-font-color), var(--msv-quickView-primary-btn-border));
        background: var(--msv-quickView-btn-bg);
        color: $quickView-custom-btn-color;
        flex-grow: 1;
        font-size: 13px;
        padding: 7px;
        width: 100%;
        line-height: unset;
        display: none;
        cursor: pointer;

        &:hover, &:focus {
            background: var(--msv-quickView-btn-bg);
            color: $quickView-custom-btn-color;
        }
    }

    &__dialog {
        width: 778px;
        height: 607px;

        .msc-modal__content {
            background: $msv-dialog-modal-content-background-color;
        }
    }

    &__add-to-cart-container {
        display: inline-block;
        padding: 10px 19px 12px 1px;

        .msc-add-to-cart {
            min-width: 219px;
            min-height: 48px;
            border-radius: 2px;
        }
    }

    &__body {
        max-height: fit-content;
        display: flex;
        flex-flow: row;

        .msc-rating {
            &__count,
            &__star {
                color: var(--msv-quickView-rating-star);
            }

            &__half-star {
                &::after {
                    color: var(--msv-quickView-rating-star);
                }
            }
        }

        .msc-price__actual {
            @include font-content-xl();
            color: var(--msv-quickView-text-font-color);
        }

        .msc-price__strikethrough {
            @include font-content-xl(var(--msv-font-weight-light));
        }

        .ms-quickView__key_in_price {
            margin-top: 20px;

            .ms-quickView__key_in_price-label {
                @include font-content-l(var(--msv-font-weight-light));
                color: var(--msv-quickView-title-font-color);
                display: block;
                margin-bottom: $quickView-label-margin-bottom;
            }

            .ms-quickView__key_in_price_custom-amount__input {
                @include form-control();
                -moz-appearance: textfield;
                padding: $quickView-custom-amount-input-padding;
                text-align: left;

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                }
            }
        }

        .ms-text-block {
            margin-top: 0;
            margin-bottom: 1rem;

            @include font-content(var(--msv-font-weight-normal),
            var(--msv-quickView-text-font-size), $msv-line-height-m);
        }

        .product-add-to-cart {
            margin-top: 20px;

            .buttons {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
            }

            .msc-add-to-cart {
                @include primary-button(var(--msv-quickView-primary-btn-bg),
                var(--msv-quickView-primary-btn-font-color), var(--msv-quickView-primary-btn-border));
                font-size: var(--msv-quickView-btn-font-size);
                line-height: $msv-line-height-l;
                flex-grow: 1;
            }

            .ms-wishlist-items__product-status {
                width: 100%;
                padding-top: $quickView-product-status-padding-top;
            }

            .msc-add-to-cart-extra-actions {
                @include font-glyph-standard();
                @include secondary-button(var(--msv-quickView-secondary-btn-bg),
                var(--msv-quickView-secondary-btn-font-color), var(--msv-quickView-secondary-btn-border));
                color: var(--msv-quickView-btn-extra-actions-color);
                padding-left: 0;
                padding-right: 0;
                position: relative;
                min-width: $quickView-add-to-cart-extra-actions-width;
                width: $quickView-add-to-cart-extra-actions-width;
                flex: 0 0 $quickView-add-to-cart-extra-actions-width;
                margin-left: $quickView-add-to-cart-extra-actions-margin;

                &:not(:disabled):hover,
                &:not(:disabled):not(.disabled):active:focus {
                    color: var(--msv-quickView-btn-extra-actions-color);
                    border-color: transparent;
                }
            }
        }

        .msc-dropdown__select {
            @include form-control();
            width: 279px;
            height: 50px;
        }

        .quantity {
            .quantity-input {
                @include form-control();
                width: 159px;
                display: inline-block;
                margin-left: 8px;
                margin-right: 8px;
                -moz-appearance: textfield;
                padding: $quickView-quantity-input;
                vertical-align: bottom;

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                }

                &:disabled {
                    /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
                    background-color: #FFFFFF !important;
                    /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
                    border: 1px solid #D1D1D1 !important;
                }
            }

            .quantity__controls {
                padding: $quickView-incr-decr;
                padding-left: 0;
                padding-right: 0;
                background: var(--msv-quickView-btn-bg);
                background-position: center;
                background-repeat: no-repeat;
                border: 1px solid var(--msv-quickView-btn-border-color);
                min-width: auto;
                width: 48px;
                height: 48px;
                cursor: pointer;

                &:disabled {
                    color: $msv-gray-300;
                }
            }

            .increment {
                @include add-icon($msv-Add, after);
                color: var(--msv-quickView-btn-font-color);

                &.disabled {
                    color: $msv-gray-300;
                }

                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled):active:focus {
                    color: var(--msv-quickView-btn-font-color);
                }
            }

            .decrement {
                @include add-icon($msv-Remove, after);
                color: var(--msv-quickView-btn-font-color);

                &.disabled {
                    color: $msv-gray-300;
                }

                &:not(:disabled):not(.disabled):active,
                &:not(:disabled):not(.disabled):active:focus {
                    color: var(--msv-quickView-btn-font-color);
                }
            }
        }
    }

    &__inventory-info {
        margin-top: $quickView-box-top-margin;
    }

    &__product-title {
        @include font-content(var(--msv-font-weight-light), var(--msv-quickView-title-font-size), $msv-line-height-xxl);
        color: var(--msv-quickView-title-font-color);
        margin-bottom: 8px;
        margin-top: -10px;
    }

    &__inventory-label {
        @include font-content(var(--msv-font-weight-normal), var(--msv-body-font-size-l), $msv-line-height-m);
    }

    &__product-description {
        @include font-content(var(--msv-font-weight-light), var(--msv-quickView-text-font-size), $msv-line-height-m);
        color: var(--msv-quickView-text-font-color);
        margin-bottom: $quickView-box-bottom-margin;
        margin-left: $quickView-box-left-margin;
    }

    &__configure {
        @include font-content-l(var(--msv-font-weight-normal));
    }

    &__dropdown {
        margin-top: 20px;

        &-quantity-label {
            @include font-content-l(var(--msv-font-weight-light));
            color: var(--msv-quickView-title-font-color);
        }
    }

    &__media-gallery {
        display: block;
        min-width: 259px;
        flex-grow: 1;
        flex-wrap: nowrap;
        width: 40%;
        margin-right: 40px;

        &__carousel,.msc-carousel {
            height: 440px;
            left: 60px;
            right: -40px;
            top: 15px;
            width: 259px;
            background-color: #FFFFFF;

            &__indicators {
                bottom: -80px;
            }
        }

        .ms-media-gallery__thumbnails-container {
            width: fit-content;
            height: 100px;

            .msc-ss-carousel {
                width: fit-content;
                height: fit-content;
                bottom: 426px;
            }

            .msc-ss-carousel-slide {
                display: block;
            }

            .ms-media-gallery__thumbnail-item:not(:last-child) {
                margin-bottom: 10px;
                margin-right: 20px;
            }

            .ms-media-gallery__carousel {
                margin-bottom: 0;
            }
        }
    }

    &__add-to-wishlist-container {
        display: inline-block;

        .msc-add-to-wishlist {
            width: 48px;
            height: 48px;
            margin: -10px;
        }
    }

    &__product-quantity-label {
        @include font-content-l(var(--msv-font-weight-light));
        color: var(--msv-quickView-title-font-color);
        display: block;
        margin-bottom: $quickView-label-margin-bottom;
    }

    @media (max-width: $msv-breakpoint-l) {
        &__media-gallery {
            display: block;
            min-width: $media-gallery-width;
            flex-grow: 1;
            flex-wrap: nowrap;
            padding-right: $gutter-width;
        }

        .ms-media-gallery {
            padding-bottom: 54px;

            .msc-carousel {
                height: 440px;
                left: 60px;
                right: -40px;
                top: 15px;

                &__indicators {
                    bottom: -36px;
                }

                &__control {
                    &__prev, &__next {
                        bottom: -40px;
                        height: auto;
                        top: auto;
                        width: auto;
                    }

                    &__prev__icon {
                        @include add-icon($msv-ChevronLeft);
                        margin-left: 0;
                    }

                    &__next__icon {
                        @include add-icon($msv-ChevronRight);
                        margin-right: 0;
                    }
                }
            }
        }
    }

    //mobile vp

    @media (max-width: $msv-breakpoint-m) {
        flex-flow: column;
    }
}
