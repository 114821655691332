/* -------------------------------------------------------
   Start of _icon-label
   ------------------------------------------------------- */

//==============================================================================
// Icon with label
// Demo on icons-usage.php
//==============================================================================
.cui.icon-label {
	@include flexbox;
	@include align-items(center);
	//min-width: $basic-space*240rem;

	&.hide-label {
		min-width: auto;
	}

	span {
		vertical-align: middle;
		//display: inline-block;
	}

	.icon-descript {
		//display: block;
		font-family: $font-primary;
		font-weight: $font-weight-normal;
		line-height: $line-height-compact;
		//margin-left: 0;
		margin-left: $spacer-xxs;
		max-width: 80%;
		width: 100%;
		height: auto;
		//padding-top: $spacer-xxs;
		text-align: left;

		@media( min-width: $breakpoint-lg ) {
			display: inline-block;
			// margin-left: $spacer-xxs;
			padding-top: 0;
		}
	}
}

.cui.icon-label-group {
	width: 100%;
	position: relative;

	.icon-descript {
		display: inline-block;
		margin-left: $spacer-md;

		span {
			display: block;
		}

		.label {
			font-weight: $font-weight-bold;
			margin-bottom: $tiny-spacer-sm;
		}
	}

	span[class*="cicon-"] {
		position: absolute;
		top: 0;
		padding-top: $tiny-spacer-xs;
	}
}

// Options to hide the icon or label
.cui.icon-label,
.cui.icon-label-group {
	&.hide-icon {
		[class*="cicon-"] {
			display: none;
		}

		.icon-descript {
			margin-left: 0;
		}
	}
}


.cui.icon-label.hide-label {
	.icon-descript {
		@include setSROnly();
	}
}

//==============================================================================
// Label with icon - button and text
// Demo on text.php
//==============================================================================

.cui.label-text {
	border-radius: $default-border-radius;
	border: 0;
	height: auto;
	margin: 0;
	display: inline-block;
	font-size: $base-font-size;
	text-decoration: none;
	padding: $tiny-spacer-xs $spacer-xxs;
	line-height: $line-height-default;

	&:hover {
		cursor: default;
	}
}

button.cui.label-text,
a.cui.label-text {
	&.coc-secondary-blue-access {
		&:hover {
			background: $coc-secondary-dark-blue-D;
		}
	}

	&:hover {
		cursor: pointer;
	}
}

//==============================================================================
// Label with description
//==============================================================================

.cui.label-widget {
	width: 100%;

	p {
		display: inline;
	}
}

// End of .cui.label-widget
