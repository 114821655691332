/* -------------------------------------------------------
   Start of _header
   ------------------------------------------------------- */

.skip-links {
	@include listUnstyled();

	.skip-link {
		overflow: hidden;
		position: absolute;
		top: -$basic-space*20000rem;
		width: $header-skip-link-width-height;
		height: $header-skip-link-width-height;

		&:focus {
			background-color: white;
			padding: $tiny-spacer-xs;
			top: 0;
			right: 0;
			z-index: $header-skip-links-z;
			width: auto;
			height: auto;
		}
	}
}

.cui.coc-header {
	position: relative;
	z-index: $header-z;
	width: 100%;
	box-shadow: $default-base-shadow;

	.header-inner {
		@include align-items(center);
		@include flexbox();
		background: $bg-light;
		height: $header-height;
	}

	.coc-logo {
		@include align-self(flex-start);
		@include flex-shrink (0);// Do not allow the dimensions to change
		@include flex-grow (0);// Do not allow the dimensions to change
		margin-right: $header-gutter;
		position: relative;
		width: $coc-logo-width;
		height: $coc-logo-height;
		display: inline-block;
		// Required to ensure it overlaps the menu (when open) and for the layout on larger screens
		z-index: $header-logo-z;

		.coc-logo-desktop {
			display: none;
		}
	}

	.app-title {
		font-size: $header-app-title-sm;

		@media( min-width: $breakpoint-md ) {
			font-size: $header-app-title-md;
		}

		@media( min-width: $header-breakpoint-xxl ) {
			font-size: $header-app-title-lg;
		}
	}

	.cui.search-container {
		margin-right: $header-gutter;
		max-width: $header-search-max-width;
		width: 100%;
	}

	@media( min-width: $breakpoint-sm ) {
		&.with-mobile-only-search {
			.cui.search-container {
				display: none !important;
			}
		}
	}// End of min-width: $breakpoint-sm

	@media( min-width: $breakpoint-md ) {
		.header-inner {
			height: $header-height-sm;
		}

		.coc-logo {
			margin-left: $header-gutter;
			width: $coc-logo-width-sm;
			height: $coc-logo-height-sm;

			.coc-logo-mobile {
				display: none;
			}

			.coc-logo-desktop {
				display: block;
			}
		}
	}// End of min-width: $breakpoint-md

	@media( min-width: $breakpoint-lg ) {
		.cui.nav-mega {
			.nav-menu {
				padding: $spacer-xs 0 0 $spacer-xxs;
			}
		}
	}// End of min-width: $breakpoint-lg

	@media( min-width: $header-breakpoint-xxl ) {
		.header-inner {
			height: $header-height-lg;
		}

		.coc-logo {
			margin-right: $header-gutter-lg;
			margin-left: $header-gutter-lg;
			width: $coc-logo-width-lg;
			height: $coc-logo-height-lg;
		}

		.cui.nav-mega {
			.nav-menu {
				padding-left: $header-meganav-left-offset-lg;
			}
		}

		&.with-nav-only {
			height: 6.45rem;

			.cui.nav-mega {
				position: absolute;
				top: $header-meganav-only-top-offset-lg;
				left: 0;

				.nav-menu {
					background: transparent;
				}
			}
		}
	}// End of min-width: $header-breakpoint-xxl

	// -------------------------------------------------------
	// Print
	//
	// -------------------------------------------------------

	@media print {
		.coc-logo {
			margin-left: 0;
			width: $coc-logo-width-sm;
			height: auto;

			.coc-logo-desktop {
				display: inline-block;
				width: $coc-logo-width-sm;
				height: auto;
			}
		}

		.header-inner {
			background-color: transparent;
		}

		.coc-logo-mobile {
			display: none;
		}
	}
}// End of .cui-coc-header
