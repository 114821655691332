$footer-ms-footer-padding-top: 48px;
$footer-ms-footer-padding-left: 40px;
$footer-ms-footer-padding-xl: 48px 0;
$footer-ms-footer-margin-left-mobile: 0;
$footer-category-max-width: 200px;
$footer-category-padding-bottom: 15px;
$footer-item-padding-desktop: 5px 0;
$footer-item-padding-right-mobile: 0;
$footer-heading-title-line-height: 24px;
$footer-heading-title-margin-bottom: 0.5rem;
$footer-list-item-icon-left: 10px;
$footer-list-item-icon-number: 3;
$footer-list-item-icon-top: 40px;
$footer-row-padding-top-mobile: 32px;
$footer-back-to-top-line-height: 24px;
$footer-back-to-top-height: 48px;
$footer-back-to-top-width: 48px;
$footer-back-to-top-right: 24px;
$footer-back-to-top-bottom: 225px;
$footer-back-to-top-border-radius: 2px;
$footer-categoty-back-to-top-max-width: 0;
$social-footer-padding-left: 60px;

:root {
    --msv-footer-bg: #{$cui-gray-primary};
    // Headings
    --msv-footer-heading-font-color: #{$msv-white};
    --msv-footer-heading-font-size: var(--msv-body-font-size-l);

    //Links
    --msv-footer-link-font-color: #{$msv-white};
    --msv-footer-link-font-size: var(--msv-body-font-size-m);
}

footer {
    background-color: var(--msv-footer-bg);

    .ms-footer {
        &__list {
            width: auto;
            display: flex;
            justify-content: center;
        }

        .ms-back-to-top {
            @include vfi();
            background: var(--msv-bg-color);
            border: none;
            border-radius: $footer-back-to-top-border-radius;
            bottom: $footer-back-to-top-bottom;
            cursor: pointer;
            display: none;
            height: $footer-back-to-top-height;
            min-width: auto;
            opacity: 0;
            padding: 0;
            position: absolute;
            right: $footer-back-to-top-right;
            text-align: center;
            vertical-align: middle;
            width: $footer-back-to-top-width;

            &:hover {
                background: var(--msv-accent-secondary-btn);
            }

            .fa-arrow-up {
                color: var(--msv-accent-brand-color);
                font-size: var(--msv-body-font-size-xl);
                left: 50%;
                line-height: $footer-back-to-top-line-height;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);

                &::before {
                    @include msv-icon();
                    content: $msv-ChevronUp;
                }
            }
        }

        &__item {
            padding: $footer-item-padding-desktop;
            &.copyright {
                color: $msv-white;
            }
        }

        &__heading__title {
            color: var(--msv-footer-heading-font-color);
            font-size: var(--msv-footer-heading-font-size);
            font-weight: var(--msv-font-weight-normal);
            line-height: $msv-line-height-l;
            margin-bottom: $footer-heading-title-margin-bottom;
        }
        .list-title {
            .ms-footer__heading__title {
                font-weight: var(--msv-font-weight-bold);
                color: $msv-gray-400;
                font-size: $msv-font-size-xl;
            }
        }
        &__link {
            @include vfi();
            display: inline-flex;
            color: $msv-white;
            img {
                @include vfi();
            }
            @include cui-focus();
            div,
            &__text {
                color: var(--msv-footer-link-font-color);
                font-size: var(--msv-footer-link-font-size);
                font-weight: var(--msv-font-weight-normal);
                line-height: $msv-line-height-m;
            }
        }

        & > * {
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            padding-top: $footer-ms-footer-padding-top;
            padding-left: 0;
            > * {
                padding-bottom: $footer-category-padding-bottom;

                .ms-footer__list {
                    display: flex;
                    width: auto;
                    flex-flow: column;
                    justify-content: center;
                    &-item {
                        display: flex;
                        justify-content: center;
                    }
                }
                &:nth-child(3),
                &:nth-child(5) {
                    padding-bottom: 0;
                }
                &:nth-child(2),
                &:nth-child(4),
                &:nth-child(6) {
                    .ms-footer__list {
                        flex-flow: nowrap;
                    }
                }
                &:nth-child(4) {
                    .ms-footer__list {
                        &-item {
                            &:first-child {
                                &::after {
                                    content: '|';
                                    margin: 0 5px;
                                    color: #fff;
                                    line-height: 2.5;
                                }
                            }
                        }
                    }
                }
                &:nth-child(6) {
                    .ms-footer__list {
                        &-item {
                            &:first-child {
                                padding-right: 5px;
                            }
                            &:nth-child(2) {
                                &::after {
                                    content: '|';
                                    margin: 0 5px;
                                    color: #fff;
                                    line-height: 2.5;
                                }
                            }
                        }
                    }
                }
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
    .social-footer {
        display: none;
        padding-left: $social-footer-padding-left;
        .ms-footer__list {
            display: flex;
            width: max-content;
            .ms-footer__item {
                margin-right: 40px;
                margin-bottom: 19px;
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-footer {
            margin-left: $footer-ms-footer-margin-left-mobile;

            &__item {
                padding-right: $footer-item-padding-right-mobile;
            }

            > * {
                padding-top: $footer-row-padding-top-mobile;
                padding-left: 0;
                text-align: center;
                > * {
                    max-width: 100%;

                    &:nth-child(4) {
                        .ms-footer__list {
                            display: inline-flex;
                        }

                        @for $i from 3 to ($footer-list-item-icon-number + 2) {
                            .ms-footer__list-item:nth-child(#{$i}) {
                                left: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-m) {
        .ms-footer {
            .ms-back-to-top[aria-hidden='false'] {
                display: block;
                opacity: 1;
                transform: translateX(0%);
            }
        }
    }
}
