// -------------------------------------------------------
// Start of _variables
//
// Purpose: Contains the general abstracted variables as
// well as the settings for various components
// -------------------------------------------------------
// Assumes the browser default, typically '16px'
// 1rem = 16px, 1px = 0.0625rem (1/16)
//$basic-space:            0.0625; //1px

// -------------------------------------------------------
// Colour helpers
//
// Default background, border, and status colours
// -------------------------------------------------------

// Default colours
$coc-default-background:    $white;
$coc-default-color:         $coc-secondary-2D;
$coc-dark-text-color:       $black;

$success:                   $coc-secondary-green-safety;
$warning:                   $coc-secondary-yellow-D;
$danger:                    $coc-secondary-red;

// Background variable$spacer-xss
$bg-light:                  $coc-secondary-10L;
$bg-medium:                 $coc-secondary-9L;
$bg-dark:                   $coc-secondary-8L;
$bg-darkest:                $coc-secondary;

// Border variables
$border-color-light:        $coc-secondary-9L;
$border-color-medium:       $coc-secondary-8L;
$border-color-dark:         $coc-secondary-7L;

// Colours for elements
$context-title-color:        $coc-secondary;
$context-title-letter-space: $basic-space*1.5rem;


// -------------------------------------------------------
// Text
//
// -------------------------------------------------------

$font-primary:           'Open Sans', Helvetica, Arial, Verdana, sans-serif;
$font-condensed:         'Open Sans Condensed', Helvetica, Arial, Verdana, sans-serif;
$font-icon:              'cicon';

$base-font-size:         1rem; //16px
$smaller-font-size:      $basic-space * 14rem; //14px
$small-font-size:        $basic-space * 15rem; //15px
$medium-font-size:       $basic-space * 17rem; //17px used on blockquote
$large-font-size:        $basic-space * 18rem; //18px
$larger-font-size:       $basic-space * 20rem; //20px

$font-weight-normal:     400;
$font-weight-semi-bold:  600;// Semi-bold weight for Open Sans
$font-weight-bold:       800;// Bold weight for Open Sans

$line-height-default:    1.5;
$line-height-compact:    1.3;// Used for headings


// -------------------------------------------------------
// Headings
//
// -------------------------------------------------------

$h1-font-size:           $basic-space * 26rem; //26px
$h1-font-size-xs:        $basic-space * 36rem; //36px
$h1-font-size-sm:        $basic-space * 40rem; //40px
$h1-font-size-md:        $basic-space * 45rem; //45px

$h2-font-size:           $basic-space * 23rem; //23px
$h2-font-size-xs:        $basic-space * 28rem; //28px
$h2-font-size-sm:        $basic-space * 30rem; //30px
$h2-font-size-md:        $basic-space * 32rem; //32px

$h3-font-size:           $basic-space * 20rem; //20px
$h3-font-size-xs:        $basic-space * 21rem; //21px
$h3-font-size-sm:        $basic-space * 22rem; //22px
$h3-font-size-md:        $basic-space * 23rem; //23px

$h4-font-size:           $basic-space * 19rem; //19px
$h5-font-size:           1rem; //16px
$h6-font-size:           1rem; //16px

$heading-border:            $basic-space * 3.1875rem;
$heading-underline-padding: $basic-space * 16rem;

// -------------------------------------------------------
// Breakpoints
//
// Based off Bootstrap grid
// -------------------------------------------------------

$breakpoint-xs:            0; // not used anywhere
$breakpoint-sm:            576px;
$breakpoint-md:            768px;
$breakpoint-lg:            992px;
$breakpoint-xl:            1200px;
$breakpoint-xxl:           1400px; //(larger desktops, 1400px and up)
$breakpoint-xxxl:          1600px; //(extra larger desktops, for card layout only)

$breakpoint-sm-height:     350px;

// Helpers
$breakpoint-xs-max:        $breakpoint-sm - 1;
$breakpoint-sm-max:        $breakpoint-md - 1;
$breakpoint-md-max:        $breakpoint-lg - 1;
$breakpoint-lg-max:        $breakpoint-xl - 1;
$breakpoint-xl-max:        $breakpoint-xl - 1;
$breakpoint-xxl-max:       $breakpoint-xxl - 1;
$breakpoint-xxxl-max:      $breakpoint-xxxl - 1; //(extra larger desktops, for card layout only))

$breakpoint-sm-height-max: $breakpoint-sm-height - 1;


// -------------------------------------------------------
// Spacing
//
// -------------------------------------------------------

// Spacer units
// MC.2018.03.06: Do not refer to `$spacer-unit` by itself, use the appropriate `$spacer-*` var
$spacer-unit:         $basic-space * 36rem; //36px

$tiny-spacer-xs:      $spacer-unit/12;  //  3px
$tiny-spacer-sm:      $spacer-unit/6;   //  6px
$spacer-xxs:          $spacer-unit/4;   //  9px
$spacer-xs:           $spacer-unit/3;   // 12px
$spacer-sm:           $spacer-unit/2;   // 18px
$spacer-md:           $spacer-unit*0.75;// 27px
$spacer-lg:           $spacer-unit;     // 36px
$spacer-xl:           $spacer-unit*1.5; // 54px
$spacer-xxl:          $spacer-unit*2;   // 72px
$spacer-xxxl:         $spacer-unit*2.5;   // 90px
$spacer-xxxxl:        $spacer-unit*3;   // 108px

// edge-to-edge
$content-additional-offset: $spacer-xl;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;
$grid-row-columns:            6 !default;

// -------------------------------------------------------
// preload
//
// -------------------------------------------------------
$preload-icon-border: $tiny-spacer-sm;
// -------------------------------------------------------
// Grid
//
// -------------------------------------------------------

$col-total:           12;

$gutter:              $basic-space * 30rem !default;// Should be the same as the Bootstrap gutter width used in the Bootstrap grid
$gutter-compact:      $spacer-xs;


// -------------------------------------------------------
// Icon / svg illustration image sizing
//
// -------------------------------------------------------

$cicon-size-xs:      75%;// Used for icons that need to be a bit smaller for visual balance (plus-thin, minus-thin, close-thin)
$cicon-size-sm:      $base-font-size;     // 16px
$cicon-size-md:      $cicon-size-sm * 1.5;// 24px
$cicon-size-lg:      $cicon-size-sm * 2;  // 32px

$cimg-size-default:  $basic-space * 64rem; //64px
$cimg-size-lg:       $cimg-size-default * 2; //128px


// -------------------------------------------------------
// Borders
//
// -------------------------------------------------------

$border-thin:                      $basic-space * 1rem; //1px
$border-width-md:                  $basic-space * 2rem; //2px
$border-width-lg:                  $basic-space * 4rem; //4px

$default-border-radius:            $basic-space * 4rem; //4px


// -------------------------------------------------------
// Sizing
//
// -------------------------------------------------------

$block-max-width:    $basic-space * 640rem; //640px


// -------------------------------------------------------
// Ratios
//
// -------------------------------------------------------

$ratio-percent-21x9:               42.85%;
$ratio-percent-16x9:               56.25%;
$ratio-percent-4x3:                75%;
$ratio-percent-3x4:                133.33%;
$ratio-percent-5x6:                120%;
$ratio-percent-1x1:                100%;
$various-md-height:                $basic-space * 360rem; //360px
$ratio-custom-sm:                  $basic-space * 270rem; //270px
$ratio-custom-md:                  $basic-space * 350rem; //350px
$ratio-custom-lg:                  $basic-space * 480rem; //480px


// -------------------------------------------------------
// Z-indexes
//
// All z-index values should be set as variables and live here in order to
// see all of the interelationships and prevent conflicts.
// -------------------------------------------------------

// MC.2019.03.21: Use $focus-z on items that are directly lined up with
// other elements (ex. secondary nav, tabs). Applying the z-index on focus
// ensures the focus glow outline is not cut off by the adjacent elements.
$focus-z:                              1 !default;

// The header z-index is mainly applied due to it being
// the parent container for the mega nav. When the mega nav is open,
// the bg-overlay is displayed and is intended to cover everything else on the
// page. The header must therefore have a higher z-index than the bg-overlay.
// The z-index cannot be applied to the mega nav itself because it is nested
// inside the header.
$header-menu-bg-overlay-z:          9999 !default;
$header-z:                         10000 !default;
$header-skip-links-z:                  1 !default;
$header-logo-z:                        1 !default;
$toggled-search-z:                     1 !default;

// The secondary nav z-index should be the same as the header as it must
// overlap the bg-overlay.
$nav-secondary-z:                  $header-z !default;

// The hero banner body requires a z-index as it must overlap the
// background image.
$hero-banner-body-z:                   1 !default;

// The modal must have a higher z-index than the header.
$modal-overlay-z:                  10010 !default;
$modal-z:                          10011 !default;

$imgviewer-modal-z:                10010 !default;
$imgviewer-modal-control-z:        10011 !default;
$imgviewer-modal-header-z:         10012 !default;

$card-video-iframe-z:                 -1 !default;

$wordmark-border-z:                    1 !default;
$wordmark-text-z:                      2 !default;
$wordmark-left-accent-z:               3 !default;

$tooltip-z:                        10010 !default;

// parallax
$parallax-video-z:                 -9999 !default;

// dropdown menu
$dropdown-menu-z:                 10000 !default;

// map skip
$map-skip-z:                        3 !default;
$map-skip-z-f:                      9999 !default;

// card slider
$card-sider-z:                      1 !default;
// -------------------------------------------------------
// Focus glow
//
// -------------------------------------------------------
$focus-box-shadow-widths:           0 0 $basic-space*3rem $basic-space*3rem; //0 0 3px 3px
$focus-box-shadow:                  $focus-box-shadow-widths $focus-glow-color;


// -------------------------------------------------------
// Box shadows
//
// -------------------------------------------------------
// $shadow-h-offset: 					$basic-space*3rem;
// $shadow-v-offset: 					$basic-space*5rem;

// $shadow-hover-h-offset: 			$basic-space*4rem;
// $shadow-hover-v-offset: 			$basic-space*20rem;

// $default-base-shadow:               0 $shadow-h-offset $shadow-v-offset rgba( $black, 0.15 ); //0 3px 5px
// $default-hover-shadow:              0 $shadow-hover-h-offset 0.5rem rgba( $black, 0.25 ); //0 4px 20px
// $default-active-shadow:             inset 0 $basic-space*1rem $basic-space*6rem rgba( $black, 0.25 ); //0 1px 6px

$shadow-h-offset: 			$basic-space*3rem;
$shadow-v-offset: 			$basic-space*3rem;
$shadow-blur: 				$basic-space*7rem;

$shadow-hover-h-offset: 	$basic-space*5rem;
$shadow-hover-v-offset: 	$basic-space*5rem;
$shadow-hover-blur:         $basic-space*8rem;

$default-base-shadow:       $shadow-h-offset $shadow-v-offset $shadow-blur rgba( $black, 0.15 );
$default-hover-shadow:      $shadow-hover-h-offset $shadow-hover-v-offset $shadow-hover-blur rgba( $black, 0.25 );
$default-active-shadow:     inset 0 $basic-space*1rem $basic-space*6rem rgba( $black, 0.25 );

// $btn-shadow-h-offset: 				$basic-space*2rem;
// $btn-shadow-v-offset: 				$basic-space*5.5rem;
// $btn-shadow-v-offset-2: 			$basic-space*5rem;


// $btn-base-shadow:					0 $btn-shadow-h-offset $btn-shadow-v-offset rgba( $black, 0.15 );  //0 2px 5.5px
// $btn-hover-shadow:					0 $btn-shadow-h-offset $btn-shadow-v-offset-2 rgba( $black, 0.3 );  //0 2px 5px
// $btn-active-shadow:					inset 0 $btn-shadow-h-offset $btn-shadow-v-offset-2 rgba( $black, 0.3 ); //0 2px 5px

// Box shadows new setting
// do not use this setting
// only use it on the card slider
// $shadow-h-offset-update: 			$basic-space*3rem;
// $shadow-v-offset-update: 			$basic-space*3rem;
// $shadow-blur-update: 				$basic-space*7rem;

// $shadow-hover-h-offset-update: 		$basic-space*3rem;
// $shadow-hover-v-offset-update: 		$basic-space*3rem;
// $shadow-hover-blur-update:          $basic-space*8rem;

// $default-base-shadow-update:        $shadow-h-offset-update $shadow-v-offset-update $shadow-blur-update rgba( $black, 0.15 );
// $default-hover-shadow-update:       $shadow-hover-h-offset-update $shadow-hover-v-offset-update $shadow-hover-blur-update rgba( $black, 0.25 );
// $default-active-shadow-update:      inset 0 $basic-space*1rem $basic-space*6rem rgba( $black, 0.25 );

// -------------------------------------------------------
// Transitions
//
// -------------------------------------------------------
$transition-duration:               0.25s;
$transition-timing-function:        ease;


// -------------------------------------------------------
// Buttons
//
// -------------------------------------------------------

// Button variables
$btn-border-thin:                   $basic-space*2rem; //2px
$btn-border-thick:                  $basic-space*4rem; //4px
$btn-text-color:                    $black;
$btn-text-color-disabled:           rgba( $black, 0.25 );

//Button font size
$btn-font-sm:                       $base-font-size;
$btn-font-lg:                       $larger-font-size;
$btn-font-xl:                       $basic-space * 22rem;

//Button padding
$btn-sm-padding:					0.0625rem 0.5625rem;
$btn-md-padding:					0.313rem 0.5625rem;
$btn-lg-padding:					0.25rem 0.75rem;
$btn-xl-padding:					0.4095em 1.125rem;

// Primary button
$btn-primary-text-color:            $coc-main;
$btn-primary-bg:                    $white;
$btn-primary-border-color:          $coc-main;
$btn-primary-color:                 $coc-secondary-1D;
$btn-primary-icon-color:            $coc-main;

$btn-primary-hover-border-color:    $coc-link-hover-color;
$btn-primary-hover-color:           $coc-secondary-2D;

$btn-primary-active-border-color:   $coc-secondary-red;

// Utility button
$btn-utility-bg-ghost:              transparent;
$btn-utility-bg-solid:              $white;
$btn-utility-border-color:          $coc-secondary;
$btn-utility-color:                 $coc-secondary;

$btn-utility-hover-border-color:    $black;
$btn-utility-hover-color:           $black;

$btn-utility-active-border-color:   $coc-secondary-1D;
$btn-utility-active-color:          $coc-secondary-1D;

// icon only
$btn-icon-only-bg:               $white;
$btn-icon-only-sm:               $basic-space*28rem;
$btn-icon-only-md:               $basic-space*36rem;
$btn-icon-only-lg:               $basic-space*48rem;

// Disabled button
$btn-disabled-bg:                   $coc-secondary-8L;
$btn-disabled-border-color:         $coc-secondary-5L;
$btn-disabled-color:                $coc-secondary-4L;


// -------------------------------------------------------
// Headings
//
// -------------------------------------------------------
$subtitle-hr-color:         $coc-secondary-2D !default;
$subtitle-hr-height:        $basic-space*3rem; //3px
$subtitle-hr-height-sm:     $basic-space*4rem; //4px
$subtitle-hr-height-md:     $basic-space*7rem; //9px

// -------------------------------------------------------
// Footer
//
//
// -------------------------------------------------------
$footer-copyright-font-size: $basic-space*12rem; //12px
$footer-translate-select-height: $basic-space*30rem;
$footer-translate-select-width: $basic-space*170rem;
$footer-translate-image-width: $basic-space*54rem;


// -------------------------------------------------------
// Blockquote
//
// -------------------------------------------------------
$blockquote-color:            $coc-secondary;
$blockquote-ex-spacing:       $spacer-md;
$blockquote-font-style:       italic;

// Large variant
$blockquote-lg-font-size:     $basic-space*22rem; //22px
$blockquote-lg-font-style:    normal;
$blockquote-lg-icon-color:    $coc-main;
$blockquote-lg-icon-size:     $basic-space*36rem; //36px
$blockquote-lg-icon-size-xs:  $basic-space*72rem; //72px
$blockquote-lg-ex-spacing:    $spacer-lg;


// -------------------------------------------------------
// Forms
//
// -------------------------------------------------------

// Form-control
$input-border-width:          $border-thin;
$input-border-color:          $coc-secondary-6L;
$input-border:                $input-border-width solid $input-border-color;
$input-border-radius:         $basic-space*2.5rem;//2.5px
$input-background-color:      $white;
$input-disabled-bg:           $coc-secondary-9L;
$input-color:                 $coc-secondary-3D;
$input-line-height:           $line-height-default;
$input-padding-y:             $basic-space*10rem; //10px
$input-padding-x:             $spacer-xs;
$input-height-inner:          ($base-font-size * $input-line-height) + ($input-padding-y * 2);
$input-height:                $input-height-inner + ($input-border-width * 2);

// Form group
$fieldset-bordered-padding:   $spacer-xs $spacer-sm $spacer-sm;

// Form Check
$form-check-input-margin-y:             $basic-space*4rem; //4px
$form-check-input-gutter:               $basic-space*21rem; //21px
$form-check-inline-margin-x:            $spacer-xxs;
$form-check-inline-input-margin-x:      $tiny-spacer-sm;
$check-disabled-color:                  $coc-secondary-3L;

// -------------------------------------------------------
// Header
//
// -------------------------------------------------------
$header-height:                         $basic-space*74rem; //74px
$header-gutter:                         $spacer-sm;

$header-height-sm:                      $basic-space*60rem; //60px

$header-gutter-lg:                      $spacer-lg;
$header-height-lg:                      $basic-space*84rem; //84px
$header-height-full:                    $basic-space*103rem; //103px same height as logo
$header-breakpoint-xxl:                 1350px;
// MC.2019.02.05: The following is used to simplify the browser calculation
// needed  for the mega nav offset as the calculation of the rem and px values
// needs to occur at the browser value since the logo needs to be in px
// for better reflow and accessibility.
// $header-meganav-left-xs is the padding-left of the nav item;
$header-meganav-left-xs: $spacer-xs;
$header-meganav-left-offset-lg-wo-logo: ($header-gutter-lg * 2) - $header-meganav-left-xs;
$header-meganav-left-offset-lg:         calc( #{$coc-logo-width-lg} + #{$header-meganav-left-offset-lg-wo-logo} );

$header-meganav-only-top-offset-lg:     $basic-space*40rem; // 40px For the header variant that contains the nav and no search
$header-skip-link-width-height:         $basic-space*1rem;
$header-app-title-sm:                   $basic-space*22rem;
$header-app-title-md:                   $basic-space*24rem;
$header-app-title-lg:                   $basic-space*26rem;
$header-search-max-width:               $basic-space*480rem;


// -------------------------------------------------------
// Mega menu
//
// -------------------------------------------------------

$mega-nav-btn-height:                       $basic-space*46rem; //46px
$mega-nav-header-padding-sm:  				$basic-space*36rem;
$mega-nav-header-padding-md:  				$basic-space*45rem;
$mega-nav-header-padding-lg:  				$basic-space*57rem;

$mega-nav-default-font-size:                $base-font-size;
$mega-nav-menu-header-font-size-mobile-btn: $basic-space*13rem;
$mega-nav-menu-header-font-size: 			$basic-space*20rem;
$mega-nav-arrow-size-mobile:                120%;
$mega-nav-arrow-size-desktop:               75%;

// -------------------------------------------------------
// Search
//
// -------------------------------------------------------
$search-max-width:           $basic-space*560rem; //560px
$search-btn-margin:          $basic-space*5rem; //5px
$search-btn-width:           $input-height - ($search-btn-margin * 2);
$search-suggest-btn-font-size: $basic-space*18rem; //18px;
$search-suggest-btn-border-color: $coc-secondary;
$search-suggest-btn-font-color: $coc-secondary;
$search-suggest-btn-border-radius: $basic-space * 16rem;


// -------------------------------------------------------
// Alerts
//
// -------------------------------------------------------
$alert-border-width-mobile:      $basic-space*2rem; //2px
$alert-border-width:             $basic-space*4rem; //4px
$alert-border-left-width:        $basic-space*6rem;
$alert-padding:                  $spacer-xxs;
$alert-padding-sm:               $spacer-sm;

// Alert colours
$alert-error-color:              $coc-secondary-red;
$alert-error-color-hover:        $coc-main;
$alert-error-text-color:         $white;

$alert-caution-color:            $coc-secondary-yellow-D;
$alert-caution-color-hover:      #fbb001;
$alert-caution-text-color:       $black;

$alert-info-color:               $coc-secondary-blue-access;
$alert-info-color-hover:         #005dc4;
$alert-info-text-color:          $white;

$alert-success-color:            $coc-secondary-green-L;
$alert-success-color-hover:      #7ec822;
$alert-success-text-color:       $black;

$alert-misc-color:               $coc-secondary;
$alert-misc-text-color:          $white;

// Inline alerts
$alert-inl-border-left-width: $spacer-lg;


// -------------------------------------------------------
// Secondary nav
//
// -------------------------------------------------------

$nav-sec-width-md:               $basic-space*300rem !default; //300px
$nav-sec-border-width-xl:        $basic-space*6rem; //6px

// -------------------------------------------------------
// Accordions and tabs
//
// -------------------------------------------------------

$acctab-border-width:               $basic-space*2rem; //2px
$acctab-border-color:               $coc-secondary-7L;
$acctab-link-bg:                    $coc-secondary-9L;
$acctab-link-hover-bg:              $coc-secondary-8L;
$acctab-link-focus-bg:              $white;
$acctab-button-max-width-xs:        $basic-space*150rem;
$acctab-button-max-width-sm:        $basic-space*170rem;
$acctab-button-max-width-md:        $basic-space*210rem;
$acctab-button-max-width-lg:        $basic-space*250rem;
$acctab-button-max-width-xl:        $basic-space*340rem;

// -------------------------------------------------------
// Breadcrumbs
//
// -------------------------------------------------------

$bcrumb-icon-color:                 $coc-secondary-2L;
$bcrumb-icon-font-size:             $basic-space*9rem;//9px
$bcrumb-gradient-color-opaque:      $white;
$bcrumb-gradient-color-transparent: rgba( white, 0 );


// -------------------------------------------------------
// Card
//
// -------------------------------------------------------

$card-border:               $border-thin solid $border-color-medium;
$card-border-hover:         $border-thin solid $coc-secondary-6L;
$card-title-line-height:    $line-height-compact;
$card-title-size:           $h3-font-size;
$card-title-size-xs:        $h3-font-size-xs;
$card-title-size-sm:        $h3-font-size-sm;
$card-title-size-md:        $h3-font-size-md;
$card-title-margin-bottom:  $spacer-xxs;
$card-body-padding:         $spacer-sm;
$card-p-margin-bottom:      $spacer-sm;
$card-ex-spacing:           $gutter;


// -------------------------------------------------------
// Tile
//
// -------------------------------------------------------

$tile-btn-border-width-thin:    $border-thin;
$tile-btn-border-width-thick:   $basic-space*6rem; //6px
$tile-title-size:               $h3-font-size;
$tile-title-size-xs:            $h3-font-size-xs;
$tile-title-size-sm:            $h3-font-size-sm;
$tile-title-size-md:            $h3-font-size-md;
$tile-title-margin-bottom:      $card-title-margin-bottom;
$tile-p-margin-bottom:          $card-p-margin-bottom;


// -------------------------------------------------------
// Modal
//
// -------------------------------------------------------

$modal-overlay-bg-color:    $black;
$modal-bg-color:            $white;
$modal-header-bg-color:     $coc-secondary-9L;
$modal-header-border:       $border-thin solid $coc-secondary-8L;

$modal-horz-padding:        $spacer-sm;
$modal-vert-padding:        $spacer-xs;
$modal-horz-padding-md:     $spacer-md;
$modal-vert-padding-md:     $spacer-sm;

// Values below are the same as the .container widths from Bootstrap
$modal-lg:                  $basic-space*700rem; //700px
$modal-md:                  $basic-space*540rem; //540px
$modal-sm:                  $basic-space*360rem; //360px

// -------------------------------------------------------
// Banner grid
//
// -------------------------------------------------------
$grid-banner-font-size-lg: $basic-space*23rem; //23px
$grid-banner-video-container-height: $ratio-custom-md; //350px

// -------------------------------------------------------
// Hero banner
//
// -------------------------------------------------------
$hero-banner-image-height: $ratio-custom-md; //350px
$hero-banner-title-font-size: $h1-font-size-xs;


// -------------------------------------------------------
// Image viewer
//
// -------------------------------------------------------

$imgviewer-modal-overlay-bg-color:    rgba( 0, 0, 0, 0.9 );
$imgviewer-modal-header-bg-color:     $coc-secondary-9L;
$imgviewer-modal-border:              $border-thin solid $coc-secondary-8L;

$imgviewer-modal-header-height:       $basic-space*50rem; //50px
$imgviewer-modal-padding:             $spacer-xs;
$imgviewer-modal-padding-sm:          $spacer-md;

$imgviewer-btn-control-size:          $spacer-lg;
$imgviewer-btn-control-bg-color:      $coc-secondary-9L;
$imgviewer-btn-control-border-color:  $coc-secondary-5L;
$imgviewer-btn-control-border:        $border-thin solid $imgviewer-btn-control-border-color;

$imgviewer-modal-zoom-in-top:         $imgviewer-modal-header-height + $imgviewer-modal-padding;
$imgviewer-modal-zoom-in-top-sm:      $imgviewer-modal-header-height + $imgviewer-modal-padding-sm;
$imgviewer-modal-zoom-out-top:        $imgviewer-modal-zoom-in-top + $imgviewer-btn-control-size + $spacer-xs;
$imgviewer-modal-zoom-out-top-sm:     $imgviewer-modal-zoom-in-top-sm + $imgviewer-btn-control-size + $spacer-sm;

// Small landscape mobile position for the zoom out button
$imgivewer-modal-zoom-out-top-mob-landscape:   $imgviewer-modal-zoom-in-top;// Make the top aligned with the zoom-in button
$imgivewer-modal-zoom-out-right-mob-landscape: ($imgviewer-modal-padding * 2) + $imgviewer-btn-control-size;

$imgviewer-modal-toggle-caption-height: $basic-space*50rem; //50px


// -------------------------------------------------------
// Content block
//
// -------------------------------------------------------

$content-block-bg:                  $bg-medium;
$content-block-border-color:        $border-color-medium;
$content-block-bg-light:            $bg-light;
$content-block-border-color-light:  $border-color-light;

$content-block-ex-spacing:          $spacer-lg;
$content-block-col-body-padding:    $spacer-sm;
$content-block-col-body-padding-xs: $spacer-md;

$content-block-img-height-sm:       $basic-space*270rem; //270px
$content-block-img-height-md:       $basic-space*360rem; //360px
$content-block-img-height-lg:       $basic-space*540rem; //540px


// -------------------------------------------------------
// Pagination
//
// -------------------------------------------------------

$pagination-bg:                     $btn-utility-bg-solid !default;
$pagination-color:                  $btn-utility-color !default;
$pagination-border-color:           $coc-secondary-6L !default;
$pagination-border-width:           $btn-border-thin !default;

$pagination-hover-bg:               $btn-utility-bg-solid !default;
$pagination-hover-border-color:     $btn-utility-hover-border-color !default;
$pagination-hover-color:            $btn-utility-hover-color !default;

$pagination-active-border-color:    $btn-utility-active-border-color !default;
$pagination-active-color:           $btn-utility-active-color !default;

$pagination-line-height:            $line-height-default !default;
$pagination-gutter:                 $tiny-spacer-sm !default;
$pagination-gutter-sm:              $spacer-xxs !default;
$pagination-padding-y:              $spacer-xxs !default;
$pagination-padding-x:              $basic-space*15rem !default; //15px
$pagination-my-spacing:             $spacer-lg !default;// Margin on y-axis

$pagination-page-text-px:           $spacer-xxs;// .page-text x-axis padding


// -------------------------------------------------------
// Text content with hero
//
// -------------------------------------------------------

$c-tch-bg-sm: $basic-space*270rem; //270px
$c-tch-bg-md: $basic-space*360rem;
$c-tch-bg-lg: $basic-space*480rem;
$c-tch-bg-xl: $basic-space*630rem;

// -------------------------------------------------------
// Tooltip
//
// -------------------------------------------------------

$tooltip-btn-background:       transparent;
$tooltip-btn-border-color:     transparent;
$tooltip-btn-color:            $coc-secondary-2D;
$tooltip-btn-hover-background: $coc-secondary-9L;

$tooltip-background:           $coc-default-background !default;
$tooltip-border-width:         $border-thin !default;
$tooltip-border-color:         $border-color-medium !default;
$tooltip-border-radius:        $default-border-radius !default;
$tooltip-color:                $coc-default-color !default;
$tooltip-font-size:            $base-font-size !default;
$tooltip-max-width:            $basic-space*270rem !default; //270px
$tooltip-padding-y:            $spacer-xs !default;
$tooltip-padding-x:            $spacer-sm !default;

$tooltip-arrow-width:          $spacer-sm !default;
$tooltip-arrow-height:         $tooltip-arrow-width / 2 !default;


// -------------------------------------------------------
// Barchart
//
// -------------------------------------------------------
$data-bar-height:               $base-font-size;
$bar-chart-border-thin:         $border-thin solid $coc-secondary-8L;
$bar-chart-border-thick:        $basic-space*2rem solid $coc-secondary-2D; //2px
$bar-chart-border-thick-light:  $basic-space*6rem solid $coc-secondary-8L; //6px
$bar-chart-hightlight-width:    $basic-space*5rem;


// -------------------------------------------------------
// Table
//
// -------------------------------------------------------

$table-border-color:               $border-color-medium;
$table-gradient-color-opaque:      $white;
$table-gradient-color-transparent: rgba( white, 0 );

// -------------------------------------------------------
// data-display-widge
//
// -------------------------------------------------------
$data-display-gradient-color-opaque:      $bg-medium;
$data-display-gradient-color-transparent: rgba( $bg-medium, 0 );

// -------------------------------------------------------
// Text on image
//
// -------------------------------------------------------

$text-image-title-font-size-sm: $basic-space*18rem; //18px
$text-image-title-font-size-md: $basic-space*28rem; //28px
$text-image-title-font-size-lg: $basic-space*32rem; //32px
$text-image-title-line-height: 1.8;
$text-image-title-letter-space: $basic-space*1rem;
$text-image-title-padding-sm: $tiny-spacer-sm;
$text-image-title-padding: $spacer-xs;
$bg-red: $coc-main;
$bg-white: $white;

//simple - marketing component
$text-image-bg-height-lg: $basic-space*650rem;
$text-image-bg-height-md: $basic-space*450rem;
$text-image-bg-height-sm: $basic-space*350rem;
$text-image-bg-height-xs: $basic-space*250rem;

// -------------------------------------------------------
// Start of _widget.scss
// Other navigation
// -------------------------------------------------------
$list-widget-title-font-size: $basic-space*23rem;

// -------------------------------------------------------
// Media object
//
// -------------------------------------------------------
$img-md: $basic-space*64rem; //64px The icon image size
$img-lg: $basic-space*128rem; //128px The icon image size

// -------------------------------------------------------
// parallax
//
// -------------------------------------------------------
$parallax-bg-white: rgba( 255, 255, 255, 0.9 ), $black;
$parallax-bg-black: rgba( 0, 0, 0, 0.8 ), $white;
$parallax-line-height: 1.9;
$parallax-title-font-size-sm: $basic-space*18rem; //18px
$parallax-title-font-size-md: $basic-space*28rem; //28px
$parallax-title-font-size-lg: $basic-space*32rem; //32px
$parallax-sub-title-font-size-md: $basic-space*16rem; //16px
$parallax-sub-title-font-size-lg: $basic-space*23rem; //23px
$parallax-title-padding-sm: $tiny-spacer-sm;

// -------------------------------------------------------
// contact info block
//
// -------------------------------------------------------
$contact-info-space-xxxs: $tiny-spacer-sm; //6px
$contact-info-space-xxs: $spacer-xxs; //9px
$contact-info-space-xs: $spacer-xs; //12px
$contact-info-space-s: $spacer-sm; //18px
$contact-info-space-sm: $basic-space*24rem; //24px
$contact-info-space-md: $spacer-md; //27px
$contact-info-space-lg: $spacer-lg; //36px

// -------------------------------------------------------
// Map
//
// -------------------------------------------------------
$map-space-xxs: $spacer-xxs;
$map-space-sm: $spacer-sm;
$map-height: $basic-space*900rem;
$map-height-md: $basic-space*350rem;
$map-height-data: $basic-space*225rem;
$map-border : $tiny-spacer-xs; //3px
$map-skip-position : $tiny-spacer-xs;
$map-esri-btn: $basic-space*32rem;

// -------------------------------------------------------
// Duration block
//
// -------------------------------------------------------
$duration-block-border-spacing-sm: $basic-space * 6rem; //6px
$duration-block-border-spacing-md: $spacer-xs; //12px
$duration-block-border-spacing-lg: $spacer-sm; //18px

$duration-block-border-radius:   $spacer-xs; //12px
$duration-block-clock-border-radius:   $spacer-xxs; //9px

$duration-block-font-size-md: $basic-space * 18rem; //18px
$duration-block-font-size-lg: $basic-space * 27rem; //27px
$duration-block-clock-number-font-size-sm: $basic-space * 18rem; //18px
$duration-block-clock-number-font-size-md: $basic-space * 21rem; //21px
$duration-block-clock-number-font-size-lg: $basic-space * 31rem; //39px

// -------------------------------------------------------
// card slide
//
// -------------------------------------------------------
$slide-spacing: $spacer-sm; //18px between each slide
$slide-spacing-adjust: $shadow-hover-v-offset;
$slide-button-container-width: $btn-icon-only-lg*2 + $basic-space * 18rem;

// -------------------------------------------------------
// search-image-block-container
//
// -------------------------------------------------------
//$emotive-container-height-lg: $basic-space*500rem;
$search-image-block-container-height-md: $basic-space*400rem;
$search-image-block-container-height-sm: $basic-space*325rem;
$search-image-block-container-title-font-size-sm: $basic-space*24rem; //18px
$search-image-block-container-title-font-size-md: $basic-space*28rem; //28px
$search-image-block-container-title-font-size-lg: $basic-space*32rem; //32px
$search-image-block-container-title-padding-sm: $tiny-spacer-sm;
$search-image-block-container-title-padding: $spacer-xs;
$search-image-block-container-title-margin-sm: $basic-space*56rem;
$search-image-block-container-title-margin-md: $basic-space*72rem;
$search-image-block-container-title-margin-lg: $basic-space*94rem;

// -------------------------------------------------------
// toggle-container
//
// -------------------------------------------------------
$toggle-container-img-ratio-sm: 16.25%;
$toggle-container-img-ratio-md: 26.25%;
$toggle-container-img-ratio-lg: 36.25%;

// -------------------------------------------------------
// drop down menu
//
// -------------------------------------------------------
$dropdown-min-width: $basic-space*160rem;
$dropdown-toggle-padding: 0.313rem 0.5625rem;
$dropdown-padding: $spacer-xs $spacer-sm;
$dropdown-border: $border-width-md solid $input-border-color;
$dropdown-border-active: $border-width-md solid $coc-secondary;
$dropdown-menu-border: $border-thin solid $input-border-color;
$dropdown-space-xs: $tiny-spacer-sm;
$dropdown-space-sm: $spacer-xs;
$dropdown-active-bg-color: $coc-secondary-10L;
$dropdown-background-size-width: $basic-space*21rem;
$dropdown-background-size-height: $basic-space*36rem;

// -------------------------------------------------------
// adaptable-hero-banner
//
// -------------------------------------------------------
$adaptable-banner-height-small-mobile: $basic-space*350rem;
$adaptable-banner-height-mobile: $basic-space*400rem; //210
$adaptable-banner-height-sm: $basic-space*500rem;
$adaptable-banner-height-md:	$basic-space*625rem;
$adaptable-banner-height-lg:	$basic-space*750rem;
$adaptable-banner-comp-body-padding: $spacer-xxl;
$adaptable-banner-comp-body-padding-mobile: $spacer-md;
$adaptable-banner-title-lg: $basic-space*72rem;
$adaptable-banner-title-md: $basic-space*56rem;
$adaptable-banner-title-sm: $basic-space*40rem;


// -------------------------------------------------------
// Print
//
// -------------------------------------------------------

$print-body-min-width: $breakpoint-lg !default;
$print-border-color:   $border-color-medium;
$print-border-width:   $border-thin;
$print-border:         $print-border-width solid $print-border-color;
