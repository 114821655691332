$msv-breakpoint-xs: $breakpoint-xs;
$msv-breakpoint-s: $breakpoint-xs;
$msv-breakpoint-sd: $breakpoint-sm;
$msv-breakpoint-sm: $breakpoint-md - 1;
$msv-breakpoint-m: $breakpoint-md;
$msv-breakpoint-l: $breakpoint-lg;
$msv-breakpoint-xl: $breakpoint-xxl;

$msv-gutter-width-s: 16px;
$msv-gutter-width-m: 20px;
$msv-gutter-width-l: 60px;

$msv-container-max-width-s: $breakpoint-md;
$msv-container-max-width-m: $breakpoint-lg;
$msv-container-max-width-l: $breakpoint-xxl;

$msv-container-min-width-m: 769px;
