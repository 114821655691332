/* -------------------------------------------------------
   Start of _input-groups
   ------------------------------------------------------- */

// This is used particularly for the sub-search pattern.
// Its use could be extended for anything where there is a
// form-control and a button to the right of it.
.cui.input-group {
	@include flexbox();

	.form-control {
		border-radius: $default-border-radius 0 0 $default-border-radius;
		-webkit-box-flex: 1 1 auto;
		-webkit-flex: 1 1 auto;
		-ms-flex: 1 1 auto;
		-moz-flex: 1 1 auto;
		flex: 1 1 auto;

		&:focus {
			position: relative;
			z-index: $focus-z;
		}
	}

	[class*="btn"] {
		border-radius: 0 $default-border-radius $default-border-radius 0;
		@include flex-shrink (0);// Prevents shrinking and text wrapping
	}
}
