/* -------------------------------------------------------
   Start of _data-display-widget
   ------------------------------------------------------- */
.cui.content-lookup {
	padding: $spacer-sm $spacer-md;

	.details {
		color: $coc-default-color;
		font-size: $base-font-size;
		font-weight: $font-weight-normal;
		margin: 0;
		padding-top: $spacer-xxs;
		padding-bottom: $spacer-sm;
	}

	// pagination
	nav {
		display: grid;
	}

	table.cui {
		margin-top: $spacer-sm;
		margin-bottom: $spacer-sm;
	}

	.table-responsive-wrapper .overlay.left {
		@include gradient-x( $data-display-gradient-color-opaque, $data-display-gradient-color-transparent );
	}

	.table-responsive-wrapper .overlay.right {
		@include gradient-x( $data-display-gradient-color-transparent, $data-display-gradient-color-opaque );
	}

	.pagination {
		margin-top: $spacer-xs;
		margin-bottom: $spacer-xs;
	}

	@media( max-width: $breakpoint-xs-max ) {
		.cui[class*="btn"] {
			float: left;

			+ .cui[class*="btn"] {
				// Ensure buttons are on their own line
				clear: left;
				// Add spacing between buttons
				margin-top: $spacer-xxs;
			}
		}
	}

	@media( min-width: $breakpoint-lg ) {
		@include align-items(center);

		.details {
			-webkit-box-flex: 1;
			-webkit-flex: 1;
			-ms-flex: 1;
			-moz-flex: 1;
			flex: 1;// Allow the details element to take up the entire space minus the cicon
		}
	}

	// themes
	// The default is medium grey
	&.bg-medium {
		border: $border-thin solid $border-color-medium !important;
	}
}
