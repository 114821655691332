@import 'coc-styles';
@import 'base';
@import 'placeholders';
@import 'headings';
@import 'text';
@import 'links';
@import 'lists';
@import 'buttons';
@import 'tables';
@import 'forms';
@import 'spinner';
@import 'images';
@import 'colours';
@import 'cicon';
@import 'svg-images';
@import 'utilities';
@import 'animate';
@import 'animations-transitions';

// Components
@import 'components/header';
@import 'components/menu-mega';
@import 'components/menu-mega-simplified';
@import 'components/menu-secondary';
@import 'components/banners';
@import 'components/content-block';
@import 'components/block-banner';
@import 'components/accordions';
@import 'components/tabs';
@import 'components/card';
@import 'components/tooltip';
@import 'components/catalogue-group';
@import 'components/search';
@import 'components/footer';
@import 'components/alert-banner';
@import 'components/breadcrumb';
@import 'components/icon-label';
@import 'components/modal';
@import 'components/input-groups';
@import 'components/widget';
@import 'components/cta-block';
@import 'components/wordmark';
@import 'components/pagination';
@import 'components/text-on-image';
@import 'components/linear-navigation-footer';
@import 'components/image-viewer';
@import 'components/application-components';
@import 'components/page-title-block';
@import 'components/bar-chart';
@import 'components/parallax-banner';
@import 'components/icon-subject-layout';
@import 'components/data-display-widget';
@import 'components/contact-info-block';
@import 'components/map';
@import 'components/duration-block';
@import 'components/card-slider';
@import 'components/background-image-block';
@import 'components/search-image-block-container';
@import 'components/toggle-container';
@import 'components/adaptable-hero-banner';

// Deprecated
//@import "components/content-block-legacy";// MC.2019.03.28: Commenting out as it's been deprecated since 1.6
@import 'components/tile';

// Dev
//@import "dev/card-with-list-links";// MC.2019.03.28: The styles for this are completely ready but it isn't known if this pattern will be used going forward or not.

// Print
@import 'print';

// Credits
@import 'credits';
